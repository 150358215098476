/**
 * Vue composable for the creating and managing the echarts instance.
 * Functionality:
 * - Installations of the Apache ECharts library
 * - Creates the echarts instance
 * - Bind the echarts instance to the DOM element
 * - Provides the echarts instance to the template
 * - On demand charts component registration (Importing Required Charts and Components to Have Minimal Bundle Size)
 */

import * as echarts from 'echarts/core';
import {onUnmounted, ref} from 'vue';
import {EventBus} from "@common/lib/event-bus";

/**
 * @param options
 * @param chartTypes
 * @returns {{setup: init, chartRef: null}}
 */
export default function useEcharts(options, chartTypes = []) {
    const chartRef = ref(null);

    /**
     * The setup function that initializes the ECharts instance and registers the required charts and components.
     *
     * @param {Object} options The ECharts options object.
     * @param {Boolean} notMerge Whether to merge with the previous options or not.
     */
    const init = (options = {}, notMerge = true) => {
        // Import the required charts and components to have a minimal bundle size
        // Read more: https://echarts.apache.org/handbook/en/basics/import/#importing-required-charts-and-components-to-have-minimal-bundle
        import('echarts/charts').then((charts) => {
            import('echarts/components').then((components) => {
                import('echarts/renderers').then((renderers) => {
                    import('echarts/features').then((features) => {

                        const chartsToInstall = []
                        // Check the chartTypes array to install the required charts
                        if (chartTypes.includes('bar')) {
                            chartsToInstall.push([charts.BarChart])
                        }
                        if (chartTypes.includes('line')) {
                            chartsToInstall.push([charts.LineChart])
                        }
                        if (chartTypes.includes('scatter')) {
                            chartsToInstall.push([charts.ScatterChart])
                        }
                        if (chartTypes.includes('pie')) {
                            chartsToInstall.push([charts.PieChart])
                        }
                        if (chartTypes.includes('custom')) {
                            chartsToInstall.push([charts.CustomChart])
                        }

                        // Register the required charts, components and renderers
                        echarts.use([
                            ...chartsToInstall,
                            components.TitleComponent,
                            components.TooltipComponent,
                            components.LegendComponent,
                            components.GridComponent,
                            components.DataZoomComponent,
                            components.VisualMapComponent,
                            components.MarkLineComponent,
                            features.UniversalTransition,
                            renderers.SVGRenderer,])

                        if (chartRef.value && options) {
                            // disposing the chart instance if it already exists.
                            echarts?.dispose(chartRef.value);
                            const chartInstance = echarts.init(chartRef.value, 'light', {renderer: 'svg'});
                            chartInstance.setOption(options, notMerge);

                            chartInstance.on('click', (params, ec) => {

                                if(params?.seriesId?.includes("PostEngagementChart")){
                                    EventBus.$emit('fetch-engagement-data', {
                                        name: params.value[4],
                                        id: params.value[0],
                                        type: 'echarts'
                                    })
                                    return
                                }

                                let value = null
                                if(params?.seriesId?.includes("allTypesGraph")){
                                    const validTypes = [
                                        'CAROUSEL ALBUM',
                                        'IMAGE',
                                        'VIDEO REEL',
                                        'VIDEO',
                                    ]
                                    value = validTypes.includes(params.name)
                                        ? params.name
                                        : validTypes.includes(params.value)
                                            ? params.value
                                            : null
                                }
                                if (value) {
                                    EventBus.$emit('chart-type', value)
                                }
                            })

                            window.addEventListener('resize', function () {
                                chartInstance.resize();
                            })
                        }
                    })
                })
            })
        })
    }

    onUnmounted(() => {
        if (chartRef.value) {
            echarts?.dispose(chartRef.value);
        }
        window.removeEventListener('resize', function () {
            chartRef.value?.resize();
        })
    })

    return {
        chartRef,
        setup: init
    };
}
