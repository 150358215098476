/**
 *  This file is composable conversion of HelperMixin (src/modules/common/mixins/utils/helperMixin.js)
 *  You can move required methods from HelperMixin to this file and use it in your components
 */

const createHmac = require('create-hmac')

const KEY =
  '589db1163efbe7fd8eb102a8dd311115e85b938a6ef1b56a591f5be1e89756cf773d0bf8f49e5535e891cc7648ac25c6d2a755309699fbdd30cae354ff633775'
const SALT =
  '15bf311c00e8619b90f393603b4f248165eb816e040ab329339be22c978a4199cfe7a17687eee52aab3cdf1c8f5775c0febb92e33ca6829f2182a3376023424c'
const RESIZED_API_URL = 'https://image-proxy.cstuinternal.com/'

export default function useNumber() {
  // We are not using signed resized URL because it's too slow. Will you it once we will be able to reduced it's waiting time

  /**
   * Generates a signed and resized image URL.
   *
   * @param {string} url - The original image URL.
   * @param {number} [width=0] - The desired width of the resized image.
   * @param {number} [height=450] - The desired height of the resized image.
   * @param {string} [resizingType='auto'] - The type of resizing to apply.
   * @param {string} [gravity='sm'] - The gravity or focus point for the resizing.
   * @param {number} [enlarge=0] - Whether to allow enlarging the image.
   * @param {string} [extension='png'] - The desired image extension.
   * @returns {string} - The signed and resized image URL.
   */
  function getSignedResizedImageURL(
    url,
    width = 0,
    height = 450,
    resizingType = 'auto',
    gravity = 'sm',
    enlarge = 0,
    extension = 'png'
  ) {
    // encodedUrl to Base64
    const urlSafeBase64 = (string) => {
      return Buffer.from(string)
        .toString('base64')
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
    }

    // Signing the URL and Calculating the HMAC digest using SHA256
    const sign = (salt, target, secret) => {
      const hexDecode = (hex) => Buffer.from(hex, 'hex')

      const hmac = createHmac('sha256', hexDecode(secret))
      hmac.update(hexDecode(salt))
      hmac.update(target)
      return urlSafeBase64(hmac.digest())
    }

    // Check if the image is gif
    if (/.gif/gi.test(url)) {
      return url
    } else {
      const encodedUrl = urlSafeBase64(url)
      const path = `/${resizingType}/${width}/${height}/${gravity}/${enlarge}/${encodedUrl}.${extension}`

      const signature = sign(SALT, path, KEY)
      return `${RESIZED_API_URL}${signature}${path}`
    }
  }

  /**
   * Generates a resized image URL based on the provided parameters.
   *
   * @param {string} url - The original image URL.
   * @param {number} [width=0] - The desired width of the resized image.
   * @param {number} [height=450] - The desired height of the resized image.
   * @param {string} [resizingType='auto'] - The type of resizing to apply.
   * @param {string} [gravity='sm'] - The gravity or focus point for the resizing.
   * @param {number} [enlarge=0] - Whether to allow enlarging the image beyond its original size.
   * @returns {string} - The URL of the resized image or the original URL if resizing is not applicable.
   */
  function getResizedImageURL(
    url,
    width = 0,
    height = 450,
    resizingType = 'auto',
    gravity = 'sm',
    enlarge = 0
  ) {
    // Check if the image is gif
    try {
      let domain = new URL(url)
      domain = domain.hostname

      if (/.gif/gi.test(url)) {
        return url
      } else {
        if (domain !== 'storage.googleapis.com') {
          return this.getSignedResizedImageURL(url, width, height)
        } else {
          return `https://image-proxy.cstuinternal.com/contentstudio/${resizingType}/${width}/${height}/${gravity}/${enlarge}/plain/${url}`
        }
      }
    } catch (e) {
      // console.error(e)
      return url
    }
  }

  /**
   * Validates an email address using a regular expression.
   *
   * This function checks if the provided email address matches
   * the standard pattern for emails.
   *
   * @param {string} email - The email address to validate.
   * @returns {boolean} - Returns true if the email is valid, else false.
   */
  function validateEmail(email) {
    // Regular expression pattern for email validation
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    // Test the email against the regular expression
    return re.test(email)
  }
  return {
    getResizedImageURL,
    getSignedResizedImageURL,
    validateEmail
  }
}
