<script setup>
import { computed, watch } from 'vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/tiktok/components/graphs/MainGraph.vue'

const props = defineProps({
  label: {
    type: String,
    default: 'Engagements',
  },
  defaultType: {
    type: String,
    default: 'line',
    validator: (v) => {
      return ['line', 'bar'].includes(v)
    },
  },
})

const {
  multipleSeriesLineChartOptions: lineChartOptions,
  legendOptions,
  topPerformingGraphData: engagementsData,
  PLATFORM_TITLE_AND_COLOR,
  isReportView,
  dataZoomOptions,
  isNoAnalyticsData,
} = useOverviewAnalytics(props.label)

const isNoData = computed(() => {
  return isNoAnalyticsData(engagementsData)
})

const chartTypes = [
  {
    name: PLATFORM_TITLE_AND_COLOR.facebook.title,
    api_response_key:'fb_engagement' ,
    color: PLATFORM_TITLE_AND_COLOR.facebook.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.instagram.title,
    api_response_key: 'insta_engagement',
    color: PLATFORM_TITLE_AND_COLOR.instagram.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.linkedin.title,
    api_response_key: 'linkedin_engagement',
    color: PLATFORM_TITLE_AND_COLOR.linkedin.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.pinterest.title,
    api_response_key: 'pinterest_engagement',
    color: PLATFORM_TITLE_AND_COLOR.pinterest.color,
  },
  {
    name: PLATFORM_TITLE_AND_COLOR.tiktok.title,
    api_response_key: 'tiktok_engagement',
    color: PLATFORM_TITLE_AND_COLOR.tiktok.color,
  },
]

watch(
  () => engagementsData,
  async () => {
    lineChartOptions.value.legend = {
      ...legendOptions,
      data: chartTypes?.map((chartType) => chartType.name),
    }

    lineChartOptions.value.series = []

    const options = engagementsData.value || {}
    const xAxisData = options?.buckets

    // line chart options
    chartTypes.forEach((chartType) => {
      const apiKey = chartType?.api_response_key

      const lineSeries = {
      name: chartType.name,
      yAxisIndex: 0,
      type: 'line',
      data: options[apiKey] || [],
      color: chartType.color,
      areaStyle: { opacity: 0.3, cursor: 'auto'},
      lineStyle: { opacity : 0.6 },
      cursor: 'auto',
      showSymbol: true,
      symbolSize: 8,
      colorBy: 'series'
    }

      lineChartOptions.value.series.push(lineSeries)
    })

    lineChartOptions.value.xAxis.data = xAxisData
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper type="Engagements-Graph" :enable-modal="true">
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <h2 class="text-base font-weight-500 select-none">
              Engagements Across Network
            </h2>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  Inspect the day-to-day engagement metrics (Pin clicks,
                  outbound clicks and saves) received each day during the
                  selected time period, regardless of the post publication date.
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <MainGraph
          v-if="!isNoData"
          :key="Math.random()"
          type="line"
          :chart-options="
            slotProps.isModal
              ? {
                  ...lineChartOptions,
                  ...dataZoomOptions,
                  grid: { ...lineChartOptions.grid, bottom: 70 },
                }
              : lineChartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p>No Pins data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
