<template>
  <b-modal
      id="twitter_job_settings"
      dialog-class="flex justify-center items-center"
      centered
      hide-footer
      hide-header
      size="md"
      @hidden="resetForm"
  >
    <!-- Modal Header -->
    <div class="modal_head">
      <h4 class="text-lg font-semibold">X (Twitter) Data Sync Schedule</h4>
      <v-menu
          class="pt-1"
          :popper-triggers="['hover']"
          placement="top"
          popper-class="first-comment__info-popover"
          :delay="300"
      >
        <i class="far fa-question-circle p-0 mx-2 cursor-pointer"></i>
        <template v-slot:popper>
          <p class="text-sm text-gray-900">
            Configure the schedule to automatically fetch the latest X (Twitter)
            analytics data.
          </p>
        </template>
      </v-menu>
      <button
          type="button"
          class="modal_head__close bg-transparent"
          data-dismiss="modal"
          @click="handleModalClose"
      >&times;
      </button>
    </div>
    <!-- Modal Body -->
    <div class="modal_body">
      <!-- Job Type Select -->
      <div class="field_group py-2 px-8">
        <label class="block font-semibold mb-2">Sync Frequency</label>
        <CstDropdown
            class="w-full"
            size="small"
            button-classes="flex rounded-xl w-full"
            container-classes="max-h-[10rem]"
        >
          <template v-slot:selected>
            <span class="flex flex-row w-full items-center">
              <span
                  class="capitalize_text text-left inline-flex flex-col mx-0.5 justify-start"
              >
                {{ selectedJobType || 'Select Job Type' }}
              </span>
            </span>
          </template>
          <template v-slot>
            <CstDropdownItem @click="selectJobType('daily')">
              Daily
            </CstDropdownItem>
            <CstDropdownItem @click="selectJobType('weekly')">
              Weekly
            </CstDropdownItem>
            <CstDropdownItem @click="selectJobType('monthly')">
              Monthly
            </CstDropdownItem>
            <CstDropdownItem @click="selectJobType('never')">
              Never
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>

      <div v-if="selectedJobType === 'weekly'" class="field_group py-2 px-8">
        <label class="block font-semibold mb-2">Select Weekday</label>
        <CstDropdown
            class="w-full"
            size="small"
            button-classes="flex rounded-xl w-full"
            container-classes="max-h-[10rem]"
        >
          <template v-slot:selected>
            <span class="flex flex-row w-full items-center">
              <span
                  class="capitalize_text text-left inline-flex flex-col mx-0.5 justify-start"
              >
                {{ selectedWeekday || 'Select Weekday' }}
              </span>
            </span>
          </template>
          <template v-slot>
            <CstDropdownItem
                v-for="weekday in weekdays"
                :key="weekday"
                @click="selectWeekday(weekday)"
            >
              {{ weekday }}
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>
      <div v-if="selectedJobType === 'monthly'" class="field_group py-2 px-8">
        <label class="block font-semibold mb-2">Date of month</label>
        <CstDropdown
            class="w-full"
            size="small"
            button-classes="flex rounded-xl w-full"
            container-classes="max-h-[10rem]"
        >
          <template v-slot:selected>
            <span class="flex flex-row w-full items-center">
              <span
                  class="capitalize_text text-left inline-flex flex-col mx-0.5 justify-start"
              >
                {{ selectedDate || 'Select Date' }}
              </span>
            </span>
          </template>
          <template v-slot>
            <CstDropdownItem
                v-for="date in dates"
                :key="date"
                @click="selectDate(date)"
            >
              {{ date }}
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>
      <!-- Post Count -->
      <div v-if="selectedJobType !== 'never'" class="field_group py-2 px-8">
        <div class="flex items-center">
          <label class="font-semibold mb-2"> Tweets to Process </label>
          <v-menu
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">
                Select the number of latest tweets to be processed during each
                sync. Higher counts consume more monthly credits.
              </p>
            </template>
          </v-menu>
        </div>

        <CstDropdown
            class="w-full"
            size="small"
            button-classes="flex rounded-xl w-full"
            container-classes="max-h-[10rem]"
        >
          <template v-slot:selected>
            <span class="flex flex-row w-full items-center">
              <span
                  class="capitalize_text text-left inline-flex flex-col mx-0.5 justify-start"
              >
                {{ selectedPostCount || 'Select Post Count' }}
              </span>
            </span>
          </template>
          <template v-slot>
            <CstDropdownItem
                v-for="count in postCounts"
                :key="count"
                @click="selectPostCount(count)"
            >
              {{ count }}
            </CstDropdownItem>
          </template>
        </CstDropdown>
      </div>
      <!-- Modal Footer -->
      <div class="field_group float-right pt-8 pb-4 mr-8">
        <CstButton
            class="w-24 mx-2"
            variant="outlined"
            @click="handleModalClose"
        >
          Cancel
        </CstButton>
        <CstButton
            class="w-24 mx-2 text-nowrap"
            variant="primary"
            :loading="isLoading"
            :disabled="!isFormValid || isLoading"
            @click="handleSubmit"
        >
          {{ computedButtonLabel }}
        </CstButton>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import {computed, inject, ref, watch} from 'vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstButton from '@/src/components/UI/Button/CstButton.vue'
import useTwitterAnalytics from '@/src/modules/analytics/views/twitter/composables/useTwitterAnalytics'

const { platformJob, createTwitterJob, updateTwitterJob } =
    useTwitterAnalytics()

const root = inject('root')
const { $bvModal } = root

// States
const weekdays = ref([
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
])
// generate dates from 1 to 30
const dates = ref(Array.from({ length: 30 }, (_, i) => i + 1))
const postCounts = ref([10, 20, 30, 50, 80, 100, 120, 150])

const jobExists = ref(null)
const selectedJobType = ref(null)
const selectedWeekday = ref(null)
const selectedDate = ref(null)
const selectedPostCount = ref(null)
const isLoading = ref(false)

// watcher helper
const resetForm = () => {
  const {_id, job_type, trigger_day, post_count} = platformJob.value || {
    job_type: 'weekly',
    trigger_day: 1,
    post_count: 40,
  };

  jobExists.value = _id || null;
  selectedJobType.value = job_type || null;
  selectedWeekday.value = job_type === 'weekly' ? weekdays.value[trigger_day - 1] : null;
  selectedDate.value = job_type === 'monthly' ? trigger_day : null;
  selectedPostCount.value = post_count || null;
};

// Computed
const computedButtonLabel = computed(() =>
    jobExists.value ? 'Update' : 'Create'
)
const isFormValid = computed(() => {
  return (
      selectedPostCount.value !== null &&
      (selectedJobType.value === 'daily' ||
          (selectedJobType.value === 'weekly' && selectedWeekday.value !== null) ||
          (selectedJobType.value === 'monthly' && selectedDate.value !== null) ||
          selectedJobType.value === 'never')
  )
})

// Watchers
watch(platformJob, resetForm)

// Methods
const selectJobType = (jobType) => {
  selectedJobType.value = jobType
}
const selectPostCount = (count) => {
  selectedPostCount.value = count
}
const selectWeekday = (weekday) => {
  selectedWeekday.value = weekday
}
const selectDate = (date) => {
  selectedDate.value = date
}

/**
 * Handles the form submission for the Twitter job settings modal.
 * Updates or creates a Twitter job based on the form inputs.
 * Hides the modal after the submission is complete.
 */
const handleSubmit = async () => {
  isLoading.value = true

  // Map the trigger index based on the selected job type
  const triggerIndexMap = {
    weekly: Math.max(weekdays.value.indexOf(selectedWeekday.value) + 1, 0),
    monthly: selectedDate.value,
  }

  // Prepare the job settings object
  const jobSettings = {
    job_type: selectedJobType.value,
    trigger_day: triggerIndexMap[selectedJobType.value]?.toString() || '1',
    post_count: selectedPostCount.value,
  }

  // Update or create the Twitter job based on jobExists value
  if (jobExists.value) {
    await updateTwitterJob(jobSettings)
  } else {
    await createTwitterJob(jobSettings)
  }

  isLoading.value = false
  $bvModal.hide('twitter_job_settings')
}

const handleModalClose = () => {
  $bvModal.hide('twitter_job_settings')
}
</script>
