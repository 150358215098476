<script setup>
import { computed } from 'vue'
import useWhiteLabelApplication from '@modules/setting/composables/whitelabel/useWhiteLabelApplication'
import CSLogo from '@modules/account/assets/img/logo/contentstudio-logo-original.png'

const { isWhiteLabelDomain, appData } = useWhiteLabelApplication()

const logoSrc = computed(() => {
  return isWhiteLabelDomain.value
    ? appData.value.brandLogo
      ? appData.value.brandLogo
      : ''
    : CSLogo
})
</script>

<template>
  <div>
    <img v-if="logoSrc" :src="logoSrc" alt="ContentStudio" width="150" class="object-contain max-h-[4rem]" />
  </div>
</template>
