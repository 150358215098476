<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import { EventBus } from '@common/lib/event-bus'
import useHelper from '@common/composables/useHelper'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import textHelper from '@common/lib/text-helper'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'

const store = useStore()

const { validateEmail } = useHelper()

const {
  getSocialImageRounded,
  analyticsEnabledTwitterAccounts,
  getAllPinterestProfiles,
} = useComposerHelper()

const reportTypeDetail = {
  export:{
    title: 'Export Report',
    action:'render',
    buttonText: 'Export',
  },
  email:{
    title: 'Send PDF as Email',
    action:'email',
    buttonText: 'Send',
  },
  schedule:{
    title: 'Schedule PDF as Email',
    buttonText: 'Schedule',
  }
}
const optionsDropdown = [
  {
    id: 'overview_single_pdf',
    value: 'single-pdf-overview',
    title: 'Overview layout in single PDF',
    description: 'Generate a PDF with a high-level summary of insights from all selected social accounts for quick performance assessment.',
    type: 'overview'
  },
  {
    id: 'overview_multiple_pdf',
    value: 'multiple-pdf-overview',
    title: 'Overview layout in multiple PDFs',
    description: 'Generate a PDF with detailed insights for each social account, providing a clear breakdown for deeper analysis.',
    type: 'overview'
  },
  {
    id: 'platform_single_pdf',
    value: 'single-pdf-detailed',
    title: 'Platform specific layout in single PDF',
    description: 'Generate a PDF with a high-level summary of insights from all selected social accounts for quick performance assessment.',
    type: 'platform'

  },
  {
    id: 'platform_multiple_pdf',
    value: 'multiple-pdf-detailed',
    title: 'Platform specific layout in multiple PDFs',
    description: 'Generate multiple PDFs with platform-specific insights from all selected social accounts for deeper analysis.',
    type: 'platform'

  }
];

const selectedOptionType = ref()

const reportPayload = ref({
    accounts: [],
    type: '',
    date: '',
    interval: '',
    action: '',
    platform_type: '',
    topPosts: 10,
    allAccountsSelected: false,
    labels: [],
    campaigns: [],
    workspaceId:''
})

const accounts = ref([])
const selectedAccounts = ref([])
const individualSelectedAccounts = ref([])
const network = ref(null)
const allSelected = ref(null)
const selectedReportMode = ref('')

// Vuex getters as computed properties
const getReportsServiceCalls = computed(
  () => store.getters.getReportsServiceCalls
)
const getScheduledReportItem = computed(
  () => store.getters.getScheduledReportItem
)
const getFacebookPagesList = computed(
  () => store.getters.getFacebookPagesList
)
const getInstagramAccounts = computed(
  () => store.getters.getInstagramAccounts
)
const getTiktokAccounts = computed(() => store.getters.getTiktokAccounts)
const getYoutubeAccounts = computed(() => store.getters.getYoutubeAccounts)
const getPinterestBoards = computed(() => store.getters.getPinterestBoards)
const getLinkedinCompanyPages = computed(
  () => store.getters.getLinkedinCompanyPages
)
const getProfile = computed(() => store.getters.getProfile)

const getSelectedType = computed(() => {
  return selectedOptionType.value?.title
})
// Additional computed properties
const getSocialAccounts = computed(() => {
  if (network.value === 'campaign-and-label') {
    return accounts.value
  }
  return accounts.value?.filter((account) => filterAccounts(account))
})

const isPinterestAccountAnalytics = computed(
  () => network.value === 'pinterest'
)

const isTwitterAnalytics = computed(() => network.value === 'twitter')

const isGroupOrIndividual = computed(
  () => network.value === 'group' || network.value === 'individual'
)
const isModeExport = computed(
  () => selectedReportMode.value === 'export'
)
const isModeEmail = computed(
  () => selectedReportMode.value === 'email'
)
const getModalTitle = computed(() => {
  return reportTypeDetail[selectedReportMode.value]?.title
})
const getButtonText = computed(() => {
  return reportTypeDetail[selectedReportMode.value]?.buttonText
})

// Watchers
watch(getScheduledReportItem, () => {
  if (selectedAccounts.value) {
    allSelected.value =
      selectedAccounts.value.length === accounts.value.length &&
      selectedAccounts.value.length > 0
  } else if (individualSelectedAccounts.value) {
    allSelected.value =
      individualSelectedAccounts.value?.length ===
        getSocialAccounts.value?.length &&
      individualSelectedAccounts.value.length > 0
  }
})

watch(selectedAccounts, () => {
  if (selectedAccounts.value.length) {
    allSelected.value =
      selectedAccounts.value.length === accounts.value.length &&
      selectedAccounts.value.length > 0
  }
})

watch(individualSelectedAccounts, () => {
  allSelected.value =
    individualSelectedAccounts.value?.length ===
      getSocialAccounts.value?.length &&
    individualSelectedAccounts.value.length > 0
})

// Lifecycle hooks
onBeforeUnmount(() => {
  EventBus.$off('schedule-report')
  EventBus.$off('edit-schedule-report')
  EventBus.$off('export-report-overview')
  EventBus.$off('send-report-by-email')
})

onMounted(() => {
  EventBus.$on(
    'schedule-report',
    ({ accounts: eventAccounts, network: eventNetwork, topPosts, labels, campaigns }) => {
      selectedReportMode.value = 'schedule'
      getScheduledReportItem.value.topPosts = topPosts
      getScheduledReportItem.value.labels = labels
      getScheduledReportItem.value.campaigns = campaigns
      accounts.value = populateAccounts()
      store.commit('SET_SCHEDULED_REPORT_ITEM_ACCOUNTS', eventAccounts)
      store.commit('SET_SCHEDULED_REPORT_PLATFORM_TYPE', eventNetwork)
      store.commit(
        'SET_SCHEDULED_REPORT_ITEM_TYPE',
        eventNetwork === 'group' ? 'group' : 'individual'
      )
      network.value = eventNetwork
      if (
        network.value === 'group' ||
        network.value === 'individual' ||
        network.value === 'campaign-and-label'
      ) {
        selectedAccounts.value = individualSelectedAccounts.value =
          eventAccounts.map((account) => getAccountId(account))
      } else {
        if (getAccountId(eventAccounts))
          individualSelectedAccounts.value = [getAccountId(eventAccounts)]
      }
    }
  )

  EventBus.$on('edit-schedule-report', ({ reportData }) => {
    selectedReportMode.value = 'schedule'

    if (Object.hasOwn(reportData, 'platform_type')) {
      network.value = reportData.platform_type
    } else {
      if (reportData.type === 'group') {
        network.value = 'group'
        store.commit('SET_SCHEDULED_REPORT_PLATFORM_TYPE', 'group')
      } else if (reportData.type === 'individual') {
        network.value = 'individual'
        store.commit('SET_SCHEDULED_REPORT_PLATFORM_TYPE', 'individual')
      } else {
        network.value = reportData.type
        store.commit('SET_SCHEDULED_REPORT_PLATFORM_TYPE', reportData.type)
        store.commit('SET_SCHEDULED_REPORT_ITEM_TYPE', 'individual')
      }
    }
    accounts.value = populateAccounts()
    store.commit(
      'SET_SCHEDULED_REPORT_ITEM_ACCOUNTS',
      network.value === 'group' || network.value === 'individual'
        ? accounts.value
        : accounts.value.filter(
            (account) => account.account_type === network.value
          )
    )

    if (network.value === 'group') {
      selectedAccounts.value = reportData.accounts.map((account) =>
        getAccountId(account)
      )
    } else {
      individualSelectedAccounts.value = reportData.accounts.map((account) =>
        getAccountId(account)
      )
    }
  })

  EventBus.$on(
    'send-report-by-email',
    ({
      accounts: eventAccounts = [],
      network: eventNetwork = null,
      startDate = '',
      endDate = '',
      topPosts = 10,
      allAccountsSelected = false,
      labels = [],
      campaigns = [],
    }) => {
      selectedReportMode.value = 'email'
      accounts.value = populateAccounts()
      network.value = eventNetwork
      store.commit('SET_SCHEDULED_REPORT_ITEM_TYPE', eventNetwork)
      if (network.value === 'group' || network.value === 'individual') {
        selectedAccounts.value = individualSelectedAccounts.value = eventAccounts.map(
          (account) => getAccountId(account)
        )
      } else {
        if (getAccountId(eventAccounts)) {
          individualSelectedAccounts.value = [getAccountId(eventAccounts)]
        }
      }
      reportPayload.value = {
        accounts: eventAccounts,
        type: eventNetwork,
        date: `${startDate} - ${endDate}`,
        action: reportTypeDetail.email.action,
        platform_type: eventNetwork,
        topPosts,
        allAccountsSelected,
        labels,
        campaigns,
        workspaceId: store.getters?.getActiveWorkspace?._id,
      }
    }
  );

  EventBus.$on(
    'export-report-overview',
    ({
      accounts: eventAccounts = [],
      network: eventNetwork = null,
      startDate = '',
      endDate = '',
      topPosts = 10,
      allAccountsSelected = false,
      labels = [],
      campaigns = [],
    }) => {
      selectedReportMode.value = 'export'
      accounts.value = populateAccounts()
      network.value = eventNetwork
      store.commit('SET_SCHEDULED_REPORT_ITEM_TYPE', eventNetwork)

      if (network.value === 'group' || network.value === 'individual') {
        selectedAccounts.value = individualSelectedAccounts.value = eventAccounts.map(
          (account) => getAccountId(account)
        )
      } else {
        if (getAccountId(eventAccounts)) {
          individualSelectedAccounts.value = [getAccountId(eventAccounts)]
        }
      }

      reportPayload.value = {
        accounts: eventAccounts,
        type: eventNetwork,
        date: `${startDate} - ${endDate}`,
        action: reportTypeDetail.export.action,
        platform_type: eventNetwork,
        topPosts,
        allAccountsSelected,
        labels,
        campaigns,
        workspaceId: store.getters?.getActiveWorkspace?._id,
      }
    }
  );
})

// Methods
function reset() {
  store.commit('RESET_SCHEDULED_REPORT_ITEM')
  selectedAccounts.value = []
  accounts.value = []
  individualSelectedAccounts.value = []
  allSelected.value = null
  network.value = null
  selectedOptionType.value = null
  reportPayload.value = {
    accounts: [],
    type: null,
    date: '',
    interval: 'monthly',
    action: reportTypeDetail.email.action,
    platform_type: null,
    topPosts: 10,
    allAccountsSelected: false,
    labels: [],
    campaigns: [],
    workspaceId: store.getters?.getActiveWorkspace?._id,
  }
}

function populateAccounts() {
  const accountsList = []
  getFacebookPagesList.value?.forEach((item) => {
    item.account_type = 'facebook'
    accountsList.push(item)
  })
  analyticsEnabledTwitterAccounts.value?.forEach((item) => {
    item.account_type = 'twitter'
    accountsList.push(item)
  })
  getAllPinterestProfiles()?.forEach((item) => {
    item.account_type = 'pinterest'
    item.board_id = item.profile_id
    accountsList.push(item)
  })
  getPinterestBoards.value?.forEach((item) => {
    item.account_type = 'pinterest'
    accountsList.push(item)
  })
  getLinkedinCompanyPages.value?.forEach((item) => {
    item.account_type = 'linkedin'
    accountsList.push(item)
  })
  getInstagramAccounts.value?.items?.forEach((item) => {
    item.account_type = 'instagram'
    accountsList.push(item)
  })
  getTiktokAccounts.value?.items?.forEach((item) => {
    item.account_type = 'tiktok'
    accountsList.push(item)
  })
  getYoutubeAccounts.value?.items?.forEach((item) => {
    item.account_type = 'youtube'
    accountsList.push(item)
  })
  return accountsList
}

async function scheduleReport() {
  let accountsToSet = []

  if (network.value === 'group') {
    accountsToSet = selectedAccounts.value
      .map((accountId) =>
        accounts.value.find(
          (account) => accountId === getAccountId(account)
        )
      )
      .filter(Boolean)
  } else {

    if (!selectedOptionType.value?.value && !['campaign-and-label'].includes(network.value)) {
      store.dispatch('toastNotification', {
        message: 'Please select a report type',
        type: 'error',
      })
      return
    }
    store.commit('SET_SCHEDULED_REPORT_ITEM_TYPE', selectedOptionType.value?.value)


    accountsToSet = individualSelectedAccounts.value
      .map((accountId) =>
        accounts.value.find(
          (account) => accountId === getAccountId(account)
        )
      )
      .filter(Boolean)
  }

  store.commit('SET_SCHEDULED_REPORT_ITEM_ACCOUNTS', accountsToSet)
  const response = await store.dispatch(
    'scheduleReportsService',
    getScheduledReportItem.value
  )
  if (response?.name) reset()
}

async function exportReport() {
  // use renderReportsService
  let accountsToSet = []

    if (network.value === 'group') {
      accountsToSet = selectedAccounts.value
        .map((accountId) =>
          accounts.value.find(
            (account) => accountId === getAccountId(account)
          )
        )
        .filter(Boolean)
    } else {
      accountsToSet = individualSelectedAccounts.value
        .map((accountId) =>
          accounts.value.find(
            (account) => accountId === getAccountId(account)
          )
        )
        .filter(Boolean)
    }
    // selectedOptionType.value?.value is empty then thow notifcation and return

    if (!selectedOptionType.value?.value && !['group','campaign-and-label'].includes(network.value)) {
      store.dispatch('toastNotification', {
        message: 'Please select a report type',
        type: 'error',
      })
      return
    }

  // if indiudal then we dont need array buffer
  const download = network.value === 'group';

  const response = await store.dispatch(
    'renderReportsService',
    {
      ...reportPayload.value,
       workspaceId: store.getters?.getActiveWorkspace?._id,
       type: !['group','campaign-and-label'].includes(network.value) ? selectedOptionType.value?.value : undefined,
       platform_type: network.value,
       accounts: accountsToSet,
    },
    download
  )
  console.log('response', response.data)
  if (download){
    const url = window.URL.createObjectURL(
      new Blob([response.data], { type: 'application/pdf' })
    )
    const link = document.createElement('a')
    link.href = url

    const fileName =
      textHelper.ucFirst(network.value) +
    ` Report (${textHelper.ucFirst(
      store.getters?.getActiveWorkspace.slug
    )}) - ${reportPayload.value?.date}.pdf`

    link.setAttribute('download', fileName)
    link.setAttribute('target', '_blank')
    link.download = fileName
    document.body.appendChild(link)
    link.click()

    // Clean up the DOM by removing the link
    document.body.removeChild(link)
    // eslint-disable-next-line no-undef
    $('#scheduleReport').modal('hide')
    reset()
  }
  else if (response?.status) {
    // eslint-disable-next-line no-undef
    $('#scheduleReport').modal('hide')
    reset()
    store.dispatch('toastNotification', {
        message: `Your report is being generated. You will receive a notification once the report is ready. You can track the progress in the <a onclick="redirectUsingVueRoute('download_reports_v3')" href="#">Download Reports</a> section.`,
        type: 'success',
     })
    // hide modal
  }
  else {
    store.dispatch('toastNotification', {
        message: 'Something went wrong',
        type: 'error',
      })
  }
}

async function emailReport() {
  let accountsToSet = []

  if (network.value === 'group') {
    accountsToSet = selectedAccounts.value
      .map((accountId) =>
        accounts.value.find(
          (account) => accountId === getAccountId(account)
        )
      )
      .filter(Boolean)
  } else {
    accountsToSet = individualSelectedAccounts.value
      .map((accountId) =>
        accounts.value.find(
          (account) => accountId === getAccountId(account)
        )
      )
      .filter(Boolean)
  }
  const fileName =
        textHelper.ucFirst(network.value) +
      ` Report (${textHelper.ucFirst(
        store.getters?.getActiveWorkspace.slug
      )}) - ${reportPayload.value?.date}.pdf`

  if(getScheduledReportItem.value?.email_list?.length === 0) {
    store.dispatch('toastNotification', {
      message: 'Please add email address where we should be sending the report.',
      type: 'error',
    })
    return
  }

  const response = await store.dispatch(
    'saveReportsService',
    {
      ...reportPayload.value,
      workspaceId: store.getters?.getActiveWorkspace?._id,
      type: network.value !== 'group' ? selectedOptionType.value?.value : undefined,
      platform_type: network.value,
      accounts: accountsToSet,
      email_list : getScheduledReportItem.value.email_list,
      file_name: fileName,
    }
  )
  console.log('response', response.status)
  if (response?.status) {
    // eslint-disable-next-line no-undef
    $('#scheduleReport').modal('hide')
    reset()
    // hide modal
  }
}


function onButtonClick () {
  switch (selectedReportMode.value) {
    case 'schedule' :
      scheduleReport()
      break
    case 'export':
      exportReport()
      break
    case 'email':
      emailReport()
      break
    default:
      break
  }
}

function addEmailToList(event) {
  let email = getScheduledReportItem.value.email
  if (event.key === ',') {
    email = email.substring(0, email.length - 1)
  }

  if (validateEmail(email)) {
    if (getScheduledReportItem.value.email_list.includes(email)) {
      store.dispatch('toastNotification', {
        message: 'Email address already added.',
        type: 'error',
      })
    } else {
      getScheduledReportItem.value.email_list.push(email)
      getScheduledReportItem.value.email = null
    }
  }
}

function removeEmailFromList(index) {
  getScheduledReportItem.value.email_list.splice(index, 1)
}

function copyEmailMyself() {
  if (getScheduledReportItem.value.copy_email_to_myself) {
    if (
      getScheduledReportItem.value.email_list.indexOf(
        getProfile.value.email
      ) === -1
    ) {
      getScheduledReportItem.value.email_list.unshift(getProfile.value.email)
    }
  } else {
    if (
      getScheduledReportItem.value.email_list.indexOf(
        getProfile.value.email
      ) !== -1
    ) {
      getScheduledReportItem.value.email_list.splice(
        getScheduledReportItem.value.email_list.indexOf(
          getProfile.value.email
        ),
        1
      )
    }
  }
}

function changeExportType() {
  if (network.value !== 'group' && network.value !== 'individual') return
  network.value = network.value === 'group' ? 'individual' : 'group'
  store.commit('SET_SCHEDULED_REPORT_ITEM_TYPE', network.value)
  store.commit('SET_SCHEDULED_REPORT_PLATFORM_TYPE', network.value)
  if (network.value === 'group') {
    allSelected.value =
      selectedAccounts.value.length === getSocialAccounts.value.length &&
      selectedAccounts.value.length > 0
  } else {
    allSelected.value =
      individualSelectedAccounts.value?.length ===
        getSocialAccounts.value?.length &&
      individualSelectedAccounts.value.length > 0
  }
}

function handleSelectAll(event) {
  if (event?.target?.checked) {
    const filteredAccounts = accounts.value.filter((account) =>
      filterAccounts(account)
    )
    if (network.value === 'group') {
      selectedAccounts.value = filteredAccounts.map((account) =>
        getAccountId(account)
      )
    } else if (
      network.value === 'individual' ||
      network.value === 'campaign-and-label'
    ) {
      individualSelectedAccounts.value = filteredAccounts.map((account) =>
        getAccountId(account)
      )
    } else {
      individualSelectedAccounts.value = filteredAccounts
        .filter((account) => account.account_type === network.value)
        .map((account) => getAccountId(account))
    }
  } else {
    if (network.value === 'group' && selectedAccounts.value.length) {
      selectedAccounts.value = []
    } else {
      if (individualSelectedAccounts.value.length) {
        individualSelectedAccounts.value = []
      }
    }
  }
}

function getAccountId(account) {
  const keyMap = {
    facebook: 'facebook_id',
    instagram: 'instagram_id',
    linkedin: 'linkedin_id',
    twitter: 'twitter_id',
    tiktok: 'platform_identifier',
    youtube: 'platform_identifier',
    pinterest: 'board_id',
  }

  return account[keyMap[account.account_type]] || null
}

function getAccountName(account) {
  return account?.platform_name || account?.name || account?.username
}

function getAccountImage(account) {
  switch (account.account_type) {
    case 'tiktok':
    case 'youtube':
      return account.platform_logo
        ? account.platform_logo
        : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    default:
      return account.image
        ? account.image
        : 'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
  }
}

function isPinterestAccount(account) {
  return account.account_type === 'pinterest'
}

function isTwitterAccount(account) {
  return account.account_type === 'twitter'
}

function filterTwitterAccounts(account) {
  return (
    !isTwitterAccount(account) ||
    (isTwitterAccount(account) && isTwitterAnalytics.value)
  )
}

function filterPinterestAccounts(account) {
  return (
    !isPinterestAccount(account) ||
    (isPinterestAccount(account) && isPinterestAccountAnalytics.value)
  )
}

function filterAccounts(account = {}) {
  if (network.value === 'campaign-and-label') {
    return true
  }
  return (
    (isGroupOrIndividual.value || account?.account_type === network.value) &&
    filterPinterestAccounts(account) &&
    filterTwitterAccounts(account)
  )
}

function isDropdownOptionAllowed(option) {
  if(option.type === 'overview' && !['group','individual'].includes(network.value)) {
    return false;
  }
  return true
}
</script>

<template>
  <div id="scheduleReport" class="modal fade scheduleEmail normal_modal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal_head d-flex align-items-center">
          <h2>{{ getModalTitle }}</h2>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            @click="reset"
            >&times;</button>
        </div>
        <div class="modal_body basic_form m_t_15">
          <div v-if="!(isModeExport || isModeEmail)" class="input_field mt-0">
            <label for="">Export Name</label>
            <input
              v-model="getScheduledReportItem.name"
              type="text"
              placeholder="Enter a name for export..."
            />
          </div>
          <div
            v-if="network !== 'campaign-and-label'"
            class="input_field mt-3 repeat_option"
          >
            <label for="">Export Type</label>
              <div v-if="network === 'group' || network === 'individual'" class="d-flex align-items-start">
                <div class="radio_input_image">
                  <input
                      id="group_type"
                      v-model="getScheduledReportItem.type"
                      name="exportTypeRadio"
                      value="group"
                      type="radio"
                      :disabled="network !== 'group' && network !== 'individual'"
                      @click="changeExportType"
                  />
                  <label for="group_type" class="radio_left w-[24rem]">
                    <div class="profile_picture d-flex align-items-starkot fb">
                      <div class="text_block">
                        <p class="text"><span class="bold">Group</span></p>
                        <p class="sub_text"
                        >Generate a combined report for all selected
                          accounts.</p
                        >
                      </div>
                    </div>
                  </label>
                </div>
                <div class="radio_input_image">
                  <input
                      id="individual_type"
                      name="exportTypeRadio"
                      value="individual"
                      type="radio"
                      :checked="network !== 'group'"
                      @click="changeExportType"
                  />
                  <label for="individual_type" class="radio_left w-[24rem]">
                    <div class="profile_picture d-flex align-items-start fb">
                      <div class="text_block">
                        <p class="text"><span class="bold">Individual</span></p>
                        <p class="sub_text"
                        >Generate individual reports for each selected
                          account.</p
                        >
                      </div>
                    </div>
                  </label>
                </div>
              </div>
              <div v-if="network !== 'group'" class="mt-2">
                <CstDropdown
                  class="block"
                  full-width
                  dropdown-placement="bottom"
                  container-classes="!max-h-96"
                  dropdown-classes= '!rounded-md !border-[#70707029]'
                  button-classes="flex !px-5 !py-3 !w-full !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
                >
                  <template v-slot:arrow>
                    <img :src="ChevronDownIcon" alt="chevron-down" />
                  </template>
                  <template v-slot:selected>
                    <p class="text-sm font-weight-500 capitalize">
                      {{ getSelectedType || 'Select Report Type' }}
                    </p>
                  </template>
                  <template v-slot>
                    <template
                      v-for="(item, index) in optionsDropdown"
                      :key="`activity_type_${index}`"
                    >
                      <template v-if="isDropdownOptionAllowed(item)">
                        <CstDropdownItem @click="selectedOptionType = item">
                          <p class="text-gray-900 font-medium text-sm">{{ item.title }}</p>
                          <span class="text-gray-700 text-xs">{{ item.description }}</span>
                        </CstDropdownItem>
                      </template>
                    </template>
                  </template>
                </CstDropdown>
              </div>
          </div>

          <div class="input_field mt-3">
            <div class="d-flex">
              <label for="">Social Accounts</label>
              <div
                  class="checkbox_input_image checkboxx"
              >
                <input
                  id="select_all_accounts"
                  v-model="allSelected"
                  type="checkbox"
                  @click="handleSelectAll"
                />
                <label for="select_all_accounts" class="checkbox_right">
                </label>
              </div>
            </div>

            <div class="accounts container">
              <ul class="account_ul ks-cboxtags overflow-auto">
                <template v-for="(item, key) in getSocialAccounts" :key="key">
                  <li class="">
                    <input
                      v-if="network === 'group'"
                      :id="item._id"
                      v-model="selectedAccounts"
                      type="checkbox"
                      :value="getAccountId(item)"
                    />
                    <input
                        v-else
                        :id="item._id"
                        v-model="individualSelectedAccounts"
                        type="checkbox"
                        :value="getAccountId(item)"
                    />
                    <label
                      v-tooltip="item.account_type === 'pinterest' ? item.type : ''"
                      :for="item._id"
                    >
                    <span class="relative mr-0.5">
                        <img
                        class="account_img"
                        :src="getAccountImage(item)"
                        alt=""
                        @error="
                            $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                          "
                        />
                        <img
                          :src="getSocialImageRounded(item.account_type)"
                          alt=""
                          class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                          />
                      </span>
                      <p class="account_p">{{ getAccountName(item) }}</p>
                    </label>
                  </li>
                </template>
              </ul>
            </div>
          </div>
          <div v-if="!(isModeExport || isModeEmail)" class="input_field mt-3 repeat_option">
            <label for="">Interval</label>
            <div class="d-flex align-items-start">
              <div class="radio_input_image">
                <input
                  id="week_interval"
                  v-model="getScheduledReportItem.interval"
                  checked
                  name="repeat_radio"
                  value="weekly"
                  type="radio"
                />
                <label for="week_interval" class="radio_left">
                  <div class="profile_picture d-flex align-items-starkot fb">
                    <div class="text_block">
                      <p class="text"><span class="bold">Weekly</span></p>
                      <p class="sub_text"
                        >Every Monday you'll be sent a report of the previous
                        week.</p
                      >
                    </div>
                  </div>
                </label>
              </div>

              <div class="radio_input_image">
                <input
                  id="month_interval"
                  v-model="getScheduledReportItem.interval"
                  name="repeat_radio"
                  type="radio"
                  value="monthly"
                />
                <label for="month_interval" class="radio_left">
                  <div class="profile_picture d-flex align-items-start fb">
                    <div class="text_block">
                      <p class="text"><span class="bold">Monthly</span></p>
                      <p class="sub_text"
                        >On 2nd of every month you'll be sent a report of the
                        previous month.</p
                      >
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>

          <div v-if="!isModeExport" class="input_field">
            <div class="multi_select">
              <div class="d-flex mb-2 align-items-center">
                <label for="" class="mb-0"
                  >Email To (Separate emails with a comma)</label
                >
                <div class="checkbox_input_image ml-auto">
                  <input
                    id="copy_email_to_myself"
                    v-model="getScheduledReportItem.copy_email_to_myself"
                    type="checkbox"
                    @change="copyEmailMyself"
                  />
                  <label for="copy_email_to_myself" class="checkbox_left">
                    Send a copy to myself
                  </label>
                </div>
              </div>

              <div class="multi_input">
                <input
                  v-model="getScheduledReportItem.email"
                  type="text"
                  placeholder="Enter up to 25 email address..."
                  @keyup.enter="addEmailToList($event)"
                  @keyup.188="addEmailToList($event)"
                />
              </div>
              <div class="multi_tags">
                <ul>
                  <li
                    v-for="(item, key) in getScheduledReportItem.email_list"
                    :key="key"
                    class="green"
                  >
                    <span class="value">{{ item }}</span>
                    <span class="remove" @click="removeEmailFromList(key)"
                      >&times;</span
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="input_field mt-4">
            <button
              class="btn gradient_btn large_btn btn_loader loader_right"
              :disabled="getReportsServiceCalls.save"
              @click="onButtonClick"
            >
              <span>{{ getButtonText }}</span>
              <clip-loader
                v-if="getReportsServiceCalls.save"
                :color="'#ffffff'"
                :size="'16px'"
              ></clip-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.accounts {
  max-height: 150px;
  overflow: auto;
  border: 1px solid #cbceda;
  padding: 0.5rem;
  border-radius: 5px;
}

.account_ul {
  display: flex;
  flex-wrap: wrap;
  cursor: default;
}

.account_img {
  font-size: 13px;
  height: 25px;
  width: 25px;
  border-radius: 2rem;
}

.account_p {
  font-size: 0.9rem;
  margin-right: 0.5rem;
}

.account_i {
  color: #ffffff;
  border-radius: 0.6rem;
  position: relative;
  right: 0.6rem;
  top: 0.6rem;
  height: 0.8rem;
  width: 0.8rem;
}

.container {
}

ul.ks-cboxtags {
  list-style: none;
}

ul.ks-cboxtags li {
  display: flex;
}

ul.ks-cboxtags li label {
  display: flex;
  font-size: 0.8rem;
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(139, 139, 139, 0.3);
  color: #adadad;
  border-radius: 25px;
  white-space: nowrap;
  margin: 3px 0px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  transition: all 0.2s;
}

ul.ks-cboxtags li label {
  align-items: center;
  padding: 0.3rem 0.5rem;
  cursor: pointer;
  margin-right: 0.2rem;
}

ul.ks-cboxtags li label::before {
  display: flex;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-weight: 900;
  font-size: 0.8rem;
  padding: 2px 6px 2px 2px;
  content: '✚';
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='radio']:checked + label::before,
ul.ks-cboxtags li input[type='checkbox']:checked + label::before {
  content: '✔';
  transform: rotate(-360deg);
  transition: transform 0.3s ease-in-out;
}

ul.ks-cboxtags li input[type='radio']:checked + label,
ul.ks-cboxtags li input[type='checkbox']:checked + label {
  border: 2px solid #4cdd99;
  color: #3a4557;
  transition: all 0.2s;
}

.select_all label {
  position: absolute;
  right: 2rem;
  top: 16rem;
}

.select_all label span {
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 50%;
  transition: all linear 0.3s;
}

.select_all label span :after {
  content: 's';
  position: absolute;
  top: -1px;
  left: 6px;
  border-bottom: 2px solid #fff;
  border-right: 2px solid #fff;
  height: 9px;
  width: 4px;
  transform: rotate(45deg);
  visibility: hidden;
}

.select_all input {
  display: none;
}

.select_all input :checked {
  background: #ccc;
}

.select_all input :after {
  visibility: visible;
}

ul.ks-cboxtags li input[type='radio'],
ul.ks-cboxtags li input[type='checkbox'] {
  opacity: 0;
}

.checkboxx {
  position: absolute;
  right: 2.5rem;
}
</style>
