<!--
  CalendarView.vue

  Description:
  This component is a part of the planner module, providing a calendar interface using FullCalendar for managing and displaying plans and notes.

  Features:
  - Shows events (plans and notes) on a calendar interface with day, week, and month views.
  - Fetches and displays plans based on the selected date range and filters.
  - Allows interaction with calendar events such as viewing, editing, duplicating, deleting, and rescheduling plans.
  - Provides a modal for editing and previewing plans and notes using the PlannerPostStatus component.
  - Integrates with Pusher for real-time updates and Vuex for state management.
  - Responsive design with automatic adjustment of elements based on screen size.
  - Custom popover for additional information or actions when interacting with calendar items.
  - Utilizes a color-coded system for representing different event types and statuses.
  - Allows users to toggle calendar features such as weekends visibility and custom tooltips for navigation buttons.
  - Handles events gracefully with animations and transitions, improving user engagement.

  Dependencies:
  - Vuex for state management.
  - FullCalendar for calendar rendering.
  - Vue3, lodash for utility functions.
  - Various custom helper functions and constants for handling backend communication and event management.

  Setups and Lifecycle:
  - Listeners and event handlers are dynamically bound and cleaned up during mounting and unmounting.
  - Uses Vue's composition API to organize and reuse functionalities.
  - Trigger functions on component hooks such as created, mounted, and beforeUnmount to prepare and clean up component status.

  Overall, `CalendarView.vue` serves as the user interface for viewing and interacting with plans and notes within a guided time frame, leveraging FullCalendar's robust capabilities to offer a seamless scheduling experience.
-->
<template>
  <div
    class="planner-calender-main"
    :class="{
      loader_overlay_with_loader: isFetchingPlans,
    }"
  >
    <beat-loader v-if="isFetchingPlans" :color="'005fd0'"></beat-loader>

      <transition name="fade-list-item">
        <template v-if="showNullPlansSection">
          <div class="warning_box text-center mx-0">
            <p>No posts found for the selected filters and date-range.</p>
          </div>
        </template>
      </transition>

    <PlannerNotesModal/>

    <FullCalendar
      class="planner-app-calendar"
      ref="calendar"
      :options="{
        ...calendarOptions,
        timeZone: getActiveWorkspace?.timezone,
        events: [ ...notes, ...plans],
        initialView: getCalendarViewPreference,
        buttonHints: {
          today: todayToolTipText,
        },
      }"
    >
      <template v-slot:eventContent="arg">
        <CalendarEvent
          :key="arg.event.extendedProps?._id"
          :item="arg.event.extendedProps"
          :plan="
            plans.filter(
              (plan) => plan._id === arg.event.extendedProps?._id
            )?.[0]
          "
          :type="
            arg.event.extendedProps?.common_box_status !== undefined
              ? 'plan'
              : 'note'
          "
        ></CalendarEvent>
      </template>
    </FullCalendar>
  </div>

  <b-modal
    id="post-status-modal"
    size="lg"
    centered
    :no-close-on-backdrop="true"
    hide-header
    hide-footer
    dialog-class="max-w-7xl"
  >
    <PlannerPostStatus :item="selectedPost" modal-id="post-status-modal" />
  </b-modal>

</template>

<script>
// Core Imports
import { mapGetters } from 'vuex'
import PlannerPostStatus from '@src/modules/planner_v2/components/PlannerPostStatus'
// Full Calendar Imports
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import momentTimezonePlugin from '@fullcalendar/moment-timezone'
import useDateFormat from '@common/composables/useDateFormat'
import { CC_POST_RESCHEDULE_PLAN_ERROR } from '@common/constants/messages'
import { getPlanMedia } from '@common/lib/planner'
import { EventBus } from '@common/lib/event-bus'
import { swalAttributes } from '@common/constants/common-attributes'
import { pusherSocketPublish } from '@common/lib/pusher'
import debounce from 'lodash/debounce'
import { planner } from '@src/modules/planner/store/mutation-types'
import proxy from '@common/lib/http-common'
import { plannerDefaultCalendarView } from '@src/modules/publish/config/api-utils'
import usePlannerHelper from '@src/modules/planner_v2/composables/usePlannerHelper'
import CalendarEvent from '../components/CalendarEvent'
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import PlannerNotesModal from '../components/PlannerNotesModal.vue'
import { usePlannerNotes } from '@modules/planner_v2/composables/usePlannerNotes'
import ComposeNote from '@assets/img/planner/compose-note.svg'
import EditNote from '@assets/img/planner/edit-note.svg'

FullCalendar.compatConfig = {
  MODE: 3,
}
CalendarEvent.compatConfig = {
  MODE: 3,
}

const clone = require('rfdc/default')

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarEvent,
    PlannerPostStatus,
    PlannerNotesModal
  },
  props: {
    loader: {
      type: Boolean,
      default: false,
    },
    plans: {
      type: Array,
      default: () => [],
    },
    notes: {
      type: Array,
      default: () => [],
    },
    isPlatformSelected: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'calendar-date-change',
    'handle-query-change',
    'replace-plan',
    'reject-with-comment',
    'preview-plan',
    'approve-with-comment',
  ],
  setup() {
    const { isEditablePost, canEditThisPost } = usePlannerHelper()
    const { openDraftComposer, draftPlanId } = useComposerHelper()
    const { openModal } = usePlannerNotes()
    const { momentWrapper} = useDateFormat()
    return {
      isEditablePost,
      canEditThisPost,
      openDraftComposer,
      draftPlanId,
      momentWrapper,
      openModal
    }
  },
  data() {
    return {
      // Planner Notes Modal Attributes
      note: {},
      isEditing: false,
      todayToolTipText: 'Current Week',
      selectedPost: null,
      isInitialDatesSet: true,
      calendarDefaultView: this.getCalendarViewPreference,
      calendarOptions: {
        // Plugins
        plugins: [
          dayGridPlugin,
          interactionPlugin, // needed for dateClick
          momentTimezonePlugin,
        ],
        // Components Options
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,dayGridWeek',
        },

        // Custom
        customButtons: {
          myCustomButton: {
            text: 'custom!',
            click: function () {
              alert('clicked the custom button!')
            },
          },
        },

        // Views Options
        editable: true,
        // selectable: true,
        // selectMirror: true,
        dayMaxEvents: 10,
        weekends: true,
        contentHeight: 'auto',
        expandRows: false,
        dayHeaderFormat: { weekday: 'long' },
        dayCellContent: this.handleDayCellContent,
        views: {
          week: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
          day: {
            dayHeaderFormat: {
              weekday: 'long',
              month: 'numeric',
              day: 'numeric',
            },
          },
        },
        datesSet: this.handleDatesSet,
        viewDidMount: this.handleViewDidMount,
        // dayCellContent: this.handleDayCellContent,
        // dayCellClassNames: 'h-100 fc-options-hover',
        dayCellClassNames: 'fc-cell',
        dayHeaders: true,

        // Events Options
        initialEvents: [],
        events: [],
        eventColor: 'transparent',
        select: this.handleDateSelect,
        eventClick: this.handleEventClick,
        eventsSet: this.handleEvents,
        eventDisplay: 'block',
        eventDrop: this.reschedulePlan,
        firstDay: null,
        // eventContent: this.handleEventContent
        /* you can update a remote database when these fire:
                eventAdd:
                eventChange:
                eventRemove:
                */
      },
      currentEvents: [],
      labels_channel: '',
      previousTopOffset: '',
    }
  },
  computed: {
    ...mapGetters([
      'getPlans',
      'getPlannerLoaders',
      'getPublishSelection',
      'getWorkspaceDetails',
      'getWorkspaces',
      'getCalendarViewPreference',
      'getActiveWorkspace',
    ]),
    isFetchingPlans() {
      return this.loader
    },
    planItemsCount() {
      return this.plans.length
    },
    /* isPlatformsSelected () {
      return this.getPublishSelection.platforms.selection.length
    }, */
    showNullPlansSection() {
      return !this.isFetchingPlans && !this.planItemsCount
    },
  },
  watch: {
    showNullPlansSection(newValue) {
      if (newValue) {
        this.$set(this.calendarOptions, 'events', [this.notes, ...this.plans])
      }
    },
    plans(newValue) {
      this.$nextTick(() => {
        const scrollerElements = document.querySelectorAll('.fc-scroller')
        if (scrollerElements.length > 1) {
          const scrollerElement = scrollerElements[1]

          scrollerElement.scrollTo({
            top: this.$store.state.planner.calendarScrollPosition,
            behavior: 'smooth',
          })
        }
      })
    },
    scroll(val) {
      debounce(() => {
        this.handleTableHeight()
      }, 650)()
    },
    'getWorkspaces.activeWorkspace.first_day'(firstDay) {
      this.calendarOptions.firstDay = firstDay?.day ? firstDay.key : 1
    },
  },
  created() {
    const query = Object.assign({}, this.$route.query)
    delete query.order_by
    this.$router.push({ query })
  },
  mounted() {
    this.calendarOptions.firstDay = this.getWorkspaces.activeWorkspace.first_day
      ? this.getWorkspaces.activeWorkspace.first_day.key
      : 1
    EventBus.$on('post-status-modal', (post) => {
      this.selectedPost = post
      this.$bvModal.show('post-status-modal')
    })

    this.$nextTick(() => {
      this.labels_channel = pusherSocketPublish.subscribe(
        `labels_${this.getActiveWorkspace._id}`
      )
      this.bindPusherLabels(this.labels_channel)
    })

    this.$nextTick(() => {
      this.handleTableHeight()
    })
    window.addEventListener('resize', () => {
      this.handleTableHeight()
    })
    const scrollerElements = document.querySelectorAll('.fc-scroller')

    if (scrollerElements.length > 1) {
      const scrollerElement = scrollerElements[1]

      scrollerElement.addEventListener('scroll', this.handleScroll)
    }

    EventBus.$on('empty-calendar-events', () => {
      this.calendarOptions.events = []
      this.removeEventCalendar()
    })

    document.addEventListener('click', (e) => {
      if (!e.target.closest('.popover') && !e.target.closest('.fc-event')) {
        this.closePopovers()
      }
    })
  },

  beforeUnmount() {
    delete this.$route.query.date
    window.removeEventListener('resize', () => {
      this.handleTableHeight()
    })
    const scrollerElements = document.querySelectorAll('.fc-scroller')

    if (scrollerElements.length > 1) {
      const scrollerElement = scrollerElements[1]

      scrollerElement.removeEventListener('scroll', this.handleScroll)
    }
    this.$store.commit(planner.SET_CALENDAR_SCROLL_POSITION, 0)

    EventBus.$off('post-status-modal')

    EventBus.$off('empty-calendar-events')

    // setting user default calendar view ['month','week'] based on user Selection
    if (this.calendarDefaultView !== this.getCalendarViewPreference) {
      this.setPlannerDefaultCalendarView(this.calendarDefaultView)
    }

    document.removeEventListener('click', this.closePopovers)

  },
  methods: {
    async removeEventCalendar() {
      if (this.$refs?.calendar) {
        console.log('removeEventCalendar')
        const calendarApi = this.$refs.calendar?.getApi()
        if (calendarApi) {
          console.log('removeEventCalendar: removeAllEvents')
          await calendarApi.removeAllEvents()
        }
      }
    },
    handleTableHeight() {
      if (this.$el && this.$el instanceof HTMLElement) {
        const offset = this.$el.getBoundingClientRect()
        this.previousTopOffset = offset
        const scroll =
          window.scrollY ||
          window.scrollTop ||
          document.getElementsByTagName('html')[0].scrollTop
        this.$el.style.height = `calc(100vh - ${
          scroll ? this.previousTopOffset.top : offset.top
        }px)`
      }
    },
    /**
     * handleDayCellContent
     *
     * Description:
     * This function is responsible for custom rendering of day cells within the calendar. It modifies the appearance and content of each cell based on specific conditions (e.g., future dates, permissions).
     *
     * Parameters:
     * - arg: An object containing details about the day cell, such as date and whether it's a current day.
     * - createElement: A function used to generate HTML elements dynamically.
     *
     * Returns:
     * - An object containing the DOM nodes to be used within a calendar day cell.
     *
     * Usage:
     * The function alters day cells to include additional buttons for creating new posts or notes. These buttons conditionally appear depending on user permission and date relevance, enhancing interactivity within the calendar.
     *
     * Note:
     * Ensure function guards against inappropriate manipulations by verifying permissions and date conditions.
     */

    // https://preactjs.com/guide/v8/api-reference/ - createElement Guide
    handleDayCellContent(arg, createElement) {
      const day = this.momentWrapper(arg.date).formatTimezone().format('D')
      const wrapper = document.createElement('div')
      const dayContent = document.createElement('span')

      const noteWrapper = document.createElement('div')
      noteWrapper.className= 'parent-note-wrapper'

      const note = document.createElement('img')
      note.src= require('@assets/img/planner/note.svg')
      note.className="w-3.5 w-3.5"
      note.title = 'New Note'
      note.addEventListener('click', (e) =>
          this.openModal()
      )


      dayContent.className = arg.isToday
        ? 'fc-daygrid-day-no active'
        : 'fc-daygrid-day-no'
      dayContent.textContent = day
      if (
        (arg.isFuture || arg.isToday) &&
        !arg.isOther &&
        this.hasPermission('can_access_top_header')
      ) {
        const post = document.createElement('i')
        post.className = 'fas fa-plus'
        post.title = 'New Social Post'
        post.addEventListener('click', (e) =>
          this.createContent(e, 'post', arg.isToday, arg.date)
        )
        const blog = document.createElement('i')
        blog.className = 'fas fa-file'
        blog.title = 'New Blog Post'
        blog.addEventListener('click', (e) =>
          this.createContent(e, 'blog', arg.isToday, arg.date)
        )

        const icons = document.createElement('span')
        icons.className = 'fc-daygrid-top-icon'
        icons.appendChild(post)
        icons.appendChild(blog)

        noteWrapper.appendChild(note)
        wrapper.appendChild(icons)
        wrapper.appendChild(dayContent)

        return {domNodes: [noteWrapper, wrapper]}
      }

      noteWrapper.appendChild(note)

      wrapper.appendChild(dayContent)
      return {domNodes: [noteWrapper, wrapper]}
    },
    async createContent(e, type, isToday, date) {
      if (type === 'post') {
        const res = await this.openDraftComposer(
          '⚠️ Unfinished Post is in the Composer!',
          'You have a post currently in the composer that is minimized. What would you like to do?',
          'Save & Create New',
          'Return to Composer'
        )
        if (res === null) {
          return
        } else if (!res) {
          this.changePublishTypeFromCalender(type, isToday, date)
        } else {
          const workspace = this.$route.params.workspace
          history.pushState(
            {},
            null,
            `/${workspace}/composer/${this.draftPlanId}`
          )
          this.$bvModal.show('composer-modal')
        }
        return
      }
      this.changePublishTypeFromCalender(type, isToday, date)
    },
    handleDayClassNames(arg) {
      console.log('handleDayClassNames')
    },
    handleDateClick(arg) {
      alert('date click! ' + arg.dateStr)
    },
    handleViewDidMount(event) {
      this.fetchPlansWithCalendarDate(event)
    },

    async handleDatesSet(event) {
      await this.fetchPlansWithCalendarDate(event)
      if (this.isInitialDatesSet) {
        this.isInitialDatesSet = false
        return
      }
      this.$emit('handle-query-change')
    },
    /**
     * fetchPlansWithCalendarDate
     *
     * Description:
     * This function retrieves plans for display in the calendar based on the provided date range. It dynamically adjusts the calendar’s event data when navigating between views or changing date ranges.
     *
     * Parameters:
     * - event: Contains information about the current calendar view and date range.
     *
     * Usage:
     * Invoke to load and refresh plans each time the calendar's visible date range or view changes. Crucial for maintaining correct and updated content within the calendar interface.
     *
     * Returns:
     * - Promise<void> indicating when the fetching and rendering of calendar plans is complete.
     */
    async fetchPlansWithCalendarDate(event) {
      this.calendarDefaultView =
        event?.view?.type || this.getCalendarViewPreference

      this.todayToolTipText =
        event?.view?.type === 'dayGridWeek' ? 'Current Week' : 'Current Month'

      const start = this.momentWrapper(event.view.activeStart).format(
        'MMMM DD, YYYY'
      )
      const end = this.momentWrapper(event.view.activeEnd).format(
        'MMMM DD, YYYY'
      )

      // remove all events from calendar before fetching new events
      this.calendarOptions.events = []
      this.removeEventCalendar()

      const date = clone(start + ' - ' + end)
      this.$emit('calendar-date-change', date)

      await this.$router.push({
        query: { ...this.$route.query, date },
      })
    },
    handleWeekendsToggle() {
      this.calendarOptions.weekends = !this.calendarOptions.weekends // update a property
    },
    handleDateSelect(selectInfo) {
      this.closePopovers()
    },
    async handleEventClick(info) {
      const target = info.jsEvent.target
      const event = target.getAttribute('data-event')
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      this.closePopovers()
      if (event) {
        item.stateObject = this
        switch (event) {
          case 'viewItemAttachment':
            // eslint-disable-next-line no-case-declarations
            let plan = target.getAttribute('data-plan')
            if (plan) {
              // finding plan from states
              plan = item.stateObject.plans.find((item) => item._id === plan)
              if (plan) {
                // checking for plan media
                const attachment = getPlanMedia(plan)
                if (attachment) {
                  EventBus.$emit('displayFile', {
                    type: attachment.type,
                    media: attachment.media,
                    index: 0,
                  })
                  item.stateObject.$bvModal.show('display-file-modal')
                }
              }
            }
            break
          case 'editCalendarItem':
            const resp = await this.openDraftComposer(
              '⚠️ Unfinished Post is in the Composer!',
              'You have a post currently in the composer that is minimized. What would you like to do?',
              'Save & Edit Selected',
              'Return to Composer'
            )
            if (resp === null) {
              return
            } else if (!resp) {
              EventBus.$emit('reset-composer-data')
              this.$store.dispatch('editPlan', item)
            } else {
              const workspace = this.$route.params.workspace
              history.pushState(
                {},
                null,
                `/${workspace}/composer/${this.draftPlanId}`
              )
              this.$bvModal.show('composer-modal')
            }
            break
          case 'deleteCalendarItem':
            if (item?.post_state === 'published' && !item?.blog_reference) {
              await this.fetchPlanAccounts(item?._id)
              this.$bvModal.show('delete-post-modal')
              return
            }
            // eslint-disable-next-line no-case-declarations
            const res = await this.$bvModal
              .msgBoxConfirm('Are you sure you want to delete this post?', {
                title: 'Remove Post',
                ...swalAttributes(),
              })
              .then((res) => res)
              .catch(() => null)

            if (res) {
              await this.$store.dispatch('removePlan', {
                id: item._id,
              })
            }
            break
          case 'replaceCalendarItem':
            this.$emit('replace-plan', item)
            break
          case 'approveCalendarItem':
            this.changePlanStatusMethod('scheduled', item, true)
            break
          case 'rejectCalendarItem':
            this.changePlanStatusMethod('rejected', item, true)
            break
          case 'duplicateCalendarItem':
            const response = await this.openDraftComposer(
              '⚠️ Unfinished Post is in the Composer!',
              'You have a post currently in the composer that is minimized. What would you like to do?',
              'Save & Create Duplicate',
              'Return to Composer'
            )
            if (response === null) {
              return
            } else if (!response) {
              EventBus.$emit('reset-composer-data')
              this.$store.dispatch('clonePlan', item)
            } else {
              const workspace = this.$route.params.workspace
              history.pushState(
                {},
                null,
                `/${workspace}/composer/${this.draftPlanId}`
              )
              this.$bvModal.show('composer-modal')
            }
            break
          case 'calendarItemCheckbox':
            // eslint-disable-next-line no-case-declarations
            const planId = target.getAttribute('value')
            if (target.checked) {
              this.getPlans.selected_plans.push(planId)
            } else {
              this.getPlans.selected_plans.splice(
                this.getPlans.selected_plans.indexOf(planId),
                1
              )
            }
            break
          case 'approvalCalendarItemReject':
            this.$emit('reject-with-comment', item)
            break
          case 'approvalCalendarItemApprove':
            this.$emit('approve-with-comment', item)
            break
        }
      } else {
        if (item?.common_box_status !== undefined) {
          this.$emit('preview-plan', item._id)
        } else {
          const popover = this.createPopover(
            info.el,
            info.event.title,
            info.event.extendedProps
          )
        }
      }
    },
    /**
     * handleEvents
     *
     * Description:
     * Manages events currently displayed in the calendar. This function is triggered whenever events are fetched, added, removed, or updated in the calendar's interface.
     *
     * Parameters:
     * - events: An array representing the events currently displayed in the calendar.
     *
     * Usage:
     * This function can be used to update local data structures or trigger additional UI updates based on the active calendar events.
     *
     * Notes:
     * Consider edge cases where event data may conflict or require synchronization with other components or backend services.
     */
    handleEvents(events) {
      this.currentEvents = events
    },
    async reschedulePlan(info) {
      const item = JSON.parse(JSON.stringify(info.event.extendedProps))
      item.stateObject = this
      if (!this.canEditPlan(item)) {
        info.revert()
        return false
      }
      item.start = this.momentWrapper(info.event.start)
      item.startStr = info.event.startStr // startStr YYYY-MM-DD
      if (item.content_category_id && item.content_category_id.length > 0) {
        this.$store.dispatch('toastNotification', {
          message: CC_POST_RESCHEDULE_PLAN_ERROR,
          type: 'error',
        })
        info.revert()
        return false
      }
      // Allow only draft or scheduled or reviewed post to reschedule
      if (
        item.post_state !== 'draft' &&
        item.post_state !== 'scheduled' &&
        item.post_state !== 'reviewed'
      ) {
        this.$store.dispatch('toastNotification', {
          message:
            'You can only reschedule a draft or scheduled or in review post.',
          type: 'error',
        })
        info.revert()
        return false
      }

      const status = await this.$store.dispatch('reschedulePlan', item)
      if (!status) info.revert()
    },
    async handleScroll(event) {
      debounce(() => {
        const scrollPosition = event.target.scrollTop
        if (!this.isFetchingPlans) {
          this.$store.commit(
            planner.SET_CALENDAR_SCROLL_POSITION,
            scrollPosition
          )
        }
      }, 500)()
    },
    /**
     * Sets user default calendar View ['month','week'] based on user Selection
     * @param defaultView
     */
    setPlannerDefaultCalendarView(defaultView) {
      const self = this
      proxy
        .post(plannerDefaultCalendarView, {
          planner_default_calendar_view: defaultView,
        })
        .then(() => {
          self.$store.dispatch('setDefaultCalendarView', defaultView)
        })
        .catch((err) => {
          console.log('PROXY::plannerDefaultView ~ err -> ', err)
        })
    },
    canEditPlan(item) {
      return this.isEditablePost(item) && this.canEditThisPost(item)
    },
    createPopover(eventEl, title, item) {
      const popover = document.createElement('div')
      popover.className = 'popover notes-popup note-popup-open bg-white shadow-md w-[26rem] mx-auto absolute z-10 border-0'

      popover.innerHTML = `<div class="relative bg-white  rounded-lg shadow-md w-[26rem] mx-auto">
        <div id="caret" class="absolute top-[-24px] left-[30px] transform -translate-x-1/2">
          <i style="color: ${item.note_color}" class="fa fa-solid fa-caret-up text-4xl"></i>
        </div>
        <div class="rounded-lg" style="border-top: 4px solid ${item.note_color}">
          <div style="border-bottom: 1px solid #e3e3e3" class="flex justify-between items-center p-4">
            <div class"header">
              <h3 class="text-lg text-[#3A4557] font-semibold">${item.note_title}</h3>
              <span class="text-sm text-[#757A8A]">${item.from_start} - ${item.from_end}</span>
            </div>
            <div class="close-button">
            <button id="closepopover" class="btn text-gray-400 bg-[#3A45570D] ">
              <i class="font-light fas fa-times"></i>
            </button>
            </div>
          </div>

          <div style="border-bottom: 1px solid #e3e3e3" class="p-4">
            <p class="text-sm text-[#757A8A] pb-4">${item.description}</p>
          </div>
          <div class="flex items-center justify-between p-[10px]">
            <div class="flex items-center">
              <img src='${item.author_image ||  'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'}' alt="Author" class="rounded-full border mr-2 w-8 h-8">
              <span class="text-sm text-[#757A8A]">By: <strong class="font-medium text-[#3A4557]">${item.author}</strong></span>
            </div>
            <div class="flex items-center space-x-1">
                <button id="composeButton" class="btn !bg-[#EAF3FF] font-light hover:bg-blue-600 rounded-md">
                  <img src='${ComposeNote}' class='w-4 h-4 mr-1'> <span>Compose Post</span>
                </button>

                <button id="edite-note" class="btn btn-link">
                  <img src='${EditNote}' class='w-4 h-4'>
                </button>
            </div>
          </div>
        </div>
      </div>`

      // Append popover to the calendar container instead of body
      const note_popover = document.getElementById(`${item._id}_note_popover`)
      note_popover.appendChild(popover)

      const closeBtn = popover.querySelector('#closepopover')
      closeBtn.addEventListener('click', () => {
        if (popover.parentNode) {
          popover.parentNode.removeChild(popover)
        }
      })

      const composeBtn = popover.querySelector('#composeButton')
      composeBtn.addEventListener('click', () => {
        let description = item.note_title + ' ' + item.description
        this.changePublishType('Composer Social', false, '', description)
      })

      const editNoteBtn = popover.querySelector('#edite-note')
      editNoteBtn.addEventListener('click', () => {
        this.openModal(item)
      })


      popover.style.position = 'absolute'
      popover.style.left = '20px'
      popover.style.top = `46px` // Add some space between the event and popover

      if(popover.getBoundingClientRect().right + 50 >= window.innerWidth) {
        popover.style.left = 'auto'
        popover.style.right = '10rem'

        const caret = popover.querySelector('#caret');
        caret.style.left = 'auto';
        caret.style.right = '30px';
      }

      return popover
    },

    /**
     * closePopovers
     *
     * Description:
     * Closes any open popover elements present in the document. This function is useful for maintaining a clean UI by ensuring auxiliary UI elements are removed when not in focus.
     *
     * Usage:
     * Typically called when interacting with non-calendar areas to ensure popovers are closed when their content is no longer relevant.
     *
     * Notes:
     * Consider integrating responsive behavior that automatically collapses or removes popovers based on user interactions beyond simple clicks.
     */
    closePopovers() {
      const popovers = document.querySelectorAll('.popover')
      if (popovers.length > 0) {
        popovers.forEach((popover) => {
          document.body.removeChild(popover);
        });
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.$refs?.calendar) {
      const calendarApi = this.$refs.calendar?.getApi()
      if (calendarApi) {
        calendarApi.removeAllEvents()
        calendarApi.destroy()
      }
    }
    this.$nextTick(() => {
      next()
    })
  },
}
</script>

<style lang="scss">
// @import '~@fullcalendar/common/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/timegrid/main.css';
</style>
