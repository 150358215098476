<template>
  <div
    class="bg-white p-5 rounded-md"
    :class="{
      'color-border': !isModal,
      'cst-editor': !isModal,
    }"
  >
    <!--    component header-->
    <div class="mb-6 w-full flex justify-between items-center">
      <div class="flex items-center gap-2">
        <h2 class="text-base font-weight-500 select-none capitalize">{{
          title
        }}</h2>
        <v-menu
            :popper-triggers="['hover']"
            placement="top"
            popper-class="first-comment__info-popover"
            :delay="300"
        >
          <i class="far fa-question-circle p-0 cursor-pointer"></i>
          <template v-slot:popper>
            <p class="text-sm text-gray-900">
              {{getComponentInfoTooltip(type, platformName, selectedType)}}
            </p>
          </template>
        </v-menu>
      </div>

      <div class="flex justify-center items-center">
        <div
          v-if="!isModal && data.length"
          v-tooltip="{
            content: tooltipHtml('Maximize'),
            theme: 'light',
            allowHTML: true,
          }"
          class="
            cursor-pointer
            w-10
            h-10
            flex
            justify-center
            items-center
            bg-gray-50
            hover:bg-gray-100
            active:bg-gray-300
            rounded-full
          "
          @click="$bvModal.show(`overview-analytics-${modalKey}-modal`)"
        >
          <img
            src="@src/assets/img/full_screen.svg"
            alt="fullScreen"
            class="w-5 h-5"
          />
        </div>
        <div
          v-if="isModal"
          v-tooltip="{
            content: tooltipHtml('Minimize'),
            theme: 'light',
            allowHTML: true,
          }"
          class="
            cursor-pointer
            w-10
            h-10
            flex
            justify-center
            items-center
            bg-gray-50
            hover:bg-gray-100
            active:bg-gray-300
            rounded-full
          "
          @click="$bvModal.hide(`overview-analytics-${modalKey}-modal`)"
        >
          <i class="fa fa-times fa-lg"></i>
        </div>
      </div>
    </div>

<!--    body -->
    <div v-if="isLoading">
      <SkeletonBox class="!w-full !h-[400px]"></SkeletonBox>
    </div>
    <div v-else-if="isAllDataFetched" class="analytics-no-data-found">
      <img src="@src/assets/img/analytics/data_fetching.gif" alt="" />
      <p>Data is being fetched.</p>
    </div>
    <div v-else-if="!data.length" class="analytics-no-data-found">
      <img
        src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
        alt=""
      />
      <p>No data found.</p>
    </div>
    <div
      v-else
      class="flex border !border-[#E9EFF6] tooltip-handler"
      :class="{
        'max-h-[350px] overflow-auto': isSmallCard,
      }"
    >
      <table class="w-full text-sm text-justify text-gray-900 rounded-lg">
        <thead class="bg-gray-200 rounded-lg text-sm text-gray-900">
          <tr>
            <th v-if="showInfoCell" class="p-3 select-none group w-[40%]"
              ><p class="font-weight-500 font-0-95rem ml-1">Name</p></th
            >
            <th
              v-for="(listItem, key) in props.headers"
              :key="`${key}_header_${type}`"
              class="p-3 select-none group"
              :class="{
                'cursor-pointer hover:bg-gray-300':
                  listItem !== 'image',
              }"
              @click="sortColumn(listItem, key)"
            >
              <v-menu
                :popper-triggers="['hover']"
                placement="top"
                popper-class="first-comment__info-popover"
                class="first-comment__base-width"
                :delay="300"
              >
                <div class="w-full flex justify-between items-center">
                  <p
                    class="font-weight-500 text-left font-0-95rem"
                    v-html="getHeaderTitles(listItem)"
                  ></p>
                  <i
                    v-if="isSortable(key, listItem)"
                    class="fa text-gray-700 group-hover:text-gray-900"
                    :class="state.isSorted ? 'fa-sort-desc' : 'fa-sort-asc'"
                  ></i>
                </div>
                <template v-slot:popper>
                  <p
                    class="text-gray-900"
                    v-html="tooltipHtml(getTableHeaderTooltips(listItem, platformName, type, selectedType))"
                  ></p>
                </template>
              </v-menu>
            </th>
          </tr>
        </thead>

        <component
          :is="typeToComponent[type]"
          :key="getTableKey(type)"
          :is-sorted="state.isSorted"
          :table-data="state.tableData"
          :headers="props.headers"
          :platform-name="platformName"
          :chart-data="chartData"

          v-on="$listeners"
        />
      </table>
    </div>
    <b-modal
      :id="`overview-analytics-${modalKey}-modal`"
      centered
      hide-footer
      hide-header
      body-class="!overflow-y-auto h-screen"
      content-class="!mt-2 !rounded-none"
      dialog-class="cst-modal"
      @hide="commonMethods.toggleWidgets(false)"
      @shown="commonMethods.toggleWidgets(true)"
    >
      <OverviewTable
        :title="title"
        :data="data"
        :type="type"
        :headers="headers"
        :is-modal="true"
        :is-all-data-fetched="isAllDataFetched"
        :is-loading="isLoading"
        :chart-data="chartData"
        :selected-type="selectedType"
        :all-available-types="allAvailableTypes"
        :show-info-cell="showInfoCell"
        :index-to-sort="indexToSort"
        :single-hashtag-data="singleHashtagData"
        :single-hashtag-header="singleHashtagHeader"
        :platform-name="platformName"
        :modal-key="modalKey"
        v-on="$listeners"
      />
    </b-modal>
  </div>
</template>

<script setup>
// libraries
import { reactive, computed, watch } from 'vue'

// components
import { commonMethods } from '@common/store/common-methods'
import PlatformDataTableHelper from '@src/modules/analytics/views/overviewV2/components/PlatformDataTableHelper.vue'

// composable
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';

const props = defineProps({
  showInfoCell: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: "Post Breakdown by Campaign & Label",
  },
  data: {
    type: Array,
    default: () => [],
  },
  headers: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: 'breakdown',
  },
  isModal: {
    type: Boolean,
    default: false,
  },
  indexToSort: {
    type: Number,
    default: 0,
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
  chartData: {
    type: Object,
    default: () => {},
  },
  selectedType: {
    type: String,
    default: '',
  },
  allAvailableTypes: {
    type: Array,
    default: () => [],
  },
  singleHashtagData: {
    type: Object,
    default: () => {},
  },
  singleHashtagHeader: {
    type: Array,
    default: () => [],
  },
  colors: {
    type: Array,
    default: () => [],
  },
  isAllDataFetched: {
    type: Boolean,
    default: false,
  },
  platformName: {
    type: String,
    default: ''
  },
  isSmallCard: {
    type: Boolean,
    default: false,
  },
  modalKey: {
    type: String,
    default: ''
  },
})

const { tooltipHtml } = useComposerHelper()
const { getHeaderTitles, getComponentInfoTooltip, getTableHeaderTooltips } = useOverviewAnalytics()

const state = reactive({
  tableData: computed(() => props.data),
  singleHashDataArray: [],
  isSorted: true,
  sortedHeaderKey: props.indexToSort,
  isLoading: false,
  expandedRowIndex: [],
})

// dynamic component handling
const typeToComponent = {
  topPlatform: PlatformDataTableHelper,
}

watch(
  () => props.singleHashtagData,
  (newData) => {
    state.isLoading = false
    state.singleHashDataArray.push(newData)
  }
)

/**
 * @description checks whether to show the sorting icon or not.
 * @param key
 * @param headerName
 * @returns {boolean}
 */
const isSortable = (key, headerName) => {
  const nonSortableItems = []
  return !nonSortableItems.includes(headerName) && key === state.sortedHeaderKey
}


/**
 * @description called when any thead is clicked in order to sort the respective column
 * @param header
 * @param index
 */
const sortColumn = (header, index) => {
  if (state.sortedHeaderKey === index && state.isSorted) {
    state.tableData?.sort((a, b) => b[header] - a[header])
    state.isSorted = false
    return
  }
  state.sortedHeaderKey = index
  state.isSorted = true
  state.tableData?.sort((a, b) => a[header] - b[header])
}

const getTableKey = (type) => {
  switch (type) {
    case 'breakdown':
    case 'impressions':
    case 'engagements':
      return `breakdown_table`
    default:
      return ''
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 1417px) {
  .tooltip-handler {
    overflow-x: auto;
  }
}
</style>
