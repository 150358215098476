import { facebook } from '@src/modules/integration/store/states/mutation-types'

const getDefaultFacebookAccountsState = () => {
  return {
    items: [],
    all_items: []
  }
}

export default {
  state: {
    accounts: getDefaultFacebookAccountsState()
  },
  getters: {
    getFacebookAccounts: (state) => state.accounts,
    getFacebookPagesList: (state) =>
        state.accounts.items.filter((item) => item.type !== 'Group')
  },
  actions: {},
  mutations: {
    [facebook.SET_ACCOUNTS] (state, account) {
      state.accounts.items = account.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },

    [facebook.SET_ALL_ACCOUNTS] (state, account) {
      state.accounts.all_items = account.sort((a,b)=> a.name.toLowerCase().localeCompare(b.name.toLowerCase()))
    },

    [facebook.SET_DEFAULT] (state) {
      state.accounts = getDefaultFacebookAccountsState()
    },
    [facebook.SET_LOCATION] (state, payload) {

      console.log('setLocation payload', payload)
      const account = state.accounts.all_items.find(acc => acc._id === payload.platform_id)
      if (account) {
        console.log('setLocation account', account)
        account.location = payload.location
      }
      const itemAccount = state.accounts.items.find(acc => acc._id === payload.platform_id)
      if (itemAccount) {
        itemAccount.location = payload.location
      }
    }
  }
}