<script setup>
import {
  ref,
  watch,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
  computed,
  inject,
} from 'vue'

// components
import CstButton from '@ui/Button/CstButton.vue'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import DashboardCard from '@src/modules/dashboard/components/DashboardCard.vue'
import DashboardAdsCarousel from '@src/modules/dashboard/components/DashboardAdsCarousel.vue'
import ContentPublishingDonut from '@src/modules/dashboard/components/charts/ContentPublishingDonut.vue'
import ToDoBar from '@src/modules/dashboard/components/charts/ToDoBar.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import DashboardAnnouncementsCarousel from '@src/modules/dashboard/components/DashboardAnnouncementsCarousel.vue'
import DashboardVideosCarousel from '@src/modules/dashboard/components/DashboardVideosCarousel.vue'
import DashboardSupportCenter from '@src/modules/dashboard/components/DashboardSupportCenter.vue'
import DashboardNotifications from '@src/modules/dashboard/components/DashboardNotifications.vue'
import DashboardNotificationBanner from '@src/modules/dashboard/components/DashboardNotificationBanner.vue'
// import BlackFridayBanner from '@src/modules/dashboard/components/BlackFridayBanner.vue'

// composable
import useDashboard from '@src/modules/dashboard/composables/useDashboard'
import usePermissions from '@common/composables/usePermissions'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
// utils
import { useStore } from '@state/base'
import { useRouter } from '@src/router'
import { EventBus } from '@common/lib/event-bus'
import moment from 'moment'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";
import {useFeatures} from "@modules/billing/composables/useFeatures";
import {useBilling} from "@modules/billing/composables/useBilling";
import BillingTest from "@modules/billing/components/BillingTest.vue";
import useNumber from '@/src/modules/common/composables/useNumber'

const { getters } = useStore()
const router = useRouter()
const root = inject('root')
const { $bvModal } = root
const socialAccountsCard = ref(null)
const profilesToShow = ref(9)
const dashboardBannerState = ref(true)
const resizeObserver = ref(null)

const { getSocialImageRounded, channelImage, getPlatformName, isDraftComposer, draftPlanId } =
  useComposerHelper()
const { formatNumber } = useNumber()

const {
  getPercentage,
  getContentPublishingStats,
  getInboxStats,
  getApprovalPublishingStats,
  getFilterDate,
  getNewsData,
  getVideosData,
  dateFilters,
  contentPublishingDateFilterText,
  approvalPublishingDateFilterText,
  inboxLoader,
  inboxData,
  contentPublishingData,
  contentPublishingLoader,
  contentApprovalLoader,
  contentApprovalData,
  newsData,
  newsLoader,
  videosData,
  videosLoader,
  allUnReadNotificationsCount,
} = useDashboard()

const { hasPermission, isPlanUpgradeable, isTrialPlan } = usePermissions()
const {canAccess} = useFeatures()
const {showUpgradeModal} = useBilling()

// Feature check with custom message
const inboxAccess = canAccess('social_inbox')

const {appData} = useWhiteLabelApplication()

const shouldShowBanner = computed(
  () =>
    getters?.getProfile?.dashboard_banner_view_status &&
    dashboardBannerState.value &&
    isValidDeadline()
)

const computeWelcomeMessage = computed(() => {
  const profile = getters?.getProfile
  return profile?.firstname ?
      `Welcome back, ${profile?.firstname?.split(' ')[0]}!` :
      `Welcome back!`
})

const socialProfiles = computed(() =>
  getters?.getSocialAccountsList?.slice(0, profilesToShow.value)
)

watch(
  () => getters.getActiveWorkspace._id,
  (newId, oldId) => {
    if (newId !== oldId) fetchDashboardStats()
  }
)

onMounted(() => {
  fetchDashboardStats()
  document.body.style.overflow = 'hidden'
  resizeObserver.value = new ResizeObserver(onSocialCardResize)
  if (socialAccountsCard.value) {
    resizeObserver.value.observe(socialAccountsCard.value)
  }

  EventBus.$on(
    'hide-dashboard-banner',
    () => (dashboardBannerState.value = false)
  )
})

onBeforeUnmount(() => {
  if (resizeObserver.value) {
    resizeObserver.value.disconnect()
  }
})

onUnmounted(() => {
  document.body.style.overflow = 'revert'
})

const isValidDeadline = () => {
  return true
  // const now = moment.utc()
  // const start = moment.utc('2024-08-13T19:00:00');
  // const end = moment.utc('2024-08-15T04:00:00');
  //
  // return now?.isBetween(start, end, null, '[]') // Using inclusive comparison
}

const fetchDashboardStats = () => {
  contentPublishingDateFilterText.value = ''
  approvalPublishingDateFilterText.value = ''
  getNewsData()
  getVideosData()
  getContentPublishingStats(dateFilters.Today)
  getInboxStats()
  getApprovalPublishingStats(dateFilters.Today)
}

const redirectToPlanner = (status = '', date) => {
  switch (status) {
    case 'scheduled':
    case 'published':
    case 'partial':
    case 'failed':
      router.push({
        name: 'planner_list_v2',
        query: { statuses: status, date: getFilterDate(date) },
      })
      break
    case 'rejected':
    case 'under_review':
    case 'missed_review':
      router.push({
        name: 'planner_list_v2',
        query: {
          statuses: status,
          date: getFilterDate(date),
        },
      })
      break
  }
}

const onClickInboxLock = () => {
  if (!inboxAccess.value?.allowed && getters.getPlan?.subscription?.is_lifetime) {
    router.push({
      name: 'upgrade_lifetime_addon',
      params: { workspace: getters.getActiveWorkspace.slug },
    })
  } else if (!inboxAccess.value?.allowed) {
    showUpgradeModal()
  }
}

const redirectToInbox = (filter = 'unassigned') => {
  const routes = {
    assigned: { name: 'inboxConversation', params: { filter: 'assigned' } },
    unassigned: { name: 'inboxConversation', params: { filter: 'unassigned' } },
    mine: { name: 'inboxConversation', params: { filter: 'mine' } },
  }

  if (inboxAccess.value?.allowed) {
    return router.push({
      path: '/' + getters.getActiveWorkspace.slug + '/inbox/' + routes[filter]?.params?.filter + '/conversation',
    })
  }
  onClickInboxLock()
}

const redirectToBilling = () => {
  router.push({
    name: 'plan',
    params: {
      workspace: getters.getActiveWorkspace.slug,
    },
  })
}

const redirectToBlogs = () => {
  router.push({
    name: 'blogs',
    params: {
      workspace: getters.getActiveWorkspace.slug,
    },
  })
}

const redirectToSocialAccounts = () => {
  router.push({
    name: 'social',
    params: {
      workspace: getters.getActiveWorkspace.slug,
    },
  })
}

const onSocialCardResize = () => {
  const element = socialAccountsCard.value
  if (!element) return // Exit early if element is not available

  const elementWidth = element.getBoundingClientRect()?.width
  let profilesCount

  switch (true) {
    case elementWidth < 470 && elementWidth >= 323:
    case elementWidth >= 303 && elementWidth <= 328:
      profilesCount = 9
      break
    case elementWidth >= 470:
      profilesCount = 17
      break
    case elementWidth >= 291 && elementWidth <= 303:
      profilesCount = 7
      break
    default:
      profilesCount = 8
  }

  profilesToShow.value = profilesCount
}

// example for updating theme
// const updateTheme = () => {
//   document.documentElement.style.setProperty('--cs-primary', 'red')
// }
</script>

<template>
  <div class="flex flex-col items-center w-full">
    <!--    banner-->
    <transition name="slidedown">
      <DashboardNotificationBanner v-if="shouldShowBanner" />
      <!--      <BlackFridayBanner />-->
    </transition>

<!--    <BillingTest />-->
    <div class="dashboard overflow-x-hidden w-full p-4">
      <!--   first row -->
      <!--      welcome-->
      <div
        class="welcome-banner flex justify-between items-center p-7 rounded-sm mx-2 sm:p-5 sm:mx-1"
      >
        <div>
          <p
            class="text-3xl font-semibold leading-none mb-4 text-secondary-cs sm:text-2xl sm:mb-3"
            >
            {{ computeWelcomeMessage }}</p
          >
          <sub
            class="text-sm display-block leading-none mb-9 text-secondary-cs sm:mb-5"
            >Master your social media presence with {{appData?.businessName}}!</sub
          >

          <div class="flex flex-wrap gap-3 sm:gap-2">
            <CstButton
              v-if="!getters?.getSocialAccountsList?.length"
              variant="secondary"
              class="!px-5 !py-3 !rounded-lg"
              @click="redirectToSocialAccounts"
            >
              <p class="leading-4 font-medium text-light-cs"
                >Connect Accounts</p
              >
            </CstButton>
            <CstButton
              v-else
              variant="secondary"
              class="!px-5 !py-3 !rounded-lg"
              @click="changePublishType('Composer Social', isDraftComposer, draftPlanId)"
              ><p class="leading-4 font-medium text-light-cs"
                >Create Social Post</p
              >
            </CstButton>
            <CstButton
              v-if="!getters?.getBlogAccountsList?.length"
              variant="outlined"
              class="!px-5 !py-3 !rounded-lg !border-secondary-cs !bg-transparent"
              @click="redirectToBlogs"
            >
              <p class="leading-4 font-normal text-secondary-cs"
                >Connect Blogs/Web</p
              >
            </CstButton>
            <CstButton
              v-else
              variant="outlined"
              class="!px-5 !py-3 !rounded-lg !border-secondary-cs !bg-transparent"
              @click="changePublishType('Composer Article')"
            >
              <p class="leading-4 font-normal text-secondary-cs"
                >Create Blog Post</p
              >
            </CstButton>
          </div>
        </div>

        <img
          src="@src/assets/img/dashboard/images/dashboard-welcome.svg"
          :draggable="false"
          class="welcome-image w-[17rem]"
          alt="img"
        />
      </div>
      <!--      plan info-->
      <div
        v-if="hasPermission('can_see_subscription')"
        class="plan-info grid-item grid-item"
      >
        <DashboardCard>
          <template v-slot:header-content>
            <div class="flex items-center justify-between h-full pl-7 pr-4">
              <p class="text-lg font-medium">Plan Info</p>
              <template v-if="isTrialPlan()">
                <div class="flex gap-2">
                  <CstButton
                    variant="outlined"
                    class="!px-3 !py-[0.438rem] !rounded !border-primary-cs"
                    @click="showUpgradeModal('change-trial-plan')"
                    ><p class="text-xs font-normal">Change Plan</p></CstButton
                  >
                  <CstButton
                    variant="primary"
                    class="!px-3 !py-[0.438rem] !rounded"
                    @click="showUpgradeModal"
                    ><p class="text-xs font-normal">Subscribe</p></CstButton
                  >
                </div>
              </template>
              <template v-else>
                <div class="flex gap-2">
                  <CstButton
                    v-if="!isPlanUpgradeable"
                    variant="outlined"
                    class="!px-3 !py-[0.438rem] !rounded !border-primary-cs"
                    @click="showUpgradeModal"
                    ><p class="text-xs font-normal">Upgrade</p></CstButton
                  >
                  <CstButton
                    v-if="!getters?.getPlan?.subscription?.paddle_billing"
                    variant="primary"
                    class="!px-3 !py-[0.438rem] !rounded"
                    @click="$bvModal.show('increase-limits-dialog')"
                    ><p class="text-xs font-normal"
                      >Increase Limits</p
                    ></CstButton
                  >
                </div>
              </template>
            </div>
          </template>
          <template v-slot:body-content>
            <div class="flex flex-col flex-grow-1 gap-4 justify-center mx-auto">
              <div class="flex gap-[1.375rem] max-w-[18.125rem] items-center">
                <p class="min-w-[4.313rem] text-sm leading-3">Workspace</p>
                <div
                  class="w-[7.25rem] bg-gray-cs-100 rounded-lg overflow-hidden h-2"
                >
                  <div
                    class="bg-primary-cs-600 text-xs leading-none h-2"
                    :style="{
                      width: `${getPercentage(
                        getters?.getPlan?.used_limits?.workspaces,
                        getters?.getPlan?.subscription?.limits?.workspaces,
                      )}%`,
                    }"
                  ></div>
                </div>
                <span class="font-semibold text-secondary-cs text-sm"
                  >{{ getters?.getPlan?.used_limits?.workspaces }}/{{
                    getters?.getPlan?.subscription?.limits?.workspaces >= 1000 ?
                        '&#8734;' : getters?.getPlan?.subscription?.limits?.workspaces
                  }}</span
                >
              </div>
              <div class="flex gap-[1.375rem] max-w-[18.125rem] items-center">
                <p class="min-w-[4.313rem] text-sm leading-3">Members</p>
                <div
                  class="w-[7.25rem] bg-gray-cs-100 rounded-lg overflow-hidden h-2"
                >
                  <div
                    class="bg-primary-cs-900 text-xs leading-none py-1"
                    :style="{
                      width: `${getPercentage(
                        getters?.getPlan?.used_limits?.members,
                        getters?.getPlan?.subscription?.limits?.members,
                      )}%`,
                    }"
                  ></div>
                </div>
                <span class="font-semibold text-secondary-cs text-sm"
                  >{{ getters?.getPlan?.used_limits?.members }}/{{
                    getters?.getPlan?.subscription?.limits?.members >= 1000 ?
                        '&#8734;' : formatNumber(
                            getters?.getPlan?.subscription?.limits?.members,
                        )
                  }}</span
                >
              </div>
              <div class="flex gap-[1.375rem] max-w-[18.125rem] items-center">
                <p class="min-w-[4.313rem] text-sm leading-3">Accounts</p>
                <div
                  class="w-[7.25rem] bg-gray-cs-100 rounded-lg overflow-hidden h-2"
                >
                  <div
                    class="bg-primary-cs-600 text-xs leading-none py-1"
                    :style="{
                      width: `${getPercentage(
                        getters?.getPlan?.used_limits?.profiles,
                        getters?.getPlan?.subscription?.limits?.profiles,
                      )}%`,
                    }"
                  ></div>
                </div>
                <span class="font-semibold text-secondary-cs text-sm"
                  >{{ getters?.getPlan?.used_limits?.profiles }}/{{
                    formatNumber(
                      getters?.getPlan?.subscription?.limits?.profiles,
                    )
                  }}</span
                >
              </div>
            </div>
            <div
              v-if="getters?.getPlan.trial_overs_in >= 0 && isTrialPlan()"
              class="mt-auto bg-[#FFF9EE] h-[2.875rem] flex items-center justify-center rounded-b-lg"
            >
              <p class="text-sm italic font-normal leading-6"
                >Trial ending in
                <span
                  v-if="getters?.getPlan?.trial_overs_in > 0"
                  class="font-bold"
                  >{{ getters?.getPlan?.trial_overs_in }}
                  {{
                    getters?.getPlan?.trial_overs_in > 1 ? 'days' : 'day'
                  }}</span
                >
                <span v-else class="font-bold"> Trial ending today! </span>
              </p>
            </div>
            <div
              v-else
              v-tooltip="{
                content: 'View Billing & Plan Details',
              }"
              class="text-secondary-cs-700 text-sm font-normal flex items-center justify-center mb-[0.75rem] cursor-pointer px-2 py-1 hover:text-primary-cs"
              @click="redirectToBilling"
            >
              See Details
            </div>
          </template>
        </DashboardCard>
      </div>
      <!--      social accounts details-->
      <div v-else ref="socialAccountsCard" class="social-accounts grid-item">
        <DashboardCard body-classes="overflow-hidden">
          <template v-slot:header-content>
            <div class="flex items-center justify-between h-full px-7">
              <p class="text-lg font-medium">Social Accounts </p>
              <img
                v-tooltip="{ content: 'Connect Social Accounts' }"
                src="@assets/img/dashboard/icons/add-more.svg"
                alt=""
                class="cursor-pointer"
                @click="$bvModal.show('get-started-modal')"
              />
            </div>
          </template>
          <template v-slot:body-content>
            <div
              class="flex flex-wrap items-center justify-center py-7 px-9 gap-4 h-full"
            >
              <template v-if="getters?.getSocialAccountsList?.length">
                <template
                  v-for="(profile, profileIndex) in socialProfiles"
                  :key="`profile-${profileIndex}`"
                >
                  <div class="relative">
                    <img
                      v-tooltip="{
                        content: getPlatformName(profile),
                      }"
                      :src="channelImage(profile, profile.channel_type)"
                      alt=""
                      class="profile-image-rounded"
                      @error="
                        $event.target.src = `https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg`
                      "
                    />
                    <img
                      v-if="profile?.validity !== 'valid'"
                      v-tooltip="{
                        content: `Access token for '${
                          'platform_name' in profile
                            ? profile.platform_name
                            : profile.name
                        }' has expired.`,
                      }"
                      alt=""
                      src="@assets/img/common/alert.svg"
                      class="absolute -top-1.5 right-0 w-[1.063rem] h-[1.063rem]"
                    />
                    <img
                      :src="getSocialImageRounded(profile.channel_type)"
                      alt=""
                      class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                    />
                  </div>
                </template>
                <div
                  v-if="getters?.getSocialAccountsList.length > 9"
                  v-tooltip="{
                    content: 'View All Social Accounts',
                  }"
                  class="profile-image-rounded bg-primary-cs-600 text-white text-sm font-semibold flex justify-center items-center cursor-pointer"
                  @click="redirectToSocialAccounts"
                >
                  + {{ getters?.getSocialAccountsList.length - profilesToShow }}
                </div>
              </template>
              <template v-else>
                <CstButton
                  variant="primary"
                  class="!px-3 !py-[0.438rem] !rounded"
                  @click="$bvModal.show('get-started-modal')"
                  ><p class="text-xs font-normal"
                    >Connect Social Account</p
                  ></CstButton
                >
              </template>
            </div>
          </template>
        </DashboardCard>
      </div>
      <!--      news-->
      <div class="grid-item whats-new card-hover-border">
        <div
          v-if="newsLoader"
          class="flex items-center justify-center w-full h-full"
        >
          <clip-loader :color="'#436aff'" :size="'2rem'" />
        </div>
        <DashboardAnnouncementsCarousel
          v-else-if="newsData?.announcements?.length"
          :news-data="newsData.announcements"
        />
      </div>

      <!--   2nd row -->
      <!--      content publishing-->
      <div class="content-publishing grid-item">
        <DashboardCard body-classes="overflow-hidden justify-center">
          <template v-slot:header-content>
            <div class="flex items-center justify-between h-full pl-7 pr-4">
              <p class="text-lg font-medium">Content Publishing</p>
              <div class="flex gap-2">
                <CstDropdown
                  button-classes="flex justify-between bg-main-gray !py-2 !pl-2 !pr-[0.625rem] !border-gray-cs-100 !bg-gray-cs-200"
                  container-classes="w-[8rem]"
                >
                  <template v-slot:selected>
                    <p class="text-secondary-cs text-sm leading-none">{{
                      contentPublishingDateFilterText
                    }}</p>
                  </template>
                  <template v-slot:arrow>
                    <i class="fa fa-angle-down text-md text-secondary-cs"></i>
                  </template>
                  <template v-slot>
                    <template
                      v-for="(
                        publishingFilter, publishingFilterIndex
                      ) in dateFilters"
                      :key="`date-filter-${publishingFilterIndex}`"
                    >
                      <CstDropdownItem
                        @click="getContentPublishingStats(publishingFilter)"
                        ><p class="text-secondary-cs text-sm">{{
                          publishingFilter
                        }}</p>
                      </CstDropdownItem>
                    </template>
                  </template>
                </CstDropdown>
              </div>
            </div>
          </template>
          <template v-slot:body-content>
            <div
              v-if="contentPublishingLoader"
              class="flex items-center justify-center w-full h-full"
            >
              <clip-loader :color="'#436aff'" :size="'2rem'" />
            </div>
            <template v-else>
              <ContentPublishingDonut
                :published-posts-value="contentPublishingData?.published"
                :scheduled-posts-value="contentPublishingData?.scheduled"
                :partially-failed-posts-value="contentPublishingData?.partial"
                :failed-posts-value="contentPublishingData?.failed"
              />
              <div
                class="mt-auto mb-[3.125rem] flex items-center justify-center px-5"
              >
                <div
                  v-tooltip="{
                    content: contentPublishingData?.published
                      ? 'View Published Posts'
                      : '',
                  }"
                  class="cursor-pointer"
                  @click="
                    redirectToPlanner(
                      'published',
                      contentPublishingDateFilterText,
                    )
                  "
                >
                  <div
                    class="w-9 h-9 flex items-center justify-center rounded-[8px] bg-[#DBF1E6] bg-opacity-50"
                  >
                    <img
                      src="@assets/img/icons/statuses/published_new.svg"
                      alt=""
                      class="w-5 h-5"
                    />
                  </div>
                  <p
                    class="text-secondary-cs text-sm font-normal mt-2 mb-[0.125rem]"
                    >Published Posts</p
                  >
                  <p class="text-secondary-cs text-xl font-black"
                    >{{
                      contentPublishingData?.published
                        ? contentPublishingData.published
                        : '-'
                    }}
                  </p>
                </div>
                <img
                  src="@assets/img/dashboard/icons/content-separator.svg"
                  class="content-publishing-separator"
                  alt=""
                />
                <div
                  v-tooltip="{
                    content: contentPublishingData?.scheduled
                      ? 'View Scheduled Posts'
                      : '',
                  }"
                  class="cursor-pointer"
                  @click="
                    redirectToPlanner(
                      'scheduled',
                      contentPublishingDateFilterText,
                    )
                  "
                >
                  <div
                    class="w-9 h-9 flex items-center justify-center rounded-lg bg-[#FDF2DC] bg-opacity-50"
                  >
                    <img
                      src="@assets/img/icons/statuses/scheduled.svg"
                      alt=""
                      class="w-5 h-5"
                    />
                  </div>
                  <p
                    class="text-secondary-cs text-sm font-normal mt-2 mb-[0.125rem]"
                    >Scheduled Posts</p
                  >
                  <p class="text-secondary-cs text-xl font-black"
                    >{{
                      contentPublishingData?.scheduled
                        ? contentPublishingData.scheduled
                        : '-'
                    }}
                  </p>
                </div>
                <img
                  src="@assets/img/dashboard/icons/content-separator.svg"
                  class="content-publishing-separator"
                  alt=""
                />
                <div
                  v-tooltip="{
                    content: contentPublishingData?.partial
                      ? 'View Partially Failed'
                      : '',
                  }"
                  class="cursor-pointer"
                  @click="
                    redirectToPlanner(
                      'partial',
                      contentPublishingDateFilterText,
                    )
                  "
                >
                  <div
                    class="w-9 h-9 flex items-center justify-center rounded-[8px] bg-[#FFE7EC] bg-opacity-50"
                  >
                    <img
                      src="@assets/img/icons/statuses/partially_failed.svg"
                      alt=""
                      class="w-5 h-5"
                    />
                  </div>
                  <p
                    class="text-secondary-cs text-sm font-normal mt-2 mb-[0.125rem]"
                    >Partially Failed</p
                  >
                  <p class="text-secondary-cs text-xl font-black"
                    >{{
                      contentPublishingData?.partial
                        ? contentPublishingData.partial
                        : '-'
                    }}
                  </p>
                </div>
                <img
                  src="@assets/img/dashboard/icons/content-separator.svg"
                  class="content-publishing-separator"
                  alt=""
                />
                <div
                  v-tooltip="{
                    content: contentPublishingData?.failed
                      ? 'View Failed Posts'
                      : '',
                  }"
                  class="cursor-pointer"
                  @click="
                    redirectToPlanner('failed', contentPublishingDateFilterText)
                  "
                >
                  <div
                    class="w-9 h-9 flex items-center justify-center rounded-[8px] bg-[#F7DDDE] bg-opacity-50"
                  >
                    <img
                      src="@assets/img/icons/statuses/failed.svg"
                      alt=""
                      class="w-5 h-5"
                    />
                  </div>
                  <p
                    class="text-secondary-cs text-sm font-normal mt-2 mb-[0.125rem]"
                    >Failed Posts</p
                  >
                  <p class="text-secondary-cs text-xl font-black"
                    >{{
                      contentPublishingData?.failed
                        ? contentPublishingData.failed
                        : '-'
                    }}
                  </p>
                </div>
              </div>
            </template>
          </template>
        </DashboardCard>
      </div>
      <!--      inbox details-->
      <div class="inbox grid-item">
        <DashboardCard>
          <template v-slot:header-content>
            <div class="flex items-center justify-between h-full pl-7 pr-4">
              <p class="text-lg font-medium">Inbox</p>
              <CstButton
                variant="outlined"
                :disabled="!inboxAccess.allowed"
                class="!px-3 !py-[0.438rem] !rounded !border-primary-cs !shadow-none"
                @click="redirectToInbox('unassigned')"
                ><p class="text-xs font-normal">See Details</p></CstButton
              >
            </div>
          </template>
          <template v-slot:body-content>
            <div
              v-if="inboxLoader"
              class="flex items-center justify-center w-full h-full"
            >
              <clip-loader :color="'#436aff'" :size="'2rem'" />
            </div>
            <div v-else class="flex-grow-1 flex flex-col relative">
              <div
                v-if="!inboxAccess.allowed"
                v-tooltip="inboxAccess.error.message"
                class="h-full w-full bg-white/90 cursor-pointer absolute bottom-0 right-0 flex flex-col items-center justify-center"
                @click="onClickInboxLock"
              >
                <img
                  src="@assets/img/dashboard/icons/lock.svg"
                  :draggable="false"
                  alt=""
                />
                <p class="text-secondary-cs font-normal text-base"
                  >Upgrade Plan</p
                >
              </div>
              <div class="flex items-center justify-center h-full">
                <div
                  v-tooltip="{
                    content: inboxData?.ASSIGNED ? 'View My Assignments' : '',
                  }"
                  class="cursor-pointer"
                  @click="redirectToInbox('mine')"
                >
                  <img
                    src="@assets/img/dashboard/icons/clip-board.svg"
                    :draggable="false"
                    alt=""
                    class="w-9 h-9"
                  />
                  <p class="text-secondary-cs text-sm font-normal mt-3 mb-1.5"
                    >My Assignments</p
                  >
                  <p class="text-secondary-cs text-xl font-black">{{
                    inboxData?.MINE ? inboxData.MINE : '-'
                  }}</p>
                </div>
                <img
                  src="@assets/img/dashboard/icons/content-separator.svg"
                  class="inbox-separator"
                  alt=""
                />
                <div
                  v-tooltip="{
                    content: inboxData?.UNASSIGNED
                      ? 'View Total Unassigned'
                      : '',
                  }"
                  class="cursor-pointer"
                  @click="redirectToInbox('unassigned')"
                >
                  <img
                    src="@assets/img/dashboard/icons/unassigned-box.svg"
                    :draggable="false"
                    alt=""
                    class="w-9 h-9"
                  />
                  <p class="text-secondary-cs text-sm font-normal mt-3 mb-1.5"
                    >Total Unassigned</p
                  >
                  <p class="text-secondary-cs text-xl font-black">
                    {{ inboxData?.UNASSIGNED ? inboxData.UNASSIGNED : '-' }}</p
                  >
                </div>
                <img
                  src="@assets/img/dashboard/icons/content-separator.svg"
                  :draggable="false"
                  class="inbox-separator"
                  alt=""
                />
                <div
                  v-tooltip="{
                    content: inboxData?.MARKED_AS_DONE
                      ? 'View Total Assigned'
                      : '',
                  }"
                  class="cursor-pointer"
                  @click="redirectToInbox('assigned')"
                >
                  <img
                    src="@assets/img/dashboard/icons/assigned-check.svg"
                    :draggable="false"
                    alt=""
                    class="w-9 h-9"
                  />
                  <p class="text-secondary-cs text-sm font-normal mt-3 mb-1.5"
                    >Total Assigned</p
                  >
                  <p class="text-secondary-cs text-xl font-black">
                    {{
                      inboxData?.MARKED_AS_DONE ? inboxData.ASSIGNED : '-'
                    }}</p
                  >
                </div>
              </div>
            </div>
          </template>
        </DashboardCard>
      </div>
      <!--      notifications-->
      <div class="notification grid-item">
        <DashboardCard body-classes="overflow-hidden p-1">
          <template v-slot:header-content>
            <div class="flex items-center justify-between h-full px-7">
              <p class="text-lg font-medium">My Notifications</p>
              <p
                v-if="allUnReadNotificationsCount > 0"
                class="text-primary-cs text-lg font-medium animate-pulse"
                >{{ allUnReadNotificationsCount }}
              </p>
            </div>
          </template>
          <template v-slot:body-content>
            <DashboardNotifications
              :notification-loader="getters.getNotificationsFetchState"
            />
          </template>
        </DashboardCard>
      </div>
      <!--      to do details-->
      <div class="grid-item to-do">
        <DashboardCard>
          <template v-slot:header-content>
            <div class="flex items-center justify-between h-full pl-7 pr-4">
              <p class="text-lg font-medium">To Do</p>
              <div class="flex gap-2">
                <CstDropdown
                  button-classes="flex justify-between bg-main-gray !py-2 !pl-2 !pr-[0.625rem] !border-gray-cs-100 !bg-gray-cs-200"
                  container-classes="w-[8rem]"
                >
                  <template v-slot:selected>
                    <p class="text-secondary-cs text-sm leading-none">{{
                      approvalPublishingDateFilterText
                    }}</p>
                  </template>
                  <template v-slot:arrow>
                    <i class="fa fa-angle-down text-md text-secondary-cs"></i>
                  </template>
                  <template v-slot>
                    <template
                      v-for="(todoFilter, todoFilterIndex) in dateFilters"
                      :key="`date-filter-${todoFilterIndex}`"
                    >
                      <CstDropdownItem
                        @click="getApprovalPublishingStats(todoFilter)"
                        ><p class="text-secondary-cs text-sm">{{
                          todoFilter
                        }}</p>
                      </CstDropdownItem>
                    </template>
                  </template>
                </CstDropdown>
              </div>
            </div>
          </template>
          <template v-slot:body-content>
            <div
              v-if="contentApprovalLoader"
              class="flex items-center justify-center w-full h-full"
            >
              <clip-loader :color="'#436aff'" :size="'2rem'" />
            </div>
            <div v-else class="flex items-center justify-between h-full px-6">
              <div class="w-2/3 h-full flex items-center justify-center">
                <ToDoBar
                  :approval-posts-value="contentApprovalData?.review"
                  :missed-approval-posts-value="contentApprovalData?.missed"
                  :rejected-posts-value="contentApprovalData?.rejected"
                />
              </div>
              <div
                class="flex h-full w-full flex-wrap justify-between px-4 items-center mr-6"
              >
                <div
                  v-tooltip="{
                    content: contentApprovalData?.review
                      ? 'View Needs Approval Posts'
                      : '',
                  }"
                  class="cursor-pointer w-[4rem]"
                  @click="
                    redirectToPlanner(
                      'under_review',
                      approvalPublishingDateFilterText,
                    )
                  "
                >
                  <p class="text-secondary-cs text-sm font-normal mb-2"
                    >Needs Approval</p
                  >
                  <div class="flex gap-2 items-center justify-start">
                    <div
                      class="w-3 h-3 rounded-[0.125rem] bg-approval-cs"
                    ></div>
                    <p class="text-secondary-cs text-xl font-black"
                      >{{
                        contentApprovalData?.review
                          ? contentApprovalData.review
                          : '-'
                      }}
                    </p>
                  </div>
                </div>
                <div
                  v-tooltip="{
                    content: contentApprovalData?.missed
                      ? 'View Missed Approval Posts'
                      : '',
                  }"
                  class="cursor-pointer w-[4rem]"
                  @click="
                    redirectToPlanner(
                      'missed_review',
                      approvalPublishingDateFilterText,
                    )
                  "
                >
                  <p class="text-secondary-cs text-sm font-normal mb-2"
                    >Missed Approval</p
                  >
                  <div class="flex gap-2 items-center justify-start">
                    <div class="w-3 h-3 rounded-[0.125rem] bg-missed-cs"></div>
                    <p class="text-secondary-cs text-xl font-black"
                      >{{
                        contentApprovalData?.missed
                          ? contentApprovalData.missed
                          : '-'
                      }}
                    </p>
                  </div>
                </div>
                <div
                  v-tooltip="{
                    content: contentApprovalData?.rejected
                      ? 'View Rejected Posts'
                      : '',
                  }"
                  class="cursor-pointer w-[4rem]"
                  @click="
                    redirectToPlanner(
                      'rejected',
                      approvalPublishingDateFilterText,
                    )
                  "
                >
                  <p class="text-secondary-cs text-sm font-normal mb-2"
                    >Rejected Posts</p
                  >
                  <div class="flex gap-2 items-center justify-start">
                    <div
                      class="w-3 h-3 rounded-[0.125rem] bg-rejected-cs"
                    ></div>
                    <p class="text-secondary-cs text-xl font-black"
                      >{{
                        contentApprovalData?.rejected
                          ? contentApprovalData.rejected
                          : '-'
                      }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </DashboardCard>
      </div>

      <!--  3rd row -->
      <!--      ads carousel-->
      <div class="grid-item cs-ads-card card-hover-border">
        <DashboardAdsCarousel />
      </div>
      <!--      youtube videos carousel-->
      <div class="videos rounded-sm p-[1.875rem] flex mx-2">
        <div
          v-if="videosLoader"
          class="flex items-center justify-center w-full h-full"
        >
          <clip-loader :color="'#436aff'" :size="'2rem'" />
        </div>
        <DashboardVideosCarousel
          v-else-if="videosData?.videos?.length"
          :videos-data="videosData.videos"
        />
      </div>
      <!--      support center-->
      <div
        class="grid-item support inline-block overflow-hidden h-full w-full p-1 card-hover-border"
      >
        <DashboardSupportCenter />
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.dashboard {
  display: grid;
  gap: 1.5rem;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  max-width: 125rem;
}

.grid-item {
  border-radius: 8px;
  box-shadow: 0 3px 10px 0 rgba(211, 217, 236, 0.2);
}

// common ones
.videos,
.welcome-banner {
  grid-column: span 3 / auto;
}

.content-publishing,
.inbox,
.to-do {
  grid-column: span 2 / auto;
}

.social-accounts,
.support,
.plan-info,
.cs-ads-card,
.whats-new {
  grid-column: span 1 / auto;
  height: 14.375rem;
}

.notification,
.content-publishing {
  grid-row: span 2 / auto;
  height: 30.25rem;
}

.welcome-banner,
.to-do,
.inbox,
.videos {
  height: 14.313rem;
}

.welcome-banner,
.videos {
  background-image: repeating-linear-gradient(
      0deg,
      rgba(117, 122, 138, 0.5),
      rgba(117, 122, 138, 0.5) 10px,
      transparent 10px,
      transparent 20px,
      rgba(117, 122, 138, 0.5) 20px
    ),
    repeating-linear-gradient(
      90deg,
      rgba(117, 122, 138, 0.5),
      rgba(117, 122, 138, 0.5) 10px,
      transparent 10px,
      transparent 20px,
      rgba(117, 122, 138, 0.5) 20px
    ),
    repeating-linear-gradient(
      180deg,
      rgba(117, 122, 138, 0.5),
      rgba(117, 122, 138, 0.5) 10px,
      transparent 10px,
      transparent 20px,
      #333333 20px
    ),
    repeating-linear-gradient(
      270deg,
      rgba(117, 122, 138, 0.5),
      rgba(117, 122, 138, 0.5) 10px,
      transparent 10px,
      transparent 20px,
      rgba(117, 122, 138, 0.5) 20px
    );
  background-size: 1.5px 100%, 100% 1.5px, 1.5px 100%, 100% 1.5px;
  background-position: 0 0, 0 0, 100% 0, 0 100%;
  background-repeat: no-repeat;
}

// specific ones
.notification {
  grid-column: span 1 / auto;
}

.max-screen-item {
  display: none;
}

.profile-image-rounded {
  width: 2.625rem;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 0.25px solid #e8e8e8;
}

.inbox-separator {
  margin-inline: 3.5rem;
}
.content-publishing-separator {
  margin: 2.25rem;
}

@media (max-width: 640px) {
  .welcome-image {
    display: none;
  }
  .dashboard {
    grid-template-columns: minmax(0, 1fr);
  }
  .welcome-banner,
  .content-publishing,
  .inbox,
  .to-do,
  .notification,
  .videos,
  .whats-new,
  .support,
  .plan-info,
  .social-accounts,
  .cs-ads-card {
    grid-column: span 1 / auto;
  }

  .cs-ads-card {
    grid-row-start: 11;
  }

  .content-publishing-separator {
    margin: 1.25rem;
  }
  .inbox-separator {
    margin-inline: 1.5rem;
  }
}

@media (width > 640px) and (width <= 1000px) {
  .dashboard {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .welcome-banner,
  .content-publishing,
  .inbox,
  .to-do,
  .videos,
  .notification {
    grid-column: span 2 / auto;
  }
  .videos {
    grid-row-start: 9;
  }
  .social-accounts,
  .plan-info,
  .whats-new,
  .support,
  .cs-ads-card {
    grid-column: span 1 / auto;
  }
  .content-publishing-separator {
    margin: 2rem;
  }
}

@media (width > 1000px) and (width <= 1420px) {
  .welcome-image {
    display: none;
  }
  .dashboard {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .welcome-banner,
  .plan-info,
  .social-accounts,
  .whats-new,
  .videos,
  .cs-ads-card,
  .support {
    grid-column: span 2 / auto;
  }
  .notification {
    grid-column-start: 5;
    grid-column-end: 7;
    grid-row-start: 2;
    grid-row-end: 5;
  }
  .content-publishing {
    grid-column: span 4 / auto;
    grid-row: span 2 / auto;
    grid-row-start: 2;
    grid-row-end: 5;
  }
  .to-do,
  .inbox {
    grid-column: span 3 / auto;
  }

  .content-publishing-separator {
    margin: 2rem;
  }
}

@media (width > 1420px) and (width <= 1560px) {
  .dashboard {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .welcome-banner,
  .videos {
    grid-column: span 4 / auto;
  }
  .content-publishing,
  .to-do,
  .inbox {
    grid-column: span 3 / auto;
  }
  .notification,
  .plan-info,
  .social-accounts,
  .whats-new,
  .cs-ads-card,
  .support {
    grid-column: span 2 / auto;
  }
  .inbox-separator {
    margin-inline: 2rem;
  }
  .content-publishing-separator {
    margin: 1.5rem;
  }
}

//@media (width >= 2560px) {
//  .max-screen-item {
//    display: flex;
//    grid-column: span 1 / auto;
//    height: 14.375rem;
//  }
//  .notification {
//    grid-row: span 3 / auto;
//  }
//}
</style>
