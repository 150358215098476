<template>
<div class="relative flex items-center" tabindex="0" @blur="closeBackgroundBox">
<!-- Template List -->
<div
    v-if="isShowTemplateList"
    class="absolute left-full flex bg-gray-50 shadow-lg border space-x-1 ml-2 z-10 rounded-lg p-2 items-center animate-fade-right"
>
  <!-- Template Items -->
  <div
      v-for="(item, index) in computeFacebookBackgroundList"
      :key="index"
      class="cursor-pointer"
      @click.prevent="$emit('set-facebook-background-id', item.preset_id)"
  >
    <!-- Image Templates -->
    <img
        :draggable="false"
        v-if="item.type === 'image'"
        :class="{ 'ring-1 ring-[#0086ff]': item.preset_id === facebookBackgroundId }"
        class="w-7 h-7 border border-transparent rounded-lg"
        :src="require(`@assets/img/composer/facebook_template/${item.thumbnail}`)"
        :alt="item.background_description"
    />
    <!-- Solid/Gradient Templates -->
    <div
        v-tooltip="`${item.preset_id ? '' : 'Remove background'}`"
        v-if="item.type === 'solid' || item.type === 'gradient'"
        :class="[
            'w-7 h-7 border border-transparent rounded-lg',
            { 'ring-1 ring-[#0086ff]': item.preset_id === facebookBackgroundId },
          ]"
        :style="{
            backgroundColor: item.type === 'solid' ? item.background_color : '',
            backgroundImage: item.type === 'gradient' ? item.background_color : '',
          }"
    ></div>
  </div>
  <!-- More Backgrounds Button -->
  <div
      v-tooltip="'More backgrounds'"
      class="cst-editor__toolbar-btn text-[#808080] relative mx-1 cursor-pointer w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md"
      @click="$bvModal.show('facebook_background_modal')"
  >
    <img
        :draggable="false"
        alt="facebook more templates"
        class="w-7 h-7 rounded-lg"
        src="@assets/img/composer/facebook_template/facebook_more_templates.png"
    />
  </div>
</div>

<!-- Toggle Button -->
<div
    v-if="isShowTemplateList"
    class="cst-editor__toolbar-btn text-[#808080] relative cursor-pointer ml-1 w-7 h-7 flex items-center justify-center bg-cs-secondary rounded-md transition-all ease-in-out"
    @click="isShowTemplateList = !isShowTemplateList"
>
  <i class="fas fa-angle-right text-lg font-normal"></i>
</div>

<!-- Show Background List Button -->
<div class="relative" v-if="!isShowTemplateList">
  <div
      v-tooltip="{ content: computeTooltip, allowHTML: true, maxWidth: 500 }"
      :class="{ 'opacity-70 cursor-not-allowed': !isShowBackgroundList }"
      class="relative ml-1 cursor-pointer w-8 h-8 transition-all ease-in-out"
      @click="showBackgroundList"
  >
    <img
        :draggable="false"
        alt="facebook template icon"
        class="w-8 h-8"
        src="@assets/img/composer/facebook_template/facebook_template_icon.png"
    />
  </div>
</div>
</div>
</template>

<script setup>
import {computed, ref} from "vue";
import { useComposerHelper } from '@modules/composer_v2/composables/useComposerHelper'
import { useWindowSize } from '@vueuse/core'

// declarations
const { FACEBOOK_BACKGROUNDS } = useComposerHelper()
const isShowTemplateList = ref(false)
const props = defineProps({
  facebookBackgroundId: {
    type: String,
    default: ''
  },
  facebookGroupSelected: {
    type: Boolean,
    default: false
  },
  facebookPageSelected: {
    type: Boolean,
    default: false
  },
  isAllowedFacebookBackgrounds: {
    type: Boolean,
    default: true
  },
  facebookPostType: {
    type: String,
    default: 'feed'
  },
  characterCount: {
    type: Number,
    default: 0
  }
})

defineEmits(['set-facebook-background-id'])

const { width } = useWindowSize()

const showBackgroundList = () => {
  if(isShowBackgroundList.value) {
    isShowTemplateList.value = !isShowTemplateList.value
  }
}

const closeBackgroundBox = () => {
  if(isShowTemplateList.value) isShowTemplateList.value = false
}

const isShowBackgroundList = computed(() => {
  return props.isAllowedFacebookBackgrounds &&
      props.facebookPostType === 'feed' &&
      props.characterCount <= 130 &&
      props.facebookPageSelected
})

const computeTooltip = () => {
  return `<div class="p-1">
            <p class="text-sm"><strong>Facebook Text Background</strong></p>
            <ul class="text-sm mt-1 !list-disc">
                ${props.facebookGroupSelected || !props.facebookPageSelected ? '<li>- Exclusive to Facebook Pages</li>' : ''}
                ${!props.isAllowedFacebookBackgrounds || props.facebookPostType !== 'feed' ?  '<li>- Limited to Feed posts (Text only)</li' : ''}
                ${props.characterCount > 130 ? '<li>- Maximum 130 characters allowed</li>' : ''}
            </ul>
           </div>
          `
}

const computeFacebookBackgroundList = computed(() => {
  return width.value <= 1440 ?  FACEBOOK_BACKGROUNDS.ranked.slice(0, 5) : FACEBOOK_BACKGROUNDS.ranked
})

</script>

<style scoped>
@keyframes fade-right {
  0% {
    opacity: 0;
    transform: translateX(-2rem); /* From left to right */
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate-fade-right {
  animation: fade-right 0.5s both;
}
</style>
