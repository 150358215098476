<!-- CstConfirmationPopup.vue -->
<script setup>
import { ref } from 'vue'
import ConfirmationContent from '@ui/ConfirmatinContent.vue'

const props = defineProps({
  modalName: {
    type: String,
    default: '',
  },
  isOpen: {
    type: Boolean,
    default: false,
  },
  displayType: {
    type: String,
    default: 'modal',
    validator: (value) => ['modal', 'card'].includes(value),
  },
  primaryButtonText: {
    type: String,
    default: 'Delete',
  },
  secondaryButtonText: {
    type: String,
    default: 'Cancel',
  },
  loading: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['confirmed', 'cancelled', 'update:isOpen'])

const modalRef = ref(null)

const handleConfirm = () => {
  emit('confirmed')
  // closeComponent();
}

const handleCancel = () => {
  emit('cancelled')
  closeComponent()
}

const closeComponent = () => {
  if (props.displayType === 'modal') {
    modalRef.value?.hide()
  } else {
    emit('update:isOpen', false)
  }
}
</script>

<template>
  <b-modal
    v-if="displayType === 'modal'"
    :id="`${modalName}-confirmation-modal`"
    ref="modalRef"
    centered
    dialog-class="flex justify-center items-center"
    hide-footer
    hide-header
    size="md"
    @hidden="$emit('cancelled')"
  >
    <ConfirmationContent
        :on-confirm="handleConfirm"
        :on-cancel="handleCancel"
        :primary-button-text="primaryButtonText"
        :secondary-button-text="secondaryButtonText"
        :loading="loading"
    >
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </ConfirmationContent>
  </b-modal>

  <div
    v-else-if="displayType === 'card'"
    class="bg-white rounded-xl shadow-lg max-w-md w-full"
  >
    <ConfirmationContent :on-confirm="handleConfirm" :on-cancel="handleCancel">
      <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
        <slot :name="name" v-bind="slotData" />
      </template>
    </ConfirmationContent>
  </div>
</template>
