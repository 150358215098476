<script setup>
import { computed, watch } from 'vue'

import debounce from 'lodash.debounce'
import CardsComponent from '@src/modules/analytics/views/overviewV2/components/CardsComponent.vue'
import useOverviewAnalytics from '@src/modules/analytics/views/overviewV2/composables/useOverviewAnalytics.js'
import EngagementsGraph from '@src/modules/analytics/views/overviewV2/components/graphs/EngagementsGraph.vue'
import OverviewTable from '@src/modules/analytics/views/overviewV2/components/OverviewTable.vue'
import PerformanceComparison from '@src/modules/analytics_v3/components/PerformanceComparison'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper'
import useNumber from '@/src/modules/common/composables/useNumber'

const { tooltipHtml, handleImageError } = useComposerHelper()
const { formatNumber } = useNumber()

const {
  routes,
  dateRange,
  isLoadingStates,
  selectedAccount,
  getTopPerformingPlatform,
  selectedPerformanceType,
  performanceRadio,
  getSelectedPerformanceData,
  comparisonType,
  selectedComparisonType,
  getAccountStatistics,

  fetchMedia,
  comparisonGraphTooltipFormatter,
} = useOverviewAnalytics()

watch(
  () => [dateRange, selectedAccount],
  debounce(async () => {
    fetchMedia(routes.SUMMARY)
    fetchMedia(routes.TOP_PERFORMING_GRAPH)
    fetchMedia(routes.GET_PLATFORM_DATA, { type: 'grouped' })
    fetchMedia(routes.GET_PLATFORM_DATA, { type: 'individual' })

  }, 500),
  { deep: true, immediate: true }
)

const isGrouped = computed(() => {
  return selectedPerformanceType.value?.chartType === performanceRadio[0].chartType
})
</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-4 gap-4 mb-7">
      <CardsComponent />
    </div>
    <div class="grid grid-cols-2 gap-4 mb-7">
      <EngagementsGraph
        label="Engagements"
        default-type="line"
      />
      <OverviewTable
        :title="'Top Performing Platforms'"
        :data="getTopPerformingPlatform"
        :is-all-data-fetched="false"
        :headers="['rateOfDistribution', 'totalEngagement']"
        :is-loading="isLoadingStates[routes?.GET_PLATFORM_DATA]"
        :chart-data="{}"
        :platform-name="'overview'"
        :type="'topPlatform'"
        :modal-key="'topPlatform'"
      />
    </div>
    <div class="grid grid-cols-1 gap-4 mb-7">
      <PerformanceComparison
        :title="'Performance Comparison'"
        :legend="{
          show: true,
          data: getSelectedPerformanceData[selectedComparisonType.chartType]?.legends,
        }"
        :x-axis-label="'Engagement Rate'"
        :y-axis-label="'Followers'"
        :is-all-data-fetched="false"
        :data="getSelectedPerformanceData[selectedComparisonType.chartType]?.data"
        :is-loading="isLoadingStates[routes?.GET_PLATFORM_DATA]"
        type="performanceComparison"
        :is-local-image="isGrouped"
        :custom-tooltip-formatter="comparisonGraphTooltipFormatter"
        :xy-axis-keys="{ x: 'xAxis', y: 'yAxis' }"
        :allow-data-zoom="false"
      >
      <template v-slot:header-left>
        <CstDropdown
            dropdown-placement="bottom"
            container-classes="!max-h-96"
            button-classes="flex !w-[16rem] !rounded-none !bg-transparent !border-2 my-0 !border-dashed !border-t-0 !border-l-0 !border-r-0 !border-[#3A4872] !text-[#3A4872] !px-0"
          >
            <template v-slot:arrow>
              <i class="fa fa-angle-down" aria-hidden="true"></i>
            </template>
            <template v-slot:selected>
              <p class="text-base font-weight-500 capitalize">
                {{ selectedComparisonType.label }}
              </p>
            </template>
            <template v-slot>
              <template
                v-for="(item, index) in comparisonType"
                :key="`comparison_type_${index}`"
              >
                <CstDropdownItem @click="selectedComparisonType = item">
                  <p class="text-gray-900 text-sm">{{ item.label }}</p>
                </CstDropdownItem>
              </template>
            </template>
          </CstDropdown>
      </template>
      <template v-slot:header-right >
        <div class="grid place-items-start">
          <div class="grid grid-cols-2 gap-2 rounded-md bg-gray-200 p-2">
            <template
                v-for="(item, index) in performanceRadio"
                :key="`performance_type_${index}`"
              >
              <div>
                <label
                  class="m-0 block cursor-pointer select-none rounded-md p-2 text-center "
                  :class="{
                    'bg-cs-primary text-white': item?.chartType === selectedPerformanceType?.chartType,
                  }"
                  @click="selectedPerformanceType = item"
                >
                  {{item.label}}
                </label>
              </div>
            </template>

          </div>
      </div>
      </template>
      </PerformanceComparison>
    </div>
    <div class="grid grid-cols-1 gap-4 mb-7">
      <div class=" bg-white border cst-editor rounded-sm p-5 flex flex-col gap-4">
        <div class="flex items-center gap-2 mb-6 mt-2">
          <h2 class="text-base font-weight-500 select-none capitalize"> Accounts Statistics </h2>
          <v-menu
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
          >
            <i class="far fa-question-circle p-0 cursor-pointer"></i>
            <template v-slot:popper>
              <p class="text-sm text-gray-900">Watch and compare different statistics of your accounts</p>
            </template>
          </v-menu>
        </div>
        <div class="bg-white-100 account-grid-container ">
          <template v-for="(item) in getAccountStatistics" :key="item">
            <div class="relative cursor-pointer select-none rounded-md border bg-white shadow-md cst-editor ">
              <img :src="item?.platformImage" alt="platform image" class="absolute -top-2 right-0 h-10 w-10 rounded-full object-contain border" @error="handleImageError" />

              <div class="flex flex-col items-center p-4">
                <img :src="item?.image" alt="account photo" class="h-14 w-14 rounded-full object-contain border" @error="handleImageError" />
                <span
                  v-tooltip="{
                    content: tooltipHtml(item?.name),
                    theme: 'light',
                    allowHTML: true,
                  }"
                  class="inline-block max-w-[75%] truncate"
                >
                  {{ item?.name }}
                </span>
              </div>
              <hr class="m-3" />
              <div class="grid grid-cols-2 p-4 gap-y-3">
                <div class="">Platform</div>
                <div class="text-right font-semibold capitalize">{{  item?.platform_type }}</div>
                <div class="">Followers</div>
                <div
                  v-tooltip="{
                    content: tooltipHtml(item?.followers),
                    theme: 'light',
                    allowHTML: true,
                  }"
                  class="text-right font-semibold">{{ formatNumber(item?.followers) }}</div>
                <div class="">Posts</div>
                <div
                  v-tooltip="{
                    content: tooltipHtml(item?.total_posts),
                    theme: 'light',
                    allowHTML: true,
                  }"
                  class="text-right font-semibold">{{ formatNumber(item?.total_posts) }}</div>
                <div class="">Impressions</div>
                <div
                  v-tooltip="{
                    content: tooltipHtml(item?.total_impressions),
                    theme: 'light',
                    allowHTML: true,
                  }"
                  class="text-right font-semibold">{{ formatNumber(item?.total_impressions) }}</div>
                <div class="">Engagements</div>
                <div
                  v-tooltip="{
                    content: tooltipHtml(item?.total_engagement),
                    theme: 'light',
                    allowHTML: true,
                  }"
                  class="text-right font-semibold">{{ formatNumber(item?.total_engagement) }}</div>
              </div>
            </div>

          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.account-grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(13rem, 1fr));
    gap: 16px;
}
</style>
