<template>
  <b-modal
      id="get-welcome-modal"
      body-class="!p-0"
      dialog-class="!max-w-[35.9rem]"
      size="xl"
      centered
      no-close-on-backdrop
      hide-header
      hide-footer
      no-close-on-esc
  >
    <div class="bg-gray-50 lg:!bg-transparent pb-4">
      <img src="@assets/img/onboarding/welcome.svg" class="rounded-lg w-full mb-[1rem]" />
      <div class="px-16">
        <div class="text-center pb-[2.8rem] space-y-4">
          <h2 class="text-[1.8rem] text-[#3C4549] font-bold">Welcome to ContentStudio!</h2>
          <p class="text-[#3C4549] font-normal">You’re successfully registered and just a few steps away from getting fully set up. Let’s get started! 🚀</p>
        </div>
        <div class="w-full bg-gray-100 h-1.5 rounded-md overflow-hidden mb-4">
          <div class="h-full bg-[#2A70FC] rounded-md progress-animation"></div>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script setup>

import {inject} from "vue";

const root = inject('root')
const { $bvModal } = root

const props = defineProps({
  onClose: {
    type: Function,
    required: true
  }
})

const closeWelcomeModal = () => {
  $bvModal.hide('get-welcome-modal')
  props.onClose()
}
</script>
<style scoped>
@keyframes progress {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}

.progress-animation {
  animation: progress 2.3s ease-in-out;
  animation-fill-mode: forwards;
}
</style>
