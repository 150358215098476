<template>
  <div
    v-if="isOpen"
    v-click-away="handleClickAway"
    class="w-max absolute left-0 rounded-lg dropdown-shadow bg-white focus:outline-none z-50"
    style="min-width: 18rem; max-width: 18rem"
  >
    <div class="p-3">
      <template v-if="getReplug.item._id">
        <span class="text-gray-700 block w-full py-2">Add Replug CTA</span>
        <CstDropdown
          class="w-full my-2"
          size="small"
          button-classes="flex justify-between bg-cs-light-gray"
        >
          <template v-if="selectedBrand && selectedBrand._id" v-slot:selected>
            {{ selectedBrand.name }}
          </template>
          <template v-else v-slot:selected> Select Brand </template>
          <CstDropdownItem @click="selectBrand({})"
            >Select Brand</CstDropdownItem
          >
          <template v-for="(item, index) in getReplug.brands" :key="index">
            <CstDropdownItem @click="selectBrand(item)">{{
              item.name
            }}</CstDropdownItem>
          </template>
        </CstDropdown>

        <template v-if="selectedBrand && selectedBrand._id">
          <CstDropdown
            class="w-full my-2"
            size="small"
            button-classes="flex justify-between bg-cs-light-gray"
          >
            <template
              v-if="selectedCampaign && selectedCampaign._id"
              v-slot:selected
            >
              {{ selectedCampaign.name }}
            </template>
            <template v-else v-slot:selected> Select Campaign </template>
            <CstDropdownItem @click="selectCampaign({})"
              >Select Campaign</CstDropdownItem
            >
            <template v-for="(item, index) in selectedBrand.campaign" :key="index">
              <CstDropdownItem @click="selectCampaign(item)">{{
                item.name
              }}</CstDropdownItem>
            </template>
          </CstDropdown>
          <CstInputFields
            :value="inputUrl"
            class="w-full my-2"
            type="text"
            placeholder="URL"
            readonly="readonly"
          />
          <button
            class="flex items-center mt-2 text-center text-sm bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2"
            @click="applyReplug"
          >
            <span>Apply</span>
            <clip-loader
              v-if="loader"
              class="mx-2"
              :color="'#fff'"
              :size="'1rem'"
            />
          </button>
        </template>
      </template>
      <template v-else>
        <div class="text-center">
          <span class="block w-full py-1"
            >You have not connected replug yet.</span
          >
          <button
            class="mt-2 text-center text-sm focus:outline-none rounded-md text-white border-0 px-3 py-2 bg-cs-primary"
            @click="connectAccount('replug', 'connect')"
            >Connect Account
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstInputFields from '@ui/Input/CstInputFields'
import { mapGetters } from 'vuex'
import { fetchLongLinkUrl } from '@src/modules/publish/config/api-utils'
import { UNKNOWN_ERROR } from '@common/constants/messages'
import useIntegrationComposable from "@modules/integration/Composables/useIntegrationComposable";
export default {
  name: 'ReplugDropdown',
  components: {
    CstDropdown,
    CstDropdownItem,
    CstInputFields,
  },
  props: {
    detail: {
      type: Object,
      default: () => ({}),
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
    loader: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['handle-click', 'handle-replug'],
  setup(){
    const {getAuthorizationUrl} = useIntegrationComposable()
    return {getAuthorizationUrl}
  },
  data() {
    return {
      selectedBrand: {},
      selectedCampaign: {},
      inputUrl: '',
      originalLink: '',
    }
  },
  computed: {
    ...mapGetters(['getReplug']),
  },
  watch: {
    loader: function (val) {
      if (!val) this.$emit('handle-click', this.isOpen, 'replug')
    },
  },
  mounted() {
    console.debug('Replug Dropdown mounted')
  },
  beforeUnmount() {
    this.$off('handle-replug')
  },
  methods: {
    handleClickAway() {
      if (!this.loader) this.$emit('handle-click', this.isOpen, 'replug')
    },
    getUrl() {
      let url = ''
      if (this.detail.url) url = this.detail.url
      else {
        let urls = []
        if (this.detail.message)
          urls = this.getLinksFromText(this.detail.message)
        if (urls && urls.length > 0) url = urls[0]
      }
      if (url && this.originalLink !== url) {
        this.originalLink = url
        if (this.isShortLink(url)) {
          this.postRequest(
            fetchLongLinkUrl,
            {
              link: url,
              workspace_id: this.getWorkspaces.activeWorkspace._id,
            },
            (response) => {
              if (response.data.status) {
                this.inputUrl = this.removeExistingUtmParameters(
                  response.data.link
                )
              }
            },
            (response) => {
              console.debug('fetchLongLinkEXCEPTION', response)
              this.alertMessage(UNKNOWN_ERROR, 'error')
            }
          )
        } else {
          this.inputUrl = url
        }
      }
    },
    async connectAccount(type, process) {
      const details = { type, process }

      const baseUrl = window.location.href.split('#')[0];
      details.callback_url = `${baseUrl}#${type}`;

      await this.getAuthorizationUrl({
        platform: type,
        connectionDetails: details,
      })
    },
    selectBrand(brand) {
      this.selectedBrand = brand
    },
    selectCampaign(campaign) {
      this.selectedCampaign = campaign
    },
    applyReplug() {
      if (this.selectedBrand && !this.selectedBrand._id) {
        this.alertMessage('Please select replug brand.', 'error')
        return false
      } else if (this.selectedCampaign && !this.selectedCampaign._id) {
        this.alertMessage('Please select replug campaign', 'error')
        return false
      } else if (!this.inputUrl || this.inputUrl.trim().length === 0) {
        this.alertMessage(
          'Please enter a URL to the message box in order to apply Replug.',
          'info'
        )
        return false
      } else if (!this.isValidUrl(this.inputUrl)) {
        this.alertMessage('Link is not valid.', 'error')
        return false
      }

      this.$emit('handle-replug', {
        link: this.inputUrl,
        campaign: this.selectedCampaign._id,
      })
    },
  },
}
</script>

<style scoped></style>
