// List of all possible features
export const ALL_FEATURES = [
  // standard
  'ai_content_creation',
  'publishing_scheduling',
  'visual_calendar',
  'social_media_analytics',
  'media_assets_library',
  'auto_first_comment',
  'twitter_threads',
  'reels_shorts_scheduling',
  'carousel_posts_scheduling',
  'canva_integration',
  'custom_link_shortener',
  'custom_video_thumbnails',

  // advanced
  'social_inbox',
  'competitor_analytics',
  'campaign_label_analytics',
  'exports_schedule_reports',
  'publishing_queues',
  'evergreen_recycling',
  'bulk_upload_csv',
  'article_automation',
  'video_automation',
  'rss_scheduling',
  'grid_view_planning',
  'blog_publishing',
  'content_discovery',
  'influencer_discovery',
  'team_collaboration',
  'approval_workflow',
  'feedly_integration',
  'twitter_custom_app',
  'twitter_cs_app',
  'shareable_link',
  'insta_collab_post',
  'inbox',

  // agency-unlimited
  'white_label_reports',
  'easy_connect',
  'complete_client_management',
  'dedicated_onboarding',
  'concierge_setup',
  'priority_support',
  'live_training',
  'white_label_addon',
]

// x custom app integration/ insta collab post / automation single key / whitelabel key / shareable post link /
