<template>
  <b-modal
    id="delete-post-modal"
    dialog-class="flex justify-center items-center"
    :body-class="showAccountSelection ? 'min-h-[550px]' : 'min-h-[380px]'"
    centered
    hide-footer
    hide-header
    size="md"
    @show="handleShow"
    @hidden="handleHide"
  >
    <!-- Modal Head -->
    <div class="modal_head">
      <div class="flex item-center justify-between w-full">
        <h4 class="text-xl">
          <template v-if="showAccountSelection">
            <div class="d-flex align-items-center">
              <span class="font-semibold"> Delete Post </span>
              <v-menu
                class=""
                :popper-triggers="['hover']"
                placement="top"
                popper-class="first-comment__info-popover"
                :delay="300"
              >
                <i class="far fa-question-circle p-0 mx-2 cursor-pointer"></i>
                <template v-slot:popper>
                  <p class="text-sm text-gray-900">
                    This will permanently delete the post from both
                    {{appData.businessName}} and the social media platform.
                    <br />
                    <b>Note: </b> Due to API limitations, posts from Facebook
                    Groups, Instagram, Threads, TikTok, Facebook Stories, and
                    GMB video posts cannot be deleted directly from the social
                    media platforms through {{appData.businessName}}. While you can remove
                    these posts within {{appData.businessName}}, you will need to delete
                    them manually on the respective platforms.
                  </p>
                </template>
              </v-menu>
            </div>
          </template>
          <template v-else>
            <span> Delete Post </span>
          </template>
        </h4>
        <a
          href="https://docs.contentstudio.io/article/1040-how-to-delete-a-post"
          class="text-blue-400 text-sm mt-2"
          target="_blank"
        >
          Learn More
        </a>
      </div>

      <button
        type="button"
        class="modal_head__close bg-transparent"
        data-dismiss="modal"
        aria-label="Close"
        @click.stop="handleClose"
        >&times;
      </button>
    </div>

    <!-- Modal Body -->
    <div class="modal_body">
      <div
        v-if="!selectedPlanAccounts.length"
        class="flex items-center justify-center h-32"
      >
        <p class="text-gray-700 text-md text-center">
          Your post has been deleted.
        </p>
      </div>
      <template v-else>
        <template v-if="!showAccountSelection">
          <!-- Deletion Type Selection -->
          <div class="py-2">
            <div
              class="
                flex
                items-start
                gap-2
                p-2.5
                border border-gray-200
                rounded-lg
                mb-2.5
                cursor-pointer
                cst-editor
              "
              :class="{
                'bg-blue-50 border-blue-500':
                  deletionType === 'contentStudioOnly',
              }"
              @click="selectDeletionType('contentStudioOnly')"
            >
              <img
                src="@assets/img/planner/cs-rounded.png"
                alt="cs icon"
                class="w-6 h-6 mr-2"
              />
              <div>
                <h5 class="text-base font-medium"
                  >Delete from {{appData.businessName}} Only</h5
                >
                <p class="text-sm font-normal"
                  >This option will delete the post from {{appData.businessName}} only, but
                  it will remain on the social media platform.</p
                >
              </div>
              <input
                type="radio"
                :checked="deletionType === 'contentStudioOnly'"
              />
            </div>
            <div
              class="
                flex flex-col
                gap-2
                p-2.5
                border border-gray-200
                rounded-lg
                mb-2.5
                cursor-pointer
                cst-editor
              "
              :class="{
                'bg-blue-50 border-blue-500':
                  deletionType === 'contentStudioAndSocial',
              }"
              @click="selectDeletionType('contentStudioAndSocial')"
            >
              <div class="flex items-start">
                <img
                  src="@assets/img/planner/cs-social.png"
                  alt="cs icon"
                  class="w-9 h-6 mr-1"
                />
                <div>
                  <h5 class="text-base font-medium"
                    >Delete from {{appData.businessName}} + Social Media Platforms</h5
                  >
                  <p class="text-sm font-normal"
                    >This option will permanently delete the post from both
                    {{appData.businessName}} and the social media platform.</p
                  >
                </div>
                <input
                  type="radio"
                  :checked="deletionType === 'contentStudioAndSocial'"
                />
              </div>
              <!-- Info -->
              <div class="flex items-start my-2 p-2 bg-[#fff7ac] rounded-md">
                <i
                  class="fa fa-exclamation-triangle h-5 w-5 pt-1 text-black"
                  aria-hidden="true"
                  style="color: #ed900c"
                ></i>
                <p class="px-1 text-sm font-normal text-gray-900">
                  <b>Note: </b>Due to API limitations, posts from
                  <b>Facebook Groups</b>, <b>Instagram</b>, <b>Threads</b>,
                  <b>TikTok</b>, <b>Facebook Stories</b>, and
                  <b>GMB video posts</b> cannot be deleted directly from the
                  social media platforms through {{appData.businessName}}. While you can
                  remove these posts within {{appData.businessName}}, you will need to
                  delete them manually on the respective platforms.
                </p>
              </div>
            </div>
          </div>
        </template>

        <!-- Account Selection Block -->
        <template v-else>
          <div
            v-if="selectedPlanAccounts.length"
            class="mt-3 flex items-center justify-between px-3"
          >
            <h6 class="text-gray-800"> Select Social Accounts </h6>
            <div class="mr-4 gap-2 flex items-center">
              <label for="select-all" class="text-sm text-gray-800 mx-1 pt-2.5"
                >Select All</label
              >
              <input
                id="select-all"
                v-model="selectAll"
                type="checkbox"
                :disabled="isLoading || allAccountsDeleted"
                :class="{
                  'cursor-not-allowed': isLoading || allAccountsDeleted,
                  'cursor-pointer': !isLoading && !allAccountsDeleted,
                }"
                class="
                  h-4
                  w-4
                  checked:border-blue-300
                  rounded-md
                  border-gray-300
                "
                @change="handleSelectAll"
              />
            </div>
          </div>

          <!-- Account Selection -->
          <div
            class="
              flex flex-col
              space-y-3
              text-gray-600
              h-[400px]
              min-h-[300px]
              mt-3
              px-1
              py-2
              overflow-auto
              scrollbar-gutter
              rounded-lg
              border border-gray-300
            "
          >
            <template
              v-for="(account, index) in selectedPlanAccounts"
              :key="index"
            >
              <div
                :class="[
                  'account-container rounded',
                  {
                    'expanded bg-gray-100':
                      showWarningMsg[account._id] ||
                      showErrorMessage[account._id],
                  },
                ]"
              >
                <div
                  class="
                    flex
                    items-center
                    py-2
                    px-3
                    rounded-lg
                    relative
                    cursor-pointer
                    hover:bg-gray-100
                  "
                  @click="handleClick(account)"
                >
                  <span class="relative">
                    <img
                      :src="account?.image"
                      alt=""
                      class="rounded-full object-cover border"
                      data-fallback="0"
                      height="30"
                      width="30"
                      @error="handleImageError"
                    />
                    <img
                      :src="
                        getSocialImage(account.platform_type?.toLowerCase())
                      "
                      alt="social icon"
                      class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                    />
                  </span>
                  <span class="flex flex-col ml-3 flex-1">
                    <span class="flex flex-row">
                      <span
                        class="
                          text-sm text-ellipsis
                          font-normal
                          text-black-100
                          select-none
                        "
                      >
                        {{
                          account.platform.length > 40
                            ? account.platform.substring(0, 30) + '...'
                            : account.platform
                        }}
                      </span>
                    </span>
                    <span class="block text-xs select-none text-gray-800">
                      {{ account.account_type }}
                    </span>
                  </span>
                  <template v-if="account.inProgress">
                    <div
                      v-tooltip="'In Progress'"
                      class="flex align-items-center"
                      style="
                        background-color: #62b5ff;
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        justify-content: center;
                      "
                    >
                      <!-- status section -->
                      <div class="v-spinner">
                        <div class="v-clip inside-loader"></div>
                      </div>
                    </div>
                  </template>

                  <template v-else-if="account.isDeleted">
                    <img
                      src="@src/assets/img/icons/planner/published_icon.svg"
                      alt="Deleted"
                      class="ml-auto w-6 h-6"
                    />
                  </template>
                  <template
                    v-else-if="
                      account.isDeletedFailed || account.deleted_message
                    "
                  >
                    <img
                      v-tooltip="
                        'Post deletion failed. Click to view more information.'
                      "
                      src="@assets/img/icons/planner/failed_icon.svg"
                      alt="failed"
                      class="ml-auto w-5 h-5 cursor-pointer"
                      @click.stop="toggleErrorMessage(account._id)"
                    />
                  </template>

                  <template v-else>
                    <template
                      v-if="isNotDeletablePlatforms(account) || account.error"
                    >
                      <img
                        v-tooltip="'View Warning'"
                        src="@src/assets/img/composer/warning-icon.svg"
                        alt="warning"
                        class="ml-auto mr-2 w-5 h-5 cursor-pointer"
                        @click.stop="toggleWarningMsg(account._id)"
                      />
                      <input
                        :id="`account-${index}`"
                        v-model="deleteCsOnlyAccount"
                        type="checkbox"
                        :value="account._id"
                        class="
                          ml-auto
                          h-4
                          w-4
                          checked:border-blue-300
                          rounded-md
                          border-gray-300
                        "
                        :class="{
                          'cursor-pointer':
                            !isNotDeletablePlatforms(account) && !account.error,
                        }"
                        :disabled="
                          (isNotDeletablePlatforms(account) &&
                            !deleteCsOnlyAccount.includes(account._id)) ||
                          (account.error &&
                            !deleteCsOnlyAccount.includes(account._id))
                        "
                      />
                    </template>
                    <template v-else>
                      <input
                        :id="`account-${index}`"
                        v-model="accountSelection"
                        type="checkbox"
                        :value="account._id"
                        class="
                          ml-auto
                          h-4
                          w-4
                          checked:border-blue-300
                          rounded-md
                          border-gray-300
                          cursor-pointer
                        "
                      />
                    </template>
                  </template>
                </div>
                <Transition name="bounce">
                  <div
                    v-if="showWarningMsg[account._id]"
                    class="flex flex-col px-3 py-1.5"
                  >
                    <hr class="m-0 text-gray-800 mb-2" />
                    <p
                      class="text-sm text-gray-800 flex-1"
                      v-html="getWarningMessage(account)"
                    ></p>
                    <div class="flex items-center mt-2">
                      <input
                        :id="`account-warning-${index}`"
                        v-model="deleteCsOnlyAccount"
                        type="checkbox"
                        :value="account._id"
                        class="
                          ml-2
                          h-4
                          w-4
                          checked:border-blue-300
                          cursor-pointer
                          rounded-md
                          border-gray-300
                          mr-1
                        "
                        @change="handleCsOnlyDelete(account._id)"
                      />
                      <label
                        :for="`account-warning-${index}`"
                        class="
                          text-sm
                          font-normal
                          pt-2.5
                          ml-2
                          text-gray-800
                          cursor-pointer
                        "
                      >
                        Delete from {{appData.businessName}} Only
                      </label>
                    </div>
                  </div>
                </Transition>
                <Transition name="bounce">
                  <div
                    v-if="showErrorMessage[account._id]"
                    class="flex flex-col px-3 py-1.5"
                  >
                    <hr class="m-0 text-gray-800 mb-2" />
                    <div class="flex item-center">
                      <p class="text-sm text-red-600 flex-1">
                        {{ account?.deleted_message }}
                      </p>
                      <div
                        class="
                          text-[#2961D2]
                          cursor-pointer
                          hover:text-[#214EA8]
                          svg-hover
                          align-text-center
                        "
                        @click.stop="handleRetry(account._id)"
                      >
                        <span class="text-sm mx-1 whitespace-nowrap"
                          >Retry</span
                        >
                        <span class="">
                          <img
                            src="@src/assets/img/common/sync-rounded.svg"
                            alt="sync"
                            class="h-5 w-5"
                          />
                        </span>
                      </div>
                    </div>
                    <p class="text-gray-800 text-sm pt-2">
                      {{
                        `Retry deletion or you can delete the post from ${appData.businessName}
                          now, but you will need to manually delete it from ${
                            account.platform_type === 'Gmb'
                              ? 'GMB'
                              : account.platform_type
                          }..`
                      }}
                    </p>
                    <div class="flex items-center mt-2">
                      <input
                        :id="`account-warning-${index}`"
                        v-model="deleteCsOnlyAccount"
                        type="checkbox"
                        :value="account._id"
                        class="
                          ml-2
                          h-4
                          w-4
                          checked:border-blue-300
                          cursor-pointer
                          rounded-md
                          border-gray-300
                          mr-1
                        "
                        @change="handleCsOnlyDelete(account._id)"
                      />
                      <label
                        :for="`account-warning-${index}`"
                        class="
                          text-sm
                          font-normal
                          pt-2.5
                          ml-2
                          text-gray-800
                          cursor-pointer
                        "
                      >
                        Delete from {{appData.businessName}} Only
                      </label>
                    </div>
                  </div>
                </Transition>
              </div>
            </template>
          </div>
        </template>
      </template>
      <!-- Modal Footer -->
      <div
        v-if="selectedPlanAccounts.length > 0"
        class="field_group float-right flex items-end gap-4 py-2"
      >
        <template v-if="allAccountsDeleted">
          <CstButton type="button" variant="secondary" @click.stop="handleClose"
            >Close
          </CstButton>
        </template>
        <template v-else>
          <!-- Back Button -->
          <CstButton
            v-if="showAccountSelection"
            type="button"
            variant="outlined"
            @click.stop="showAccountSelection = false"
            >Back
          </CstButton>

          <!--   Cancel Button       -->
          <CstButton
            v-else
            type="button"
            variant="outlined"
            @click.stop="handleClose"
            >Cancel
          </CstButton>

          <!--          Next button-->
          <CstButton
            v-if="
              deletionType === 'contentStudioAndSocial' && !showAccountSelection
            "
            type="button"
            variant="primary"
            :disabled="deletionType === ''"
            @click.stop="showAccountSelection = true"
            >Next
          </CstButton>

          <!-- Delete Button -->
          <CstButton
            v-if="
              deletionType !== 'contentStudioAndSocial' || showAccountSelection
            "
            variant="primary"
            class="!bg-red-600"
            :disabled="isDisabled"
            @click.stop="handleDelete"
          >
            Delete
          </CstButton>
        </template>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import { computed, inject, ref, watch, onUnmounted, h } from 'vue'
import { swalAttributes } from '@common/constants/common-attributes'
import { useStore } from '@state/base'
import { EventBus } from '@common/lib/event-bus'
import CstButton from '@/src/components/UI/Button/CstButton.vue'
import usePlannerHelper from '@/src/modules/planner_v2/composables/usePlannerHelper'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";

// Declarations
const {
  selectedPlanAccounts,
  deletePlanAccounts,
  unsubscribePusherEvents,
  subscribeAndBindPusherEvents,
  isLoading,
  planId,
} = usePlannerHelper()

const {appData} = useWhiteLabelApplication()

const root = inject('root')
const { $bvModal } = root
const store = useStore()

// States
const accountSelection = ref([])
const selectAll = ref(false)
const showWarningMsg = ref({})
const showErrorMessage = ref({})
const deletionType = ref('')
const showAccountSelection = ref(false)
const deleteCsOnlyAccount = ref([])
const fetchNextPlan = ref(false)
const refetchPlans = ref(false)
// Track that user has performed delete action to refetch plans
const hasPerformedDelete = ref(false)

// Computed properties
const getDeletedAccounts = computed(() =>
  selectedPlanAccounts.value?.filter((account) => account.isDeleted)
)

// Check if the all accounts are deleted
const allAccountsDeleted = computed(
  () =>
    selectedPlanAccounts.value.length > 0 &&
    selectedPlanAccounts.value.length === getDeletedAccounts.value.length
)
/**
 * Computed property to determine if the delete button should be disabled.
 *
 * This property evaluates the current state of the deletion process and returns
 * a boolean indicating whether the delete button should be disabled.
 *
 * @returns {boolean} - True if the delete button should be disabled, false otherwise.
 */
const isDisabled = computed(() => {
  // If no deletion type is selected, disable the delete button
  if (deletionType.value === '') {
    return true
  }
  // If account selection is being shown, disable the delete button if no accounts are selected
  if (showAccountSelection.value) {
    return (
      accountSelection.value.length === 0 &&
      deleteCsOnlyAccount.value.length === 0
    )
  }
  // Otherwise, enable the delete button
  return false
})

// Error While Delete Accounts Exists returns boolean
const errorAccountsExists = computed(() =>
  selectedPlanAccounts.value
    .filter((account) => !getDeletedAccounts.value.includes(account))
    .some((account) => account.deleted_message)
)

// Failed to publish Accounts Exists returns boolean
const failedAccountsExists = computed(() =>
  selectedPlanAccounts.value
    .filter((account) => !account.isDeleted)
    .some((account) => account.error)
)

// Watchers
watch(
  () => accountSelection.value,
  (newValue) => {
    const temp = selectedPlanAccounts.value
      .filter((account) => !getDeletedAccounts.value.includes(account))
      .map((account) => account._id)
    selectAll.value = temp.length === newValue.length
  }
)

// Lifecycle hooks
onUnmounted(() => {
  unsubscribePusherEvents()
})

// Methods

/**
 * Handles the selection of all accounts.
 *
 * This function toggles the selection of all accounts based on the current state of the `selectAll` checkbox.
 * If `selectAll` is checked, it checks for any non-deletable platforms, error accounts, or failed accounts.
 * If any of these conditions are met, it shows a confirmation modal.
 * Otherwise, it selects all accounts that are not already deleted.
 * If `selectAll` is unchecked, it clears the account selection and the `deleteCsOnlyAccount` array.
 */
const handleSelectAll = () => {
  if (selectAll.value) {
    // Check if there are any non-deletable platforms among the selected accounts
    const notDeletablePlatformExists = selectedPlanAccounts.value
      .filter((account) => !getDeletedAccounts.value.includes(account))
      .some((account) => isNotDeletablePlatforms(account))

    // Show confirmation modal if there are non-deletable platforms, error accounts, or failed accounts
    if (
      notDeletablePlatformExists ||
      errorAccountsExists.value ||
      failedAccountsExists.value
    ) {
      showConfirmationModal()
    } else {
      // If all accounts are already deleted, clear the account selection
      if (
        selectedPlanAccounts.value.length === getDeletedAccounts.value.length
      ) {
        accountSelection.value = []
        return
      }

      // Select all accounts that are not already deleted
      accountSelection.value = selectedPlanAccounts.value
        .filter((account) => !getDeletedAccounts.value.includes(account))
        .map((account) => account._id)
    }
  } else {
    // Clear the account selection and the deleteCsOnlyAccount array
    accountSelection.value = []
    deleteCsOnlyAccount.value = []
  }
}

const showConfirmationModal = () => {
  // Message
  const messageVNode = h('div', { class: ['d-flex', 'flex-column'] }, [
    h('div', { class: 'font-bold mb-2' }, 'Limited Selection'),
    h('div', {
      innerHTML:
        'Accounts without restrictions will be included in your selection. However, some accounts have special restrictions and will need to be selected manually. ' +
        'Please review and select these accounts individually.',
    }),
  ])
  // Show the modal
  $bvModal
    .msgBoxConfirm([messageVNode], {
      ...swalAttributes(),
      okTitle: 'Ok',
      cancelTitle: 'Cancel',
    })
    .then((value) => {
      if (value) {
        // Filter accounts that are not in the deleted list and not on non-deletable platforms
        accountSelection.value = selectedPlanAccounts.value
          .filter(
            (account) =>
              !getDeletedAccounts.value.includes(account) &&
              !isNotDeletablePlatforms(account) &&
              !account.error &&
              !account.deleted_message
          )
          .map((account) => account._id)
      } else {
        // Reset all selections and flags if canceled
        accountSelection.value = []
        selectAll.value = false
        deleteCsOnlyAccount.value = []
      }
    })
}

/**
 * Handles the deletion of selected accounts.
 *
 * This function performs the deletion process based on the selected deletion type.
 * If the deletion type is 'contentStudioOnly', it dispatches an action to remove the plan.
 * If the deletion type is 'contentStudioAndSocial', it prepares a payload with the selected accounts
 * and their respective deletion types, then dispatches an action to delete the plan accounts.
 * After the deletion process, it resets the states and closes the modal.
 *
 * @async
 * @function handleDelete
 * @returns {Promise<void>}
 */
const handleDelete = async () => {
  if (deletionType.value === 'contentStudioOnly') {
    const response = await store.dispatch('removePlan', { id: planId.value })
    if (response) {
      await store.dispatch('toastNotification', {
        message: `Post has been deleted successfully from ${appData.value.businessName}.`,
        type: 'success',
      })
      hasPerformedDelete.value = true
      fetchNextPlan.value = true
      resetStates()
      handleClose()
    }
  } else {
    const payload = selectedPlanAccounts.value
      .filter((account) => accountSelection.value.includes(account._id))
      .map((account) => ({
        id: account._id,
        delete_cs: deleteCsOnlyAccount.value.includes(account._id),
      }))
    await deletePlanAccounts(payload)
    if (selectAll.value) {
      showErrorMessage.value = {}
      showWarningMsg.value = {}
    }
    // Set the flag to true to refetch the plans after closing the modal
    hasPerformedDelete.value = true
    refetchPlans.value = true
    resetStates()
  }
}

/**
 * Handles the deletion of accounts from ContentStudio only.
 *
 * This function adds or removes the account ID from the `deleteCsOnlyAccount` array and the `accountSelection` array.
 * It only modifies the `accountSelection` array if the `selectAll` checkbox is not checked.
 *
 * @param {string} accountId - The ID of the account to be added or removed.
 */
const handleCsOnlyDelete = (accountId) => {
  // Add or remove the account ID from the deleteCsOnlyAccount array and accountSelection array
  // Only add and remove ID in account selection if select all is not checked
  accountSelection.value = accountSelection.value.includes(accountId)
    ? accountSelection.value.filter((id) => id !== accountId)
    : [...accountSelection.value, accountId]
}

// Retry the deletion of the account
const handleRetry = async (accountId) => {
  await deletePlanAccounts([{ id: accountId, delete_cs: false }])
  toggleErrorMessage(accountId)
  resetStates()
  hasPerformedDelete.value = true
}

const isNotDeletablePlatforms = (account) => {
  switch (account.platform_type) {
    case 'Facebook':
      return account.account_type !== 'Page' || account.post_type === 'story'
    case 'Instagram':
    case 'TikTok':
      return true
    case 'Threads':
      return true
    case 'Gmb':
      return account.post_type === 'video'
    default:
      return false
  }
}

// show warning message based on platform type
const getWarningMessage = (account) => {
  if (account.error)
    return `Post <b>failed</b> to publish on <b>${account.platform}</b>. It can only be deleted from ${appData.value.businessName}.`
  switch (account.platform_type) {
    case 'Facebook':
      if (account.post_type === 'story') {
        return `Due to API limitations, post from <b>Facebook Stories</b> cannot be deleted via ${appData.value.businessName}. You can delete the post from ${appData.value.businessName} now, but you will need to manually delete it from Facebook.`
      }
      return `Due to API limitations, post from <b>Facebook Groups</b> cannot be deleted via ${appData.value.businessName}. You can delete the post from ${appData.value.businessName} now, but you will need to manually delete it from Facebook.`
    case 'Instagram':
      return `Due to API limitations, post from <b>Instagram</b> cannot be deleted via ${appData.value.businessName}. You can delete the post from ${appData.value.businessName} now, but you will need to manually delete it from Instagram.`
    case 'TikTok':
      return `Due to API limitations, post from <b>TikTok</b> cannot be deleted via ${appData.value.businessName}. You can delete the post from ${appData.value.businessName}, but you will need to manually delete it from TikTok.`
    case 'Threads':
      return `Due to API limitations, post from <b>Threads</b> cannot be deleted via ${appData.value.businessName}. You can delete the post from ${appData.value.businessName} now, but you will need to manually delete it from Threads.`
    case 'Gmb':
      if (account.post_type === 'video') {
        return `Due to API limitations, post from <b>GMB (Video)</b> cannot be deleted via ${appData.value.businessName}. You can delete the post from ${appData.value.businessName} now, but you will need to manually delete it from GMB.`
      }
      return
    default:
      return ''
  }
}

const getSocialImage = (channelType) => {
  if (channelType === 'twitter') {
    return require(`@assets/img/integration/twitter-x-rounded.svg`)
  }
  return channelType
    ? require(`@assets/img/integration/${channelType}-rounded.svg`)
    : ''
}

const handleClose = () => {
  resetStates()
  isLoading.value = false
  $bvModal.hide('delete-post-modal')
}

const handleImageError = (event) => {
  event.target.src =
    'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
}

const toggleWarningMsg = (accountId) => {
  showWarningMsg.value = {
    ...showWarningMsg.value,
    [accountId]: !showWarningMsg.value[accountId],
  }
}

const toggleErrorMessage = (accountId) => {
  showErrorMessage.value = {
    ...showErrorMessage.value,
    [accountId]: !showErrorMessage.value[accountId],
  }
}

const handleShow = () => {
  unsubscribePusherEvents()
  subscribeAndBindPusherEvents()
  resetStates()
  selectedPlanAccounts.value.forEach((account) => {
    if (account?.deleted_message) {
      showErrorMessage.value = {
        ...showErrorMessage.value,
        [account._id]: true,
      }
    }
  })
}

const handleClick = (account) => {
  if (account.isDeleted || isLoading.value) return
  if (isNotDeletablePlatforms(account) || account.error) {
    toggleWarningMsg(account._id)
    return
  } else if (account.isDeletedFailed || account.deleted_message) {
    toggleErrorMessage(account._id)
    return
  }
  accountSelection.value = accountSelection.value.includes(account._id)
    ? accountSelection.value.filter((id) => id !== account._id)
    : [...accountSelection.value, account._id]
}


const selectDeletionType = (type) => {
  deletionType.value = type
}
const handleHide = () => {
  resetStates()
  isLoading.value = false
  deletionType.value = ''
  showAccountSelection.value = false
  deleteCsOnlyAccount.value = []
  showErrorMessage.value = {}
  showWarningMsg.value = {}

  // Refetch the plans after closing the modal if the user has performed a delete action
  if (hasPerformedDelete.value) {
    // check if all accounts are deleted then fetch next or if fetchNextPlan is true then fetch next plan
    if (allAccountsDeleted.value || fetchNextPlan.value) {
      EventBus.$emit('fetch-plans-after-delete-update-preview', {
        refetchPlans: refetchPlans.value,
        fetchNextPlan: true,
      })
    } else {
      EventBus.$emit('fetch-plans-after-delete-update-preview', {
        refetchPlans: refetchPlans.value,
        fetchNextPlan: false,
      })
    }

    hasPerformedDelete.value = false
    fetchNextPlan.value = false
    refetchPlans.value = false
  }
}

const resetStates = () => {
  accountSelection.value = []
  selectAll.value = false
  showWarningMsg.value = {}
  deleteCsOnlyAccount.value = []
}
</script>

<style scoped>
.account-container {
  transition: all 0.3s ease-in-out;
}

.scrollbar-gutter {
  scrollbar-gutter: stable;
}

.bounce-enter-active {
  animation: bounce-in 0.3s;
}

.bounce-leave-active {
  animation: bounce-in 0.5s reverse;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: translateY(-10px) scale(0.9);
  }

  100% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}

.inside-loader {
  height: 1rem;
  width: 1rem;
  border-width: 2px;
  border-style: solid;
  border-color: white white transparent;
  border-radius: 100%;
  background: transparent !important;
}
</style>
