import { computed, ref , onMounted, onBeforeUnmount} from 'vue'
import { useStore } from '@state/base'
import { onboardingStepsURL, setWorkspacePreferencesUrl, fetchWorkspacesUrl, removeMemberURL } from '@src/modules/setting/config/api-utils'
import { workspaceTypes } from '@src/modules/setting/store/states/mutation-types'
import proxy from '@common/lib/http-common'
import sortBy from 'lodash.sortby'
import useToast  from '@src/modules/common/composables/useToast'
import { EventBus } from '@src/modules/common/lib/event-bus'


const userWorkspaces = ref([]);
const isWorkspaceLoading = ref(false)

export default function useWorkspace() {
  const store = useStore()
  const { alertMessage } = useToast()

  onMounted(() => {
    EventBus.$on('reset-users-workspaces', async () => {
      resetUserWorkspaces()
    })
  })

  onBeforeUnmount(() => {
    EventBus.$off('reset-users-workspaces')
  })


  const isActiveUserSuperAdmin = () => {
    const teamMembers = store.getters.getActiveWorkspace.members
    for (let i = 0; i < teamMembers.length; i++) {
      const member = teamMembers[i]
      if (member.user && store.getters.getProfile.email === member.user.email) {
        return member.role === 'super_admin'
      }
    }
    return false
  }

  const getSuperAdmin = () => {
    if (
      store.getters.getActiveWorkspace &&
      store.getters.getActiveWorkspace.members
    ) {
      const superAdmin = store.getters.getActiveWorkspace.members.find(
        (member) => member.role === 'super_admin'
      )
      if (superAdmin) {
        return superAdmin.user
      }
    }
    return store.getters.getProfile
  }

  const onboardingStepsCompleted = async (step = null) => {
    if (step) {
      try {
        const resp = await proxy.post(onboardingStepsURL, {
          workspace_id: store.getters.getActiveWorkspace._id,
          step,
        })

        if (resp.data.status === true) {
          store.commit(
            workspaceTypes.SET_ACTIVE_WORKSPACE_ONBOARDING_STEPS,
            resp.data.steps
          )
        }
      } catch (e) {
        console.error('ERROR IN ONBOARDING STEP', e)
      }
    }
  }

  const isMonthlyPlan = () => {
        const plan = store.getters?.getPlan?.subscription
        return plan?.slug?.includes('monthly') || plan?.display_name?.includes('Monthly')
     }

  const getSubscriptionLimits = (key) => {
    if (
        store.getters?.getPlan &&
        store.getters?.getPlan.subscription &&
        store.getters?.getPlan.subscription.limits &&
        store.getters?.getPlan.subscription.limits[key]
    ) {
      return store.getters.getPlan.subscription.limits[key] ?? 0
    }
    return 0
  }

  const getUsedLimits = (key) => {
    if (
        store.getters?.getPlan &&
        store.getters?.getPlan.used_limits &&
        store.getters?.getPlan.used_limits[key]
    ) {
      return store.getters?.getPlan.used_limits[key] ?? 0
    }
    return 0
  }
  const updateWorkspacePreferences = async (key, data) => {

    try {
      const resp = await proxy.post(
        setWorkspacePreferencesUrl,
        {
          workspace_id: store.getters.getActiveWorkspace?._id,
          preferences: {
            [key]: data,
          },
        }
      )
      if (resp.data.status === true) {
        store.commit(workspaceTypes.SET_WORKSPACE_PREFERENCES, resp.data.preferences)
      }
    } catch (e) {
      console.error('ERROR IN SETTING PREFERENCES', e)
    }
  }

  const getPreferences = computed(() => {
    const activeWorkspace = store.getters.getWorkspaces?.items?.find(
      (item) => item.workspace_id === store.getters.getActiveWorkspace._id
    )

    if (activeWorkspace) {
      return activeWorkspace?.preferences || {}
    }
    return null
  })

  const fetchAllUserWorkspaces = async () => {
    isWorkspaceLoading.value = true
    try {
      const response = await proxy.post(fetchWorkspacesUrl, {})
      if (response.data.status === true) {
        userWorkspaces.value = response.data.workspaces
      }
    } catch (error) {
      console.debug('Exception in fetchWorkspace', error)
    } finally {
      isWorkspaceLoading.value = false
    }
  }

  const filteredWorkspacesOwnedByMe = (me) =>  {
    const ownedByMe = []
    const ownedByOthers = []
    for (let x = 0; x <  userWorkspaces.value.length; x++) {
      const workspace =  userWorkspaces.value[x]

      if (workspace.workspace.user_id === store.getters.getProfile._id) {
        ownedByMe.push(workspace)
      } else {
        ownedByOthers.push(workspace)
      }
    }

    const arrayToUse = me ? ownedByMe : ownedByOthers
    return sortBy(arrayToUse, function (item) {
      return [item.workspace.name.toLowerCase()]
    })
  }

  const updateUserWorkspaces = (info = {}) => {
    console.log('Method:updateUserWorkspaces')
    const { workspace_id, type, fields, updateType = 'default' } = info;
    if (type === 'update') {
      userWorkspaces.value = userWorkspaces.value.map(el => {
        if (el.workspace_id === workspace_id) {
          if(updateType === 'default') {
            Object.keys(fields).forEach(field => {
              el[field] = fields[field];
            });
          }else if (updateType === 'onhold'){
            Object.keys(fields).forEach(field => {
              el['workspace'][field] = fields[field];
            });
          }
        } else if (fields.default) {
          el.default = false;
        }
        return el;
      });
    } else if (type === 'remove') {
      const index = userWorkspaces.value.findIndex(el => el.workspace_id === workspace_id);
      if (index !== -1) {
        userWorkspaces.value.splice(index, 1);
      }
    }
  }

  const changeWorkspaceMemberDetail = (workspaceId, members) => {
    console.debug('Updating workspace members', { workspaceId, members })
     // Get all workspaces from the store
    const allWorkspaces =  userWorkspaces.value

     // Update the members of the specific workspace
    allWorkspaces.map((item) => {
      if (item.workspace._id === workspaceId) {
        item.workspace.members = members
      }
    })
    // Commit the updated workspaces back to the Vuex store

    store.commit(
      workspaceTypes.SET_WORKSPACE_ITEMS,
      JSON.parse(JSON.stringify(allWorkspaces))
    )
  }

  const removeTeamMemberFromUserWS = async (payload) => {
    try {
      console.debug('Method:removeTeamMemberFromUserWS')
      const response = await proxy.post(removeMemberURL, payload)
      if (response.data.status === true) {
        alertMessage(response.data.message, 'success')
        changeWorkspaceMemberDetail(payload.workspace_id, response.data.members)
      } else {
        alertMessage(response.data.message, 'error')
      }
      return response.data
    } catch (error) {
      console.debug('Exception in fetchWorkspace', error)
      return []
    }
  }

  const resetUserWorkspaces = () => {
    // Get all workspaces from the store
    userWorkspaces.value = [];
    isWorkspaceLoading.value = false
  }

  return {
    isWorkspaceLoading,
    userWorkspaces,

    // computed
    getPreferences,
    // onboarding
    onboardingStepsCompleted,
    // permissions
    isActiveUserSuperAdmin,
    getSuperAdmin,
    isMonthlyPlan,
    getSubscriptionLimits,
    getUsedLimits,
    updateWorkspacePreferences,
    fetchAllUserWorkspaces,
    filteredWorkspacesOwnedByMe,
    updateUserWorkspaces,
    removeTeamMemberFromUserWS,
    changeWorkspaceMemberDetail,
  }
}
