<!-- /src/modules/billing/components/PricingCard.vue -->
<script setup>
import { computed, ref } from 'vue'
import CstButton from '@ui/Button/CstButton.vue'
import { useBilling } from '@modules/billing/composables/useBilling'

const props = defineProps({
  plan: {
    type: Object,
    required: true,
  },
  isAnnually: {
    type: Boolean,
    required: true,
  },
  changeTrialPlan: {
    type: Boolean,
    default: false,
  },
})

// State
const {
  calculatePrice,
  formatPrice,
  isCurrentPlan,
  socialAccounts,
  handlePlanChange,
} = useBilling()

// Local state
const isLoading = ref(false)

// Computed
const isCurrentPlanCheck = computed(() =>
  isCurrentPlan(props.plan.id, props.isAnnually, props.changeTrialPlan)
)

const isAgencyPlan = computed(() => props.plan.id === 'agency-unlimited')

const totalPrice = computed(() =>
  calculatePrice(props.plan, props.isAnnually, socialAccounts.value)
)

const getFeatureListHeading = computed(() => {
  if (props.plan.id === 'standard') return 'You get:'
  return `Everything in '${
    props.plan.id === 'agency-unlimited' ? 'Advanced' : 'Standard'
  }', plus:`
})

const formattedTotalPrice = computed(() => formatPrice(totalPrice.value))

const formattedAnnualPrice = computed(() => formatPrice(totalPrice.value * 12))

const getSaveAmount = computed(() => {
  if (!isAgencyPlan.value) return props.plan.billing?.yearly?.saveAmount
  const monthlyPrice = calculatePrice(props.plan, false, socialAccounts.value)
  return monthlyPrice * 12 - totalPrice.value * 12
})

const getCTAText = computed(() => {
  if (isCurrentPlanCheck.value) return 'Current Plan'
  if (props.changeTrialPlan) return 'Choose Plan'
  return props.plan?.ctaText
})

// Methods for social accounts handling
const incrementAccounts = () => {
  socialAccounts.value = Number(socialAccounts.value) + 1
}

const decrementAccounts = () => {
  if (socialAccounts.value > 1) {
    socialAccounts.value = Number(socialAccounts.value) - 1
  }
}

const validateNumericInput = (event) => {
  const target = event.target
  // Remove non-numeric characters and convert to number
  let value = target.value.replace(/\D/g, '')
  value = value.slice(0, 5)
  socialAccounts.value = Number(value)
}

const finalizeInput = () => {
  socialAccounts.value = Math.min(
    Math.max(Number(socialAccounts.value) || 25, 25),
    99000
  )
}

const handleCTAClick = async () => {
  if (isCurrentPlanCheck.value) return

  isLoading.value = true
  try {
    await handlePlanChange(props.plan, props.isAnnually, props.changeTrialPlan)
  } catch (error) {
    console.error('Plan change error:', error)
  } finally {
    isLoading.value = false
  }
}
</script>

<template>
  <div class="relative w-full max-w-sm">
    <!-- Popular Badge -->
    <div
      v-if="plan.isPopular"
      class="absolute inset-x-0 top-[-1.65rem] mx-auto text-sm py-1 text-center text-white font-semibold rounded-tl-lg rounded-tr-lg bg-[#007bff] border !border-[#007bff]"
    >
      Most popular
    </div>

    <!-- Card Header -->
    <div
      :class="{ '!border-2 !border-[#007bff]': plan.isPopular }"
      class="flex flex-col h-full p-6 rounded-lg border bg-white"
    >
      <!-- Main Content -->
      <div class="flex-grow space-y-4">
        <div class="flex flex-col md:flex-row items-center gap-2">
          <h3 class="text-2xl font-bold">{{ plan.name }}</h3>
          <span
            v-if="isCurrentPlan(plan?.id, isAnnually)"
            class="bg-yellow-400 h-max leading-none px-3 py-1 rounded-full text-sm w-max"
          >
            Current Plan
          </span>
        </div>

        <!-- Pricing -->
        <div v-if="!changeTrialPlan" class="space-y-1">
          <div class="flex items-baseline space-x-1">
            <span class="text-4xl font-bold">${{ formattedTotalPrice }}</span>
            <span>/month</span>
          </div>
          <div class="text-sm">
            ${{ formattedAnnualPrice }} billed annually
          </div>
          <div v-if="isAnnually" class="text-blue-500 text-sm">
            You'll save ${{ getSaveAmount }} per year
          </div>
        </div>

        <!-- CTA Button -->
        <CstButton
          :disabled="
            isCurrentPlan(plan.id, isAnnually, changeTrialPlan) || isLoading
          "
          :loading="isLoading"
          :text="getCTAText"
          :variant="plan.isPopular ? 'primary' : 'secondary'"
          @click="handleCTAClick"
        />

        <!-- Resource Limits -->
        <div :class="{ 'pt-4': !plan.isPopular }" class="space-y-3">
          <!-- Social Accounts -->
          <div v-if="isAgencyPlan" class="flex items-center justify-between">
            <div class="flex items-center space-x-2">
              <div class="flex items-center rounded-md gap-2">
                <button
                  :disabled="socialAccounts <= 25 || changeTrialPlan"
                  class="px-2 py-1 bg-white hover:bg-gray-100 border rounded-lg !border-[#18181B]"
                  @click="decrementAccounts"
                >
                  <i class="fa fa-minus w-4 h-4"></i>
                </button>
                <input
                  v-model="socialAccounts"
                  class="px-3 py-1.5 border border-[#CED4DA] rounded-lg w-14 text-center"
                  inputmode="numeric"
                  pattern="[0-9]+"
                  type="text"
                  @blur="finalizeInput"
                  @input="validateNumericInput"
                />
                <button
                  :disabled="changeTrialPlan"
                  class="px-2 py-1 bg-white hover:bg-gray-100 border rounded-lg !border-[#18181B]"
                  @click="incrementAccounts"
                >
                  <i class="fa fa-plus w-4 h-4"></i>
                </button>
              </div>
              <span
                >social accounts
                <i
                  v-tooltip="plan?.limits?.socialAccounts?.tooltip"
                  class="far fa-info-circle text-gray-600"
                ></i
              ></span>
            </div>
          </div>
          <div v-else class="flex items-center space-x-2">
            <span
              >{{ plan.limits.socialAccounts.value }}
              social accounts
              <i
                v-tooltip="plan.limits.socialAccounts.tooltip"
                class="far fa-info-circle text-gray-600"
              ></i>
            </span>
          </div>

          <!-- Workspaces -->
          <div class="flex items-center space-x-2">
            <span>
              {{
                typeof plan.limits.workspaces.value === 'number'
                  ? `${plan.limits.workspaces.value} workspace${
                      plan.limits.workspaces.value > 1 ? 's' : ''
                    }`
                  : plan.limits.workspaces.value
              }}
              <i
                v-tooltip="plan.limits.workspaces.tooltip"
                class="far fa-info-circle text-gray-600"
              ></i>
            </span>
          </div>

          <!-- Users -->
          <div class="flex items-center space-x-2">
            <span>
              {{
                typeof plan.limits.users.value === 'number'
                  ? `${plan.limits.users.value} user${
                      plan.limits.users.value > 1 ? 's' : ''
                    }`
                  : plan.limits.users.value
              }}
              <i
                v-tooltip="plan.limits.users.tooltip"
                class="far fa-info-circle text-gray-600"
              ></i>
            </span>
          </div>
        </div>

        <hr
          class="m-0 border !border-[#CED4DA] !border-l-0 !border-r-0 !border-b-0"
        />

        <!-- Features List -->
        <div class="space-y-4 pt-4 pb-6">
          <h4 class="font-medium">{{ getFeatureListHeading }}</h4>
          <ul class="space-y-2">
            <li
              v-for="feature in plan.features"
              :key="feature.text"
              class="flex items-center space-x-2"
            >
              <i class="fa fa-check text-green-500 w-4 h-4"></i>
              <span>{{ feature.text }}</span>
              <span
                v-if="feature.isAddOn"
                class="ml-2 bg-blue-50 text-blue-500 uppercase font-bold text-xs px-3 py-1 rounded-full whitespace-nowrap"
              >
                ADD ON
              </span>
              <i
                v-if="feature.tooltip"
                v-tooltip="{
                  content: feature.tooltip?.content || '',
                  allowHTML: feature.tooltip?.allowHTML || false,
                }"
                class="far fa-info-circle text-gray-600 ml-1.5"
              ></i>
            </li>
          </ul>
        </div>
      </div>

      <hr
        class="m-0 border !border-[#CED4DA] !border-l-0 !border-r-0 !border-b-0"
      />

      <!-- Additional Info -->
      <div
        v-if="plan.additionalInfo"
        v-tooltip="{
          content: plan.additionalInfo.tooltip?.content
            ? plan.additionalInfo.tooltip?.content
            : '',
          allowHTML: plan.additionalInfo.tooltip?.allowHTML,
        }"
        class="pt-2.5 text-sm"
      >
        {{ plan.additionalInfo.value }}
        <i
          v-if="plan.additionalInfo.tooltip?.content"
          class="far fa-info-circle text-gray-600"
        ></i>
      </div>
    </div>
  </div>
</template>
