// File: /src/modules/billing/constants/plansDetails.js

export const planTypes = [
  'standard-monthly',
  'standard-annually',
  'advanced-monthly',
  'advanced-annually',
  'agency-unlimited-monthly',
  'agency-unlimited-annually',
]
export const planTypesWithoutSuffix = [
  'standard',
  'advanced',
  'agency-unlimited',
]

const pricingPlans = [
  {
    id: 'standard',
    name: 'Standard',
    description: 'Ideal for solo entrepreneurs and freelancers',
    billing: {
      monthly: {
        price: 29,
        annual: 348,
      },
      yearly: {
        price: 19,
        annual: 228,
        saveAmount: 120,
        discountCode: 'ANNUAL34OFF',
      },
    },
    limits: {
      socialAccounts: {
        value: 5,
        tooltip:
          'The number of social accounts/pages you can connect to ContentStudio.',
      },
      workspaces: {
        value: 1,
        tooltip: 'A personalized dashboard to manage a client or a brand.',
      },
      users: {
        value: 1,
        tooltip:
          'A user is an individual login who manages the social media account(s) connected in a workspace.',
      },
    },
    features: [
      { text: 'AI content creation' },
      { text: 'Publishing & scheduling' },
      { text: 'Visual content calendar' },
      { text: 'Social media analytics' },
      { text: 'Media & assets library' },
      { text: 'Auto first comment' },
      { text: 'X (Twitter) threads' },
      { text: 'Reels and shorts scheduling' },
      { text: 'Carousel posts scheduling' },
    ],
    additionalInfo: {
      value: 'Additional limits not available',
      tooltip: '',
    },
    isPopular: false,
    ctaText: 'Upgrade Now',
  },
  {
    id: 'advanced',
    name: 'Advanced',
    description: 'Tailored for growing businesses and teams',
    billing: {
      monthly: {
        price: 69,
        annual: 828,
      },
      yearly: {
        price: 49,
        annual: 588,
        saveAmount: 240,
        discountCode: 'ANNUAL29OFF',
      },
    },
    limits: {
      socialAccounts: {
        value: 10,
        tooltip:
          'The number of social accounts/pages you can connect to ContentStudio.',
      },
      workspaces: {
        value: 2,
        tooltip: 'A personalized dashboard to manage a client or a brand.',
      },
      users: {
        value: 2,
        tooltip:
          'A user is an individual login who manages the social media account(s) connected in a workspace.',
      },
    },
    features: [
      { text: 'Social inbox' },
      { text: 'Competitor analytics' },
      { text: 'Campaign & label analytics' },
      { text: 'Exports and schedule reports' },
      { text: 'Publishing queues' },
      { text: 'Evergreen recycling' },
      { text: 'Bulk upload posts via CSV' },
      { text: 'Schedule posts via RSS' },
      { text: 'Grid view for planning' },
      { text: 'Blog publishing' },
      { text: 'Content discovery' },
      { text: 'Influencer discovery' },
      { text: 'Team collaboration' },
      { text: 'Approval workflow' },
    ],
    additionalInfo: {
      value: 'Additional limits are available',
      tooltip: {
        content: `
                    <p class="text-xs">$5 per extra social account</p>
                    <p class="text-xs">$10 per extra user</p>
                    <p class="text-xs">$10 per extra workspace</p>
                `,
        allowHTML: true,
      },
    },
    isPopular: false,
    ctaText: 'Upgrade Now',
  },
  {
    id: 'agency-unlimited',
    name: 'Agency Unlimited',
    description: 'Perfect for digital marketing agencies',
    billing: {
      monthly: {
        price: 139,
        annual: 1668,
      },
      yearly: {
        price: 99,
        annual: 1188,
        saveAmount: 480,
        discountCode: 'ANNUAL29OFF',
      },
    },
    limits: {
      socialAccounts: {
        value: 25,
        tooltip:
          'The number of social accounts/pages you can connect to ContentStudio.',
      },
      workspaces: {
        value: 'Unlimited workspaces',
        tooltip: 'A personalized dashboard to manage a client or a brand.',
      },
      users: {
        value: 'Unlimited users',
        tooltip:
          'A user is an individual login who manages the social media account(s) connected in a workspace.',
      },
    },
    features: [
      {
        text: 'White label',
        isAddOn: true,
        tooltip: {
          content: `
                        <div class="text-xs">
                          <div class="flex gap-2 mb-2">
                            <div class="bg-blue-500 text-white px-3 py-1 rounded">
                              $50 / month
                              <div class="text-[10px]">Billed Monthly</div>
                            </div>
                            <div class="bg-blue-500 text-white px-3 py-1 rounded ml-2">
                              $500 / year
                              <div class="text-[10px]">Billed Yearly</div>
                            </div>
                          </div>
                            <p class="text-xs">1. Tailor the platform to your brand and showcase your agency's personality with custom logo.</p>
                            <p class="text-xs">2. Configuring your personalized domain name for white label SMM tool, eliminating any references to ContentStudio.</p>
                            <p class="text-xs">3. Send notification email to clients with your own email id.</p>
                            <p class="text-xs">4. Offer clients and content approvers their custom dashboard.</p>
                        </div>
                      `,
          allowHTML: true,
        },
      },
      {
        text: 'EasyConnect',
        tooltip: {
          content:
            'Offer your clients a secure link-based social account connection method that avoids sharing their login credentials.',
          allowHTML: false,
        },
      },
      { text: 'Complete client management' },
      { text: 'White label reports' },
      { text: 'Dedicated onboarding' },
      { text: 'Concierge setup' },
      { text: 'Priority support' },
    ],
    additionalInfo: {
      value: 'Scale easily with volume discounts',
      tooltip: {
        content: `
                    <p class="text-xs">25-50 socials: $5 per extra</p>
                    <p class="text-xs">51-100 socials: $4 per extra</p>
                    <p class="text-xs">101-200 socials: $3 per extra</p>
                    <p class="text-xs">201-500 socials: $2 per extra</p>
                    <p class="text-xs">501+ socials: $1 per extra</p>
                `,
        allowHTML: true,
      },
    },
    isPopular: true,
    ctaText: 'Upgrade Now',
  },
]

export default pricingPlans
