import {ref, inject} from 'vue'
import {useStore} from "@state/base";
import proxy from "@common/lib/http-common";
import {deletePlannerNotesURL, editPlannerNotesURL, savePlannerNotesURL} from "@src/config/api-utils";
import {swalAttributes} from "@common/constants/common-attributes";
import {EventBus} from "@common/lib/event-bus";

const {getters, dispatch} = useStore()

const note = ref({
    id: '',
    note_title: '',
    description: '',
    start_date: '',
    end_date: '',
    note_color: '',
    workspace_id: '',
})


export function usePlannerNotes() {
    const showModal = ref(false)
    const root = inject('root')
    const loader = ref(false)

    const colorOptions = [
        {name: "purple", value: "bg-[#864de9]", hex: "#864de9"},
        {name: "light_red", value: "bg-[#fea28b]", hex: "#fea28b"},
        {name: "dark_blue", value: "bg-[#0095f3]", hex: "#0095f3"},
        {name: "green", value: "bg-[#69c366]", hex: "#69c366"},
        {name: "pink", value: "bg-[#fa6ab6]", hex: "#fa6ab6"},
        {name: "blue", value: "bg-[#5cc6ff]", hex: "#5cc6ff"},
        {name: "light_blue", value: "bg[#7bd3ff]", hex: "#7bd3ff"},
        {name: "orange", value: "bg-[#e7af4d]", hex: "#e7af4d"},
        {name: "red", value: "bg-[#ff6462]", hex: "#ff6462"},
        {name: "blue2", value: "bg[#4da6ff]", hex: "#4da6ff"},
        {name: "lime", value: "bg-[#a4e786]", hex: "#a4e786"},
        {name: "light_orange", value: "bg-[#ffb572]", hex: "#ffb572"},
        {name: "indigo", value: "bg-[#7b8cff]", hex: "#7b8cff"}
    ];

    const dateOptions = () => {
        return {
            defaultValue: '',
            format: 'DD MMM YY',
        }
    }

    const getParsedDate = (date) => {
        return new Date(date).toISOString().split('T')[0]
    }

    const openModal = (editNote = null) => {
        if (editNote) {
            note.value = {
                id: editNote._id,
                note_title: editNote.note_title,
                description: editNote.description,
                start_date: new Date(editNote.start_date),
                end_date: new Date(editNote.end_date),
                note_color: editNote.note_color,
                workspace_id: getters.getActiveWorkspace._id
            }
        } else {
            resetForm()
            note.value.start_date = new Date()
            note.value.end_date = new Date()
        }
        root.$bvModal.show('planner-notes-modal')
    }

    const closeModal = () => {
        root.$bvModal.hide('planner-notes-modal')
        resetForm()
    }

    const saveNote = async () => {
        if (validateNote()) {
            // Implement your save logic here
            loader.value = true

            note.value.start_date = getParsedDate(note.value.start_date)
            note.value.end_date = getParsedDate(note.value.end_date)
            note.value.workspace_id = getters.getActiveWorkspace._id

            if(!note.value.id) {
                delete note.value.id
            }

            try {

                const requestURL = note.value.id ? editPlannerNotesURL : savePlannerNotesURL

                await proxy.post(requestURL, note.value).then(({data}) => {
                    const {status, message} = data
                    if (status) {
                        dispatch('toastNotification', {
                            message: !note.value.id ? 'Note saved successfully.' : 'Note updated successfully.',
                            type: 'success',
                        })
                        EventBus.$emit('refetch-plans')
                        loader.value = false
                    } else {
                        dispatch('toastNotification', {
                          message: message,
                          type: 'error',
                        })
                        loader.value = false
                    }
                })
            } catch (e) {
                dispatch('toastNotification', {
                    message: 'An error occurred while deleting note.',
                    type: 'error'
                })
            }

            closeModal()
        }
    }

    const deleteNote = async () => {
        const options = {
            ...swalAttributes(),
            modalClass: 'sweet-alert-confirmation fade sweet-alert-minimize-composer',
            okVariant: 'danger',
            hideHeader: false
        }
        const res = await root.$bvModal.msgBoxConfirm(
            'Are you sure you want to delete this note?',
            {
                title: 'Delete Note',
                ...options,
                cancelTitle: 'cancel',
                okTitle: 'delete',
            }
        )


        if (res === null || res === false) {
            return
        }
        try {
            await proxy.post(deletePlannerNotesURL, {
                id: note.value.id,
                workspace_id: getters.getActiveWorkspace._id
            }).then(({data}) => {
                const {status} = data
                if (status) {
                    EventBus.$emit('refetch-plans')
                    closeModal()
                    dispatch('toastNotification', {
                        message: 'Note deleted successfully.',
                        type: 'success',
                    })
                    return
                }
            })
        } catch (e) {
            dispatch('toastNotification', {
                message: 'An error occurred while deleting note.',
                type: 'error'
            })
        }
    }

    const resetForm = () => {
        note.value = {
            id: '',
            note_title: '',
            description: '',
            start_date: '',
            end_date: '',
            note_color: '',
        }
    }

    const validateNote = () => {
        const requiredFields = [
            {field: 'note_title', message: 'Note title is required.'},
            {field: 'start_date', message: 'Start date is required.'},
            {field: 'note_color', message: 'Note color is required.'}
        ];

        for (const {field, message} of requiredFields) {
            if (!note.value[field]) {
                dispatch('toastNotification', {message, type: 'error'});
                return false;
            }
        }

        return true;
    };

    const updateNoteField = (field, value) => {
        note.value[field] = value
    }

    return {
        showModal,
        note,
        colorOptions,
        loader,
        openModal,
        closeModal,
        saveNote,
        updateNoteField,
        dateOptions,
        deleteNote
    }
}
