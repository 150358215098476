<script setup>
import CstPopup from '@ui/Popup/CstPopup.vue'
import { ref } from 'vue'
import useDashboard from '@src/modules/dashboard/composables/useDashboard'

const { setDashboardBannerStatus, hasTwitterCustomApp } = useDashboard()

const showPopup = ref(false)

const closePopup = () => {
  showPopup.value = false
}

const openInNewTab = () => {
  window.open(
    'https://docs.contentstudio.io/article/1052-x-twitter-long-post',
    '_blank'
  )
}

const openTwitterCustomAppDoc = () => {
  window.open(
      'https://docs.contentstudio.io/article/966-how-to-connect-with-a-twitter-custom-app',
      '_blank'
  )
}
</script>

<template>
  <div class="px-4 max-w-[125rem] w-full">
    <div
      class="z-10 bg-no-repeat bg-right-bottom flex h-10 rounded-lg py-2 w-full pl-8 pr-6 items-center mt-4 bg-gray-cs-800 relative"
    >
      <div class="flex flex-grow my-auto gap-4 items-center justify-center">
        <span class="relative flex h-3 w-3 items-center justify-center">
          <span
            class="animate-ping absolute inline-flex h-4 w-4 rounded-full bg-primary-cs-200 opacity-75"
          ></span>
          <span
            class="relative inline-flex rounded-full h-3 w-3 bg-primary-cs-600"
          ></span>
        </span>
        <p v-if="hasTwitterCustomApp" class="text-white leading-none">
          <span class="font-semibold">
            Important: X (Twitter) has updated its policy, reducing the daily posting limit to 17 posts per day for Free Custom Apps.
          </span>
          <span
              class="text-primary-cs-500 font-semibold hover:text-primary-cs-600 cursor-pointer"
              @click="openTwitterCustomAppDoc"
          >
            [learn more]
          </span>
        </p>

        <p v-else class="text-white leading-none">
          <span class="font-semibold"
            >Exciting Update! X (Twitter) Premium users can now publish
            long-form posts of up to 25,000 characters!</span
          >
          <router-link
            class="text-primary-cs-500 font-semibold hover:text-primary-cs-600 ml-1"
            :to="{ name: 'social', params: { id: 'twitter' } }"
          >
            {{ '[connect account]' }}
          </router-link>
          <span
            class="text-primary-cs-500 font-semibold hover:text-primary-cs-600 cursor-pointer"
            @click="openInNewTab"
          >
            [learn more]
          </span>
        </p>
      </div>
      <i
        class="fas fa-times text-white text-xl font-light cursor-pointer ml-auto"
        @click="showPopup = !showPopup"
      />
      <transition
        enter-active-class="transition ease-out duration-100"
        enter-from-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-from-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <CstPopup
          v-if="showPopup"
          v-click-away="closePopup"
          parent-class="absolute top-[110%] right-0 w-[27.875rem] h-[7.25rem] cursor-default rounded-b-lg rounded-tl-lg"
          confirm-text="Yes, Hide"
          @close-popup="closePopup"
          @confirm-popup="setDashboardBannerStatus"
        >
          <template v-slot:popup-text>
            <p class="text-secondary-cs font-bold mx-6 mt-5 mb-4"
              >Are you sure you want to hide this banner?</p
            >
          </template>
        </CstPopup>
      </transition>
    </div>
  </div>
</template>
