<template v-click-away="handleClickAway">
  <div
    v-if="isOpen"
    v-click-away="handleClickAway"
    class="w-max absolute left-0 rounded-lg dropdown-shadow bg-white focus:outline-none z-50"
    style="
      min-width: 22rem;
      max-width: 22rem;
      max-height: 35rem;
      overflow: hidden;
    "
  >
    <div class="px-3 py-4">
      <span class="text-gray-700 block w-full py-2">
        Create or Select from Saved Hashtags</span
      >
      <CstDropdown
        class="w-full my-2"
        size="small"
        button-classes="flex justify-between bg-cs-light-gray"
      >
        <template v-if="selectedHashtag && selectedHashtag._id" v-slot:selected>
          {{ selectedHashtag.name }}
        </template>
        <template v-else v-slot:selected> Create New Hashtag </template>
        <CstDropdownItem @click="selectHashtag({})"
          >Create New Hashtag</CstDropdownItem
        >
        <template v-for="(item, index) in getWorkspaceHashtags.items" :key="index" >
          <CstDropdownItem @click="selectHashtag(item)">{{
            item.name
          }}</CstDropdownItem>
        </template>
      </CstDropdown>
      <template v-if="selectedHashtag && selectedHashtag._id">
        <div
          class="border rounded-md bg-cs-light-gray text-black w-full p-1 mt-1 text-base"
        >
          <div class="my-3 overflow-y-scroll max-h-28">
            <ul>
              <template v-for="(item, index) in selectedHashtag.items" :key="index" >
                <li class="inline-block bg-white p-1 m-0.5">
                  <span class="text-cs-primary">{{ item }}</span>
                </li>
              </template>
            </ul>
          </div>
          <div class="my-2">
            <ul class="list_desc">
              <li class="mt-2 text-xs">
                {{ selectedHashtag.count }} hashtag(s) from above will be
                randomly chosen
              </li>
              <li
                v-if="selectedHashtag.position === 'Append'"
                class="mt-2 text-xs"
              >
                Hashtag(s) will be appended
              </li>
              <li v-else class="mt-2 text-xs">Hashtag(s) will be replaced</li>
            </ul>
          </div>
          <div class="my-2">
            <button
              class="flex text-sm text-black bg-gray-500 rounded-md border-0 px-3 py-1 ml-auto"
              @click="updateHashtag"
              >Edit</button
            >
          </div>
        </div>
      </template>
      <template v-else>
        <CstInputFields
          class="w-full my-2"
          type="text"
          :value="editHashtag.name"
          placeholder="Name"
          @value="(val) => (editHashtag.name = val)"
        />
        <input
          v-model="itemInput"
          type="text"
          class="my-1 w-full px-2 py-2.5 text-sm rounded-lg color-border shadow-xs font-normal text-gray-900 hover:bg-gray-50 focus:outline-none bg-cs-light-gray"
          placeholder="Hashtags"
          @keyup.enter="addHashtagItems"
          @keyup.space="addHashtagItems"
        />
        <div
          v-if="editHashtag.items.length > 0"
          class="border rounded-md bg-cs-light-gray w-full p-1 my-"
        >
          <div class="w-full overflow-y-scroll max-h-28">
            <ul>
              <template v-for="(item, index) in editHashtag.items" :key="index">
                <li
                  class="inline-block bg-white p-1 m-0.5 text-black border text-base"
                >
                  <span class="text-cs-primary text-sm">{{ item }}</span>
                  <span
                    class="text-red-500 ml-1 cursor-pointer"
                    @click.prevent="removeHashtagItem(index)"
                    >&times;</span
                  >
                </li>
              </template>
            </ul>
          </div>
        </div>
        <label style="min-width: 118px" class="text-sm my-3" for="tags_number"
          >Randomly choose
          <input
            id="tags_number"
            v-model="hashtagCount"
            class="mb-0"
            style="margin-bottom: 0"
            type="number"
            min="1"
            :max="editHashtag.items.length"
          />
          hashtag(s) for each post.</label
        >
        <div class="mt-2">
          <span class="text-gray-800">Placement</span>
          <div class="block ml-2 mt-1">
            <CstRadio
              id="draft"
              v-model="editHashtag.position"
              name="placement"
              value="Append"
            >
              <span class="text-sm">Placement</span>
            </CstRadio>
          </div>
          <div class="block ml-2">
            <CstRadio
              id="replace"
              v-model="editHashtag.position"
              name="placement"
              value="Replace"
            >
              <span class="text-sm">Replace (if keyword is matched)</span>
            </CstRadio>
          </div>
        </div>
      </template>
      <button
        v-if="(selectedHashtag && selectedHashtag._id) || editHashtag._id"
        class="mt-3 text-sm bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2"
        @click="applyHashtagFnc"
        >Apply</button
      >
      <button
        v-else
        class="mt-3 text-sm bg-cs-primary focus:outline-none rounded-md text-white border-0 px-3 py-2"
        :disabled="loader"
        @click="saveApplyHashtag"
      >
        <span
          >Save &amp; Apply
          <img
            v-if="loader"
            style="width: 20px; margin-left: 8px"
            src="@src/assets/img/common/gif_loader_white.gif"
            alt=""
        /></span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations,mapActions } from 'vuex'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstInputFields from '@ui/Input/CstInputFields'
import CstRadio from '@ui/Radio/CstRadio'
const clone = require('rfdc/default')
export const hashtagDefault = () => ({
  _id: '',
  name: '',
  items: [],
  count: 1,
  position: 'Append',
})
export default {
  name: 'HashtagDropdown',
  components: {
    CstDropdown,
    CstDropdownItem,
    CstInputFields,
    CstRadio,
  },
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    applyHashtag: {
      type: Function,
      default: () => {},
    },
    type: {
      type: String,
      default: 'common',
    },
    tweetIndex: {
      type: Number,
      default: 0,
    },
    externalHashtags: {
      type: Array,
      default: () => [],
    },
  },
emits: ['handle-click'],
  data() {
    return {
      loader: false,
      itemInput: '',
      selectedHashtag: {},
      editHashtag: hashtagDefault(),
      hashtagCount: 1,
    }
  },
  computed: {
    ...mapGetters(['getNewWorkspaceHashtagLoader']),
  },
  watch: {
    hashtagCount: {
      handler: function (val) {
        this.editHashtag.count = val
      },
    },
    'editHashtag.items.length': {
      handler: function () {
        this.hashtagCount = this.editHashtag._id ? this.editHashtag.count : this.editHashtag.items.length ? this.editHashtag.items.length : 1
      },
    }
  },
  mounted() {
    console.debug('Hashtags Dropdown mounted')
    this.checkExternalHashtags()
  },
  beforeUnmount() {
    this.$off('handle-hashtags')
  },

  methods: {
    ...mapMutations([
      'SET_NEW_WORKSPACE_HASHTAG'
    ]),

    ...mapActions([
      'saveWorkspaceHashtag'
    ]),
    handleClickAway() {
      if(this.selectedHashtag?._id === '') return
      this.$emit('handle-click', this.isOpen, 'hashtag')
    },
    selectHashtag(tag) {
      console.log('selectHashtag', tag)
      this.selectedHashtag = clone(tag)
      this.editHashtag = hashtagDefault()
    },
    updateHashtag() {
      if (this.selectedHashtag && this.selectedHashtag._id) {

        this.editHashtag = clone(this.selectedHashtag)
        this.selectedHashtag._id = ""
      }
    },
    removeHashtagItem(index) {
      this.editHashtag.items.splice(index, 1)
      if(this.editHashtag.items.length < this.editHashtag.count) {
        this.editHashtag.count = this.editHashtag.items.length
      }
    },
    addHashtagItems() {
      const tagsList = []
      if (this.itemInput) {
        const splitWords = this.itemInput.split(' ')
        if (splitWords) {
          splitWords.filter((tag) => {
            if (tag) {
              if (tag.startsWith('#')) {
                tagsList.push(tag)
              } else {
                tag = '#' + tag
                tagsList.push(tag)
              }
            }
          })
        }
      }
      this.editHashtag.items.splice(
        this.editHashtag.items.length,
        0,
        ...tagsList
      )
      this.itemInput = ''
    },
    async applyHashtagFnc() {
      const value = this.editHashtag && this.editHashtag._id ? clone(this.editHashtag) : clone(this.selectedHashtag);

      if (await this.applyHashtag(this.type, 'apply', clone(value), this.tweetIndex)) {
        this.SET_NEW_WORKSPACE_HASHTAG(clone(value));
        await this.saveWorkspaceHashtag({ type: '', state: this });
        this.resetHashtagSelection();
      }
    },

    resetHashtagSelection() {
      this.selectedHashtag = {};
      this.editHashtag = hashtagDefault();
      this.$emit('handle-click', true, 'hashtag');
    },
    async saveApplyHashtag() {
      const value = clone(this.editHashtag)
      // this.$emit('handle-hashtags','save',value)
      if (await this.applyHashtag(this.type, 'save', value, this.tweetIndex)) {
        this.selectedHashtag = {}
        this.editHashtag = hashtagDefault()
        this.$emit('handle-click', true, 'hashtag')
      }
    },
    checkExternalHashtags() {
      if (this.externalHashtags.length > 0) {
        this.editHashtag = hashtagDefault()
        this.editHashtag.items = clone(this.externalHashtags)
      }
    },
  },
}
</script>

<style lang="scss">
.tag_input {
  &:disabled {
    background-color: #f5f5f5;
  }
  @apply w-full my-1 rounded-md px-2.5 py-2 text-sm border-0 font-normal text-gray-800  bg-gray-200;
}
input[type='number']::-webkit-inner-spin-button {
  opacity: 1;
}
.list_desc {
  li {
    position: relative;
    padding-left: 15px;
    &:before {
      position: absolute;
      content: '';
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #989eb5;
      left: 0;
      top: 6px;
    }
  }
}
</style>
