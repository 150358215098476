/**
 *  This file is composable conversion of platformMixin (src/modules/common/mixins/integrations/platformMixin.js)
 *  You can move required methods from platformMixin to this file and use it in your components
 */
import * as _ from 'underscore'

import { ref } from 'vue'
import { useStore } from '@state/base'

const topBannersHeight = ref(0)
export default function usePlatform() {
  const { dispatch } = useStore()

  function isIGPersonalAccount(account) {
    return account?.type === 'personal'
  }
  function hasInstaPermission(channel) {
    if (channel.account_type === 'instagram') {
      const analyticPermission = _.findWhere(channel.permissions, {
        permission: 'instagram_manage_insights',
      })
      return analyticPermission
          ? analyticPermission.status === 'granted'
          : false
    } else return true
  }

  const showToast = async (message, type) => {
    await dispatch('toastNotification', {
      message,
      type,
    })
  }

  return {
    topBannersHeight,
    isIGPersonalAccount,
    hasInstaPermission,
    showToast,
  }
}
