<!-- /src/modules/billing/components/SubscriptionPlansModal.vue -->
<script setup>
import { ref, onUnmounted, inject, computed } from 'vue'
import plansDetails from '@modules/billing/constants/plansDetails'
import SubscriptionPlanCard from '@modules/billing/components/SubscriptionPlanCard.vue'
import CstButton from '@ui/Button/CstButton.vue'
import PlansComparisonTable from '@modules/billing/components/PlansComparisonTable.vue'
import { EventBus } from '@common/lib/event-bus'

defineProps({
  context: {
    type: Object,
    default: () => ({}),
  },
})

const root = inject('root')
const { $bvModal } = root

const comparisonTableRef = ref(null)
const showDetailedComparison = ref(false)
const isAnnually = ref(true)
const modalContext = ref('upgrade-plan')
const showScrollToTopButton = ref(false)

// Listen for context updates
EventBus.$on('set-subscription-modal-context', (context) => {
  modalContext.value = context
})

const changeTrialPlan = computed(
  () => modalContext.value === 'change-trial-plan'
)

onUnmounted(() => {
  EventBus.$off('set-subscription-modal-context')
  const modalBody = document.querySelector('.modal-body')
  if (modalBody) {
    modalBody.removeEventListener('scroll', () => {})
  }
})

const scrollToTop = () => {
  const modalBody = document.querySelector('#scroller-container')
  if (modalBody) {
    modalBody.scrollIntoView({ behavior: 'smooth' })
  }
}

const toggleDetailedComparison = () => {
  showDetailedComparison.value = !showDetailedComparison.value
  if (showDetailedComparison.value) {
    setTimeout(() => {
      comparisonTableRef.value?.scrollIntoView({ behavior: 'smooth' })
    }, 0)

    const modalBody = document.querySelector('.modal-body')
    if (modalBody) {
      modalBody.addEventListener('scroll', () => {
        showScrollToTopButton.value =
          showDetailedComparison.value && modalBody.scrollTop > 400
      })
    }
  }
}

const handleYearlyToggle = (value) => {
  isAnnually.value = value
}

const onHide = () => {
  showDetailedComparison.value = false
  const modalEl = document.querySelector('.subscription-modal')
  if (modalEl) {
    modalEl.classList.add('modal-closing')
  }
}
</script>

<template>
  <b-modal
    id="subscription-plans-modal"
    body-class="overflow-auto no-scrollbar"
    content-class="subscription-modal-content"
    dialog-class="subscription-modal"
    hide-footer
    hide-header
    modal-class="overflow-hidden !p-0"
    @hide="onHide"
  >
    <div id="scroller-container" class="space-y-6 overflow-x-auto p-6 relative">
      <div class="flex justify-center">
        <div
          v-if="!changeTrialPlan"
          class="flex items-center rounded-md bg-gray-100 p-1 w-[26rem]"
        >
          <button
            class="flex-1 rounded-md px-5 py-2.5 text-center transition !border-0"
            :class="isAnnually ? 'bg-transparent' : 'bg-white font-semibold'"
            @click="isAnnually = false"
            >Monthly
          </button>
          <button
            class="flex-1 rounded-md px-4 py-2 text-center transition !border-0"
            :class="isAnnually ? 'bg-white font-semibold' : 'bg-transparent'"
            @click="isAnnually = true"
          >
            <span class="flex items-center justify-center">
              Yearly
              <span
                class="ml-2 bg-blue-50 text-blue-500 uppercase font-bold text-xs px-3 py-1 rounded-full whitespace-nowrap"
              >
                Save up to 34%
              </span>
            </span>
          </button>
        </div>
      </div>
      <div
        v-tooltip="'Close'"
        class="absolute top-0 right-5 flex items-center justify-center bg-gray-100 rounded ml-[12px] w-8 h-8 cursor-pointer p-1"
        @click="$bvModal.hide('subscription-plans-modal')"
      >
        <i class="fa fa-times font-thin text-lg"></i>
      </div>
      <div class="flex gap-6 justify-center pt-6">
        <SubscriptionPlanCard
          v-for="plan in plansDetails"
          :key="plan.id"
          :is-annually="isAnnually"
          :is-popular="plan.id === 'agency-unlimited'"
          :plan="plan"
          :change-trial-plan="changeTrialPlan"
        />
      </div>
      <div class="flex w-full justify-center">
        <CstButton
          text="Compare plans in detail"
          variant="outlined"
          :disabled="showDetailedComparison"
          @click="toggleDetailedComparison"
        />
      </div>

      <div v-if="showDetailedComparison" ref="comparisonTableRef">
        <PlansComparisonTable
          :is-annually="isAnnually"
          :change-trial-plan="changeTrialPlan"
          class="pt-12"
          @toggle-yearly="handleYearlyToggle"
        />
      </div>

      <div
        v-show="showScrollToTopButton"
        class="fixed bottom-4 left-4 z-50 cursor-pointer rounded-full bg-gray-600 opacity-60 p-2 text-white shadow-lg transition-opacity hover:bg-gray-800 hover:opacity-100"
        @click="scrollToTop"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M5 10l7-7m0 0l7 7m-7-7v18"
          />
        </svg>
      </div>
    </div>
  </b-modal>
</template>
