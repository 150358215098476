<template>
  <div class="mx-8 ml-14 2xl:mr-40">
    <div class="my-4 flex items-center">
      <div class="w-2/12"> Post Type</div>
      <div class="space-y-3 w-96">
        <CstDropdown class="w-full" size="small">
          <template v-slot:selected>
            <div class="flex flex-row space-x-2 py-1 items-center">
              <div v-if="!facebookPostType" class="flex gap-x-3 items-center">
                <img src="@src/assets/img/composer/default-option.svg" alt="Image icon" class="w-[0.9rem] h-[0.9rem]">
                <p class="text-sm leading-none">Select Post Type</p>
              </div>
              <div v-else class="flex gap-x-3 items-center">
                <img :src="facebookPostTypeObject[facebookPostType].selected_image" alt="Image icon" class="w-[0.9rem] h-[0.9rem] opacity-60">
                <p class="text-sm leading-none text-gray-800">{{ facebookPostTypeObject[facebookPostType].title }}</p>
              </div>
            </div>
          </template>

          <template v-slot>
            <CstDropdownItem v-for="(postType, key) in facebookPostTypeObject" :key="key" class="group point !pl-4 flex w-full justify-between items-center h-14" :class="{ 'opacity-50 cursor-not-allowed': isDropDownDisabled(key) }" @click="setFacebookPostType(key)">
              <div class="flex gap-x-3 items-center">
                <img :src="isSelectedPostType(postType) ? postType?.selected_image : postType.inactive_image" alt="Image icon" class="w-[0.9rem] h-[0.9rem] opacity-60">
                <div>
                  <p class="text-sm text-gray-900" :class="{ '!text-blue-400': isSelectedPostType(postType) }">{{ postType.title }}</p>
                  <p class="text-xs text-gray-700">{{ postType.description }}</p>
                </div>
              </div>
              <v-menu
                  :key="key"
                  class="mx-2 flex items-center gap-x-1 hidden group-hover:block"
                  :popper-triggers="['hover']"
                  placement="right"
                  :delay="0"
                  popper-class="first-comment__info-popover"
              >
                <!-- This will be the popover target (for the events and position) -->
                <a href="#"
                ><i class="far fa-question-circle text-base text-blue-900"></i
                ></a>
                <!-- This will be the content of the popover -->
                <template v-slot:popper>
                  <div v-if="facebookPostTypeObject[key]?.tooltip_content" v-html="facebookPostTypeObject[key]?.tooltip_content"></div>
                </template>
              </v-menu>
            </CstDropdownItem>
          </template>
        </CstDropdown>
        <div v-if="(facebookPostType !== 'story' && (!isFacebookGroupsSelected || isFacebookPageSelected)) && !facebookBackgroundId" class="flex w-full items-center">
          <div class="flex items-center space-x-2">
            <CstSwitch v-model="tempFacebookShareToStory" size="small"></CstSwitch>
            <p class="text-sm text-gray-900">Also Share to Story</p>
          </div>
          <v-menu
              class="mx-2 flex items-center gap-x-1"
              :popper-triggers="['hover']"
              placement="bottom-start"
              popper-class="first-comment__info-popover"
          >
            <!-- This will be the popover target (for the events and position) -->
            <a href="#"
            ><i class="far fa-question-circle text-base text-blue-900"></i
            ></a>
            <!-- This will be the content of the popover -->
            <template v-slot:popper>
              <div>Enable to share this post as an Facebook story alongside your feed, or reel post.
                <a
                    v-close-popper
                    href="#"
                    class="beacon"
                    data-beacon-article-modal="6656d5a3804514782072c692"
                >Learn more</a>
              </div>
            </template>
          </v-menu>
        </div>
      </div>
    </div>

    <div
      v-if="facebookSharingDetails?.video?.link && isFacebookPageSelected"
      class="my-4 flex flex-row items-center"
    >
      <div class="w-2/12">Video Title</div>
      <div class="w-10/12">
        <CstInputFields
          :value="facebookVideoTitle"
          maxlength="100"
          class="w-full"
          type="text"
          size="small"
          @value="setFacebookVideoTitle"
        />
      </div>
    </div>

    <div class="my-2 flex items-center">
      <div class="w-2/12"></div>
      <div v-if="isReelOrStory" class="w-10/12 flex items-center">
        <div class="bg-orange-100 text-orange-700 rounded-lg p-3">
          <span class="leading-4">
            <template v-if="facebookPostType === 'reel'">
              <span class="font-semibold">Reels Eligibility:</span>
              Reels option is currently only available for Pages. When a Group
              is selected, the system will publish it as a feed post.
              <span class="block mt-1 leading-4">
                Only video file with a duration between 4 and 60 seconds and
                aspect ratios of 9:16 are eligible to be posted as a Reel post.
                <a
                  href="#"
                  data-beacon-article-modal="632bf6d1172d934fa05937d1"
                  target="_blank"
                  >Learn more.</a
                >
              </span>
            </template>
            <template v-else-if="facebookPostType === 'story'">
              <span class="font-semibold">Story Eligibility:</span>
              Story option is currently only available for Pages. When a Group
              is selected, the system will publish it as a feed post.
              <span class="block mt-1 leading-4">
                Only video file with a duration between 4 and 60 seconds and
                aspect ratios of 9:16 are eligible to be posted as a Story post.
              </span>
            </template>
          </span>
        </div>
      </div>
    </div>

    <div class="my-2 flex items-center">
      <div class="w-2/12"></div>
        <div v-if="isFacebookGroupsSelected" class="w-10/12 flex items-center bg-orange-100 text-orange-700 rounded-lg p-3">
          <span class="leading-4">
              Due to API limitations, posting to Facebook groups uses mobile app push notification method.
              <a
                  href="#"
                  class="beacon"
                  data-beacon-article-modal="661cac3f9d250919f9e26672">Learn more</a>
          </span>
        </div>
      </div>
  </div>
</template>

<script>
import CstRadio from '@ui/Radio/CstRadio'
import CstInputFields from '@ui/Input/CstInputFields'
import CstDropdown from '@ui/Dropdown/CstDropdown'
import CstDropdownItem from '@ui/Dropdown/CstDropdownItem'
import CstSwitch from "@ui/Switch/CstSwitch.vue";

export default {
  name: 'FacebookOptions',
  components: {
    CstRadio,
    CstInputFields,
    CstDropdown,
    CstDropdownItem,
    CstSwitch
  },
  props: {
    facebookBackgroundId: {
      type: String,
      default: '',
    },
    facebookShareToStory: {
      type: Boolean,
      default: false,
    },
    facebookSharingDetails: {
      type: Object,
      default: () => {},
    },
    facebookPostType: {
      type: String,
      default: 'feed',
    },
    facebookVideoTitle: {
      type: String,
      default: '',
    },
    isFacebookGroupsSelected: {
      type: Boolean,
      default: false,
    },
    isFacebookPageSelected: {
      type: Boolean,
      default: false,
    },
    isFacebookCarousel: {
      type: Boolean,
      default: false,
  },
  },
  emits: [
    'set-facebook-post-type',
    'setFacebookVideoTitle',
    'set-facebook-share-to-story'
  ],
  data() {
    return {
      facebookPostTypeObject: {
        feed: {
          inactive_image: '/img/instagram-feed-icon-active.svg',
          active_image: '/img/instagram-feed-icon-active-blue.svg',
          selected_image: '/img/instagram-feed-icon-active-blue.svg',
          title: 'Feed',
          description: 'Single image or up to 10 images, or a video',
          new: false,
          tooltip_content: `
            <p class="font-bold text-gray-900 text-sm">Feed</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Single image or up to 10 images, or a video</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : JPEG (JPG), PNG</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 10 MB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: must be within a 4:5 to 1.91:1 range</li>
            </ul>
            <a v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6656d8491dd56877041fb391"
                  >Learn more</a>
          `
        },
        reel: {
          inactive_image: '/img/instagram-reels-icon-active.svg',
          active_image: '/img/instagram-reels-icon-active-blue.svg',
          selected_image: '/img/instagram-reels-icon-active-blue.svg',
          title: 'Reel',
          description: 'Only single video allowed',
          new: false,
          tooltip_content: `
            <p class="font-bold text-gray-900 text-sm">Reel</p>
            <p class="text-gray-800 text-sm">Reels can only be published on Facebook pages.</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Only single video allowed</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : mov or mp4</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Duration: 60 mins maximum, 4 seconds minimum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 1GB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommend 9:16 to avoid cropping or blank space</li>
            </ul>
            <a v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6656d8491dd56877041fb391"
                  >Learn more</a>
          `
        },
        story: {
          inactive_image: '/img/instagram-stories-icon-active.svg',
          active_image: '/img/instagram-stories-icon-active-blue.svg',
          selected_image: '/img/instagram-stories-icon-active-blue.svg',
          title: 'Story',
          description: 'Single or up to 10 images, videos, or a mix',
          new: true,
          tooltip_content: `
            <p class="font-bold text-gray-900 text-sm">Story</p>
            <p class="text-gray-800 text-sm">Story can only be published on Facebook pages.</p>
            <p class="text-gray-800 my-1 text-sm">Single or up to 10 images, videos, or a mix</p>
            <p class="font-bold text-gray-900 text-sm">Image</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : JPEG (JPG), PNG</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 10 MB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommended 9:16 to avoid cropping or blank space</li>
             </ul>
            <p class="font-bold text-gray-900 text-sm">Video</p>
            <ul class="px-4 py-1 !m-0">
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Format : mov or mp4</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">File size: 100MB maximum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Duration: 60 seconds maximum, 4 seconds minimum</li>
              <li class="!list-disc !list-item text-gray-800 text-sm !m-0">Aspect ratio: recommended 9:16 to avoid cropping or blank space</li>
             </ul>
            <a v-close-popper
                      href="#"
                      class="beacon"
                      data-beacon-article-modal="6656d5a3804514782072c692"
                  >Learn more</a>
          `
        },
      },
      tempFacebookShareToStory: this.facebookShareToStory
    }
  },
  computed: {
    getReelDisableTooltip() {
      if(this.facebookBackgroundId) {
        return 'Facebook background option is not available to Reel post type.'
      }
      if (this.isFacebookGroupsSelected && !this.isFacebookPageSelected) {
        return 'Reels can only be published on Facebook pages.'
      }
      if (this.facebookSharingDetails?.image?.length) {
        return 'Only single video allowed.'
      }
      return 'Only single video allowed.'
    },
    getStoryDisableTooltip() {
      if(this.facebookBackgroundId) {
        return 'Facebook background option is not available to Story post type.'
      }
      if (this.isFacebookGroupsSelected && !this.isFacebookPageSelected) {
        return 'Stories can only be published on Facebook pages.'
      }
      if (this.facebookSharingDetails?.image?.length > 1) {
        return 'Story option is only available when one image is selected.'
      }
      if (this.isFacebookCarousel) {
        return 'Story option is not available when carousel is selected.'
      }
      return 'Single or up to 10 images, videos, or a mix.'
    },
    /**
     * Check if the post type is reel or story
     * @returns {boolean}
     */
    isReelOrStory() {
      return ['reel', 'story'].includes(this.facebookPostType)
    },
  },
  methods: {
    setFacebookPostType(value) {
      if(this.isDropDownDisabled(value)) return
      this.$emit('set-facebook-post-type', value)
    },
    setFacebookVideoTitle(value) {
      this.$emit('setFacebookVideoTitle', value)
    },
    /**
     * this method is responsible for disabling the dropdown items
     * @param key
     * @returns {boolean|boolean}
     */
    isDropDownDisabled(key) {

      const multimedia = this.facebookSharingDetails?.multimedia

      if(key === 'feed' && !multimedia?.length && this.facebookSharingDetails.message.length) {
        return false
      }

      if(!multimedia?.length) {
        return true
      }

      if(key === 'feed') {
        return (multimedia.length > 1 && !multimedia.every(item => typeof item === 'string')) ||
            (multimedia?.multimedia?.length === 1
                && typeof multimedia[0] !== 'object')
      }

      if(key === 'reel') {
        return (this.isFacebookGroupsSelected && !this.isFacebookPageSelected) ||
            this.facebookSharingDetails?.image?.length ||
            !!this.facebookBackgroundId || this.facebookSharingDetails?.multimedia?.length > 1
      }

      if(key === 'story') {
        return (this.isFacebookGroupsSelected && !this.isFacebookPageSelected) ||
            this.isFacebookCarousel ||
            !!this.facebookBackgroundId
      }

      return false
    },
    isSelectedPostType(postType) {
      return this.facebookPostType === postType?.title?.toLowerCase()
    },
  },
  watch: {
    tempFacebookShareToStory(value) {
      this.$emit('set-facebook-share-to-story', value)
    }
  }
}
</script>
