<script setup>
import { ref, computed, watch, defineProps } from 'vue'
import AnalyticsCardWrapper from '@src/modules/analytics_v3/components/AnalyticsCardWrapper.vue'
import MainGraph from '@src/modules/analytics/views/linkedin_v2/components/graphs/MainGraph.vue'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox.vue'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import CstDropdown from '@ui/Dropdown/CstDropdown.vue'
import CstDropdownItem from '@ui/Dropdown/IconDropdownItem.vue'
import ChevronDownIcon from '@/src/assets/img/chevron-down.svg'

// state
const {
  overviewFollowersDemographicsData,
  dataZoomOptions,
  barChartOptions: chartOptions,
  isReportView,

  routes,
  isLoadingStates,
} = useLinkedinAnalytics('Follower Demographics')

const props = defineProps({
  type: {
    type: String,
    default: 'city',
    validator(value) {
      return ['city', 'country', 'industry', 'seniority'].includes(
        value.toLowerCase()
      )
    },
  },
})

const optionsDropdown = [
  {
    label: 'Followers Demographics by City',
    key: 'city',
    tooltip:
      'Shows the cumulative growth in your audience over time, reflecting the total number of new followers gained and retained across the selected period.',
  },
  {
    label: 'Followers Demographics by Country',
    key: 'country',
    tooltip:
      'Shows the cumulative growth in your audience over time, reflecting the total number of new followers gained and retained across the selected period.',
  },
  {
    label: 'Followers Demographics by Industry',
    key: 'industry',
    tooltip:
      'Shows the cumulative growth in your audience over time, reflecting the total number of new followers gained and retained across the selected period.',
  },
  {
    label: 'Followers Demographics by Seniority',
    key: 'seniority',
    tooltip:
      'Shows the cumulative growth in your audience over time, reflecting the total number of new followers gained and retained across the selected period.',
  },
]

const selectedOptionType = ref(
  optionsDropdown.find((i) => i.key === props.type) || optionsDropdown[0]
)

const isNoData = computed(() => {
  const options =
    overviewFollowersDemographicsData.value?.[selectedOptionType.value.key] ||
    {}

  return (
    Object.keys(options).length === 0 ||
    options?.values?.length === 0 ||
    options?.buckets?.length === 0
  )
})

const isLoading = computed(
  () => isLoadingStates.value?.[routes.OVERVIEW_FOLLOWERS_DEMOGRAPHICS]
)

const getSelectedType = computed(() => {
  return selectedOptionType.value.label
})

watch(
  () => [overviewFollowersDemographicsData.value, selectedOptionType.value],
  () => {
    const options =
      overviewFollowersDemographicsData.value?.[selectedOptionType.value.key] ||
      {}

    chartOptions.value.series[0].data = options?.values || []
    chartOptions.value.xAxis.name = null

    chartOptions.value.xAxis.data = options?.buckets || []
    chartOptions.value.xAxis.axisLabel.formatter = null

    const yAxisFirstIndex = Array.isArray(chartOptions.value?.yAxis)
      ? chartOptions.value.yAxis[0]
      : chartOptions.value.yAxis
    const yAxisSecondIndex = Array.isArray(chartOptions.value?.yAxis)
      ? chartOptions.value.yAxis[1]
      : chartOptions.value.yAxis

    chartOptions.value.grid = {
      ...chartOptions.value.grid,
      right: 30,
    }
    chartOptions.value.xAxis = {
      ...chartOptions.value.xAxis,
      type: 'value',
      axisLabel: {
        show: false,
      },
      axisPointer: {
        show: false,
      },
      splitLine: {
        lineStyle: {
          color: '#F2F4F6',
        },
      },
    }
    chartOptions.value.yAxis = [
      {
        ...yAxisFirstIndex,
        axisLabel: {
          ...yAxisFirstIndex.axisLabel,
          formatter: (value) => value,
        },
        type: 'category',
        name: '',
        axisPointer: {
          type: 'shadow',
        },
        data: options?.buckets || [],
      },
      {
        ...yAxisSecondIndex,
        name: '',
        type: 'category',
        position: 'right',
        axisLabel: {
          ...yAxisSecondIndex.axisLabel,
          formatter: (value) => (value !== 'null' ? value : '0'),
        },
        axisPointer: {
          type: 'shadow',
          label: {
            show: false,
          },
        },
        data: options?.values || [],
      },
    ]

    chartOptions.value.series[0] = {
      ...chartOptions.value.series[0],
      itemStyle: {},
      data: options?.values || [],
    }
  },
  {
    deep: true,
    immediate: true,
  }
)
</script>

<template>
  <AnalyticsCardWrapper
    :type="`followers-demographics-linkedin-${type}`"
    :enable-modal="true"
  >
    <template v-slot:card-header>
      <div class="w-full flex justify-between items-center">
        <div class="flex items-center gap-2">
          <div class="flex align-items-center gap-2">
            <CstDropdown
              dropdown-placement="bottom"
              container-classes="!max-h-96"
              dropdown-classes="!rounded-md !border-[#70707029]"
              button-classes="flex !px-5 !w-[22rem] !rounded-md !bg-[#F8F8F880] !border-[#70707029] !text-[#4A4A4A]"
            >
              <template v-slot:arrow>
                <img :src="ChevronDownIcon" alt="chevron-down" />
              </template>
              <template v-slot:selected>
                <p class="text-[14px] font-weight-500 capitalize">
                  {{ getSelectedType }}
                </p>
              </template>
              <template v-slot>
                <template
                  v-for="(item, index) in optionsDropdown"
                  :key="`activity_type_${index}`"
                >
                  <CstDropdownItem @click="selectedOptionType = item">
                    <p class="text-gray-900 text-sm">{{ item.label }}</p>
                  </CstDropdownItem>
                </template>
              </template>
            </CstDropdown>
            <v-menu
              v-if="!isReportView"
              :popper-triggers="['hover']"
              placement="top"
              popper-class="first-comment__info-popover"
              :delay="300"
            >
              <i class="far fa-question-circle p-0 cursor-pointer"></i>
              <template v-slot:popper>
                <p class="text-sm text-gray-900">
                  {{ selectedOptionType.tooltip }}
                </p>
              </template>
            </v-menu>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:card-body="slotProps">
      <div class="flex-1">
        <SkeletonBox
          v-if="isLoading"
          width="100%"
          height="350px"
          radius="8px"
        />
        <MainGraph
          v-else-if="!isNoData"
          :key="Math.random()"
          type="bar"
          :chart-options="
            slotProps.isModal
              ? {
                  ...chartOptions,
                  ...dataZoomOptions,
                  grid: { ...chartOptions.grid, bottom: 70 },
                }
              : chartOptions
          "
          :is-modal="slotProps.isModal"
        />
        <div v-else class="analytics-no-data-found">
          <img
            src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
            alt=""
          />
          <p v-if="fanCount <= 100"
            >You'll need 100 followers (who aren't your friends) to view this
            demographic information.</p
          >
          <p v-else>No data found.</p>
        </div>
      </div>
    </template>
  </AnalyticsCardWrapper>
</template>
