<template>
  <div v-if="showBanner" ref="stickyBanner" class="sp-banner">
    <div class="container">
      <div class="sp-banner-wrapper">
        <template v-if="profile.trial_overs_in > 0">

          <div class="sp-banner-text">Your trial will expire in
            {{profile.trial_overs_in}}
            <template v-if="profile.trial_overs_in > 1">
              days.
            </template>
            <template v-else>
              day
            </template></div>

        </template>
        <template v-else-if="profile.trial_overs_in === 0">
          <div class="sp-banner-text">Your trial will be end today!</div>
        </template>
        <div class="sp-banner-button ml-2" >

          <a href="javascript:;" @click="showUpgradeModal">
            <b data-cy='upgrade-now'>Upgrade Now!</b>
          </a>

        </div>
        <div class="sp-banner-close">
          <button @click="showBanner = false">
            <i class='icon-cancel_icon'></i>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import usePlatform from "@common/composables/usePlatform";
import {useBilling} from "@modules/billing/composables/useBilling";

export default {
  name: 'SpecialBanner',
  props: {
    profile: null,
  },
  setup(){
    const {topBannersHeight} = usePlatform()
    const {showUpgradeModal} = useBilling()

    return {
      topBannersHeight,
      showUpgradeModal
    }
  },
  data () {
    return {
      showBanner: true,
    }
  },
  watch: {
    showBanner(val) {
      // if val return
      if (!val) {
        this.topBannersHeight -= this.$refs.stickyBanner.clientHeight;
        document.documentElement.style.setProperty('--calender-height', `${270}px`);
      }
    }
  },
  mounted() {
    this.stickyBannersHeight = this.$refs.stickyBanner.clientHeight
    this.topBannersHeight += this.stickyBannersHeight;
    document.documentElement.style.setProperty('--calender-height', `${270 + this.stickyBannersHeight}px`);
  },
  beforeUnmount() {
    // reset the --calendar-height to 210px
    this.topBannersHeight -= this.$refs.stickyBanner.clientHeight;
    document.documentElement.style.setProperty('--calender-height', `270px`);
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.sp-banner {
  background: #f9f1b2;
  box-shadow: 0 5px 12px rgba(34, 36, 38, 0.2);
  color: #475059;
  padding: 0.7rem 0;
  position: relative;
  z-index: 0;

  .sp-banner-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    & > * {
      margin: 0 5px;

      &:nth-child(even) {
        margin: 1.1rem 5px 1.6rem 5px;
      }
    }

    @media only screen and (min-width: 767px) {
      flex-direction: row;
      & > * {
        margin: 0 5px;

        &:nth-child(even) {
          margin: 0 5px;
        }
      }
    }

    .sp-banner-text {
      font-size: 1rem;
      font-weight: 500;
      color: #475059;
      text-align: center;
      @media only screen and (min-width: 767px) {
        text-align: left;
      }
    }

    .sp-banner-button {
      a {
        color: inherit;
        font-size: 1rem;
        text-decoration: underline;
        font-weight: 500;
      }

      .btn {
        background: #3eb064;
        color: #fff;
        padding: 5px 8px;
        font-size: 0.875rem;
        cursor: pointer;

        &:hover {
          background: #28ab54;
        }
      }
    }

    .sp-banner-close {
      position: absolute;
      right: 12px;

      button {
        background: none;
        border: 0;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #475059;
        cursor: pointer;

        &:hover {
          color: #000000;
        }
      }
    }

  }
}
</style>
