<script setup>
import { ref, computed, onMounted, inject, onBeforeUnmount } from 'vue'
import { useStore } from 'vuex'
import SkeletonBox from '@src/modules/analytics/views/common/SkeletonBox'
import { swalAttributes } from '@common/constants/common-attributes'
import useDateFormat from "@common/composables/useDateFormat"
import { useComposerHelper } from '@src/modules/composer_v2/composables/useComposerHelper.js'
import useChannel from '@src/modules/common/composables/useChannel.js'
import useString from '@src/modules/common/composables/useString.js'

// Setup composables and store
const { momentWrapper } = useDateFormat()
const { getPlatformName, getSocialImageRounded } = useComposerHelper()
const { channelTooltipHTML,channelImage } = useChannel()
const { truncateString } = useString()

const store = useStore()
const root = inject('root')
const {$bvModal} = root
// Reactive data properties
const loading = ref(false)
const startIndex = ref(0)
const endIndex = ref(20)
const openSlideIndex = ref(null) // Track which slide is open
const modalClosable = ref(true)

// Computed properties
const getDownloadReports = computed(() => store.getters.getDownloadReports)

// Add these variables to store the polling interval
const pollInterval = ref(null)

// Modify the onMounted section to include polling
onMounted(async () => {
  loading.value = true
  try {
    const fetchReports = async () => {
      console.log('Polling reports...')
    const res = await fetchDownloadReportsService()
      if (res) {
        loading.value = false
      }
    }

    // Initial fetch
    await fetchReports()

    // Set up polling every 10 seconds
    pollInterval.value = setInterval(fetchReports, 10000)
  } catch (error) {
    console.error(error)
  }
})

// Add cleanup on component unmount
onBeforeUnmount(() => {
  if (pollInterval.value) {
    clearInterval(pollInterval.value)
  }
})

// Define actions, mutations, and methods
const fetchDownloadReportsService = store.dispatch.bind(store, 'fetchDownloadReportsService')
const removeDownloadReportService = store.dispatch.bind(store, 'removeDownloadReportService')

// Toggle slide functionality
const toggleSlide = (index) => {
  openSlideIndex.value = openSlideIndex.value === index ? null : index
}

// Config for Swal
const swalConfig = () => swalAttributes()

// Delete confirmation functionality
const onDeleteConfirmation = async (event, { reportId, index }) => {
  if (!modalClosable.value) {
    const res = await $bvModal.msgBoxConfirm(
      'Are you sure you want to delete this report? This action cannot be undone.',
      {
        title: 'Delete Report',
        ...swalConfig(),
        cancelTitle: 'Cancel',
        okTitle: 'Delete Anyway',
      }
    )
    if (res) {
      modalClosable.value = true
      const status = await removeDownloadReportService({ reportId, index })
      if (status) {
        store.dispatch('toastNotification', {
          message: 'Report has been deleted successfully.',
          type: 'success',
        })
      }
    }
  } else {
    modalClosable.value = false
  }
}

// Helper computed property to check if report is expandable
const isExpandable = (type) => type?.toLowerCase()?.includes('multiple')

</script>

<template>
  <div class="analytic_common_component custom_report_main">
    <div class="component_inner">
      <div class="report_list clear">
        <h2 class="heading">Download Reports</h2>
        <div class="clearfix"></div>
          <div class="card_content mt-3">
            <div class="social_table">
              <div class="table_wrapper">
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width: 35%">Accounts</th>
                      <th style="width: 200px" class="text-center">Type</th>
                      <th style="width: 124px" class="text-center">Status</th>
                      <th style="width: 300px" class="text-center">Period</th>
                      <th style="width: 180px" class="text-center">Creation date</th>
                      <th style="width: 120px" class="text-center">Actions</th>
                    </tr>
                  </thead>

                  <tbody>
                    <template v-if="getDownloadReports?.list.length > 0">
                      <template
                        v-for="(item, key) in getDownloadReports.list"
                        :key="`download-report-${key}`"
                      >
                        <tr
                          v-if="key >= startIndex && key < endIndex"
                          :class="{
                            'cursor-pointer': isExpandable(item.type),
                          }"
                          @click="toggleSlide(key)"
                        >
                          <td class="flex items-center">
                            <button
                              v-if="isExpandable(item.type)"
                              v-tooltip="'Click to view reports'"
                              class="btn btn-link"
                            >
                              <i
                                :class="[
                                  'fa fa-caret-right',
                                  openSlideIndex === key ? 'rotate-90' : '',
                                ]"
                              ></i>
                            </button>
                            <div>
                            <template v-if="item.accounts">
                              <!-- First 5 accounts -->
                              <template v-for="(account, accountIndex) in item.accounts.slice(0, 5)" :key="`accounts_${accountIndex}`">
                                <div
                                  v-tooltip="{
                                    content: channelTooltipHTML(account.account_type, account),
                                    classes: 'team_tooltip',
                                    allowHTML: true,
                                    theme: 'light',
                                  }"
                                  class="d-inline-block mr-3"
                                >
                                  <individual-channel-tooltip
                                    :account="account"
                                    :type="account.account_type"
                                  ></individual-channel-tooltip>
                                </div>
                              </template>

                              <!-- Remaining count circle -->
                              <b-dropdown
                                v-if="item.accounts.length > 5"
                                :menu-class="{
                                      'h-96 overflow-y-auto':
                                      item.accounts.slice(5).length > 10,
                                    }"
                                no-caret
                                toggle-class="bg-white rounded-full border-0 p-0"
                                variant="primary"
                            >
                              <template v-slot:button-content>
                                  <span
                                      class="inline-flex justify-center items-center w-9 h-9 bg-gray-400 rounded-full text-xs"
                                  >
                                    +{{ item?.accounts?.length - 5 }}
                                  </span>
                              </template>
                              <template
                                  v-for="(dropAcc, accKey) in item.accounts.slice(5)"
                                  :key="accKey"
                              >
                                <b-dropdown-item >
                                  <div >
                                          <span class="relative inline-block mr-1.5">
                                        <img
                                            :src="channelImage(dropAcc, dropAcc.account_type)"
                                            alt=""
                                            class="rounded-full object-cover border pointer-events-none w-9 h-9"
                                            data-fallback="0"
                                            @error="onError($event, dropAcc.image)"
                                        />
                                        <img
                                          :src="getSocialImageRounded(dropAcc.account_type)"
                                          :alt="dropAcc.platform"
                                          class="absolute -right-2 bottom-[-0.35rem] h-[20px]"
                                      />
                                      </span>
                                <span class="text-sm">
                                  {{
                                    truncateString(
                                      getPlatformName(dropAcc),
                                        30,
                                    )
                                  }}
                                </span>
                                  </div>
                                </b-dropdown-item>
                              </template>
                            </b-dropdown>
                            </template>
                            </div>
                          </td>
                          <td class="make-text-center uppercase">
                            {{ item.type === 'twitter' ? 'X (Twitter)' : item.type }} Report
                          </td>
                          <td class="make-text-center">
                            <template v-if="item?.progress < 100">
                              <b-progress
                                :value="item?.progress || 0"
                                :max="100"
                                show-progress
                                animated
                              ></b-progress>
                              <!-- <progress id="file" :value="item?.progress || 0" max="100"> {{item?.progress || 0}}% </progress> -->
                            </template>
                            <template v-else>
                              <span class="text-green-500"> READY </span>
                            </template>
                          </td>
                          <td class="make-text-center">
                            {{ momentWrapper(item?.date.split(' - ')[0])?.formatDate() }} -
                            {{ momentWrapper(item?.date.split(' - ')[1])?.formatDate() }}
                          </td>
                          <td class="make-text-center">
                            {{ momentWrapper(item.created_at)?.formatDate() }}
                          </td>
                          <td class="">
                            <div class="action_icons text-right">
                              <a v-if="!isExpandable(item.type)" :href="item.export_url" target="_blank">
                                <i
                                  v-tooltip="'Download Report'"
                                  class="icon_table fa fa-download"
                                ></i>
                              </a>
                              <i
                                v-tooltip="'Remove Report'"
                                class="icon_table icon-delete-cs"
                                @click.stop.prevent="onDeleteConfirmation($event, {
                                  reportId: item._id,
                                  index: key,
                                })"
                              ></i>
                            </div>
                          </td>
                        </tr>
                        <!-- Expandable content -->
                        <Transition name="slide-bottom">
                          <tr v-if="openSlideIndex === key && isExpandable(item.type)">
                            <td colspan="6">
                              <div class="slide-content">
                                <div class="p-4">
                                  <div class="accounts-grid">
                                      <div v-for="(account, idx) in item.accounts" :key="`account-${idx}`" class="account-card">
                                        <div class="account-info">
                                          <individual-channel-tooltip
                                            :account="account"
                                            :type="account.account_type"
                                          ></individual-channel-tooltip>
                                          <span v-tooltip = "getPlatformName(account)" class="account-name truncate">{{ getPlatformName(account)  }}</span>
                                        </div>
                                        <a
                                          :href="account.export_url"
                                          target="_blank"
                                          class="download-btn"
                                          :class="{ 'disabled': !account.export_url }"
                                        >
                                          <i class="fa fa-download mr-2"></i>
                                          {{ account.export_url ? 'Download Report' : 'Not Ready Yet' }}
                                        </a>
                                      </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        </Transition>
                      </template>

                      <template v-if="endIndex < getDownloadReports?.list.length">
                        <tr>
                        <td colspan="6" class="make-text-center">
                            <button
                              class="btn bg-gray-300 rounded view-more-button"
                              @click="endIndex += 20"
                            >View more</button>
                          </td>
                        </tr>
                      </template>
                    </template>

                    <template v-else>
                      <tr>
                        <td colspan="6" class="make-text-center">
                          <div v-if="loading" class="loader">
                            <SkeletonBox
                              width="100%"
                              height="250px"
                              radius="12px"
                            />
                          </div>
                          <div v-else>
                            <p class="no_report">You have not exported any report yet.</p>
                          </div>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.view-more-button {
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  background-color: #f5f5f5;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
    color: #2972b6;
  }
}

.slide-content {
  background-color: #f9f9f9;
  border-top: 1px solid #eee;
  transition: all 0.3s ease;
}

.btn-link {
  padding: 0;
  margin-right: 10px;
  color: #666;

  &:hover {
    color: #2972b6;
  }

  i {
    font-size: 16px;
    transition: transform 0.3s ease;
  }
}

.accounts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.account-card {
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.account-info {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.account-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.account-name {
  font-weight: 500;
  color: #333;
}

.download-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 1rem;
  background-color: #2972b6;
  color: white;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;

  &:hover {
    background-color: darken(#2972b6, 10%);
    color: white;
    text-decoration: none;
  }
}
.download-btn.disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
  pointer-events: none;

  &:hover {
    background-color: #cccccc;
  }
}
</style>
