<!-- ConfirmationContent.vue -->
<script setup>
import CstButton from '@ui/Button/CstButton.vue'

defineProps({
  onConfirm: {
    type: Function,
    required: true,
  },
  onCancel: {
    type: Function,
    required: true,
  },
  primaryButtonText: {
    type: String,
    default: 'Delete',
  },
  secondaryButtonText: {
    type: String,
    default: 'Cancel',
  },
  loading: {
    type: Boolean,
    default: false,
  },
})
</script>

<template>
  <div>
    <!-- Modal Head -->
    <div class="modal_head">
      <slot name="head">
        <h4 class="text-xl"> Delete Confirmation </h4>
      </slot>
      <button
          aria-label="Close"
          class="modal_head__close bg-transparent"
          data-dismiss="modal"
          type="button"
          @click.stop="onCancel"
      >&times;
      </button>
    </div>

    <!-- Modal Body -->
    <div class="modal_body">
      <slot name="body">
        <p class="text-sm py-4"> Are you sure you want to delete this item? </p>
      </slot>

      <!-- Modal Footer -->
      <div class="field_group flex items-center justify-between gap-4 py-2">
        <slot :on-cancel="onCancel" :on-confirm="onConfirm" name="footer">
          <CstButton
            :text="secondaryButtonText"
            variant="outlined"
            @click="onCancel"
          />
          <CstButton
            :class="{ '!bg-red-500 !border-red-500': primaryButtonText.includes('Delete') }"
            :text="primaryButtonText"
            :loading="loading"
            :disabled="loading"
            variant="primary"
            @click="onConfirm"
          />
        </slot>
      </div>
    </div>
  </div>
</template>
