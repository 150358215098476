<script setup>
import {useBilling} from "@modules/billing/composables/useBilling";

defineProps({
  buttons: {
    type: Object,
    required: true,
  },
  mainText: {
    type: String,
    required: true,
  },
  secondaryText: {
    type: String,
    required: true,
  },
  imgSrc: {
    type: String,
    required: true,
  },
  learnMoreLink: {
    type: String,
    default: '',
  },
  access: {
    type: Object,
    required: true,
  }
})

const {showUpgradeModal} = useBilling()
</script>

<template>
  <div
      v-tooltip="!access?.allowed ? access.error?.message : ''"
      class="rounded-[0.75rem] p-[1rem] bg-white mb-[1rem] flex justify-between hover:!ring-1 hover:!ring-[#90CAFF]"
      @click="!access?.allowed ? showUpgradeModal() : ''"
  >
    <div class="flex gap-[0.75rem] align-items-center">
      <div class="p-[0.635rem] rounded bg-[#E3F2FF]">
        <img :src="imgSrc" alt="">
      </div>
      <div>
        <p class="text-[#333C4D] font-bold text-sm">{{ mainText }}</p>
        <p class="text-[#757A8A] text-sm font-normal">{{ secondaryText }} <a :href="learnMoreLink" target="_blank">Learn
          more</a></p>
      </div>
    </div>
    <!-- Button section -->
    <div class="flex gap-[0.75rem]">
      <template v-for="(item, index) in buttons" :key="index">
        <button
            :class="[
            item.classes,
            !access?.allowed ? 'opacity-70 cursor-default pointer-events-none' : ''
          ]"
            :disabled="!access?.allowed"
            @click="access?.allowed && item.clickEvent"
        >
          {{ item.text }}
        </button>
      </template>
    </div>
  </div>
</template>