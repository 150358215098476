<template>
  <b-modal
    id="planner-notes-modal"
    @hidden="closeModal"
    :title="note.id ? 'Edit Note' : 'Add Note'"
    hide-footer
    centered
    dialog-class="xl:!max-w-[38rem]"
    no-close-on-backdrop
  >
    <div class="space-y-6 p-5">
      <div class="w-full">
        <label for="title" class="block text-sm font-medium text-gray-900 mb-1">Title</label>
        <input
            id="title"
            type="text"
            v-model="note.note_title"
            @input="(e) => updateNoteField('note_title', e.target.value)"
            placeholder="Add your title"
            class="w-full px-3 py-2 h-[2.6rem] border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-200"
        />
      </div>
      <div class="flex justify-between space-x-4">
        <div class="w-full">
          <label for="start_date" class="block text-sm font-medium text-gray-900 mb-1">Start Date</label>
          <CstInputFields
              :date-options="dateOptions"
              :value="note.start_date"
              class="planner-date-picker cursor-pointer w-full"
              placeholder="Select specific date range"
              type="date"
              :range="false"
              :date-picker-popup-classes="['planner-date-picker-popup']"
              @change="(e) => updateNoteField('start_date', e)"
          />
        </div>
        <div class="w-full">
          <label for="end_date" class="block text-sm font-medium text-gray-900 mb-1">End Date</label>
          <CstInputFields
          :date-options="dateOptions"
          :value="note.end_date"
          class="planner-date-picker cursor-pointer w-full"
          placeholder="Select specific date range"
          type="date"
          :range="false"
          :date-picker-popup-classes="['planner-date-picker-popup']"
          @change="(e) => updateNoteField('end_date', e)"
        />
        </div>
      </div>
      <div>
        <label for="content" class="block text-sm font-medium text-gray-900 mb-1">Description
          <img v-tooltip="'Planner note description is optional'" src="@assets/img/info.webp" class="w-4 h-4" alt="" />
        </label>
        <textarea
          id="description"
          v-model="note.description"
          @input="(e) => updateNoteField('description', e.target.value)"
          placeholder="Add description to your note"
          rows="4"
          class="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-200"
        ></textarea>
      </div>
      <div>
        <label class="block text-sm font-medium text-gray-900 mb-1">Color</label>
        <div class="flex flex-wrap gap-2">
          <template v-for="(color, index) in colorOptions" :key="index">
            <div
                @click="updateNoteField('note_color', color.hex)"
                class="w-6 h-6 rounded-md cursor-pointer flex justify-center items-center"
                :style="{ backgroundColor: color.hex }"
            >
              <i v-if="note.note_color === color.hex" class="fa fa-check text-white"></i>
            </div>
          </template>
        </div>
      </div>

      <div class="flex justify-between items-center pt-4" :class="{'!justify-end': !note.id}">
        <div v-if="note.id" @click.prevent="deleteNote" class="cursor-pointer flex items-center space-x-2 pt-2">
          <img
              v-tooltip="'Delete note'"
              src="@src/assets/img/integration/delete_red_light.svg"
              class="w-5 h-5"
              alt="delete note"
          />
          <span>Delete</span>
        </div>
        <CstButton class="ml-auto block" @click.prevent="saveNote">
          <span>{{ note.id ? 'Update Note' : 'Save Note' }}</span>
          <clip-loader
              v-if="loader"
              class="spinner ml-2"
              :color="'#e0dfdf'"
              :size="'15px'"
          ></clip-loader>
        </CstButton>
      </div>
    </div>
  </b-modal>
</template>


<script setup>
import { usePlannerNotes } from '@modules/planner_v2/composables/usePlannerNotes'
import CstButton from '@ui/Button/CstButton'
import CstInputFields from '@ui/Input/CstInputFields'

const {
  note,
  colorOptions,
  loader,
  closeModal,
  saveNote,
  deleteNote,
  updateNoteField,
  dateOptions
} = usePlannerNotes()

</script>

<style lang="scss">
#planner-notes-modal {
  .modal-dialog {
    .modal-content {
      .modal-header {
        border: none !important;
        border-bottom: 0px !important;
        align-items: center !important;
        padding: 1rem 2.2rem;
        h5 {
          font-size: 1.25rem;
          font-weight: 500;
        }
      }
    }
  }
}

</style>
