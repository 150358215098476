import { computed, reactive, ref, toRefs, watch } from 'vue'
import { useStore } from '@state/base'
import proxy from '@common/lib/http-common'
import {
  saveWhiteLabelEmailSettings,
  validateWhiteLabelSMTPSettings,
} from '@src/modules/setting/config/api-utils'
import useWhiteLabel from '@modules/setting/composables/whitelabel/useWhiteLabel'
import usePlatform from '@common/composables/usePlatform'

const fields = reactive({
  smtpHost: { value: '', error: '', isRequired: true },
  smtpPort: { value: '', error: '', isRequired: true },
  smtpUsername: { value: '', error: '', isRequired: true },
  smtpPassword: { value: '', error: '', isRequired: true },
  fromEmail: { value: '', error: '', isRequired: true },
  senderName: { value: '', error: '', isRequired: true },
  smtpEncryption: { value: 'tls', error: '', isRequired: true },
})

const isLoading = ref(false)
const isApiError = ref(false)

export default function useEmailSettings() {
  const { getters } = useStore()

  const { showToast } = usePlatform()
  const { whiteLabelId, emailSettings, transformEmailSettings, whiteLabelUrl } =
    useWhiteLabel()

  const isFormValid = computed(() => {
    return Object.values(fields).every(
      (field) => !field.isRequired || (field.value && !field.error)
    )
  })

  const shouldDisableNext = computed(
    () => isLoading.value || !isFormValid.value || isApiError.value
  )

  watch(
    () => emailSettings.value,
    (newVal) => {
      setEmailSettings(newVal)
    }
  )

  function setEmailSettings(settings = {}) {
    if (settings) {
      // reset errors
      clearErrors()

      fields.fromEmail.value = settings.fromEmail || ''
      fields.senderName.value = settings.senderName || ''
      fields.smtpPort.value = settings.smtpPort || ''
      fields.smtpUsername.value = settings.smtpUsername || ''
      fields.smtpPassword.value = settings.smtpPassword || ''
      fields.smtpHost.value = settings.smtpHost || ''
      fields.smtpEncryption.value = settings.smtpEncryption || 'tls'
    }
  }

  const setErrors = async (error = {}) => {
    await showToast(`${error.response?.data?.message}`, 'error')

    const errors = error.response?.data?.errors || []

    if (errors) {
      if (errors.smtp_host) fields.smtpHost.error = errors.smtp_host[0]
      if (errors.smtp_port) fields.smtpPort.error = errors.smtp_port[0]
      if (errors.smtp_username)
        fields.smtpUsername.error = errors.smtp_username[0]
      if (errors.smtp_password)
        fields.smtpPassword.error = errors.smtp_password[0]
      if (errors.sender_name) fields.senderName.error = errors.sender_name[0]
      if (errors.from_email) fields.fromEmail.error = errors.from_email[0]
      if (errors.smtp_encryption)
        fields.smtpEncryption.error = errors.smtp_encryption[0]
    }
  }

  const clearErrors = () => {
    Object.values(fields).forEach((field) => {
      field.error = ''
    })
  }

  const getPayload = () => {
    const payload = {
      smtp_host: fields.smtpHost.value,
      smtp_port: fields.smtpPort.value,
      smtp_username: fields.smtpUsername.value,
      smtp_password: fields.smtpPassword.value,
      from_email: fields.fromEmail.value,
      sender_name: fields.senderName.value,
      smtp_encryption: fields.smtpEncryption.value,
      workspace_id: getters.getActiveWorkspace._id,
    }

    if (whiteLabelId.value) {
      payload.id = whiteLabelId.value
    }

    return payload
  }

  const testSMTPConnection = async () => {
    clearErrors()

    isLoading.value = true

    const payload = getPayload()

    try {
      const { data } = await proxy.post(validateWhiteLabelSMTPSettings, payload)
      const type = data.status ? 'success' : 'warn'
      isApiError.value = !data.status
      await showToast(data.message, type)
      return data.status
    } catch (error) {
      isApiError.value = true
      await setErrors(error)

      return false
    } finally {
      isLoading.value = false
    }
  }

  const handleSubmit = async () => {
    clearErrors()

    isLoading.value = true

    const payload = getPayload()

    try {
      const { data } = await proxy.post(saveWhiteLabelEmailSettings, payload)
      if (!data.status) {
        await showToast(data.message, 'warn')
      } else {
        isApiError.value = false
        const formattedData = transformEmailSettings(data.whitelabel)
        setEmailSettings(formattedData)
        whiteLabelUrl.value = data.whitelabel?.url
        // await showToast('Email settings saved successfully', 'success')
      }
      return data.status
    } catch (error) {
      isApiError.value = true
      await setErrors(error)

      return false
    } finally {
      isLoading.value = false
    }
  }

  return {
    ...toRefs(fields),

    isFormValid,
    isLoading,
    shouldDisableNext,

    testSMTPConnection,
    handleSubmit,
  }
}
