<template>
  <div class="absolute top-0 -right-16">
    <div  @mouseenter="isExpanded = true" @mouseleave="isExpanded = false">

      <Transition
          v-if="isExpanded"
          enter-active-class="transition ease-out duration-300"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="transition ease-in duration-250"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
      >
      <!-- Expanded view on hover -->
      <LayoutCard

        class="
          absolute
          w-[15rem]
          transition-opacity
          duration-200
          shadow-lg
        "
        :class="[
          width < 1536 ? '!right-0' : '!left-[13rem]'
        ]"
        body-classes="!px-4"
      >
        <template v-slot:body>
          <ul class="space-y-6">
            <li
              v-for="(item, index) in items"
              :key="index"
              class="flex items-center space-x-4 cursor-pointer"
            >
              <div
                class="
                  w-[2.5rem]
                  h-[2.25rem]
                  flex
                  items-center
                  justify-center
                  border
                  !border-gray-cs-500
                  rounded-xl
                "
              >
                <img :src="item.icon" alt="icon" class="w-6" />
              </div>
              <div>
                <p class="font-normal text-md">{{ item.title }}</p>
              </div>
            </li>
          </ul>
        </template>
      </LayoutCard>

        </Transition>

      <!-- Collapsed view (icons only) -->
      <div v-else class="bg-white rounded-xl border !border-gray-cs-500 w-max">
        <div
          v-for="(item, index) in items"
          :key="index"
          :class="[
            index !== items.length - 1 &&
              'border !border-t-0 !border-l-0 !border-r-0 !border-b !border-gray-cs-500',
          ]"
          class="w-10 h-10 flex items-center justify-center"
        >
          <img :src="item.icon" alt="icon" class="w-6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { useWindowSize } from '@vueuse/core'
import LayoutCard from '@modules/setting/components/white-label/LayoutCard.vue'

const { width } = useWindowSize()
const items = ref([
  {
    title: 'How to Configure',
    icon: require('@src/assets/img/icons/analytic/play-icon.svg'),
  },
  {
    title: 'Learn More',
    icon: require('@src/assets/img/settings/white-label/article.png'),
  },
  {
    title: 'Book a Demo',
    icon: require('@src/assets/img/settings/white-label/demo.svg'),
  },
])

const isExpanded = ref(false)
</script>
