// this is direct conversion of channel-mixin to composition api

import { computed } from 'vue'
import { useStore } from 'vuex'
import { blogIntegrationsNames } from '@src/modules/integration/config/api-utils'
import startCase from 'lodash/startCase'

export default function useSocialChannel() {
  const store = useStore()

  // Computed properties
  const getFacebookAccounts = computed(() => store.getters.getFacebookAccounts)
  const getTwitterAccounts = computed(() => store.getters.getTwitterAccounts)
  const getPinterestBoards = computed(() => store.getters.getPinterestBoards)
  const getLinkedinAccounts = computed(() => store.getters.getLinkedinAccounts)
  const getTumblrAccounts = computed(() => store.getters.getTumblrAccounts)
  const getGmbAccounts = computed(() => store.getters.getGmbAccounts)
  const getInstagramAccounts = computed(() => store.getters.getInstagramAccounts)
  const getThreadsAccounts = computed(() => store.getters.getThreadsAccounts)
  const getYoutubeAccounts = computed(() => store.getters.getYoutubeAccounts)
  const getTiktokAccounts = computed(() => store.getters.getTiktokAccounts)

  // Methods
  /**
   * Retrieves a specific channel item based on accountId and type.
   * @param {string} accountId - The ID of the account.
   * @param {string} type - The type of the social media platform.
   * @param {object} account - Optional account object.
   * @returns {*} The matched account object or null.
   */
  const channelItem = (accountId, type, account = null) => {
    switch (type) {
      case 'facebook':
        return getFacebookAccounts.value.items.find(
          (item) =>
            item.facebook_id === accountId ||
            item.facebook_id === parseInt(accountId) ||
            (account && item.facebook_id === account.facebook_id)
        )
      case 'threads':
        return getThreadsAccounts.value.items.find(
          (item) => item.platform_identifier === accountId
        )
      case 'twitter':
        return getTwitterAccounts.value.items.find(
          (item) => item.twitter_id === accountId
        )
      case 'pinterest':
        return getPinterestBoards.value.find(
          (item) =>
            item.board_id === accountId ||
            (account && item.board_id === account.board_id)
        )
      case 'linkedin':
        return getLinkedinAccounts.value.items.find(
          (item) =>
            item.linkedin_id === accountId ||
            item.linkedin_id === parseInt(accountId) ||
            (account && item.linkedin_id === account.linkedin_id)
        )
      case 'tumblr_profile':
        return getTumblrAccounts.value.find(
          (item) =>
            item.name === accountId ||
            (account && item.name === account.name)
        )
      case 'gmb':
        return getGmbAccounts.value.items.find(
          (item) => item.name === accountId
        )
      case 'instagram':
        return getInstagramAccounts.value.items.find(
          (item) =>
            item.instagram_id === accountId ||
            (account && item.instagram_id === account.instagram_id)
        )
      case 'youtube':
        return getYoutubeAccounts.value.items.find(
          (item) =>
            item.platform_identifier === accountId ||
            (account && item.platform_identifier === account.platform_identifier)
        )
      case 'tiktok':
        return getTiktokAccounts.value.items.find(
          (item) => item.platform_identifier === accountId
        )
      default:
        return account
    }
  }

  /**
   * Constructs the background image URL for a channel.
   * @param {object} account - The account object.
   * @param {string} channelName - The name of the channel.
   * @returns {string} The CSS background image property.
   */
  const channelBackgroundImage = (account, channelName) => {
    return `url('${channelImage(
      account,
      channelName
    )}'), url('https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg')`
  }

  /**
   * Retrieves the display name of the channel.
   * @param {object} account - The account object.
   * @param {string} type - The type of the social media platform.
   * @returns {string} The name of the channel.
   */
  const channelName = (account, type) => {
    let name = ''
    switch (type) {
      case 'gmb':
        if (account.platform_name) return account.platform_name
        if (account.location_name) {
          name = `${account.location_name} - ${account.locality}`
          return account.postal_code ? `${name} - ${account.postal_code}` : name
        }
        break
      case 'pinterest':
        if (account.pinterest_id)
          return account.first_name ? `${account.first_name} ${account.last_name}` : account.username
        break
      case 'tiktok':
      case 'youtube':
      case 'threads':
        return account.platform_name
      default:
        return account.name || ''
    }
    return name || account.name || ''
  }

  /**
   * Retrieves the image URL for a channel.
   * @param {object} account - The account object.
   * @param {string} type - The type of the social media platform.
   * @returns {string} The image URL.
   */
  const channelImage = (account, type) => {
    const defaultImage =
      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'
    const gmbImage =
      'https://storage.googleapis.com/lumotive-web-storage/default/google-business.png'
    if (!account) return defaultImage

    if (account.platform_logo) return account.platform_logo
    const freshAccount = channelItem(null, type, account) || account

    switch (type) {
      case 'facebook':
        return freshAccount.image
          ? freshAccount.image
          : freshAccount.picture?.url || defaultImage
      case 'twitter':
      case 'linkedin':
      case 'pinterest':
        return freshAccount.image?.large?.url || freshAccount.image || defaultImage
      case 'instagram':
        return freshAccount.image || freshAccount.profile_picture || defaultImage
      case 'medium':
        return freshAccount.imageUrl || defaultImage
      case 'gmb':
        return freshAccount.location_name ? gmbImage : defaultImage
      case 'threads':
        return freshAccount.image || freshAccount.platform_logo || defaultImage
      default:
        return defaultImage
    }
  }

  /**
   * Generates HTML tooltip content for a channel.
   * @param {string} channelType - The type of the social media platform.
   * @param {object} channel - The channel object.
   * @param {boolean} onlyName - If true, returns only the name.
   * @returns {string} The HTML content.
   */
  const channelTooltipHTML = (channelType, channel, onlyName = false) => {
    let name = ''
    let handler = ''
    let type = ''
    let image =
      'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

    if (blogIntegrationsNames.includes(channelType)) {
      name = channel.platform_url || channel.platform_name
      image = channel.platform_logo
      if (channel.type) type = startCase(channel.type)
    } else {
      switch (channelType) {
        case 'gmb':
          image = channelImage(channel, channelType)
          name = channelName(channel, channelType)
          type = 'Location'
          break
        case 'facebook':
          image = channelImage(channel, channelType)
          type = channel.type
          break
        case 'twitter':
          image = channelImage(channel, channelType)
          handler = `<span class='handler'>@${channel.screen_name}</span>`
          type = 'Profile'
          break
        case 'linkedin':
        case 'pinterest':
          image = channelImage(channel, channelType)
          type = channel.type || 'Board'
          break
        case 'instagram':
          image = channelImage(channel, channelType)
          type = 'Profile'
          break
        case 'threads':
          image = channelImage(channel, channelType)
          name = channelName(channel, channelType)
          type = 'Profile'
          break
        case 'youtube':
          image = channelImage(channel, channelType)
          name = channelName(channel, channelType)
          type = 'Channel'
          break
        case 'tiktok':
          image = channelImage(channel, channelType)
          handler = `<span class='handler'>@${channel.platform_name}</span>`
          name = channelName(channel, channelType)
          type = 'Profile'
          break
        case 'influencer':
          image = channel.profile_image
          type = 'Followers'
          name = `${channel.name}<span class="screen_name">@${channel.screen_name}</span>`
          handler = `<strong> ${channel.follower_count}</strong>`
          break
        default:
          break
      }
    }

    name = channelName(channel, channelType) || name

    if (onlyName) return name
    return `<div class='team_detail_tooltip'>
              <div class='t_inner d-flex align-items-center'>
                <div class='img' style='background-image: url(${image}), url("https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg");'></div>
                <div class='t_content'>
                  <h3>${name}</h3>
                  <p>${type}${handler}</p>
                </div>
              </div>
            </div>`
  }

  /**
   * Generates HTML tooltip content for multiple channels.
   * @param {string} channelType - The type of the social media platform.
   * @param {Array} channels - An array of channel objects.
   * @returns {string} The HTML content.
   */
  const channelTooltipMultipleHTML = (channelType, channels) => {
    let divContainerString = ''
    const cutOff = 3
    const channelsCount = Math.min(channels.length, cutOff)

    for (let x = 0; x < channelsCount; x++) {
      const channel = channels[x]
      let name = ''
      let type = ''
      let image =
        'https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'

      image = channelImage(channel, channelType)
      type = 'Profile'
      name = channel.name || ''

      const container = `<div class='team_detail_tooltip'>
                <div class='t_inner d-flex align-items-center'>
                  <div class='img' style='background-image: url(${image}), url("https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg");'></div>
                  <div class='t_content'>
                    <h3>${name}</h3>
                    <p>${type}</p>
                  </div>
                </div>
              </div>`

      divContainerString = container + divContainerString
    }

    if (channels.length > cutOff) {
      const remainder = channels.length - cutOff
      const container = `<div class='team_detail_tooltip'>
                <div class='t_inner d-flex align-items-center'>
                  <div class='t_content'>
                    <h3><u>${remainder}</u> more ${remainder === 1 ? 'account' : 'accounts'}.</h3>
                  </div>
                </div>
              </div>`
      divContainerString = container + divContainerString
    }

    return divContainerString
  }

  /**
   * Modifies the channel name based on type.
   * @param {string} name - The original name.
   * @param {string} type - The type of the social media platform.
   * @returns {string} The modified name.
   */
  const getModifiedChannelName = (name, type) => {
    if (type === 'tumblr') {
      return name.replace('profile_', '')
    }
    return name
  }

  /**
   * Returns the display type of the channel.
   * @param {string} platformType - The platform type.
   * @returns {string} The display type.
   */
  const channelType = (platformType) => {
    switch (platformType) {
      case 'twitter':
      case 'instagram':
        return platformType === 'personal' ? 'Personal' : 'Business'
      case 'tumblr':
        return 'Profile'
      case 'gmb':
        return 'Location'
      case 'tumblrBlog':
        return 'Blog'
      case 'youtube':
        return 'Channel'
      default:
        return platformType
    }
  }

  /**
   * Retrieves channel items from the store.
   * @param {object} channel - The channel object containing the getter name.
   * @returns {Array} An array of channel items.
   */
  const getChannelItems = (channel) => {
    const getter = store.getters[channel.getter]
    return 'items' in getter ? getter.items : getter
  }

  /**
   * Retrieves the fresh account object.
   * @deprecated This method may be redundant.
   * @param {string} type - The type of the social media platform.
   * @param {object} account - The account object.
   * @returns {object} The fresh account object.
   */
  const getFreshAccount = (type, account) => {
    return channelItem(null, type, account) || account
  }

  // Return all the methods and computed properties
  return {
    // Computed properties
    getFacebookAccounts,
    getTwitterAccounts,
    getPinterestBoards,
    getLinkedinAccounts,
    getTumblrAccounts,
    getGmbAccounts,
    getInstagramAccounts,
    getThreadsAccounts,
    getYoutubeAccounts,
    getTiktokAccounts,

    // Methods
    channelItem,
    channelBackgroundImage,
    channelName,
    channelImage,
    channelTooltipHTML,
    channelTooltipMultipleHTML,
    getModifiedChannelName,
    channelType,
    getChannelItems,
    getFreshAccount,
  }
}
