<template>
  <div>
      <div class="upgrade-plan-billing-period">
        <div class="toggle-btn-single">
          <span v-if="isAdmin" class="-mt-5 mb-5 text-base relative flex items-center w-max mx-auto bg-[#ffd372] rounded-xl my-2 px-4 py-2">You are about to update the subscription on behalf of the Super Admin (<span class="text-blue-400 font-medium">{{superAdmin.email}}</span>). Please make sure to use their email address while making the purchase.</span>
          <div class="discount_wrapper">
            <div class="toggle-btn-inner">
              <div
                class="action-move"
                :class="{
                  active_left: billing_period === 'monthly',
                  active_right: billing_period === 'annually',
                }"
              ></div>
              <button
                :class="{ active: billing_period === 'monthly' }"
                class="btn btn_round border_btn large_btn"
                @click="billing_period = 'monthly'"
              >
                <span>Monthly</span>
              </button>
              <button
                :class="{ active: billing_period === 'annually' }"
                class="btn btn_round border_btn large_btn"
                @click="billing_period = 'annually'"
              >
                <span>Yearly</span>
              </button>
            </div>
            <span class="discount">save<br />20%</span>
          </div>
        </div>
      </div>
      <div v-if="!loaders.retrieve_subscription" class="upgrade-plan-box mt-5">
          <template
                  v-for="(pricingPlan, index) in getSubscriptionPlansList"
                  :key="index"
          >
              <div
                      v-if="billing_period === pricingPlan.type"
                      class="upgrade-box-column"
                      :class="{
            active: pricingPlan.level === level,
            // downgrade: pricingPlan.level < level,
          }"
              >
                  <BillingPlanTile
                          :billing_period="billing_period"
                          :pricing-plan="pricingPlan"
                  ></BillingPlanTile>
                  <template
                          v-if="
                              level === pricingPlan.level &&
                              (getProfile.state === 'deleted' || getProfile.state === 'paused')
                            "
                  >
                      <b-button
                              :disabled="pricingPlan.loader"
                              variant="studio-theme-transparent-grey"
                              data-cy="choose-plan2"
                              @click="upgradeSubscriptionPlanAction(pricingPlan)"
                      >
                          Choose Plan
                          <span v-if="pricingPlan.loader" class="ml-2">
                                <clip-loader :size="'14px'" :color="'#3a4557'"></clip-loader>
                              </span>
                      </b-button>
                  </template>
                  <template v-else-if="level === pricingPlan.level">
                      <b-button variant="studio-theme-active">Current Plan</b-button>
                  </template>
                  <template v-else>
                      <b-button
                              :disabled="pricingPlan.loader"
                              variant="studio-theme-transparent-grey"
                              data-cy="choose-plan3"
                              @click="upgradeSubscriptionPlanAction(pricingPlan)"
                      >
                          Choose Plan
                          <span v-if="pricingPlan.loader" class="ml-2">
                                <clip-loader :size="'14px'" :color="'#3a4557'"></clip-loader>
                              </span>
                      </b-button>
                  </template>
              </div>
          </template>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { pricingList } from '@common/constants/pricing'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils'
import { EventBus } from '@common/lib/event-bus'
import BillingPlanTile from "@src/modules/setting/components/billing/reusable/BillingPlanTile";
import {UNKNOWN_ERROR} from "@common/constants/messages";
import useWorkspace from "@common/composables/useWorkspace";
import {usePaddle} from "@modules/billing/composables/usePaddle";
import {useBilling} from "@modules/billing/composables/useBilling";

export default {
  components: {
    BillingPlanTile
  },
  setup(){
    const {getSuperAdmin} = useWorkspace()
    const { openCheckout } = usePaddle();
    const {hideUpgradeModal} = useBilling()

    return {
      getSuperAdmin,
      openCheckout,
      hideUpgradeModal
    }
  },
  data () {
    return {
      billing_period: 'monthly',
      level: 0,
      subscription_plans_list: pricingList,
      checked: false,
      loaders: {
        retrieve_subscription: true
      },
      new_subscription: null
    }
  },
  computed: {
    ...mapGetters(['getPlan', 'getProfile', 'getActiveWorkspace']),
    getSubscriptionPlansList () {
      const subscriptionName =
          this.$store.getters.getPlan.subscription.slug
      // check if the subscription matches in paddle list names

      this.subscription_plans_list.plans.forEach((plan) => {
        if (plan.paddle_name === subscriptionName) {
          this.level = plan.level
          this.billing_period = plan.type || 'monthly'
        }
        // check if the subscription matches in fastspring names

        if (plan.slug === subscriptionName) {
          this.level = plan.level
          this.billing_period = plan.type || 'monthly'
        }
      })

      return this.subscription_plans_list.plans
    },

    superAdmin() {
      return this.getSuperAdmin()
    },

    isAdmin () {
      if(!this.superAdmin) return false
      return this.superAdmin._id !== this.$store.getters.getProfile._id
    }
  },
  async created () {
    this.new_subscription = this.getPlan.subscription
    if (
      !this.$store.getters.getPlan.subscription &&
      this.$store.getters.getPlan.subscription.slug
    ) {
      await this.fetchPlan()
    }
    this.loaders.retrieve_subscription = false
  },
  methods: {
    upgradeSubscriptionPlanAction (subscription) {
      console.log(
        'upgradeSubscriptionPlanAction1',
          this.getProfile.state,
        subscription,
        this.isTrialPlan()
      )
      this.new_subscription = subscription

      // If user is trial or deleted state then upgrade via paddle to create new subscription
      if (this.isTrialPlan() || this.getProfile.state === 'deleted') {
        setTimeout(() => {
          this.upgradeSubscriptionPlanPaddle()
        }, 100)
      } else {
        this.$emit('showPlanConfirmation', subscription, this.billing_period, subscription.level < this.level)
        setTimeout(() => {
          $('#upgrade-plan-confirmation-box').modal('show')
          $('#upgrade-plan-confirmation-box').focus()
        }, 200)
      }
    },

    async upgradeSubscriptionPlanPaddle () {
      const routeQueryValue = this.$route.query.special
      console.log(this.isTrialPlan())

      const self = this
      if(!this.superAdmin) {
        this.alertMessage(UNKNOWN_ERROR, 'error')
      }

      // direct upgrade to avoid duplicate subscriptions
      const res = await this.upgradeBySubscriptionId(this.new_subscription.paddle_id)
      // need subscriptions means used required new subscription
      if(!res?.need_subscription) return

      const couponCode = this.billing_period === 'annually' ? 'ANNUAL20OFF' : ''

      // if the account does not have any paddle account id.
      const passthrough = {
        is_admin: this.isAdmin,
        admin_id: this.isAdmin ? this.$store.getters.getProfile._id : null,
        user_id: this.superAdmin._id,
        user_email: this.superAdmin.email,
        paddle_account_id: this.superAdmin.paddle_account_id || '',
        paddle_subscription_id: this.superAdmin.paddle_subscription_id || '',
      }

      await this.openCheckout({
        product: this.new_subscription.paddle_id,
        email: this.superAdmin.email,
        title: '',
        coupon: couponCode,
        passthrough: JSON.stringify(passthrough),
        successCallback: function (data) {
          console.log(data)
          window.fbq('track', 'Purchase', {
            value: parseInt(data.checkout.prices.customer.total),
            currency: data.checkout.prices.customer.currency
          })
          self.triggerGTMEvent(data, 'plan')
          // send event to usermaven
          self.trackUserMaven('plan_upgraded', {
            plan_name: self.new_subscription?.slug,
            plan_price: self.new_subscription?.price_int || 0,
            plan_billing_period: self.billing_period === 'annually' ? 'yearly' : 'monthly',
            old_plan_name: self.getPlan?.subscription?.slug || '',
          })
          alert('Thanks for your purchase.')

          if(self.$route.name === 'trial_expired') {
            self.$router.push({ name: 'workspaces' })
          }

          setTimeout(async () => {
            window.location.reload();
          }, 3000)
          // self.upgradeCallback(data)
        }
      })
    },

    async upgradeBySubscriptionId (subscriptionId) {
      const res = await proxy
        .post(`${billingBaseUrl}paddle/upgrade_subscription_by_id`, {
          paddle_subscription_id: subscriptionId,
          workspace_id: this.$store.getters.getActiveWorkspace._id,
        })
        .then((res) => {
          console.log(res)
          if(res.data.need_subscription) {
            return res.data
          }
          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message
            })
            return true
          }
        })
        .catch((err) => {
          if (err && err.response && err.response.status === 422) {
            this.$store.dispatch('toastNotification', {
              message: err.response.data.message,
              type: 'error'
            })
          }
          return false
        })

      if(res?.need_subscription) return res
      if (res) {
        await this.$store.dispatch('fetchProfile')
        await this.$store.dispatch('fetchPlan')
        this.identify()
        console.log('event fired for profile, plan and identify.')
        this.hideUpgradeModal()
        EventBus.$emit('reload-billing-history')
      }
    },

    async upgradeCallback (data) {
      console.log('data ->', JSON.stringify(data))

      const res = await proxy
        .post(`${billingBaseUrl}paddle/update_from_callback`, data)
        .then((res) => {
          console.log(res)
          if (res.data.message) {
            this.$store.dispatch('toastNotification', {
              message: res.data.message
            })
            return true
          }
        })
        .catch((err) => {
          return false
        })
      if (res) {
        await this.$store.dispatch('fetchProfile')
        await this.$store.dispatch('fetchPlan')
        this.identify()
        console.log('event fired for profile, plan and identify.')
        this.hideUpgradeModal()
        EventBus.$emit('reload-billing-history')
        if (
          [
            'upgrade_subscription',
            'trial_expired',
            'subscription_expired'
          ].indexOf(this.$route.name) >= 0
        ) {
          this.$router.push({ name: 'workspaces' })
        }
      }
    }
  }
}
</script>

<style lang="less">
.upgrade-pricing-span-component {
}

.billed-annually-text {
  color: #9298a2;
  font-weight: 500;
  font-weight: 500;
  font-size: 0.9em;
}
</style>
