<script setup>
import { useFeatures } from '@modules/billing/composables/useFeatures'

const { canAccess, getRemainingLimit, getLimitMessage } = useFeatures()

// Feature check with custom message
const inboxAccess = canAccess('social_inbox')

// Feature with limit check and custom message
const workspaceAccess = canAccess('team_collaboration', 'workspaces')

// Get descriptive limit message
const workspaceMessage = getLimitMessage('workspaces')

const aiAccess = canAccess('ai_content_creation')
const analyticsAccess = canAccess('social_media_analytics')
const profilesAccess = canAccess('publishing_scheduling', 'profiles')
const postsLimit = getLimitMessage('posts_per_month')
const remainingPosts = getRemainingLimit('posts_per_month')
</script>

<template>
  <!-- Feature access with custom error message -->
  <template v-if="inboxAccess.allowed"> inbox </template>
  <template v-else>
    <div class="upgrade-prompt">
      {{ inboxAccess.error.message }}
    </div>
  </template>

  <!-- Workspace limit with custom message -->
  <template v-if="workspaceAccess.allowed">
    <div class="workspace-info">
      <p>{{ workspaceMessage }}</p>
      <p>Remaining workspaces: {{ getRemainingLimit('workspaces') }}</p>
    </div>
  </template>
  <template v-else>
    <div class="limit-error">
      {{ workspaceAccess.error.message }}
    </div>
  </template>

  <template v-if="aiAccess.allowed"> ai </template>
  <template v-else> upgrade </template>

  <div>
    <p>{{ postsLimit }}</p>
    <p>Remaining posts this month: {{ remainingPosts }}</p>
  </div>

  <template v-if="analyticsAccess.allowed"> analytics </template>
  <template v-if="profilesAccess.allowed"> profiles </template>
</template>
