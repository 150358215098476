import proxy from '@common/lib/http-common'
import Router from '../../../router'

import {
  fetchInfluencerByKeyword,
  fetchInfluencerFolderURL,
  fetchInfluencerFollowersURL,
  fetchInfluencerInstagramPostsURL,
  fetchInfluencerPreviewURL,
  fetchInfluencerSharedArticlesURL,
  fetchInfluencerStats,
  fetchInfluencerTopMentionedURL,
  fetchInfluencerTopSharerURL,
  fetchInfluencerTweetsURL,
  fetchInstagramInfluencerByKeyword,
  fetchInstagramInfluencerPreviewURL,
  fetchInstagramInfluencerStats,
  fetchInstagramInfluencerTopMentionedURL,
  followInfluencerURL,
  saveInfluencerFolder,
  saveInfluencerShortlist,
  fetchYoutubeInfluencerPreviewURL,
  fetchYoutubefluencerByKeyword,
  fetchYoutubeInfluencerStats,
  fetchYoutubeInfluencerVideosURL,
  fetchDiscoverSearchHistoryURL,
  exportInfluencersURL,
  addInfluencerURL,
} from '@src/modules/discovery/config/api-utils'
import {
  TWITTER_INFLUENCER_FOLDER_SAVE,
  TWITTER_INFLUENCER_TWITTER_ACCOUNT_REQUIRED,
  TWITTER_INFLUENCER_TWITTER_EMPTY_TEXTAREA,
} from '@common/constants/messages'
import { processSocialShareUrl } from '@src/modules/publish/config/api-utils'
import { useRoute } from 'vue-router'
import { influencerTypes } from './mutation-types'

const getDefaultInfluencerState = () => {
  return {
    influencer_filters: {
      languages: [],
      location: [],
    },
    instagram_influencer_filters: {
      location: [],
      languages: [],
    },
    youtube_influencer_filters: {
      location: [],
      languages: [],
    },
    influencer_folders: {
      data: [],
    },
    influencer_loaders: {
      preview: false,
      top_mentioned: false,
      shared_articles: false,
      filter_count: false,
      posts: false,
    },
    influencer_tweet: {
      text: '',
      processTweetShare: false,
    },
    influencer_follow: {
      influencer: null,
      processInfluencerFollowLoader: false,
      fetchInfluencerFollowersLoader: false,
      influencerFollowers: [],
    },
    createListInfluencer: '',
  }
}

export default {
  state: getDefaultInfluencerState(),
  getters: {
    getInfluencerFolders: (state) => state.influencer_folders,
    getInfluencerFilters: (state) => state.influencer_filters,
    getInfluencerLoaders: (state) => state.influencer_loaders,
    getInfluencerTweet: (state) => state.influencer_tweet,
    getInfluencerFollow: (state) => state.influencer_follow,
    getCreateListInfluencer: (state) => state.createListInfluencer,
    getInstagramInfluencerFilters: (state) =>
      state.instagram_influencer_filters,
    getYoutubeInfluencerFilters: (state) => state.youtube_influencer_filters,
  },
  actions: {
    /**
     *
     * @param state
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     * @param payload = contains the payload that will be posted.
     * @returns {*}
     */
    fetchInfluencers({ state, commit, dispatch, getters }, payload) {
      // set keyword for search
      console.log('influencers route', Router.currentRoute.value)
      payload.keyword = ''
      if (Router.currentRoute.value.query.q) {
        payload.keyword = Router.currentRoute.value.query.q
      }

      let type = 'twitter'
      if (Object.hasOwn(payload, 'type')) {
        type = payload.type
        delete payload.type
      }

      payload.limit = 8
      if (!Object.hasOwn(payload, 'page_number')) payload.page_number = 1

      let url = fetchInfluencerByKeyword

      if (type === 'twitter') {
        payload.filters = getters.getInfluencerFilters
        payload.filters.is_active = !!(
          Router.currentRoute.value.query.is_active !== undefined &&
          Router.currentRoute.value.query.is_active === 'true'
        )
        payload.filters.verified = !!(
          Router.currentRoute.value.query.is_verified !== undefined &&
          Router.currentRoute.value.query.is_verified === 'true'
        )
      } else if (type === 'instagram') {
        payload.filters = getters.getInstagramInfluencerFilters
        url = fetchInstagramInfluencerByKeyword
      } else if (type === 'youtube') {
        payload.filters = getters.getYoutubeInfluencerFilters
        url = fetchYoutubefluencerByKeyword
      }

      console.debug(
        'is_active',
        payload.filters,
        Router.currentRoute.value.query.is_active &&
          Router.currentRoute.value.query.is_active === 'false'
      )

      payload.filters.selected_list = Router.currentRoute.value.query.list
        ? Router.currentRoute.value.query.list
        : null
      payload.filters.influencer_category = Router.currentRoute.value.query.type
        ? Router.currentRoute.value.query.type.split(',')
        : []
      payload.filters.sort_by = Router.currentRoute.value.query.sort
        ? Router.currentRoute.value.query.sort
        : null
      payload.filters.sort_order = 'desc'

      return proxy
        .post(url, {
          query: payload,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .catch((err) => {
          console.log('fetchInfluencers > ', err)
        })
        .then((res) => {
          console.log(res)
          return res
        })
    },

    fetchInfluencersStats({ state, commit, dispatch, getters }, payload) {
      // set keyword for search
      payload.keyword = ''
      if (Router.currentRoute.value.query.q) {
        payload.keyword = Router.currentRoute.value.query.q
      }

      let type = 'twitter'
      if (Object.hasOwn(payload, 'type')) {
        type = payload.type
        delete payload.type
      }

      if (!Object.hasOwn(payload, 'page_number')) payload.page_number = 1
      payload.limit = 20

      let url = fetchInfluencerStats
      if (type === 'twitter') {
        payload.filters = getters.getInfluencerFilters
        payload.filters.is_active = !!(
          Router.currentRoute.value.query.is_active !== undefined &&
          Router.currentRoute.value.query.is_active === 'true'
        )
        console.debug(
          'fetchInfluencers',
          Router.currentRoute.value.query.is_active
        )
        console.debug(
          'fetchInfluencers',
          Router.currentRoute.value.query.is_active
        )
        payload.filters.verified = !!(
          Router.currentRoute.value.query.is_verified !== undefined &&
          Router.currentRoute.value.query.is_verified === 'true'
        )
      } else if (type === 'instagram') {
        payload.filters = getters.getInstagramInfluencerFilters
        url = fetchInstagramInfluencerStats
      } else if (type === 'youtube') {
        payload.filters = getters.getYoutubeInfluencerFilters
        url = fetchYoutubeInfluencerStats
      }

      payload.filters.selected_list = Router.currentRoute.value.query.list
        ? Router.currentRoute.value.query.list
        : null
      payload.filters.influencer_category = Router.currentRoute.value.query.type
        ? Router.currentRoute.value.query.type.split(',')
        : []
      payload.filters.sort_by = Router.currentRoute.value.query.sort
        ? Router.currentRoute.value.query.sort
        : null
      payload.filters.sort_order = 'desc'

      return proxy
        .post(url, {
          query: payload,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .catch((err) => {
          console.log('fetchInfluencers > ', err)
        })
        .then((res) => {
          console.log(res)
          return res
        })
    },

    /**
     *
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     * @param payload['query'] = contains the payload that will be posted.
     * @returns {*}
     */
    saveInfluencerFolder({ commit, getters, dispatch }, payload) {
      payload.workspace_id = getters.getActiveWorkspace._id
      return proxy
        .post(saveInfluencerFolder, payload)
        .catch(() => {})
        .then((res) => {
          if (res.data.status) {
            commit(influencerTypes.ADD_INFLUENCER_TO_FOLDER_LIST, res.data.data)
            dispatch('toastNotification', {
              message: TWITTER_INFLUENCER_FOLDER_SAVE,
            })
            // eslint-disable-next-line no-undef
            $('#createTwitterList').modal('hide')
            return res.data.data._id
          }
          return false
        })
    },

    /**
     * Add new influencer
     * @param commit
     * @param getters
     * @param dispatch
     * @param payload
     */
    addInfluencer({ commit, getters, dispatch }, payload) {
      return proxy
        .post(addInfluencerURL, payload)
        .then((res) => {
          if (res.data.status) {
            dispatch('toastNotification', {
              message:
                'Influencer added successfully. It may take up to 15 minutes for data to be available.',
            })
            return true
          } else if (res?.data?.message) {
            dispatch('toastNotification', {
              message: res?.data?.message,
              type: 'error',
            })
          } else {
            dispatch('toastNotification', {
              message: 'Something went wrong',
              type: 'error',
            })
          }
        })
        .catch((error) => {
          console.error('add influencer error', error)
          dispatch('toastNotification', {
            message: error?.response?.data?.message || 'Something went wrong',
            type: 'error',
          })
        })
    },

    /**
     * @param commit
     * @param getters
     * fetch influencer folders w.r.t workspace_id
     */
    fetchInfluencerFolder({ commit, getters }) {
      return proxy
        .post(fetchInfluencerFolderURL, {
          workspace_id: getters.getActiveWorkspace._id,
        })
        .catch()
        .then((res) => {
          if (res.data.status) {
            commit(influencerTypes.SET_INFLUENCER_FOLDER_LIST, res.data.data)
          }
        })
    },

    /**
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     * fetch influencer data for preview
     */
    fetchInfluencerPreview({ commit, getters, dispatch }, payload) {
      commit(influencerTypes.SET_INFLUENCER_LOADER_PREVIEW, true)

      let url = null
      let query = {}

      switch (payload.type) {
        case 'twitter':
          url = fetchInfluencerPreviewURL
          query = { user_id: payload.user_id }
          break
        case 'youtube':
          url = fetchYoutubeInfluencerPreviewURL
          query = { channel_id: payload.channel_id }
          break
        case 'instagram':
          url = fetchInstagramInfluencerPreviewURL
          query = { user_id: payload.user_id }
          break
        case 'twitter_screen_name':
          url = fetchInfluencerPreviewURL
          query = { screen_name: payload.screen_name }
          break
      }
      if (!url) return false
      return proxy
        .post(url, {
          query,
          workspace_id: getters.getActiveWorkspace._id,
          type: payload.type,
        })
        .then((res) => {
          commit(influencerTypes.SET_INFLUENCER_LOADER_PREVIEW, false)
          if (res.data.status) {
            return res.data.data
          }
        })
        .catch(() => {
          commit(influencerTypes.SET_INFLUENCER_LOADER_PREVIEW, false)
        })
    },

    /**
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     * fetch influencer top mentioned users
     */
    fetchInfluencerTopMentioned({ commit, getters, dispatch }, payload) {
      console.debug('Method:fetchInfluencerTopMentioned')
      commit(influencerTypes.SET_INFLUENCER_LOADER_TOP_MENTIONED, true)
      let url = fetchInfluencerTopMentionedURL
      if (payload.type === 'instagram') {
        url = fetchInstagramInfluencerTopMentionedURL
      }
      return proxy
        .post(url, {
          query: {
            screen_names: payload.top_user_mentions,
          },
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          commit(influencerTypes.SET_INFLUENCER_LOADER_TOP_MENTIONED, false)
          if (res.data.status) {
            return res.data.data
          }
        })
        .catch(() => {
          commit(influencerTypes.SET_INFLUENCER_LOADER_TOP_MENTIONED, false)
        })
    },

    /**
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     *  fetch all Posts/Tweets that shared by influencer
     */
    fetchInfluencerPosts({ commit, getters, dispatch }, payload) {
      if (!(payload.page != null && payload.page > 0)) {
        commit(influencerTypes.SET_INFLUENCER_LOADER_POSTS, true)
      }

      let url = null
      let query = {}

      switch (payload.type) {
        case 'twitter':
          url = fetchInfluencerTweetsURL
          query = { screen_name: payload.screenName }
          break
        case 'youtube':
          url = fetchYoutubeInfluencerVideosURL
          query = { channel_id: payload.channel_id }
          break
        case 'instagram':
          url = fetchInfluencerInstagramPostsURL
          // eslint-disable-next-line no-case-declarations
          const page = payload.page ? payload.page : 0
          query = {
            screen_name: payload.screenName,
            page,
            index_flag: 'new',
          }
          break
      }
      if (!url) return false

      return proxy
        .post(url, {
          query,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (!(payload.page != null && payload.page > 0)) {
            commit(influencerTypes.SET_INFLUENCER_LOADER_POSTS, false)
          }
          if (res.data.status) {
            return res.data.data
          }
        })
        .catch(() => {
          if (!(payload.page != null && payload.page > 0)) {
            commit(influencerTypes.SET_INFLUENCER_LOADER_POSTS, false)
          }
        })
    },

    /**
     * @param commit
     * @param dispatch
     * @param getters
     * @param screenName
     * fetch all articles that shared by influencer
     */
    fetchInfluencerSharedArticles({ commit, getters, dispatch }, screenName) {
      commit(influencerTypes.SET_INFLUENCER_LOADER_SHARED_ARTICLES, true)
      return proxy
        .post(fetchInfluencerSharedArticlesURL, {
          query: {
            screen_name: screenName,
          },
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          commit(influencerTypes.SET_INFLUENCER_LOADER_SHARED_ARTICLES, false)
          if (res.data.status) {
            return res.data.data
          }
        })
        .catch(() => {
          commit(influencerTypes.SET_INFLUENCER_LOADER_SHARED_ARTICLES, false)
        })
    },

    /**
     *
     * @param commit
     * @param getters
     * @param payload
     * @param payload['screen_name']
     * @param payload['action']
     */
    saveShortlistInfluencer({ commit, getters }, payload) {
      payload.workspace_id = getters.getActiveWorkspace._id
      return proxy.post(saveInfluencerShortlist, payload)
    },

    /**
     * @param commit
     * @param dispatch
     * @param getters
     * @param state
     * tweet with mentioned influencers
     */
    processInfluencerTweet({ state, commit, dispatch, getters }) {
      if (getters.getAccountSelection.twitter.length === 0) {
        dispatch('toastNotification', {
          message: TWITTER_INFLUENCER_TWITTER_ACCOUNT_REQUIRED,
          type: 'error',
        })
        return false
      }
      if (getters.getInfluencerTweet.text.trim().length === 0) {
        dispatch('toastNotification', {
          message: TWITTER_INFLUENCER_TWITTER_EMPTY_TEXTAREA,
          type: 'error',
        })
        return false
      }
      commit('setCommonSharingDetails', null)
      commit('setCommonSharingMessage', getters.getInfluencerTweet.text)
      commit('setPublishTimeSelection', null)
      commit(influencerTypes.SET_INFLUENCER_TWEET_LOADER, true)

      const payload = {
        account_selection: getters.getAccountSelection,
        common_box_status: true,
        common_sharing_details: getters.getCommonSharingDetails,
        publish_time_options: getters.getPublishTimeOptions,
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
        status: null,
      }
      return proxy
        .post(processSocialShareUrl, payload)
        .catch()
        .then((res) => {
          if (res.data.status) {
            dispatch('toastNotification', { message: res.data.message })
            // $('#retweet_influencer_modal').modal('hide')
            // this.$bvModal.hide('retweet_influencer_modal')
          } else {
            dispatch('toastNotification', {
              message: res.data.message,
              type: 'error',
            })
          }
          commit(influencerTypes.SET_INFLUENCER_TWEET_LOADER, false)
          return res.data.status
        })
    },

    /**
     * @param commit
     * @param dispatch
     * @param getters
     * @param state
     * @param data (contain account name and follow/unfollow status)
     * follow/unfollow specific influencer account
     */
    processInfluencerFollow({ state, commit, dispatch, getters }, data) {
      commit(influencerTypes.SET_INFLUENCER_FOLLOW_LOADER, true)

      getters.getTwitterAccounts.items[data.index].is_processing = true
      const payload = {
        account_id: data.account,
        follow: data.follow,
        influencer: getters.getInfluencerFollow.influencer,
        workspace_id: getters.getWorkspaces.activeWorkspace._id,
      }
      proxy
        .post(followInfluencerURL, payload)
        .catch(() => {
          commit(influencerTypes.SET_INFLUENCER_FOLLOW_LOADER, false)
          getters.getTwitterAccounts.items[data.index].is_processing = false
        })
        .then((res) => {
          if (res.data.status) {
            dispatch('toastNotification', { message: res.data.message })
            commit(
              influencerTypes.SET_INFLUENCER_FOLLOWERS,
              res.data.influencers_followers
            )
            // $('#influencer_follow_modal').modal('hide')
          } else {
            dispatch('toastNotification', {
              message: res.data.message,
              type: 'error',
            })
          }
          getters.getTwitterAccounts.items[data.index].is_processing = false
          commit(influencerTypes.SET_INFLUENCER_FOLLOW_LOADER, false)
        })
    },

    /**
     * @param commit
     * @param dispatch
     * @param getters
     * @param state
     * @param payload
     * check follow/unfollow specific influencer account
     */
    fetchInfluencerFollowers({ state, commit, dispatch, getters }, payload) {
      commit(influencerTypes.SET_FETCH_INFLUENCER_FOLLOWERS_LOADER, true)
      proxy
        .post(fetchInfluencerFollowersURL, payload)
        .catch()
        .then((res) => {
          if (res.data.status) {
            commit(influencerTypes.SET_INFLUENCER_FOLLOWERS, res.data.followers)
          } else {
            dispatch('toastNotification', {
              message: res.data.message,
              type: 'error',
            })
            // eslint-disable-next-line no-undef
            $('#influencer_follow_modal').modal('hide')
          }
          commit(influencerTypes.SET_FETCH_INFLUENCER_FOLLOWERS_LOADER, false)
        })
    },

    fetchInfluencerTopSharers({ state, commit, dispatch, getters }, id) {
      return proxy
        .post(fetchInfluencerTopSharerURL, {
          query: {
            post_ids: [id],
          },
          workspace_id: getters.getActiveWorkspace._id,
        })
        .then((res) => {
          if (res.data.status) {
            return res.data.data
          }
        })
        .catch(() => {})
    },

    fetchInfluencerSearchHistory(context, type) {
      return proxy.post(fetchDiscoverSearchHistoryURL, {
        workspace_id: context.getters.getActiveWorkspace._id,
        type,
      })
    },

    /**
     *
     * @param commit
     * @param dispatch
     * @param getters
     * @param payload
     * @param payload['export_limit'] = contains the number of results need to fetch
     * @param payload['export_fields'] = contains the attr name to be exported in csv
     * @returns {*}
     */
    exportInfluencers({ commit, getters, dispatch }, payload) {
      payload.keyword = ''
      if (Router.currentRoute.value.query.q) {
        payload.keyword = Router.currentRoute.value.query.q
      }
      payload.page_number = 1
      payload.filters = getters.getInfluencerFilters
      return proxy
        .post(exportInfluencersURL, {
          query: payload,
          workspace_id: getters.getActiveWorkspace._id,
        })
        .catch()
        .then((res) => {
          return res
        })
    },
  },
  mutations: {
    [influencerTypes.SET_INFLUENCER_FOLDER_LIST](state, value) {
      state.influencer_folders.data = value
    },
    [influencerTypes.REMOVE_INFLUENCER_FROM_FOLDER_LIST](state, value) {
      state.influencer_folders.data.splice(value)
    },
    [influencerTypes.ADD_INFLUENCER_TO_FOLDER_LIST](state, value) {
      state.influencer_folders.data.push(value)
    },
    [influencerTypes.SET_INFLUENCER_LOADER_PREVIEW](state, value) {
      state.influencer_loaders.preview = value
    },
    [influencerTypes.SET_INFLUENCER_TWEET_TEXT](state, value) {
      state.influencer_tweet.text = value
    },
    [influencerTypes.SET_INFLUENCER_TWEET_LOADER](state, value) {
      state.influencer_tweet.processTweetShare = value
    },

    [influencerTypes.SET_INFLUENCER_FOLLOW_LOADER](state, value) {
      state.influencer_follow.processInfluencerFollowLoader = value
    },
    [influencerTypes.SET_INFLUENCER_FOLLOW_DATA](state, value) {
      state.influencer_follow.influencer = value
    },
    [influencerTypes.SET_FETCH_INFLUENCER_FOLLOWERS_LOADER](state, value) {
      state.influencer_follow.fetchInfluencerFollowersLoader = value
    },
    [influencerTypes.SET_INFLUENCER_FOLLOWERS](state, value) {
      state.influencer_follow.influencerFollowers = value
    },

    [influencerTypes.SET_INFLUENCER_LOADER_TOP_MENTIONED](state, value) {
      state.influencer_loaders.top_mentioned = value
    },
    [influencerTypes.SET_INFLUENCER_LOADER_SHARED_ARTICLES](state, value) {
      state.influencer_loaders.shared_articles = value
    },

    [influencerTypes.SET_INFLUENCER_LOADER_POSTS](state, value) {
      state.influencer_loaders.posts = value
    },

    [influencerTypes.SET_CREATE_LIST_INFLUENCER](state, value) {
      state.createListInfluencer = value
    },
    [influencerTypes.CLEAR_INFLUENCERS_SIDEBAR_FILTER](state) {
      state.influencer_filters.verified = false
      state.influencer_filters.is_active = false
      state.influencer_filters.languages = []
      state.influencer_filters.location = []
    },

    [influencerTypes.RESET_INFLUENCERS_FILTER](state) {
      state.influencer_filters.verified = false
      state.influencer_filters.is_active = false
      state.influencer_filters.languages = []
      state.influencer_filters.location = []

      state.instagram_influencer_filters.location = []
      state.instagram_influencer_filters.languages = []

      state.youtube_influencer_filters.location = []
      state.youtube_influencer_filters.languages = []
    },
    [influencerTypes.SET_INFLUENCER_FILTER_LOADER](state, value) {
      state.influencer_loaders.filter_count = value
    },

    [influencerTypes.CLEAR_INSTAGRAM_INFLUENCERS_SIDEBAR_FILTER](state) {
      state.instagram_influencer_filters.languages = []
      state.instagram_influencer_filters.location = []
    },

    [influencerTypes.CLEAR_YOUTUBE_INFLUENCERS_SIDEBAR_FILTER](state) {
      state.youtube_influencer_filters.languages = []
      state.youtube_influencer_filters.location = []
    },
  },
}
