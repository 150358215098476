<script setup>
import { computed, onBeforeMount, onMounted, onBeforeUnmount } from 'vue'
import FilterBar from '@src/modules/analytics/views/performance-report/label-and-campaign/components/FilterBar.vue'
import TabsComponent from '@src/modules/analytics/views/common/TabsComponent.vue'
import OverviewSection from '@src/modules/analytics/views/performance-report/label-and-campaign/components/OverviewSection.vue'
import useAnalyticsUtils from '@src/modules/analytics/components/common/composables/useAnalyticsUtils.js'
import { useStore } from '@state/base'
import { EventBus } from '@common/lib/event-bus'
import useLabelAndCampaign from '@src/modules/analytics/views/performance-report/label-and-campaign/composables/useLabelAndCampaign.js'

const {
  IGNORE_PLATFORMS,
  routes,
  allCampaignsAndLabels,
  isNoLabelOrCampaignSelected,
  selectedAccount,
  fetchMedia,
} = useLabelAndCampaign()

const { getters } = useStore()

const { selectAllAccounts } = useAnalyticsUtils()


onBeforeMount(() => {
  console.log('before mount')
  EventBus.$on('workspace-changed', () => {
    console.log('workspace changed')
    fetchData()
  })
})

onMounted(() => {
  fetchData()
})

onBeforeUnmount(() => {
  console.log('unmounted')
  EventBus.$off('workspace-changed')
})

const fetchData = async () => {
  await fetchMedia(routes.ALL_LABELS_AND_CAMPAIGNS)
}

const getAllAccounts = computed(() => {
  return selectAllAccounts(IGNORE_PLATFORMS)
})

const isNoLabelsOrCampaigns = computed(() => {
  return !allCampaignsAndLabels.value.campaigns.length && !allCampaignsAndLabels.value.labels.length
})

const isNoAccountSelected = computed(() => {
  return !selectedAccount.value?.length
})
</script>

<template>
  <div class="analytics-main-container">
    <FilterBar />
    <div
      v-if="!getAllAccounts.length "
      class="analytics-main-errors"
    >
      <img
        alt="No accounts connected"
        draggable="false"
        src="@modules/analytics/assets/imgs/no_data_images/no-account-connected.svg"
      />
      <p>
        <span > No Account(s) Connected </span>
        <small>Connect a social account </small>
        <router-link
          class="btn btn-studio-theme-space btn-size-small"
          :to="{
            path: `/${getters.getActiveWorkspace.slug}/settings/social`,
          }"
          >Connect Accounts
        </router-link>
      </p>
    </div>
    <div
      v-else-if="isNoLabelsOrCampaigns"
      class="analytics-main-errors"
    >
      <img
        alt="No labels or campaigns"
        draggable="false"
        src="@src/assets/img/no_data_images/no-analytical-data-available.svg"
      />
      <p>
        <span > No Campaigns & Labels in this Workspace </span>
        <small> Publish posts with Campaigns or Labels to get the insights </small>
      </p>
    </div>
    <div
      v-else-if="isNoAccountSelected"
      class="analytics-main-errors"
    >
      <img
        draggable="false"
        src="@src/modules/analytics/assets/imgs/no_data_images/no-account-selected.svg"
        alt=""
      />

      <p>
        No Account(s) Selected
        <small>Select an account from filters to see respective data</small>
      </p>
    </div>
    <div v-else>
      <div>
        <TabsComponent :tabs="['#overview']" type="campaign-and-label" :show-backdrop="isNoLabelOrCampaignSelected">
          <template v-slot:top>
            <div class="bg-black-900 bg-opacity-10 absolute w-full h-full z-10 backdrop-blur-[2px] shadow-sm rounded-md "></div>
          </template>
          <!-- overview tab -->
          <template v-slot:overview>
            <OverviewSection />
          </template>
        </TabsComponent>
      </div>
    </div>
  </div>
</template>
