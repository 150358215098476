import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import moment from 'moment'
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import useDateFormat from '@common/composables/useDateFormat'
import { EventBus } from '@common/lib/event-bus'
import { setUserPreferences } from '@src/config/api-utils'
import FollowersGrowthIcon from '@/src/assets/img/icons/analytic/colored/outlined/followers-color-outlined-icon.svg'
import EngagementPageIcon from '@/src/assets/img/icons/analytic/colored/outlined/engagement-purple-color-outlined-icon.svg'
import EngagementsColorIcon from '@/src/assets/img/icons/analytic/colored/outlined/engagement-color-outlined-icon.svg'
import ImpressionPageIcon from '@/src/assets/img/icons/analytic/colored/outlined/impressions-pink-color-outlined-icon.svg'
import ImpressionsPostIcon from '@/src/assets/img/icons/analytic/colored/outlined/impressions-yellow-color-outlined-icon.svg'
import PostClickIcon from '@/src/assets/img/icons/analytic/colored/outlined/clicks-color-outlined-icon.svg'
import PostIcon from '@/src/assets/img/icons/analytic/colored/outlined/post-color-outlined-icon.svg'
import ReachIcon from '@/src/assets/img/icons/analytic/colored/outlined/reach-color-outlined-icon.svg'
import ReactionsIcon from '@/src/assets/img/icons/analytic/colored//outlined/like-color-outlined-icon.svg'
import CommentsIconColored from '@/src/assets/img/icons/analytic/colored/outlined/comment-green-color-outlined-icon.svg'
import RepostIcon from '@/src/assets/img/icons/analytic/colored/outlined/shares-red-color-outlined-icon.svg'
import SentimentsIcon from '@/src/assets/img/icons/analytic/colored/outlined/sentiments-color-outlined-icon.svg'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper'

import EngagementIcon from '@/src/assets/img/icons/analytic/plain/total-engagement.svg'
import ReactionsPlainIcon from '@/src/assets/img/icons/analytic/plain/reactions.svg'
import CommentsIcon from '@/src/assets/img/icons/analytic/plain/comments-icon.svg'
import SharesIcon from '@/src/assets/img/icons/analytic/plain/shares.svg'
import ImpressionsIcon from '@/src/assets/img/icons/analytic/plain/post-impressions.svg'
import PostClickPlainIcon from '@/src/assets/img/icons/analytic/plain/post-clicks.svg'
import PostTypeIcon from '@/src/assets/img/icons/analytic/plain/post-type.svg'
import AngryIcon from '@/src/assets/img/icons/analytic/plain/angry.svg'
import LikeIcon from '@/src/assets/img/icons/analytic/plain/likes.svg'
import HahaIcon from '@/src/assets/img/icons/analytic/plain/haha.svg'
import HeartIcon from '@/src/assets/img/icons/analytic/plain/heart.svg'
import SadIcon from '@/src/assets/img/icons/analytic/plain/sad.svg'
import WowIcon from '@/src/assets/img/icons/analytic/plain/wow.svg'
import ReachPlainIcon from '@/src/assets/img/icons/analytic/plain/reach.svg'
import useAnalyticsUtils from '@/src/modules/analytics/components/common/composables/useAnalyticsUtils'
const { momentWrapper } = useDateFormat()

const { camelToSnake } = useComposerHelper()

const { getAccountId } = useAnalyticsUtils()
const { formatNumber } = useNumber()
const { ANALYTICS_DELIMITER } = useAnalytics()

const ongoingRequests = new Map()

const selectedAccount = ref(null)
const postsLimit = ref(10)

const isLoadingStates = ref({
  summary: false,
  overviewAudienceGrowth: false,
  overviewReach: false,
  overviewActiveUsers: false,
  overviewImpressions: false,
  overviewEngagement: false,
  overviewTopPosts: false,
  overviewAudienceLocation: false,
  overviewDemographics: false,
  overviewReelsAnalytics: false,
  overviewPublishingBehaviour: false,
  overviewVideoInsights: false,
  getTopPosts: false,
})

const mediaTypeMap = {
  images: 'Image',
  videos: 'Video',
  reels: 'Reel',
}

const routes = {
  SUMMARY: 'summary',
  OVERVIEW_AUDIENCE_GROWTH: 'overviewAudienceGrowth',
  OVERVIEW_ACTIVE_USERS: 'overviewActiveUsers',
  OVERVIEW_IMPRESSIONS: 'overviewImpressions',
  OVERVIEW_ENGAGEMENT: 'overviewEngagement',
  OVERVIEW_TOP_POSTS: 'overviewTopPosts',
  OVERVIEW_REELS_ANALYTICS: 'overviewReelsAnalytics',
  OVERVIEW_AUDIENCE_LOCATION: 'overviewAudienceLocation',
  OVERVIEW_DEMOGRAPHICS: 'overviewDemographics',
  OVERVIEW_VIDEO_INSIGHTS: 'overviewVideoInsights',
  OVERVIEW_PUBLISHING_BEHAVIOUR: 'overviewPublishingBehaviour',
  TOP_POSTS: 'getTopPosts',
}

const rollupTypes = {
  AUDIENCE: 'audience',
  IMPRESSIONS: 'impressions',
  ENGAGEMENTS: 'engagements',
  REELS: 'reels',
  VIDEO_INSIGHTS: 'Video Insights',
}

// state
const { DEFAULT_DATE_RANGE } = useAnalytics()
const dateRange = ref(DEFAULT_DATE_RANGE)

const cardsData = ref({})
const audienceGrowthData = ref({})
const activeUsersData = ref({})
const impressionsData = ref({})
const engagementsData = ref({})
const overviewTopPostsData = ref({})
const overviewAudienceLocationData = ref({})
const overviewDemographicsData = ref({})
const overviewReelsAnalyticsData = ref({})
const overviewVideoInsightsData = ref({})
const overviewPublishingBehaviourData = ref({})
const topPostsData = ref([])

const cards = ref([
  {
    title: 'Followers',
    key: 'page_follows',
    tooltip: `The total number of people who follow your Page. Insights are available from when your Facebook account is connected to the Content Studio.`,
    icon: FollowersGrowthIcon,
  },
  {
    title: 'Total Posts',
    key: 'doc_count',
    tooltip: `The total number of posts published on your Facebook Page during the selected time period.`,
    icon: PostIcon,
  },
  {
    title: 'Page Impressions',
    key: 'page_impressions',
    tooltip: `Shows how often your Page's content—like posts, stories, and ads—appeared on screens during the selected time period. Insights are available from when the Facebook account is connected with the content studio.`,
    icon: ImpressionPageIcon,
  },
  {
    title: 'Page Engagements',
    key: 'page_engagements',
    tooltip:
      'Engagement by Page shows total likes, comments, and shares during the selected time period. Insights are available from when the Facebook account is connected with the content studio.',
    icon: EngagementPageIcon,
  },
  {
    title: 'Page Sentiments',
    key: 'total_engagement_rate_per_impression',
    polarityKeys: {
      positive: 'positive_sentiment',
      negative: 'negative_sentiment',
    },
    tooltip:
      'Sentiments show if reactions and comments on your posts are positive or negative during the selected time period. Insights are available from when your Facebook account is connected to the Content Studio.',
    icon: SentimentsIcon,
  },
  {
    title: 'Post Impressions',
    key: 'impressions',
    tooltip: `The total number of times your Page's posts published during the selected time period entered a person's screen. Posts include statuses, photos, links, videos, and more.`,
    icon: ImpressionsPostIcon,
  },
  {
    title: 'Post Reach',
    key: 'reach',
    tooltip:
      'Reach indicates the total number of unique users who have seen your content posted during the selected the time period.',
    icon: ReachIcon,
  },
  {
    title: 'Post Engagement',
    key: 'total_engagement',
    tooltip:
      'The total number of engagement actions (reactions, comments, and shares) received on all the posts published during the selected time period.',
    icon: EngagementsColorIcon,
  },
  {
    title: 'Post Clicks',
    key: 'posts_clicks',
    tooltip:
      'The total number of times people clicked anywhere in your posts published during the selected time period.',
    icon: PostClickIcon,
  },
  {
    title: 'Post Reactions',
    key: 'reactions',
    tooltip:
      'The total number of reactions your posts received which were published during the selected time period.',
    icon: ReactionsIcon,
  },
  {
    title: 'Post Comments',
    key: 'comments',
    tooltip:
      'The total number of comments your posts received which were published during the selected time period.',
    icon: CommentsIconColored,
  },
  {
    title: 'Post Shares',
    key: 'repost',
    tooltip:
      'Shares indicate how many times users have shared your content during the selected time period, helping expand its visibility by reaching their followers.',
    icon: RepostIcon,
  },
])

const audienceRollupKeys = ref([
  {
    title: 'Page Likes',
    key: 'fan_count',
    tooltip:
      '<b>Page Likes</b><br/>Shows the total number of users who have liked your Facebook page, along with the percentage increase or decrease compared to the previous period.',
  },
  {
    title: 'Talking about Page',
    key: 'talking_about_count',
    tooltip:
      '<b>Talking about Page</b><br/>Indicates how many users are actively engaging with or talking about your page, along with the percentage change compared to the previous period.',
  },
])

const impressionsRollupKeys = ref([
  {
    title: 'Page Imps.',
    key: 'total_impressions',
    tooltip:
      '<b>Page Impressions</b><br/>The total number of times your page content has been seen by users. This includes both unique and repeated views.',
  },
  {
    title: 'Avg. Imps. / day',
    key: 'avg_impressions_per_day',
    tooltip:
      '<b>Average Impressions per Day</b><br/>The average number of times your page content has been seen by users per day over the specified period.',
  },
  {
    title: 'Avg. Imps. / week',
    key: 'avg_impressions_per_week',
    tooltip:
      '<b>Average Impressions per Week</b><br/>The average number of times your page content has been seen by users per week over the specified period.',
  },
])

const engagementsRollupKeys = ref([
  {
    title: 'Page Engs.',
    key: 'page_engagements',
    tooltip:
      '<b>Page Engagements</b><br/>The total number of times your page content has been interacted with by users. This includes likes, comments, shares, and other actions.',
  },
  {
    title: 'Avg. Engs. / day',
    key: 'avg_engagements_per_day',
    tooltip:
      '<b>Average Engagements per Day</b><br/>The average number of times your page content has been interacted with by users per day over the specified period.',
  },
  {
    title: 'Avg. Engs. / week',
    key: 'avg_engagements_per_week',
    tooltip:
      '<b>Average Engagements per Week</b><br/>The average number of times your page content has been interacted with by users per week over the specified period.',
  },
])

const reelsRollupKeys = ref([
  {
    title: 'Reels',
    key: 'total_reels',
    tooltip:
      '<b>Reels</b><br/>The total number of Reels published on your page during the specified period.',
  },
  {
    title: 'Watch Time (Sec)',
    key: 'total_seconds_watched',
    tooltip:
      '<b>Watch Time in Seconds</b><br/>The cumulative amount of time users have spent watching your Reels posted during the selected time period.',
  },
  {
    title: 'Avg. Time Watched',
    key: 'average_seconds_watched',
    tooltip:
      '<b>Average Time Watched</b><br/>The average amount of time users have spent watching your Reels posted during the selected time period.',
  },
  {
    title: 'Initial Plays',
    key: 'initial_plays',
    tooltip:
      '<b>Initial Plays</b><br/>The total number of times your Reels posted during the selected time period, have been played at least once.',
  },
  {
    title: 'Reach',
    key: 'reach',
    tooltip:
      '<b>Reach</b><br/>The total number of unique accounts that have seen your Reels posted during the selected time period.',
  },
  {
    title: 'Reactions',
    key: 'reactions',
    tooltip:
      '<b>Reactions</b><br/>The total number of reactions (likes, loves, haha, wow, sad, angry) your Reels posted during the selected time period have received.',
  },
  {
    title: 'Comments',
    key: 'comments',
    tooltip:
      '<b>Comments</b><br/>The total number of comments left on your reel posted during the selected time period.',
  },
  {
    title: 'Shares',
    key: 'shares',
    tooltip:
      '<b>Shares</b><br/>The number of times your Reels posted during the selected time period have been shared by users.',
  },
])

const videoInsightsRollupKeys = ref([
  {
    title: 'Videos',
    key: 'total_posts',
    tooltip:
      '<b>Videos</b><br/>The total number of videos published on your page during the specified period.',
  },
  {
    title: 'Watch Time (Sec)',
    key: 'total_view_time',
    tooltip:
      '<b>Watch Time in Seconds</b><br/>The cumulative amount of time users have spent watching your videos published during the specified period.',
  },
  {
    title: 'Paid Watch Time (Sec)',
    key: 'paid_view_time',
    tooltip:
      '<b>Paid Watch Time in Seconds</b><br/>The cumulative amount of time users have spent watching your videos published during the selected time period that were promoted through paid advertising.',
  },
  {
    title: 'Organic Watch Time (Sec)',
    key: 'organic_view_time',
    tooltip:
      '<b>Organic Watch Time in Seconds</b><br/>The cumulative amount of time users have spent watching your videos published during the selected time period without paid promotion.',
  },
  {
    title: 'Total Views',
    key: 'total_views',
    tooltip:
      '<b>Total Views</b><br/>The total number of times your videos published during the selected time period have been viewed.',
  },
  {
    title: 'Paid Views',
    key: 'paid_views',
    tooltip:
      '<b>Paid Views</b><br/>The total number of times your videos published during the selected time period have been viewed through paid advertising.',
  },
  {
    title: 'Organic Views',
    key: 'organic_views',
    tooltip:
      '<b>Organic Views</b><br/>The number of times your video content published during the selected time period has been viewed without paid promotion.',
  },
  {
    title: 'Reactions',
    key: 'reactions',
    tooltip:
      '<b>Reactions</b><br/>The total number of reactions (likes, loves, haha, wow, sad, angry) your video content published during the selected time period has received.',
  },
  {
    title: 'Comments',
    key: 'comments',
    tooltip:
      '<b>Comments</b><br/>The total number of comments left on your video content published during the selected time period.',
  },
  {
    title: 'Shares',
    key: 'shares',
    tooltip:
      '<b>Shares</b><br/>The number of times your video content published during the selected time period, has been shared by users.',
  },
])

const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => momentWrapper(date).format('YYYY-MM-DD'))
    .join(' - ')
}

const calculateGrowthAndDiff = (current, previous) => {
  if (!current || !previous) {
    return { growth: 0, difference: 0 }
  }
  const difference = current - previous || 0
  const divisor = previous > 0 ? previous : 1
  const growth =
    difference === 0 ? 0 : formatNumber((difference / divisor) * 100)
  return { growth, difference }
}
const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: 'Default tooltip',
    icon: undefined,
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  const { current, previous } = cardsData.value
  const { growth, difference } = calculateGrowthAndDiff(
    current?.[card.key],
    previous?.[card.key]
  )

  let dataObject = {}
  if (card.polarityKeys) {
    const positive = current?.[card.polarityKeys.positive] || 0
    const negative = current?.[card.polarityKeys.negative] || 0
    const previousPositive = previous?.[card.polarityKeys.positive] || 0
    const previousNegative = previous?.[card.polarityKeys.negative] || 0

    const total = positive + negative
    const previousTotal = previousPositive + previousNegative
    const { growth, difference } = calculateGrowthAndDiff(total, previousTotal)

    dataObject = {
      showPositiveNegative: true,
      positive: formatNumber(positive),
      negative: formatNumber(negative),
      total: formatNumber(total),
      growth: growth || defaultValue.growth,
      difference: difference || defaultValue.difference,
      originalValue: total || defaultValue.originalValue,
    }
  } else {
    dataObject = {
      total: formatNumber(+current?.[card.key]) || defaultValue.total,
      growth: growth || defaultValue.growth,
      difference: difference || defaultValue.difference,
      originalValue: current?.[card.key] || defaultValue.originalValue,
    }
  }

  return {
    title: card.title,
    tooltip: card.tooltip,
    icon: card.icon,
    ...dataObject,
  }
}
const getRollupData = (dataSource, card, key) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: '',
  }

  const rollupData = dataSource.value?.[key]
  if (!rollupData) {
    return defaultValue
  }

  const { current = {}, previous = {} } = rollupData

  const { growth, difference } = calculateGrowthAndDiff(
    current?.[card.key],
    previous?.[card.key]
  )

  return {
    title: card.title,
    total: formatNumber(current?.[card.key]) || defaultValue.total,
    growth: growth || defaultValue.growth,
    difference: difference || defaultValue.difference,
    originalValue: current?.[card.key] || defaultValue.originalValue,
    tooltip: card?.tooltip || defaultValue.tooltip,
  }
}
const validPostsTableHeaders = [
  'thumbnail',
  'mediaType',
  'totalEngagement',
  'postImpressions',
  'postImpressionsUnique',
  'postClicks',
  'total',
  'like',
  'love',
  'haha',
  'wow',
  'sad',
  'angry',
  'comments',
  'shares',
]
const validPostsTableHeadersReport = [
  'thumbnail',
  'mediaType',
  'totalEngagement',
  'postImpressions',
  'postImpressionsUnique',
  'postClicks',
  'total',
  'comments',
  'shares',
]

const nonSortableItems = ['thumbnail', 'description', 'pageName', 'mediaType']

// object mapping of header values to their corresponding titles
const headerTitles = {
  thumbnail: 'Posts',
  pageName: 'Page Name',
  mediaType: 'Media Type',
  totalEngagement: 'Engagements',
  postImpressions: 'Impressions',
  postImpressionsUnique: 'Reach',
  postClicks: 'Post Clicks',
  total: 'Reactions',
  like: 'Likes',
  love: 'Love',
  haha: 'Haha',
  wow: 'Wow',
  sad: 'Sad',
  angry: 'Angry',
  comments: 'Comments',
  shares: 'Shares',
}

const headerTooltips = {
  mediaType: 'Displays the type of media (e.g., photo, video) used in posts.',
  totalEngagement:
    'Measures the total interactions (likes, comments, shares) with your posts.',
  postImpressions: 'Total times your content was displayed to users.',
  postImpressionsUnique:
    'Reach indicates the total number of unique users who have seen your content on Facebook during the selected time period. It helps you understand the impact and visibility of your posts within your audience.',
  postClicks:
    'The total number of times people clicked on anywhere in your posts during the selected time period.',
  total:
    'The total number of reactions your posts received which were published during the selected time period.',
  comments: 'Total number of comments received on your posts.',
  shares:
    'Shares indicate how many times users have shared your content during the selected time period, helping expand its visibility by reaching their followerss.',
}

// Object mapping of header values to their corresponding formatting methods
const bodyValuesMap = {
  thumbnail: (value) => value,
  pageName: (value) => value,
  mediaType: (value) => value,
  totalEngagement: (value) => formatNumber(value),
  postImpressions: (value) => formatNumber(value),
  total: (value) => formatNumber(value),
  like: (value) => formatNumber(value),
  love: (value) => formatNumber(value),
  haha: (value) => formatNumber(value),
  wow: (value) => formatNumber(value),
  sad: (value) => formatNumber(value),
  angry: (value) => formatNumber(value),
  comments: (value) => formatNumber(value),
  shares: (value) => formatNumber(value),
  description: (value) => value,
  postImpressionsUnique: (value) => formatNumber(value),
  postClicks: (value) => formatNumber(value),
}

// method to get the title corresponding to a given header value
const getHeaderTitles = (header = '') => {
  return headerTitles[header] || ''
}

// method to get the tooltip corresponding to a given header value
const getHeaderTooltips = (header = '') => {
  return headerTooltips[header] || ''
}

// method to get the formatted value for a given header and its corresponding value
const getBodyValues = (header = '', value = '') => {
  return bodyValuesMap[header] ? bodyValuesMap[header](value) : ''
}

const postModalFields = [
  {
    label: 'Engagements',
    key: 'totalEngagement',
    iconSrc: EngagementIcon,
    iconClass: 'scale-[1.2]',
    tooltip: `Measures the total interactions (likes, comments, shares) with your posts.`,
  },
  {
    label: 'Impressions',
    key: 'postImpressions',
    iconSrc: ImpressionsIcon,
    iconClass: '',
    tooltip: `Total times your content was displayed to users.`,
  },
  {
    label: 'Post Clicks',
    key: 'postClicks',
    iconClass: '',
    iconSrc: PostClickPlainIcon,
    tooltip: `The total number of times people clicked on anywhere in your posts during the selected time period.`,
  },
  {
    label: 'Reach',
    key: 'postImpressionsUnique',
    iconSrc: ReachPlainIcon,
    iconClass: 'scale-[1.2]',
    tooltip: `Reach indicates the total number of unique users who have seen your content on Facebook during the selected time period. It helps you understand the impact and visibility of your posts within your audience.`,
  },
  {
    label: 'Reactions',
    key: 'total',
    iconSrc: ReactionsPlainIcon,
    iconClass: '',
    tooltip: `The total number of reactions your posts received which were published during the selected time period.`,
  },
  {
    label: 'Likes',
    key: 'like',
    iconSrc: LikeIcon,
    iconClass: '',
    tooltip: `The total number of Likes your posts received which were published during the selected time period.`,
  },
  {
    label: 'Love',
    key: 'love',
    iconSrc: HeartIcon,
    iconClass: '',
    tooltip: `The total number of Love reacts your posts received which were published during the selected time period.`,
  },
  {
    label: 'Haha',
    key: 'haha',
    iconSrc: HahaIcon,
    iconClass: 'scale-[1.3]',
    tooltip: `The total number of Haha reacts your posts received which were published during the selected time period.`,
  },
  {
    label: 'Wow',
    key: 'wow',
    iconSrc: WowIcon,
    iconClass: '',
    tooltip: `The total number of Wow reacts your posts received which were published during the selected time period.`,
  },
  {
    label: 'Sad',
    key: 'sad',
    iconSrc: SadIcon,
    iconClass: '',
    tooltip: `The total number of Sad reacts your posts received which were published during the selected time period.`,
  },
  {
    label: 'Angry',
    key: 'angry',
    iconSrc: AngryIcon,
    iconClass: '',
    tooltip: `The total number of Angry reacts your posts received which were published during the selected time period.`,
  },
  {
    label: 'Comments',
    key: 'comments',
    iconSrc: CommentsIcon,
    iconClass: '',
    tooltip: `Total number of comments received on your posts.`,
  },
  {
    label: 'Shares',
    key: 'shares',
    iconSrc: SharesIcon,
    iconClass: '',
    tooltip: `Shares indicate how many times users have shared your content during the selected time period, helping expand its visibility by reaching their followers.`,
  },
  {
    label: 'Type',
    key: 'mediaType',
    iconSrc: PostTypeIcon,
    iconClass: '',
    tooltip: `Displays the type of media (e.g., photo, video) used in posts.`,
  },
]
const getRollup = (type) => {
  switch (type) {
    case rollupTypes.AUDIENCE:
      return {
        keys: audienceRollupKeys.value,
        getData: (card) =>
          getRollupData(audienceGrowthData, card, 'audience_growth_rollup'),
      }
    case rollupTypes.IMPRESSIONS:
      return {
        keys: impressionsRollupKeys.value,
        getData: (card) =>
          getRollupData(impressionsData, card, 'impressions_rollup'),
      }
    case rollupTypes.ENGAGEMENTS:
      return {
        keys: engagementsRollupKeys.value,
        getData: (card) =>
          getRollupData(engagementsData, card, 'engagement_rollup'),
      }
    case rollupTypes.REELS:
      return {
        keys: reelsRollupKeys.value,
        getData: (card) =>
          getRollupData(overviewReelsAnalyticsData, card, 'reels_rollup'),
      }
    case rollupTypes.VIDEO_INSIGHTS:
      return {
        keys: videoInsightsRollupKeys.value,
        getData: (card) =>
          getRollupData(overviewVideoInsightsData, card, 'video_rollup'),
      }
    default:
      return {
        keys: [],
        getData: () => {},
      }
  }
}
const getHeaderApiKey = (header) => {
  if (tableSortableItems.value.includes(header)) {
    return camelToSnake(header)
  }
  return ''
}

const canFetchMedia = computed(() => {
  return !!(
    selectedAccount.value !== undefined &&
    selectedAccount.value !== null &&
    dateRange.value.length > 0
  )
})

const fanCount = computed(() => {
  return cardsData.value?.current?.fan_count || 0
})

const tableSortableItems = computed(() => {
  return validPostsTableHeaders.filter(
    (header) => !nonSortableItems.includes(header)
  )
})

const setIsloading = (type, value) => {
  isLoadingStates.value[type] = value
}

const transformObject = (data) => {
  return data.map((post) => {
    return {
      id: post.post_id,
      pageName: post.page_name,
      pageId: post.page_id,
      permalink: post.permalink,
      statusType: post.status_type,
      mediaType: mediaTypeMap[post.media_type] || post.media_type,
      videoId: post.video_id,
      category: post.category,
      publishedBy: post.published_by,
      publishedByUrl: post.published_by_url,
      sharedFromName: post.shared_from_name,
      sharedFromId: post.shared_from_id,
      sharedFromLink: post.shared_from_link,
      like: +post.like,
      love: +post.love,
      haha: +post.haha,
      wow: +post.wow,
      sad: +post.sad,
      angry: +post.angry,
      total: +post.total,
      shares: +post.shares,
      comments: +post.comments,
      postClicks: +post.post_clicks,
      totalEngagement: +post.total_engagement,
      postEngagedUsers: +post.post_engaged_users,
      dayOfWeek: post.day_of_week,
      hourOfDay: post.hour_of_day,
      createdAt: post.created_time,
      updatedAt: post.updated_time,
      savingTime: post.saving_time,
      messageTags: post.message_tags,
      postMetadata: post.post_metadata,
      description: post.caption,
      postDescription: post.description,
      thumbnail: post.full_picture,
      postImpressions: +post.post_impressions,
      postImpressionsUnique: +post.post_impressions_unique,
      postImpressionsPaid: +post.post_impressions_paid,
      postImpressionsPaidUnique: +post.post_impressions_paid_unique,
      postImpressionsOrganic: +post.post_impressions_organic,
      postImpressionsOrganicUnique: +post.post_impressions_organic_unique,
      postImpressionsViral: +post.post_impressions_viral,
      postImpressionsViralUnique: +post.post_impressions_viral_unique,
      postVideoViews: +post.post_video_views,
      totalImpressions: +post.total_impressions,
      mediaAssets: post.media_assets.map((asset) => ({
        mediaId: asset.media_id,
        caption: asset.caption,
        link: asset.link,
        assetType: asset.assetType,
        callToAction: asset.callToAction,
        createdAt: asset.createdAt,
      })),
    }
  })
}
const showActiveUserChart = computed(() => {
  // Define the comparison date 2024-09-16
  const comparisonDate = momentWrapper('2024-09-16')
  // Check if any date in the range is before the comparison date
  const containsDateBefore = dateRange.value.some((date) =>
    momentWrapper(date).isBefore(comparisonDate)
  )
  return containsDateBefore
})

export default function useFacebookAnalytics(defaultLabel = 'Data') {
  const { getters, dispatch } = useStore()
  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    pieChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
    tooltipFormatterScroll,
    generateStackedChartOptions,
  } = useAnalytics(defaultLabel)

  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value) {
      // Check if there's an ongoing request of the same type and abort it

      if (ongoingRequests.has(type)) {
        ongoingRequests.get(type).abort()
      }

      // Create a new AbortController for the new request
      const controller = new AbortController()
      const { signal } = controller
      ongoingRequests.set(type, controller)

      try {
        setIsloading(type, true)
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          facebook_id: getAccountId(selectedAccount.value),
          facebook_accounts: [],
          twitter_accounts: [],
          instagram_accounts: [],
          pinterest_accounts: [],
          linkedin_accounts: [],
          tiktok_accounts: [],
          youtube_accounts: [],
          date: getFormattedDateRange(),
          timezone: getters.getActiveWorkspace.timezone,
          type,
          ...extraPayload,
        }
        const { data } = await proxy.post(
          BASE_ENDPOINT + 'facebook/' + type,
          payload,
          { signal }
        )

        // Handle the data based on the type
        switch (type) {
          case routes.SUMMARY:
            cardsData.value = data.overview
            break
          case routes.OVERVIEW_AUDIENCE_GROWTH:
            audienceGrowthData.value = data
            break
          case routes.OVERVIEW_ACTIVE_USERS:
            activeUsersData.value = data?.active_users
            break
          case routes.OVERVIEW_IMPRESSIONS:
            impressionsData.value = data
            break
          case routes.OVERVIEW_ENGAGEMENT:
            engagementsData.value = data?.engagement
            break
          case routes.OVERVIEW_TOP_POSTS:
            overviewTopPostsData.value = data?.top_posts || []
            break
          case routes.OVERVIEW_AUDIENCE_LOCATION:
            overviewAudienceLocationData.value = data || {
              audience_city: {},
              audience_country: {},
            }
            break
          case routes.OVERVIEW_DEMOGRAPHICS:
            overviewDemographicsData.value = data || {}
            break
          case routes.OVERVIEW_REELS_ANALYTICS:
            overviewReelsAnalyticsData.value = data || {}
            break
          case routes.OVERVIEW_VIDEO_INSIGHTS:
            overviewVideoInsightsData.value = data || {}
            break
          case routes.OVERVIEW_PUBLISHING_BEHAVIOUR:
            overviewPublishingBehaviourData.value = data || {}
            break
          case routes.TOP_POSTS:
            topPostsData.value = transformObject(data?.top_posts) || []
            break
          default:
            break
        }
      } catch (e) {
        if (e.name === 'CanceledError') {
          console.log(`Request for ${type} was aborted`)
          return
        } else {
          console.error('FETCH DATA:::', e)
        }
      }
      setIsloading(type, false)
      // Clean up the ongoing request map
      ongoingRequests.delete(type)
    }
  }

  const getTimeFormatted = (timeString) => {
    return moment(timeString).format('MMMM Do, YYYY hh:mm A')
  }

  const setFacebookBannerStatus = async (removePermanently = false) => {
    EventBus.$emit('hide-fb-analytics-banner')
    if (!removePermanently) return
    try {
      await proxy.post(setUserPreferences, {
        key: 'fb_analytics_banner',
        value: false,
      })
      await dispatch('setFacebookBannerViewStatus', false)
    } catch (e) {
      await dispatch('toastNotification', {
        message: 'Could not save preference to hide the banner.',
        type: 'error',
      })
    }
  }

  return {
    ANALYTICS_DELIMITER,
    analyticsDesignSystem,
    // variables
    routes,
    rollupTypes,
    legendOptions,
    dataZoomOptions,
    defaultChartOptions,
    postsLimit,
    postModalFields,
    tableSortableItems,
    // state
    fanCount,
    selectedAccount,
    cards,
    dateRange,
    barChartOptions,
    lineChartOptions,
    pieChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    isReportView,
    screenWidth,
    isLoadingStates,
    audienceGrowthData,
    activeUsersData,
    impressionsData,
    engagementsData,
    overviewTopPostsData,
    overviewAudienceLocationData,
    overviewDemographicsData,
    overviewReelsAnalyticsData,
    overviewVideoInsightsData,
    overviewPublishingBehaviourData,
    topPostsData,
    audienceRollupKeys,
    impressionsRollupKeys,
    engagementsRollupKeys,
    validPostsTableHeaders,
    validPostsTableHeadersReport,
    nonSortableItems,
    mediaTypeMap,
    showActiveUserChart,

    cardsData,
    // methods
    fetchMedia,
    getCardData,
    isNoAnalyticsData,
    axisLabelFormatter,
    getTimeFormatted,
    tooltipFormatterScroll,
    setIsloading,
    getRollup,
    getHeaderTitles,
    getHeaderTooltips,
    getBodyValues,
    getHeaderApiKey,
    generateStackedChartOptions,
    setFacebookBannerStatus,
  }
}
