<script setup>
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import NewStatsCard from '@/src/modules/analytics/views/common/NewStatsCard.vue'
import SkeletonBox from '@/src/modules/analytics/views/common/SkeletonBox.vue'

const { routes, cards, getCardData, isReportView, isLoadingStates } =
  useLinkedinAnalytics()
</script>

<template>
  <template v-if="isLoadingStates[routes?.SUMMARY]">
    <SkeletonBox
      v-for="card in cards"
      :key="card"
      :height="'7.25rem'"
      width="'100%'"
      class="col-span-2 2xl:col-auto"
    />
  </template>
  <template v-else>
    <NewStatsCard
      v-for="card in cards"
      :key="card"
      :show-info-tooltip="!isReportView"
      class="col-span-2 2xl:col-auto"
      :data="getCardData(card)"
    />
  </template>
</template>
