import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  COMPOSER_POCKET_ACCOUNT_REQUIRED,
  COMPOSER_SEARCH_STRING_REQUIRED,
  UNKNOWN_ERROR,
} from '@common/constants/messages'
import moment from 'moment'
import { EventBus } from '@common/lib/event-bus'
import {socialChannelsArray, swalAttributes} from '@common/constants/common-attributes'
import { fetchPlanUrl } from '@src/modules/publish/config/api-utils'
import { articlesTypes as articleTypes } from '@src/modules/discovery/store/mutation-types'
import {
  commonTypes,
  gmb,
  instagramTypes,
  publish,
  social,
  activityTypes,
  sharingTypes,
  blogPosting,
  approvalStages,
  linkedinSharingTypes,
  instagramPostingOption,
  youtubeTypes,
  tiktokTypes,
  getDefaultSharingProgressBar,
} from '@src/modules/publish/store/states/mutation-types'
import { composer } from '@src/modules/composer/store/composer-mutation-type'
import { contentCategoriesTypes } from '@src/modules/setting/store/states/mutation-types'
import { planner } from '@src/modules/planner/store/mutation-types'
import { getDefaultPublishTimeOptions } from '@src/modules/planner/store/planner'
import useDateFormat from "@common/composables/useDateFormat";

const he = require('he')
const composerMixin = {
  setup() {
    const { momentWrapper} = useDateFormat()
    return {
      momentWrapper,
    }
  },
  methods: {
    ...mapMutations([
      'RESET_COMMENTS_LIST',
      'SET_TASKS',
      articleTypes.SET_SEARCH_KEYWORD,
    ]),
    ...mapActions([
      'setSearch',
      'setFacebookContentType',
      'setTwitterContentType',
      'setQuotesSearch',
      'setPocketContentAccount',
      'resetArticleSearch',
      'resetVideo',
      'resetFacebook',
      'resetTwitter',
      'resetImagesStates',
      'resetPocket',
      'resetQuotes',
      'setPublishPlanId',
      'setPublishPlanSecondaryIds',
      'setPublicationStatus',
      'setPublicationFolderId',
      'setSearchArticlesTopic',
      'setPublishMembers',
      'setPublishLabels',
      'setCommonSharingDetails',
      'setAccountSelection',
      'setTumblrSharingDetails',
      'setPinterestSharingDetails',
      'setTwitterSharingDetails',
      'setFacebookSharingDetails',
      'setPublishPlanExecutionTime',
      'setPublishTimeSelection',
      'setSocialPlanDetail',
      'setPublishingPostDate',
      'resetLabels',
      'setTasks',
      'setComments',
      'setEditRepeatStatus',
      'setEditQueueStatus',
      'setInstagramSharingDetails',
      'setPostRepostStatus',
      'setPostReopenStatus',
      'addComments',
      'setCSVPlanId',
      'setVideoContentType',
      'getContent',
      'articles',
      'setSelectedReplugSelection',
      'setSelectedHashtagSelection',
      'setSelectedReplugSelectedItem',
      'setInitializeArticlePostCreationLoader',
      'reopenBlog',
      'reopenSocialPost',
      'resetComposerCommonStates',
      'setSocialSharingTagsSuggestionsItems',

      'resetFacebookSharingDetails',
      'resetSharingDetails',
      'resetPublishingDetails',
      'resetFacebookPostedAs',
      'setFacebookOptions',
      'resetCarouselOptions',
      'setCarouselOptions',

      'setSidebarStatus',
    ]),

    async changeAssistantType(type) {
      this.search = this.getFilters.search.trim()
      if (this.getFilters.search && this.getFilters.search.trim().length > 0) {
        if (type === 'youtube' && this.getAssistantContentType !== 'youtube') {
          this.setVideoContentType('search')
          this.getYoutubeVideo()
        } else if (
          type === 'dailymotion' &&
          this.getAssistantContentType !== 'dailymotion'
        ) {
          this.setVideoContentType('search')
          this.getDailymotionVideo()
        } else if (
          type === 'facebook' &&
          this.getAssistantContentType !== 'facebook'
        ) {
          this.setFacebookContentType('search')
          this.getFacebookContent()
        } else if (
          type === 'twitter' &&
          this.getAssistantContentType !== 'twitter'
        ) {
          this.setTwitterContentType('search')
          this.getTwitterContent()
        } else if (
          type === 'article' &&
          this.getAssistantContentType !== 'article'
        ) {
          await this.getContent({
            type: 'search',
            keyword: this.getFilters.search,
          })
          this.articles()
        } else if (
          type === 'flickr' &&
          this.getAssistantContentType !== 'flickr'
        ) {
          this.$store.commit(composer.SET_ASSISTANT_CONTENT_TYPE, type)
          this.resetImagesFilter()
        } else if (
          type === 'imgur' &&
          this.getAssistantContentType !== 'imgur'
        ) {
          this.$store.commit(composer.SET_ASSISTANT_CONTENT_TYPE, type)
          this.resetImagesFilter()
        } else if (
          type === 'giphy' &&
          this.getAssistantContentType !== 'giphy'
        ) {
          this.$store.commit(composer.SET_ASSISTANT_CONTENT_TYPE, type)
          this.resetImagesFilter()
        } else if (
          type === 'pixabay' &&
          this.getAssistantContentType !== 'pixabay'
        ) {
          this.$store.commit(composer.SET_ASSISTANT_CONTENT_TYPE, type)
          this.resetImagesFilter()
        } else if (
          type === 'quotes' &&
          this.getAssistantContentType !== 'quotes'
        ) {
          this.setQuotesSearch(this.getFilters.search.trim())
          this.searchQuotesByKeyword()
        }
      }

      if (type === 'pocket' && this.getAssistantContentType !== 'pocket') {
        this.setPocketContentAccount('')

        if (this.getPocket.status === false) {
          // this.fetchPocketAccounts(true)
          // this.setFetchPocketAccountStatus(true)
        } else {
          if (this.getPocket.length > 0) {
            this.setPocketContentAccount(this.getPocket[0].username)
            this.resetPocketFilter()
          }
        }
      }
      if (type === 'favorite' && this.getAssistantContentType !== 'favorite') {
        this.$store.commit(composer.SET_CURATION_FAVORITE_NAME, 'Select Folder')
        this.getFavoriteFolders(true)
      }
      this.$store.commit(composer.SET_ASSISTANT_CONTENT_TYPE, type)
    },

    async searchByKeywordAssistant(search) {
      console.debug('method:searchByKeywordAssistant', search)

      if (search && search.trim().length > 0) {
        this[articleTypes.SET_SEARCH_KEYWORD](search)
        if (this.getAssistantContentType === 'youtube') {
          this.setVideoContentType('search')
          this.getYoutubeVideo()
        } else if (this.getAssistantContentType === 'dailymotion') {
          this.setVideoContentType('search')
          this.getDailymotionVideo()
        } else if (this.getAssistantContentType === 'facebook') {
          this.setFacebookContentType('search')
          this.getFacebookContent()
        } else if (this.getAssistantContentType === 'twitter') {
          this.setTwitterContentType('search')
          this.getTwitterContent()
        } else if (this.getAssistantContentType === 'article') {
          await this.getContent({ type: 'search', keyword: search })
          await this.articles()
          this.checkComposerDragDrop()
        } else if (
          this.getAssistantContentType === 'flickr' ||
          this.getAssistantContentType === 'imgur' ||
          this.getAssistantContentType === 'giphy' ||
          this.getAssistantContentType === 'pixabay'
        ) {
          this.resetImagesFilter()
        } else if (this.getAssistantContentType === 'quotes') {
          this.setQuotesSearch(this.getFilters.search.trim())
        } else if (this.getAssistantContentType === 'pocket') {
          if (this.getPocketFilter.account === '') {
            this.alertMessage(COMPOSER_POCKET_ACCOUNT_REQUIRED, 'error')
          } else {
            this.resetPocketFilter()
          }
        }
      } else {
        if (this.getAssistantContentType === 'pocket') {
          this.setSearch('')
          if (this.getPocketFilter.account === '') {
            this.alertMessage(COMPOSER_POCKET_ACCOUNT_REQUIRED, 'error')
          } else {
            this.resetPocketFilter()
          }
        } else {
          this.alertMessage(COMPOSER_SEARCH_STRING_REQUIRED, 'error')
        }
      }
    },

    clearAssistantQuery() {
      this.setSearch('')
    },

    slugify(text) {
      /* eslint-disable */
      return text
        .toString()
        .toLowerCase()
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/[^\w\-]+/g, '') // Remove all non-word chars
        .replace(/\-\-+/g, '-') // Replace multiple - with single -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, '') // Trim - from end of text
      /* eslint-enable */
    },

    resetBlogSocialDetail() {
      console.debug('Method:resetBlogSocialDetail')

      EventBus.$emit('fetchedFirstComment', {
        has_first_comment: false,
        first_comment_message: '',
        first_comment_location: null,
        first_comment_accounts: [],
      })

      this.$store.commit(blogPosting.RESET_BLOG_POSTING)
      this.$store.commit(composer.RESET_COMPOSER_BLOG)
      this.$store.commit(blogPosting.RESET_BLOG_SELECTION)

      this.$store.commit(publish.SET_PLAN_APPROVAL_DATA, null)
      this.$store.commit(approvalStages.RESET_APPROVAL_DATA)

      this.setPublishLabels([])
      this.setPublishMembers([])

      this.$store.commit(activityTypes.SET_ACTIVITIES, [])
      this.$store.commit(activityTypes.SET_TOTAL_ACTIVITIES, 0)

      this.setPublishTimeSelection(null)
      this.$store.commit(sharingTypes.SET_MENTIONS_DROPDOWN_STATUS, false)
      this.$store.commit(sharingTypes.SET_HASHTAGS_DROPDOWN_STATUS, false)
      this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, true)
      this.setCommonSharingDetails(null)
      this.setFacebookSharingDetails(null)
      this.setTwitterSharingDetails(null)
      this.$store.commit(social.SET_TWITTER_OPTIONS, null)
      this.resetFacebookPostedAs([])
      this.resetCarouselOptions()
      this.$store.commit(linkedinSharingTypes.SET_SHARING_DETAILS, null)
      this.setPinterestSharingDetails(null)
      this.setTumblrSharingDetails(null)
      this.$store.commit(instagramTypes.SET_SHARING_DETAILS, null)
      this.$store.commit(gmb.SET_SHARING_DETAILS, null)
      this.$store.commit(youtubeTypes.SET_SHARING_DETAILS, null)
      this.$store.commit(social.SET_YOUTUBE_OPTIONS, null)
      this.$store.commit(social.SET_PINTEREST_OPTIONS, null)
      this.setAccountSelection(null)
      this.$store.commit(social.SET_GMB_OPTIONS, null)
      this.RESET_COMMENTS_LIST()
      this.RESET_TASK_LIST()
      this.$store.commit(sharingTypes.SET_SOCIAL_SHARING_MEDIA_DETAILS, {})

      this.setSelectedHashtagSelection(null)
      this.setSelectedReplugSelection(null)
      this.setSocialSharingTagsSuggestionsItems([])

      this.$store.commit(publish.SET_PUBLISH_SOURCE, null)
      this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, '')
      this.setPostReopenStatus(false)
      this.setCSVPlanId(null)
      this.setPublishPlanId('')
      this.setPublicationStatus('')
      this.setPublishPlanSecondaryIds([])
      this.setPublicationFolderId(null)
      this.setPublishPlanExecutionTime(null)
      this.setSocialPlanDetail([])
      this.setEditQueueStatus(false)
      this.setEditRepeatStatus(false)
      this.$store.commit(
        contentCategoriesTypes.RESET_CONTENT_CATEGORY_AVAILABLE_SLOT
      )
      this.$store.commit(publish.SET_PUBLISH_CONTENT_CATEGORY_VALUE, null)
    },

    resetSocialPostDetail() {
      console.debug('Method:resetSocialPostDetail')

      // Resetting Sharing State
      this.resetSharingDetails()
      this.resetFacebookSharingDetails()

      // Resetting Publishing Sharing State
      this.resetPublishingDetails()

      // Resetting Approval Data
      this.$store.commit(publish.SET_PLAN_APPROVAL_DATA, null)
      this.$store.commit(approvalStages.RESET_APPROVAL_DATA)

      // Resetting Labels and Members
      this.setPublishLabels([])
      this.setPublishMembers([])

      // Resetting Activity
      this.$store.commit(activityTypes.SET_ACTIVITIES, [])
      this.$store.commit(activityTypes.SET_TOTAL_ACTIVITIES, 0)
      this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, true)

      // Resetting Options
      this.setPublishTimeSelection(null)
      this.setCommonSharingDetails(null)
      this.setFacebookSharingDetails(null)
      this.setTwitterSharingDetails(null)
      this.$store.commit(linkedinSharingTypes.SET_SHARING_DETAILS, null)
      this.setPinterestSharingDetails(null)
      this.setTumblrSharingDetails(null)
      this.$store.commit(instagramTypes.SET_SHARING_DETAILS, null)
      this.$store.commit(gmb.SET_SHARING_DETAILS, null)
      this.$store.commit(youtubeTypes.SET_SHARING_DETAILS, null)
      this.$store.commit(social.SET_YOUTUBE_OPTIONS, null)
      this.$store.commit(social.SET_PINTEREST_OPTIONS, null)
      this.setAccountSelection(null)
      this.$store.commit(social.SET_GMB_OPTIONS, null)
      this.RESET_COMMENTS_LIST()
      this.RESET_TASK_LIST()

      this.setSelectedHashtagSelection(null)
      this.setSelectedReplugSelection(null)
      this.setSocialSharingTagsSuggestionsItems([])

      this.$store.commit(publish.SET_PUBLISH_SOURCE, null)
      this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, '')
      this.setPostReopenStatus(false)
      this.setCSVPlanId(null)
      this.setPublishPlanId('')
      this.setPublishPlanSecondaryIds([])
      this.setPublicationFolderId(null)
      this.setPublishPlanExecutionTime(null)
      this.setSocialPlanDetail([])
      this.setEditQueueStatus(false)
      this.setEditRepeatStatus(false)
      this.$store.commit(
        contentCategoriesTypes.RESET_CONTENT_CATEGORY_AVAILABLE_SLOT
      )
      this.$store.commit(publish.SET_PUBLISH_CONTENT_CATEGORY_VALUE, null)
    },

    changePublishTypeFromCalender(type, isToday, date) {
      console.debug('Method:changePublishTypeFromCalender')

      this.setPublicationStatus('draft')
      this.$store.commit(publish.SET_PLAN_APPROVAL_DATA, null)
      this.$store.commit(approvalStages.RESET_APPROVAL_DATA)

      if (type === 'post') {
        EventBus.$emit('publication-composer-post-loader', true)
        this.$bvModal.show('composer-modal')
        let timeType = 'now'
        let postDate = ''
        this.resetBlogSocialDetail()
        if (!isToday) {
          timeType = 'schedule'
          postDate = this.momentWrapper(moment(date).utc()).formatTimezone().format('MMM DD, YYYY HH:mm')
        }

        if (!this.hasPermission('can_schedule_plan')) {
          timeType = 'queued'
        }
        this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, 'Composer Social')
        setTimeout(function () {
          EventBus.$emit('publication-composer-post', {
            mode: 'new',
            timeType,
            postDate,
          })
        }, 100)
      } else {
        this.resetBlogSocialDetail()

        if (!isToday) {
          this.setPublishingTimeType('schedule')
          this.setPublishingPostDate(
            this.momentWrapper(moment(date).utc()).formatTimezone().format('MMM DD, YYYY HH:mm')
          )
        }
        this.setPublicationStatus('draft')
        this.$store.commit(
          blogPosting.SET_BLOG_POSTING_TYPE,
          'Composer Article'
        )
        this.processBlogPost(true, false, true)
        this.callSortableFunctionBlog()
      }
    },

    // composer main, giving an option to create either social or blog post.
    async changePublishType(type, isDraftComposer = false, planId = '', description = "") {
      let res = false
      if(isDraftComposer && type === 'Composer Social') {
        const options = {...swalAttributes(), modalClass: 'sweet-alert-confirmation fade sweet-alert-minimize-composer', okVariant: 'primary', hideHeader: false}
        res = await this.$bvModal.msgBoxConfirm(
            'You have a post currently in the composer that is minimized. What would you like to do?',
            {
              title: '⚠️ Unfinished Post is in the Composer!',
              ...options,
              cancelTitle: 'Save & Create New',
              okTitle: 'Return to Composer',
            }
        )
      }

      console.log('changePublishType', res)

      if(res === null) {
        return;
      }
      if(!res) {
        console.debug('Method:changePublishType', type)
        if (type.toLowerCase().includes('social')) {

          EventBus.$emit('publication-composer-post-loader', true)
          this.$bvModal.show('composer-modal')

          setTimeout(function () {
            EventBus.$emit('publication-composer-post', {
              mode: 'new',
              description: description
            })
          }, 100)

          return
        }

        this.clearAssistantQuery()

        // Resetting First Comment
        this.$store.commit(sharingTypes.SET_HAS_FIRST_COMMENT, false)
        this.$store.commit(sharingTypes.SET_FIRST_COMMENT_MESSAGE, '')
        this.$store.commit(sharingTypes.SET_FIRST_COMMENT_LOCATION, null)
        this.$store.commit(sharingTypes.SET_FIRST_COMMENT_ACCOUNTS, [])
        this.$store.commit(
            instagramPostingOption.SET_INSTAGRAM_POSTING_OPTION,
            null
        )

        const stateObject = this
        this.resetBlogSocialDetail()
        this.setPublicationStatus('draft')
        this.$store.commit(publish.SET_PLAN_APPROVAL_DATA, null)
        this.$store.commit(approvalStages.RESET_APPROVAL_DATA)

        // setting default composer folder in campaign dropdown
        if (type === 'Composer Article') {
          this.$store.commit(
              blogPosting.SET_BLOG_POSTING_TYPE,
              'Composer Article'
          )
          stateObject.processBlogPost(true, false, true)
          this.callSortableFunctionBlog()
        } else {
          if (!this.hasPermission('can_schedule_plan')) {
            this.setPublishingTimeType('queued')
          }
          this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, 'Composer Social')
          stateObject.processSocialShare(true, false, true)
          this.callSortableFunctionSocial()
        }
      } else {
        const workspace = this.$route.params.workspace
        history.pushState({}, null, `/${workspace}/composer/${planId}`)
        this.$bvModal.show('composer-modal')
      }
    },

    editPublication(
      id,
      duplicate,
      reopen = false,
      initialize = false,
      postType = null
    ) {
      console.debug('Method:editPublication', id, duplicate, reopen, postType)

      if (!postType || (postType && !postType.includes('blog'))) {
        console.debug('Method:editPublication-composerv2')
        EventBus.$emit('publication-composer-post', {
          mode: 'edit',
          id,
          duplicate,
          reopen,
          initialize,
          postType,
        })
        this.$bvModal.show('composer-modal')
        return
      }

      // showing the loader.

      this.setInitializeArticlePostCreationLoader(false)
      const stateObject = this

      // if it is draft post, we will redirect to the composer section.
      // if it is scheduled or other post type, we will open the popup/dialog

      if (
        postType &&
        (postType !== 'social_draft' || this.getUserRole === 'Approver') &&
        (postType !== 'blog_draft' || this.getUserRole === 'Approver') &&
        (this.$route.name === 'list_plans' ||
          this.$route.name === 'calender_plans' ||
          this.$route.name === 'feed_view')
      ) {
        this.setInitializeArticlePostCreationLoader(true)

        // show the modal based on the type.

        if (postType === 'social' || this.getUserRole === 'Approver') {
          this.$bvModal.show('social_share_modal')
        } else if (postType === 'blog' || this.getUserRole === 'Approver') {
          this.$bvModal.show('blog_share_modal')
          stateObject.setCKEditorHtml('', true)
          stateObject.setSidebarStatus('task')
        }
      } else if (this.$router.currentRoute.name !== 'composer' && !initialize) {
        // re-open case.
        if (postType === 'blog_draft') {
          this.$router.push({ name: 'composerBlog', params: { id } })
        } else if (postType === 'social_draft') {
          this.$router.push({ name: 'composerSocial', params: { id } })
        } else {
          this.$router.push({ name: 'composerBlog', params: { id } })
        }
      }

      // showing the loader till we get the item from the db.

      if (
        !postType ||
        postType === 'social_draft' ||
        postType === 'blog_draft' ||
        this.$router.currentRoute.name === 'composer'
      ) {
        this.$store.commit(planner.SET_EDIT_PLAN_LOADER, true)
      }

      // reset the blog social detials array

      this.resetBlogSocialDetail()
      const payload = {
        id,
        workspace_id: this.getWorkspaces.activeWorkspace._id,
      }
      // get the plan.

      this.postRequest(
        fetchPlanUrl,
        payload,
        (response) => {
          if (response.data.status === true) {
            const plan = response.data.plan

            // For First Comment case
            if (
              typeof plan.has_first_comment !== 'undefined' &&
              plan.has_first_comment === true &&
              Array.isArray(plan.first_comment_accounts)
            ) {
              this.$store.commit(
                sharingTypes.SET_HAS_FIRST_COMMENT,
                plan.has_first_comment
              )
              this.$store.commit(
                sharingTypes.SET_FIRST_COMMENT_MESSAGE,
                plan.first_comment_message
              )
              this.$store.commit(
                sharingTypes.SET_FIRST_COMMENT_LOCATION,
                plan.first_comment_location
              )
              this.$store.commit(
                sharingTypes.SET_FIRST_COMMENT_ACCOUNTS,
                plan.first_comment_accounts
              )

              EventBus.$emit('fetchedFirstComment', {
                has_first_comment: plan.has_first_comment,
                first_comment_message: plan.first_comment_message,
                first_comment_location: plan.first_comment_location,
                first_comment_accounts: plan.first_comment_accounts,
              })
            } else {
              this.$store.commit(sharingTypes.SET_HAS_FIRST_COMMENT, false)
              this.$store.commit(sharingTypes.SET_FIRST_COMMENT_MESSAGE, '')
              this.$store.commit(sharingTypes.SET_FIRST_COMMENT_LOCATION, null)
              this.$store.commit(sharingTypes.SET_FIRST_COMMENT_ACCOUNTS, [])

              EventBus.$emit('fetchedFirstComment', {
                has_first_comment: false,
                first_comment_message: '',
                first_comment_location: null,
                first_comment_accounts: [],
              })
            }

            // for instagram publication edit case
            if (
              typeof plan.instagram_posting_option !== 'undefined' &&
              plan.instagram_posting_option
            ) {
              this.$store.commit(
                instagramPostingOption.SET_INSTAGRAM_POSTING_OPTION,
                plan.instagram_posting_option
              )
            } else {
              this.$store.commit(
                instagramPostingOption.SET_INSTAGRAM_POSTING_OPTION,
                null
              )
            }

            if (response.data.activities) {
              this.$store.commit(
                activityTypes.SET_ACTIVITIES,
                response.data.activities.hits
              )
              this.$store.commit(
                activityTypes.SET_TOTAL_ACTIVITIES,
                response.data.activities.total
              )
            }

            // in case of the duplicate post, null the plan id, status and repost value to true.

            if (duplicate === true) {
              this.setPublishPlanId(null)
              this.setPublicationStatus('')
              this.setPostRepostStatus(true)
            } else {
              // in case of the existing post, get the category and other details.

              this.setPublishPlanId(plan._id)
              this.$store.commit(
                publish.SET_PUBLISH_CONTENT_CATEGORY_VALUE,
                plan.content_category_id
              )
              this.setPublicationStatus(plan.status)

              if (plan.approval) {
                this.$store.commit(
                  publish.SET_PLAN_APPROVAL_DATA,
                  plan.approval
                )
              }
            }

            // set the labels, folders, team members etc.

            this.$store.commit(blogPosting.SET_BLOG_POSTING_TYPE, plan.type)
            this.setPublicationFolderId(plan.folderId)
            if (
              (plan.type === 'Composer Social' ||
                plan.type === 'Composer Article') &&
              !plan.folderId &&
              (this.$route.name === 'list_plans' ||
                this.$route.name === 'calender_plans' ||
                this.$route.name === 'feed_view')
            ) {
              this.setPublicationFolderId('default')
            } else this.setPublicationFolderId(plan.folderId)
            this.setPublishLabels(plan.labels)
            this.setPublishMembers(plan.members)

            if (plan.publish_source) {
              this.$store.commit(
                publish.SET_PUBLISH_SOURCE,
                plan.publish_source
              )
            }
            // set the comments, and tasks

            if (duplicate) {
              this.$store.commit('RESET_COMMENTS_LIST', [])
              this.$store.commit('RESET_TASK_LIST', [])
              this.$store.commit(activityTypes.SET_ACTIVITIES, [])
            } else {
              this.SET_TASKS(plan.tasks)
              this.addComments({
                comments: plan.comments,
                members: this.getWorkspaces.activeWorkspace.members,
              })
            }

            // set the execution time.
            this.setPublishPlanExecutionTime(plan.execution_time)

            // if it exists, it is the blog post. (dealing blog case)

            if (plan.blog_reference) {
              const post = response.data.plan.composer_post // contains the editor, and meta data
              const plan = response.data.plan // primary secondary details

              // this method call when blog post is open directly and we need to fetch post in thatc ase

              // in case of the reopen, hide the loader

              if (initialize) {
                setTimeout(function () {
                  stateObject.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
                }, 1000)

                if (plan.status === 'draft') this.socialDraftTimmer()
              }

              setTimeout(function () {
                const blogPostText = post.description.replace(
                  /<iframe\s+.*?\s+src=(".*?").*?<\/iframe>/g,
                  '<oembed url=$1></oembed>'
                )
                stateObject.setCKEditorHtml(blogPostText, true)
              }, 2000)

              // set the meta data for the SEO

              this.$store.commit(
                blogPosting.SET_BLOG_POSTING_TITLE,
                he.decode(post.title)
              )
              this.$store.commit(blogPosting.SET_BLOG_POSTING_IMAGE, post.image)
              this.$store.commit(
                blogPosting.SET_BLOG_POSTING_CUSTOMIZE_SEO_PREVIEW_STATUS,
                post.customize_seo_preview
              )
              this.$store.commit(
                blogPosting.SET_BLOG_POSTING_META_TITLE,
                post.meta_title
              )
              this.$store.commit(
                blogPosting.SET_BLOG_POSTING_META_URL,
                post.meta_url
              )
              this.$store.commit(
                blogPosting.SET_BLOG_POSTING_META_DESCRIPTION,
                post.meta_description
              )
              this.$store.commit(blogPosting.SET_BLOG_POSTING_LINK, post.link)
              this.$store.commit(
                blogPosting.SET_BLOG_POSTING_OPTIONAL_DESCRIPTION,
                post.optional_description
              )

              if (post.seo_keyword) {
                this.$store.commit(composer.SET_SEO_KEYWORD, post.seo_keyword)
              }
              if (post.seo_url) {
                this.$store.commit(composer.SET_SEO_URL, post.seo_url)
              }

              this.setAccountSelection(plan.social.account_selection)

              this.setSelectedHashtagSelection(plan.social)
              this.processHashtagSelection(plan.social.hashtag)
              if (plan.social.replug) {
                if (!this.getFetchIntegrationsAccountsLoader) {
                  this.processReplugSelection(plan.social.replug)
                } else {
                  this.setSelectedReplugSelectedItem(plan.social.replug)
                }
              }

              this.setInitializeArticlePostCreationLoader(false)

              if (duplicate === true) {
                this.$store.commit(blogPosting.SET_BLOG_POSTING_ID, '')
                this.setPublishPlanSecondaryIds([])
                this.setSocialPlanDetail([])
                const defaultTime = {
                  time_type: 'now',
                  postSelectedDate: '',
                }
                // eslint-disable-next-line no-undef
                $(plan.selection.blog_selection.secondary_blog).each(function (
                  index,
                  el
                ) {
                  delete el.id
                  delete el.status
                  el.time = JSON.parse(JSON.stringify(defaultTime))
                })
                // eslint-disable-next-line no-undef
                $(plan.selection.blog_selection.primary_blog).each(function (
                  index,
                  el
                ) {
                  el.time = JSON.parse(JSON.stringify(defaultTime))
                })

                this.$store.dispatch(
                  'processPrimarySecondaryBlogSelection',
                  plan.selection.blog_selection
                )
              } else {
                this.$store.commit(blogPosting.SET_BLOG_POSTING_ID, post._id)
                this.$store.dispatch(
                  'processPrimarySecondaryBlogSelection',
                  plan.selection.blog_selection
                )
                this.setPublishPlanSecondaryIds(plan.secondaryIds)
                this.setSocialPlanDetail([
                  {
                    id: plan.social.id,
                    status: plan.social.status,
                    execution_time: plan.social.execution_time,
                  },
                ])
              }

              setTimeout(function () {
                stateObject.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
              }, 200)

              if (this.$router.currentRoute.name === 'composer') {
                this.$router.push({
                  name: 'composerBlog',
                  params: { id: plan._id },
                })
              }
            } else {
              // social case.

              // this method call when social post is open directly and we need to fetch post in thatc ase
              if (initialize) {
                setTimeout(function () {
                  stateObject.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
                }, 1000)

                // do not allow user to edit the existing post.

                if (plan.status === 'draft') this.socialDraftTimmer()
              }

              let defaultTimeSelection = plan.publish_time_options

              if (!defaultTimeSelection) {
                defaultTimeSelection = getDefaultPublishTimeOptions()
                defaultTimeSelection.post_date = moment
                  .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
                  .tz(this.getWorkspaces.activeWorkspace.timezone)
                  .format('MMMM DD, YYYY HH:mm')
                if (plan.status === 'review') {
                  defaultTimeSelection.plan_status = 'Under Review'
                }
              } else {
                if (defaultTimeSelection.time_type === 'schedule') {
                  defaultTimeSelection.post_date = moment
                    .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
                    .tz(this.getWorkspaces.activeWorkspace.timezone)
                    .format('MMMM DD, YYYY HH:mm')
                } else if (
                  defaultTimeSelection.time_type === 'content_category'
                ) {
                  // testing purpose

                  const publishTimeOptions = getDefaultPublishTimeOptions()
                  publishTimeOptions.post_date = moment
                    .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
                    .tz(this.getWorkspaces.activeWorkspace.timezone)
                    .format('MMMM DD, YYYY HH:mm')
                  defaultTimeSelection = Object.assign(
                    publishTimeOptions,
                    defaultTimeSelection
                  )
                  this.$store.commit(
                    contentCategoriesTypes.SET_CONTENT_CATEGORY_AVAILABLE_SLOT,
                    {
                      data: {
                        date: moment
                          .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
                          .tz(this.getWorkspaces.activeWorkspace.timezone)
                          .format('YYYY-MM-DD HH:mm:ss.SSSSSS'),
                      },
                      status: true,
                      scheduled: true,
                    }
                  )

                  console.log(
                    plan,
                    moment
                      .utc(plan.execution_time.date, 'YYYY-MM-DD HH:mm')
                      .tz(this.getWorkspaces.activeWorkspace.timezone)
                  )
                }
                plan.publish_time_options.plan_status =
                  plan.status === 'review' ||
                  (plan.approval && plan.approval.status === 'pending_approval')
                    ? 'Under Review'
                    : 'Approved'
              }

              if (plan.status !== 'draft') {
                defaultTimeSelection.repeat = 1
                defaultTimeSelection.repeat_type = 'Hour'
                defaultTimeSelection.repeat_times = 1
                defaultTimeSelection.repeat_gap = 1
              }

              // in case of collaborator which don't have access to post now
              if (
                !this.hasPermission('can_schedule_plan') &&
                defaultTimeSelection.time_type === 'now'
              ) {
                defaultTimeSelection.time_type = 'queued'
              }

              if (
                (plan.status === 'draft' ||
                  plan.status === 'failed' ||
                  ((plan.status === 'rejected' || plan.status === 'review') &&
                    plan.execution_time.date <
                      moment.utc().format('YYYY-MM-DD HH:mm:ss'))) &&
                defaultTimeSelection.time_type === 'content_category' &&
                plan.content_category_id &&
                plan.content_category_id.length > 3
              ) {
                this.$store.dispatch(
                  'nextAvailableSlot',
                  plan.content_category_id
                )
              }

              this.setPublishTimeSelection(defaultTimeSelection)
              plan.common_box_status === undefined
                ? this.$store.commit(commonTypes.SET_COMMON_BOX_STATUS, true)
                : this.$store.commit(
                    commonTypes.SET_COMMON_BOX_STATUS,
                    plan.common_box_status
                  )
              if (
                !plan.common_box_status &&
                this.isGlobalContentCategorySelected
              ) {
                this.fetchGlobalCategoryChannels()
              }
              setTimeout(function () {
                plan.common_sharing_details.message = he.decode(
                  plan.common_sharing_details.message
                )
                plan.facebook_sharing_details.message = he.decode(
                  plan.facebook_sharing_details.message
                )
                plan.twitter_sharing_details.message = he.decode(
                  plan.twitter_sharing_details.message
                )
                plan.linkedin_sharing_details.message = he.decode(
                  plan.linkedin_sharing_details.message
                )
                plan.pinterest_sharing_details.message = he.decode(
                  plan.pinterest_sharing_details.message
                )
                plan.tumblr_sharing_details.message = he.decode(
                  plan.tumblr_sharing_details.message
                )

                stateObject.setCommonSharingDetails(plan.common_sharing_details)
                stateObject.setFacebookSharingDetails(
                  plan.facebook_sharing_details
                )

                if (plan.carousel_options) {
                  stateObject.setCarouselOptions(plan.carousel_options)
                }

                stateObject.setTwitterSharingDetails(
                  plan.twitter_sharing_details
                )
                stateObject.$store.commit(
                  linkedinSharingTypes.SET_SHARING_DETAILS,
                  plan.linkedin_sharing_details
                )
                stateObject.setPinterestSharingDetails(
                  plan.pinterest_sharing_details
                )
                stateObject.setTumblrSharingDetails(plan.tumblr_sharing_details)

                if (plan.instagram_sharing_details) {
                  plan.instagram_sharing_details.message = he.decode(
                    plan.instagram_sharing_details.message
                  )
                  stateObject.$store.commit(
                    instagramTypes.SET_SHARING_DETAILS,
                    plan.instagram_sharing_details
                  )
                } else {
                  stateObject.$store.commit(
                    instagramTypes.SET_SHARING_DETAILS,
                    null
                  )
                }

                if (plan.youtube_sharing_details) {
                  plan.youtube_sharing_details.message = he.decode(
                    plan.youtube_sharing_details.message
                  )
                  stateObject.$store.commit(
                    youtubeTypes.SET_SHARING_DETAILS,
                    plan.youtube_sharing_details
                  )
                } else {
                  stateObject.$store.commit(
                    youtubeTypes.SET_SHARING_DETAILS,
                    null
                  )
                }

                if (plan.tiktok_sharing_details) {
                  stateObject.$store.commit(
                    tiktokTypes.SET_SHARING_DETAILS,
                    plan.tiktok_sharing_details
                  )
                } else {
                  stateObject.$store.commit(
                    tiktokTypes.SET_SHARING_DETAILS,
                    null
                  )
                }

                if (plan.gmb_sharing_details) {
                  plan.gmb_sharing_details.message = he.decode(
                    plan.gmb_sharing_details.message
                  )
                  stateObject.$store.commit(
                    gmb.SET_SHARING_DETAILS,
                    plan.gmb_sharing_details
                  )
                } else {
                  stateObject.$store.commit(gmb.SET_SHARING_DETAILS, null)
                }

                stateObject.$store.commit(
                  sharingTypes.SET_SOCIAL_SHARING_MEDIA_DETAILS,
                  plan.sharing_media_details ? plan.sharing_media_details : {}
                )
              }, 1000)

              const accountSelection = {
                facebook: [],
                twitter: [],
                pinterest: [],
                linkedin: [],
                tumblr: [],
                instagram: [],
                gmb: [],
                youtube: [],
                tiktok: [],
              }

              socialChannelsArray.forEach((item) => {
                if (
                  plan.account_selection[item.name] &&
                  plan.account_selection[item.name].length > 0
                ) {
                  const selectedAccounts = plan.account_selection[item.name]
                  const accountsGetter = this.$store.getters[item.getter]
                  selectedAccounts.forEach((acc) => {
                    if (Object.hasOwn(accountsGetter, 'items')) {
                      if (
                        accountsGetter.items.find(
                          (element) => element[item.key] === acc[item.key]
                        )
                      ) {
                        accountSelection[item.name].push(acc)
                      }
                    } else {
                      if (
                        accountsGetter.find(
                          (element) => element[item.key] === acc[item.key]
                        )
                      ) {
                        accountSelection[item.name].push(acc)
                      }
                    }
                  })
                }
              })
              console.debug('Social Account Selection: ', accountSelection)
              this.setAccountSelection(accountSelection)

              if (plan.facebook_options) {
                this.setFacebookOptions(plan.facebook_options)
              }

              if (plan.gmb_options) {
                this.$store.commit(social.SET_GMB_OPTIONS, plan.gmb_options)
              } else {
                this.$store.commit(social.SET_GMB_OPTIONS, null)
              }

              if (plan.youtube_options) {
                this.$store.commit(
                  social.SET_YOUTUBE_OPTIONS,
                  plan.youtube_options
                )
              } else {
                this.$store.commit(social.SET_YOUTUBE_OPTIONS, null)
              }
              if (plan.pinterest_options) {
                this.$store.commit(
                  social.SET_PINTEREST_OPTIONS,
                  plan.pinterest_options
                )
              } else {
                this.$store.commit(social.SET_PINTEREST_OPTIONS, null)
              }

              if (
                plan.twitter_options &&
                plan.twitter_options.threaded_tweets
              ) {
                plan.twitter_options.threaded_tweets.forEach((tweet) => {
                  tweet.progressBar = getDefaultSharingProgressBar()
                  tweet.upload_thread_tweets_sharing_video = false
                  tweet.uploadThreadedTweetsSharingImage = {
                    status: false,
                    position: -1,
                  }
                  // will be removed when thread tweet others feature is implemented
                  tweet.fetchThreadedTweetsShortLinks = false
                  tweet.cancelledLink = ''
                  tweet.image_suggestions = []
                  tweet.location = {
                    id: '',
                    name: '',
                  }
                })
                this.$store.commit(
                  social.SET_TWITTER_OPTIONS,
                  plan.twitter_options
                )
              } else {
                this.$store.commit(social.SET_TWITTER_OPTIONS, null)
              }

              this.setEditQueueStatus(plan.queued)
              this.setInitializeArticlePostCreationLoader(false)
              if (duplicate === true) {
                this.setEditQueueStatus(false)
                this.setPublishTimeSelection(null)
              }

              setTimeout(function () {
                stateObject.setSharingMessage(
                  'Common',
                  plan.common_sharing_details.message
                )
                stateObject.setSharingMessage(
                  'Facebook',
                  plan.facebook_sharing_details.message
                )

                stateObject.setSharingMessage(
                  'Twitter',
                  plan.twitter_sharing_details.message
                )

                stateObject.setSharingMessage(
                  'Pinterest',
                  plan.pinterest_sharing_details.message
                )
                stateObject.setSharingMessage(
                  'Tumblr',
                  plan.tumblr_sharing_details.message
                )

                if (
                  plan.linkedin_sharing_details &&
                  plan.linkedin_sharing_details.message
                ) {
                  stateObject.setSharingMessage(
                    'Linkedin',
                    plan.linkedin_sharing_details.message
                  )
                } else {
                  stateObject.setSharingMessage('Linkedin', '')
                }

                if (
                  plan.instagram_sharing_details &&
                  plan.instagram_sharing_details.message
                ) {
                  stateObject.setSharingMessage(
                    'Instagram',
                    plan.instagram_sharing_details.message
                  )
                } else {
                  stateObject.setSharingMessage('Instagram', '')
                }

                if (
                  plan.gmb_sharing_details &&
                  plan.gmb_sharing_details.message
                ) {
                  stateObject.setSharingMessage(
                    'Gmb',
                    plan.gmb_sharing_details.message
                  )
                } else {
                  stateObject.setSharingMessage('Gmb', '')
                }
                if (
                  plan.youtube_sharing_details &&
                  plan.youtube_sharing_details.message
                ) {
                  stateObject.setSharingMessage(
                    'Youtube',
                    plan.youtube_sharing_details.message
                  )
                } else {
                  stateObject.setSharingMessage('Youtube', '')
                }

                stateObject.allTextareaFocus()
                if (plan.common_sharing_details.url) {
                  stateObject.fetchComposerVariation(
                    plan.common_sharing_details.url
                  )
                  stateObject.fetchPostHashTags({
                    url: plan.common_sharing_details.url,
                  })
                }
              }, 1000)

              if (reopen) this.$store.dispatch('reopenSocialPost')

              setTimeout(function () {
                stateObject.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
              }, 200)
              if (this.$router.currentRoute.name === 'composer') {
                this.$router.push({
                  name: 'composerSocial',
                  params: { id: plan._id },
                })
              }
            }
          } else if (response.data.message) {
            this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
            this.alertMessage(response.data.message, 'error')
            if (
              this.$router.currentRoute.name === 'composerSocial' ||
              this.$router.currentRoute.name === 'composerBlog'
            ) {
              this.$router.push({ name: 'composer' })
            }
          } else {
            this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
            this.alertMessage(
              'Unable to fetch publication.Please try again.',
              'error'
            )
            if (
              this.$router.currentRoute.name === 'composerSocial' ||
              this.$router.currentRoute.name === 'composerBlog'
            ) {
              this.$router.push({ name: 'composer' })
            }
          }
        },
        (error) => {
          console.debug('Exception in editPublication ', error)
          this.$store.commit(planner.SET_EDIT_PLAN_LOADER, false)
          this.alertMessage(UNKNOWN_ERROR, 'error')
        }
      )
    },

    openMoveCampaignModel(folderId, planId) {
      console.log('Method:openMoveCampaignModel', folderId, planId)
      if (!folderId) folderId = ''
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_ID, folderId)
      this.$store.commit(composer.SET_MOVE_CAMPAIGN_PLAN_ID, planId)
      // eslint-disable-next-line no-undef
    },

    openSharePlanModel(planIds) {
      EventBus.$emit('share-plan::show', planIds)
    },

    resetComposerStates() {
      this.setPublishLabels(
        JSON.parse(JSON.stringify(this.getPublications.labels))
      )
      this.setPublishMembers(
        JSON.parse(JSON.stringify(this.getPublications.members))
      )
      EventBus.$emit('set_members_for_composer')
      this.search = this.getPublications.search

      if (this.getWorkspaces.activeWorkspace._id) {
        this.fetchPublicationFolders() // removing due to duplicate calling
        this.refetchPublications()
      }

      this.$store.dispatch('resetComposerCommonStates')
    },

    composerClose(previousRoute) {
      if (previousRoute !== null) {
        if (previousRoute.name) {
          this.$router.push({
            path: previousRoute.path,
            query: previousRoute.query,
          })
        } else this.$router.push({ name: 'planner_list_v2' })
      } else this.$router.push({ name: 'planner_list_v2' })
    },
  },
  computed: {
    ...mapGetters([
      'getWorkspaces',
      'getAssistantContentType',
      'getFilters',
      'getPocket',
      'getPocketFilter',
      'getBlogEditorSelector',
      'getComments',
      'getTasks',
      'getPublications',
      'getSecondaryBlogSelection',
      'getPublishSelection',
      'getReplug',
      'getFetchIntegrationsAccountsLoader',
      'getCarouselOptions',
    ]),
  },
}
export { composerMixin }
