<script setup>
import { plansComparison } from '@modules/billing/constants/plansComparison'
import FeatureValue from '@modules/billing/components/FeatureValue.vue'
import CstButton from '@ui/Button/CstButton.vue'
import { useStore } from '@state/base'
import pricingPlans, {
  planTypesWithoutSuffix,
} from '@modules/billing/constants/plansDetails'
import { usePaddle } from '@modules/billing/composables/usePaddle'
import proxy from '@common/lib/http-common'
import { billingBaseUrl } from '@src/config/api-utils'
import { inject, ref } from 'vue'
import usePlatform from '@common/composables/usePlatform'
import { useBilling } from '@modules/billing/composables/useBilling'

const props = defineProps({
  isAnnually: {
    type: Boolean,
    default: true,
  },
  changeTrialPlan: {
    type: Boolean,
    default: false,
  },
})

defineEmits(['toggle-yearly'])

// Initialize composables and services
const root = inject('root')
const { $bvModal } = root
const { getters } = useStore()
const { openCheckout } = usePaddle()
const { showToast } = usePlatform()
const { isCurrentPlan, socialAccounts, getPaddleCheckoutPayload } = useBilling()

// Local state
const loadingPlans = ref({})

// Get plan details
const getPlan = (planId) => {
  return pricingPlans.find((plan) => plan.id === planId)
}

// Methods
const getCTAText = (planId) => {
  if (isCurrentPlan(planId, props.isAnnually, props.changeTrialPlan))
    return 'Current Plan'
  if (props.changeTrialPlan) return 'Choose Plan'
  return 'Upgrade Now'
}

const handlePaddleCheckout = (planId) => {
  const plan = getPlan(planId)
  if (!plan) return

  const payload = getPaddleCheckoutPayload(
    plan,
    props.isAnnually,
    socialAccounts.value
  )
  openCheckout(payload)
}

const handleTrialPlanChange = async (planId) => {
  loadingPlans.value[planId] = true

  try {
    const payload = {
      name: `trial-${planId}`,
      workspace_id: getters?.getActiveWorkspace?._id,
    }

    const { data } = await proxy.post(`${billingBaseUrl}trialChange`, payload)

    if (data?.status) {
      showToast(data.message, 'success')
      setTimeout(() => window.location.reload(), 3000)
    }
  } catch (error) {
    console.error('Trial plan change error:', error)
    showToast('Failed to change trial plan', 'error')
  } finally {
    loadingPlans.value[planId] = false
    $bvModal.hide('subscription-plans-modal')
  }
}

const handleCTAClick = (planId) => {
  if (isCurrentPlan(planId, props.isAnnually, props.changeTrialPlan)) return

  if (props.changeTrialPlan) {
    handleTrialPlanChange(planId)
  } else {
    handlePaddleCheckout(planId)
  }
}

const isPlanLoading = (planId) => !!loadingPlans.value[planId]
</script>

<template>
  <div class="max-w-6xl mx-auto overflow-x-auto">
    <!-- Main Table -->
    <div class="bg-white rounded-lg shadow-sm min-w-[800px] relative">
      <!-- Header Row -->
      <div class="sticky top-0 bg-white z-20">
        <div v-if="!changeTrialPlan" class="grid grid-cols-5 mb-8 gap-[2rem]">
          <div class="col-span-2 font-semibold">
            <span class="text-xl">Features</span>
            <!-- Monthly/Yearly Toggle -->
            <div class="flex items-center rounded-md bg-gray-100 p-1 w-max">
              <button
                :class="
                  isAnnually ? 'bg-transparent' : 'bg-white font-semibold'
                "
                class="rounded-md px-5 py-2.5 text-center transition !border-0"
                @click="$emit('toggle-yearly', false)"
              >
                Monthly
              </button>
              <button
                :class="
                  isAnnually ? 'bg-white font-semibold' : 'bg-transparent'
                "
                class="rounded-md px-4 py-2 text-center transition !border-0"
                @click="$emit('toggle-yearly', true)"
              >
                <span class="flex items-center justify-center">
                  Yearly
                  <span
                    class="ml-2 bg-blue-50 text-blue-500 uppercase font-bold text-xs px-3 py-1 rounded-full whitespace-nowrap"
                  >
                    Save up to 34%
                  </span>
                </span>
              </button>
            </div>
          </div>
          <div class="col-span-3 grid grid-cols-3 gap-4">
            <div class="text-center">
              <h3 class="text-lg font-semibold mb-2">Standard</h3>
              <div class="text-2xl font-bold">
                ${{ isAnnually ? '19' : '29'
                }}<span class="text-sm font-normal">/month</span>
              </div>
            </div>
            <div class="text-center">
              <h3 class="text-lg font-semibold mb-2">Advanced</h3>
              <div class="text-2xl font-bold">
                ${{ isAnnually ? '49' : '69'
                }}<span class="text-sm font-normal">/month</span>
              </div>
            </div>
            <div class="text-center">
              <h3 class="text-lg font-semibold mb-2">Agency Unlimited</h3>
              <div class="text-2xl font-bold">
                ${{ isAnnually ? '99' : '139'
                }}<span class="text-sm font-normal">/month</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Categories and Features -->
      <div
        v-for="category in plansComparison.categories"
        :key="category.name"
        class="mb-8"
      >
        <h3
          class="text-lg font-semibold mb-4 border !border-t-0 !border-l-0 !border-r-0 !border-b !border-[#202324] py-2"
        >
          {{ category.name }}
        </h3>

        <div
          v-for="feature in category.features"
          :key="feature.name"
          class="grid grid-cols-5 py-3 border !border-t-0 !border-l-0 !border-r-0 !border-b !border-[#D1D5DB] gap-[2rem]"
        >
          <FeatureValue :feature="feature" :is-annually="isAnnually" />
        </div>
      </div>

      <!-- Buttons Row -->
      <div class="grid grid-cols-5 py-6 gap-[2rem] border-t border-[#D1D5DB]">
        <div class="col-span-2"></div>
        <div class="col-span-3 grid grid-cols-3 gap-4">
          <div
            v-for="plan in planTypesWithoutSuffix"
            :key="plan"
            class="text-center"
          >
            <CstButton
              :disabled="
                isCurrentPlan(plan, isAnnually) ||
                Object.values(loadingPlans)?.some(Boolean)
              "
              :loading="isPlanLoading(plan)"
              :text="getCTAText(plan)"
              :variant="plan === 'agency-unlimited' ? 'primary' : 'outlined'"
              class="w-full"
              @click="handleCTAClick(plan)"
            />
            <span
              v-if="!isCurrentPlan(plan, isAnnually)"
              class="pt-2 block text-sm"
              >No credit card required</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
