<template>
  <b-tabs
    v-model="tab"
    pills
    vertical
    lazy
    card
    class="px-0 flex-grow-1 flex-wrap-initial"
    :nav-class="
      type === 'library'
        ? 'modal-tab-side-nav modal-tab-side-nav--remove-breaker'
        : 'modal-tab-side-nav'
    "
    @activate-tab="tabActivated"
  >
    <template v-slot:tabs-start>
      <h5 class="modal-title">Add Media</h5>
    </template>

    <b-tab>
      <template v-slot:title>
        <i class="far fa-cloud-upload mr-3"></i> Uploads
      </template>

      <slot name="upload"></slot>
    </b-tab>

    <b-tab v-if="type !== 'library'">
      <template v-slot:title>
        <i class="far fa-layer-group mr-3"></i> Media Library
      </template>

      <slot name="library"></slot>
    </b-tab>

    <!--    <b-tab  v-if="type === 'library'" >-->
    <!--      <template #title>-->
    <!--        <i class="far fa-layer-group mr-3"></i> Recent Uploads-->
    <!--      </template>-->

    <!--      <slot name="recent"></slot>-->
    <!--    </b-tab>-->

    <b-tab>
      <template v-slot:title>
        <i class="far fa-link mr-3"></i> Direct Link
      </template>

      <slot name="direct"></slot>
    </b-tab>

    <b-tab>
      <template v-slot:title>
        <i class="fab fa-pinterest-p mr-3"></i> Pinterest
      </template>

      <slot name="pinterest"></slot>
    </b-tab>

    <b-tab>
      <template v-slot:title>
        <i class="fab fa-facebook-f mr-3"></i> Facebook
      </template>

      <slot name="facebook"></slot>
    </b-tab>

    <b-tab>
      <template v-slot:title>
        <i class="fab fa-flickr mr-3"></i> Flickr
      </template>

      <slot name="flickr"></slot>
    </b-tab>

    <b-tab>
      <template v-slot:title>
        <i class="fad fa-link mr-3"></i> Pixabay
      </template>

      <slot name="pixabay"></slot>
    </b-tab>

    <b-tab>
      <template v-slot:title>
        <i class="fad fa-sticky-note mr-3"></i> Giphy
      </template>

      <slot name="giphy"></slot>
    </b-tab>

    <b-tab>
      <template v-slot:title>
        <i class="fab fa-dropbox mr-3"></i> Dropbox
      </template>

      <slot name="dropbox"></slot>
    </b-tab>

    <b-tab>
      <template v-slot:title>
        <i class="fab fa-google-drive mr-3"></i> Google
      </template>

      <slot name="google-drive"></slot>
    </b-tab>
  </b-tabs>
</template>

<script>
export default {
  name: 'SideTabs',
  props: {
    tabIndex: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'library',
    },
  },
  data() {
    return {
      tab: this.tabIndex,
    }
  },
  watch: {
    tab(val) {
      if (val !== this.tabIndex) {
        this.$emit('changeTab', val)
      }
    },
    tabIndex(val) {
      this.tab = val
    },
  },
  mounted() {
    //fix the issue of tabIndex not being set
    // if (this.type !== 'library') {
    //   this.tab = 1
    // }
  },
  methods: {
    tabActivated(newTabIndex, oldTabIndex, $event) {
      this.$emit('uploadingChangeTab', newTabIndex, oldTabIndex, $event)
    },
  },
}
</script>
