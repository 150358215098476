import { mapGetters } from 'vuex'

export const billingMixin = {
  computed: {
    ...mapGetters(['getPlan', 'getActiveWorkspaceAddonsLifetime']),
  },
  methods: {
    shouldLockBasedOnSubAddons(type) {
      const subscription = this.getPlan.subscription
      const features = subscription.features || {}

      return !features[type] || false
    },
    getLockBasedOnSubAddonsMessage(type) {
      const message =
        'Your subscription plan ' +
        this.getPlan.subscription.display_name +
        ' does not include '
      const modType = type.replace('_', ' ').toLowerCase()

      return message + modType + ' please update your plan.'
    },
    checkIfStarterSubPlan() {
      const subscriptionId = this.getPlan.subscription._id
      return (
        subscriptionId === '62a0310c2d363a60b1e5ff75' || // starter trial
        subscriptionId === '629f4952945c0941d97530a5' || // starter monthly
        subscriptionId === '629f4964945c0941d97530ac'
      ) // starter annual
    },

    triggerGTMEvent(data, subscriptionType) {
        gtag('event', 'paid_account_upgrade', {
          'send_to': process.env.VUE_APP_GTAG_SEND_TO,
          'email': this.$store.getters?.getProfile?.email,
          'mrr': parseInt(data?.checkout?.prices?.customer?.total || 0),
          'currency': data?.checkout?.prices?.customer?.currency || 'USD',
          'transaction_id': data?.checkout?.id || '',
          subscriptionType
        });
    }
  },
}
