<template>
  <div class="billing-upgrade-page">
    <div class="billing-close-button">
      <b-button
        variant="studio-theme-transparent-grey"
        class="mr-4 mt-3"
        @click="logout"
      >
        Logout
      </b-button>
    </div>
    <div class="billing-main-heading">
      <h2>Hey {{ $store.getters.getProfile.full_name }}!</h2>
      <p
        >Your trial with {{appData.businessName}} has ended. <br />You will need to
        upgrade your plan in order to continue using {{appData.businessName}} account.</p
      >
    </div>
    <div class="billing-plans-section">
      <UpgradePlanComponent />
    </div>
    <div class="billing-higher-plan"> </div>
  </div>
  <!--<div class="email_verification d-flex align-items-center"
         :class="{'only_transparent': getTrialExpired, 'only_transparent': getSubscriptionExpired}">
    </div>-->
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import UpgradePlanComponent from '@src/modules/setting/components/billing/dialogs/UpgradePlanComponent'
import { accountTypes } from '@/src/modules/account/store/mutation-types'
import { commonMethods } from '@/src/modules/common/store/common-methods'
import useWhiteLabelApplication from "@modules/setting/composables/whitelabel/useWhiteLabelApplication";


export default {
  name: 'TrialExpired.vue',
  components: {
    UpgradePlanComponent
  },
  setup(){
    const {appData} = useWhiteLabelApplication()

    return {
      appData
    }
  },
  computed: {
    ...mapGetters(['getTrialExpired', 'getSubscriptionExpired'])
  },
  created () {
    this.Plan()
    this.fetchWorkspaces()
  },
  mounted () {
    commonMethods.toggleAiChat(true)

    if (this.$route.name === 'trial_expired') {
      this[accountTypes.SET_TRIAL_EXPIRED](true)
    }
    if (this.$route.name === 'subscription_expired') {
      this[accountTypes.SET_SUBSCRIPTION_EXPIRED](true)
    }
  },
  unmounted () {
    commonMethods.toggleAiChat()
  },
  methods: {
    ...mapMutations([
      accountTypes.SET_TRIAL_EXPIRED,
      accountTypes.SET_SUBSCRIPTION_EXPIRED
    ])
  },
}
</script>
