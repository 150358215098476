import { computed, ref } from 'vue'
import proxy from '@common/lib/http-common'
import moment from 'moment'
import { useStore } from '@state/base'
import useAnalytics from '@src/modules/analytics/components/common/composables/useAnalytics'
import useNumber from '@common/composables/useNumber'
import useAnalyticsUtils from '@/src/modules/analytics/components/common/composables/useAnalyticsUtils'
import { useComposerHelper } from '@/src/modules/composer_v2/composables/useComposerHelper';
import useString from '@/src/modules/common/composables/useString'

const { stringToHexColor } = useString()

const { channelImage,getSocialImageRounded } = useComposerHelper()

const { getAccountId, getAccountById } = useAnalyticsUtils()
const { formatNumber, roundNumber } = useNumber()
const {
  ANALYTICS_DELIMITER,
} = useAnalytics()
// constants
const IGNORE_PLATFORMS = ['youtube'];

const selectedAccount = ref(null)
const isAllAccountsSelected = ref(false)

const isLoadingStates = ref({
  getSummary: false,
  getTopPerformingGraph: false,
  getTopPerformingPlatform: false,
})

const routes = {
  SUMMARY: 'getSummary',
  TOP_PERFORMING_GRAPH : 'getTopPerformingGraph',
  GET_PLATFORM_DATA :'getPlatformData',
}
const PLATFORM_TITLE_AND_COLOR = {
  facebook: {
    title: 'Facebook',
    color: '#3b5998',
  },
  instagram: {
    title: 'Instagram',
    color: '#e4405f',
  },
  linkedin: {
    title: 'LinkedIn',
    color: '#0077b5',
  },
  tiktok: {
    title: 'TikTok',
    color: '#000000',
  },
  youtube: {
    title: 'YouTube',
    color: '#ff0000',
  },
  pinterest: {
    title: 'Pinterest',
    color: '#bd081c',
  },
}

// state
const { DEFAULT_DATE_RANGE } = useAnalytics()
const dateRange = ref(DEFAULT_DATE_RANGE)

const cardsData = ref({})
const topPerformingGraphData = ref({})
const platformData = ref({
  grouped: [],
  individual: []
})

const performanceRadio = [
  {
    label: 'Grouped',
    chartType: 'grouped',
  },
  { label: 'Individual', chartType: 'individual' },
]

const selectedPerformanceType = ref(
  performanceRadio.find((i) => i.chartType ===  performanceRadio[0]?.chartType)
)

const comparisonType = [
  {
    label: 'Followers VS Engagement Rate',
    chartType: 'followers_vs_engagement_rate',
    xAxisKey: 'followers',
    yAxisKey: 'total_engagement',

  },
  {
    label: 'Engagement Rate VS Posts',
    chartType: 'engagement_rate_vs_posts',
    xAxisKey: 'total_engagement',
    yAxisKey: 'total_posts',

  },
]

const selectedComparisonType = ref(
  comparisonType.find((i) => i.chartType ===  comparisonType[0]?.chartType)
)

const cards = ref([
  {
    title: 'Followers',
    key: 'followers',
    tooltip: `The total number of posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts.`,
  },
  {
    title: 'Total Posts',
    key: 'total_posts',
    tooltip: `The total number of impressions received by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Impressions are based on the lifetime performance of the posts.`,
  },
  {
    title: 'Total Engagements',
    key: 'total_engagement',
    tooltip:
      'The total number of engagement actions generated by the posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts. Engagement includes actions such as likes, comments, shares, and other interactions with the posts, based on their lifetime performance.',
  },
  {
    title: 'Total Impressions',
    key: 'total_impressions',
    tooltip:
      'The percentage of user interactions (such as likes, comments, shares etc.) relative to the number of times the posts associated with the selected campaign and label were seen by users (impressions), across the selected social accounts.',
  },
])

const getFormattedDateRange = () => {
  return dateRange.value
    .map((date) => moment(date).format('YYYY-MM-DD'))
    .join(' - ')
}

const getCardData = (card) => {
  const defaultValue = {
    title: card.title,
    total: 0,
    growth: 0,
    difference: 0,
    originalValue: 0,
    tooltip: 'Default tooltip',
  }

  if (!cardsData.value || !Object.keys(cardsData.value)?.length) {
    return defaultValue
  }

  const { current, percentage, difference } = cardsData.value

  return {
    title: card.title,
    total: formatNumber(current?.[card.key]) || defaultValue.total,
    growth: percentage?.[card.key] || defaultValue.growth,
    difference: difference?.[card.key] || defaultValue.difference,
    originalValue: current?.[card.key] || defaultValue.originalValue,
    tooltip: card.tooltip,
  }
}

/**
 * @description as the header data is created by the keys of tableData, this method returns proper names on basis of the key names.
 * @param value
 * @returns {string}
 */
const getHeaderTitles = (value) => {
  const titleMappings = {
    totalEngagement: 'Total Engagements',
    rateOfDistribution: 'Distribution Rate',
    default: 'Analytics'
  };

  return titleMappings[value] || titleMappings.default;
}
const getComponentInfoTooltip = (name = '', platform = '', postType = '') => {
  switch (name) {
    case 'breakdown':
      return `Provides insights into how posts are distributed across campaigns and labels, ensuring comprehensive coverage across all categories and areas of focus.`
    case 'impressions':
      return `Evaluate the impressions received on posts categorized by their associated campaigns and labels.`
    case 'engagements':
      return `Evaluate the engagements received on posts categorized by their associated campaigns and labels.`
    default:
      return ``
  }
}

const getDetailedPulseInfoTooltip = (name = '') => {
  switch (name) {
    case 'posts':
      return `Provides insights into how posts are distributed across campaigns and labels, ensuring comprehensive coverage across all categories and areas of focus.`
    case 'impressions':
      return `Evaluate the impressions received on posts categorized by their associated campaigns and labels.`
    case 'engagements':
      return `Evaluate the engagements received on posts categorized by their associated campaigns and labels.`
    default:
      return ``
  }
}

const getPulseTooltipTitle = (name = '') => {
  switch (name) {
    case 'posts':
      return `Posts Published`
    case 'impressions':
      return `Impressions`
    case 'engagements':
      return `Engagements`
    default:
      return name
  }
}

const getDetailedPulseChartTitle = (name = '') => {
  switch (name) {
    case 'totalEngagement':
      return `Posting pattern over time`
    case 'rateOfDistribution':
      return `Impressions trend over time`
    default:
      return ``
  }
}

const getTableHeaderTooltips = (value) => {
  switch (value) {
    case 'totalEngagement':
      return `The total number of posts published during the selected time period, associated with the chosen campaign and label, across the selected social accounts.`
    case 'rateOfDistribution':
      return `The percentage change in the number of Posts Published compared to the previous time period.`
    default:
      return ``
  }
}

const canFetchMedia = computed(() => {
  return !!(
    selectedAccount.value !== undefined &&
    selectedAccount.value !== null &&
    dateRange.value.length > 0
  )
})

const getTopPerformingPlatform = computed(() => {
  const currentData = platformData.value?.grouped || []
  if (!currentData.length) return currentData;

  const totalEngagement = currentData.reduce((acc, item) => acc + item?.total_engagement, 0)

  return currentData.map((item) => ({
    name: item?.platform_type || '',
    totalEngagement : item?.total_engagement || 0,
    rateOfDistribution : roundNumber((item?.total_engagement / totalEngagement) * 100) || 0,
   }))
})

const getAccountStatistics = computed(() => {
  if(!platformData.value?.individual.length ){
    return []
  }

  console.log(platformData.value?.individual)
  return platformData.value?.individual.map((item) => {
    const account = getAccountById(item?.account_id)
    return {
      name: account?.platform_name || account?.name || account?.username || item?.platform_type,
      image: channelImage(account, item?.platform_type),
      platformImage: getSocialImageRounded(item?.platform_type),
      ...item
    }
  })
})
const getGroupedGraphData = computed(() => {

  const data = {}
  const currentData = platformData.value?.grouped || []
  if (!currentData.length) {
    comparisonType?.forEach((type) => {
      data[type.chartType] = {
        legends: [],
        data: [],
      };
    });
    return data;
  }

  comparisonType?.forEach((type) => {

    data[type.chartType] = {
      legends : currentData.map((item) => PLATFORM_TITLE_AND_COLOR[item?.platform_type]?.title || ''),
      data : currentData.map((item) => ({
        "image": getSocialImageRounded(item?.platform_type || ''),
        "name": PLATFORM_TITLE_AND_COLOR[item?.platform_type]?.title,
        "xAxis": item?.[type.xAxisKey] || 0,
        "yAxis": item?.[type.yAxisKey] || 0,
        "color": PLATFORM_TITLE_AND_COLOR[item?.platform_type]?.color,
     }))
    }

  })
  return data
})

const getIndividualGraphData = computed(() => {
  const data = {}
  const currentData = platformData.value?.individual || []
  if (!currentData.length) {
    comparisonType?.forEach((type) => {
      data[type.chartType] = {
        legends: [],
        data: [],
      };
    });
    return data;
  }
  comparisonType?.forEach((type) => {
    data[type.chartType] = {
      legends : currentData.map((item) => {
        const account = getAccountById(item?.account_id)
        return account?.platform_name || account?.name || account?.username || item?.platform_type
      }),
      data : currentData.map((item, index) => {
        const account = getAccountById(item?.account_id)
        const name = account?.platform_name || account?.name || account?.username || item?.platform_type
        const image = channelImage(account, item?.platform_type)
        return {
          image,
          name,
          "xAxis": item?.[type.xAxisKey] || 0,
          "yAxis": item?.[type.yAxisKey] || 0,
          "color": stringToHexColor(name),
        }
    })
    }
  })
  return data
})

const getSelectedPerformanceData = computed(() => {
  if(selectedPerformanceType.value.chartType === 'grouped'){
    return getGroupedGraphData.value
  }
  else if(selectedPerformanceType.value.chartType === 'individual'){
    return getIndividualGraphData.value
  }
  return {}
})

const setIsloading = (type, value) => {
  isLoadingStates.value[type] = value
}

export default function useLabelAndCampaignAnalytics(defaultLabel = 'Data') {
  const { getters } = useStore()

  const {
    dataZoomOptions,
    barChartOptions,
    analyticsDesignSystem,
    BASE_ENDPOINT,
    lineChartOptions,
    multipleSeriesBarChartOptions,
    legendOptions,
    multipleSeriesLineChartOptions,
    defaultChartOptions,
    isReportView,
    screenWidth,
    isNoAnalyticsData,
    axisLabelFormatter,
    tooltipFormatterScroll,
  } = useAnalytics(defaultLabel)

  const fetchMedia = async (type, extraPayload = {}, append = false) => {
    if (canFetchMedia.value) {
      try {
        setIsloading(type, true)
        const payload = {
          workspace_id: getters.getActiveWorkspace._id,
          date: getFormattedDateRange(),
          timezone: getters.getActiveWorkspace.timezone,
          ...extraPayload,
        }

          Object.assign(payload, {
            facebook_accounts: [],
            instagram_accounts: [],
            linkedin_accounts: [],
            tiktok_accounts: [],
            youtube_accounts: [],
            pinterest_accounts: [],
            twitter_accounts: [],
            gmb_accounts: [],
            tumblr_accounts: [],
          })

          selectedAccount.value?.forEach((account) => {
            payload[`${account.account_type}_accounts`].push(
              getAccountId(account)
            )
          })

          const { data } = await proxy.post(
            BASE_ENDPOINT + type,
            payload
          )

        // let leastPosts
        // let topPosts
        switch (type) {
          case routes.SUMMARY:
            cardsData.value = data
            break
          case routes.TOP_PERFORMING_GRAPH:
            topPerformingGraphData.value = data
            break
          case routes.GET_PLATFORM_DATA:
            if (extraPayload?.type === 'grouped'){
              platformData.value.grouped = data
            }
            else if (extraPayload?.type === 'individual'){
              platformData.value.individual = data
            }
            break
          default:
            break
        }
      } catch (e) {
        console.error('FETCH DATA:::', e)
      } finally {
        setIsloading(type, false)
      }
    }
  }

  const comparisonGraphTooltipFormatter = (params) => {
    const { data } = params
    const [name, engagementByFollowers, followers, image] = data

    return `
      <div class="flex flex-col">
        <div class="flex items-center">
          <img src="${image}" class="h-10 w-10 mr-3 rounded-full overflow-hidden object-cover"  alt=""
              onerror="this.onerror=null;this.src='https://storage.googleapis.com/lumotive-web-storage/default/profile_default.svg'"
          />
          <span class="text-[#000D21] text-base font-weight-500">${name}</span>
        </div>
        <div class="flex justify-between items-center mt-4">
          <div class="flex items-end">
            <span class="text-[979CA0] text-sm mr-1.5">Followers</span>
            <span class="text-[#69788E] text-sm font-bold">
                ${followers.toLocaleString('en-US', {
                  notation: 'compact',
                  compactDisplay: 'short',
                })}
            </span>
          </div>

        </div>
          <div class="flex justify-between items-center mt-2">
          <div class="flex items-end">
            <span class="text-[979CA0] text-sm mr-1.5">Engagement Rate</span>
            <span class="text-[#69788E] text-sm font-bold">${engagementByFollowers}</span>
          </div>
        </div>
      </div>
    `
  }

  return {
    ANALYTICS_DELIMITER,
    IGNORE_PLATFORMS,
    PLATFORM_TITLE_AND_COLOR,
    analyticsDesignSystem,
    // variables
    routes,
    legendOptions,
    dataZoomOptions,
    defaultChartOptions,

    // state
    selectedAccount,
    isAllAccountsSelected,
    cards,
    dateRange,
    barChartOptions,
    lineChartOptions,
    multipleSeriesLineChartOptions,
    multipleSeriesBarChartOptions,
    isReportView,
    screenWidth,
    isLoadingStates,
    getTopPerformingPlatform,
    getGroupedGraphData,
    getIndividualGraphData,
    selectedPerformanceType,
    performanceRadio,
    comparisonType,
    selectedComparisonType,
    getSelectedPerformanceData,
    getAccountStatistics,

    cardsData,
    topPerformingGraphData,
    platformData,
    // methods
    fetchMedia,
    getCardData,
    isNoAnalyticsData,
    axisLabelFormatter,
    getHeaderTitles,
    getComponentInfoTooltip,
    getTableHeaderTooltips,
    tooltipFormatterScroll,
    setIsloading,
    getDetailedPulseInfoTooltip,
    getDetailedPulseChartTitle,
    getPulseTooltipTitle,
    comparisonGraphTooltipFormatter,
  }
}
