<template>
  <div class="linkedin-preview-container">
    <clip-loader
      v-if="isLoading"
      class="spinner"
      :color="'#e0dfdf'"
      :size="'48px'"
    ></clip-loader>
    <iframe
      v-show="!isLoading"
      :src="modifiedPostLink"
      height="300"
      width="504"
      frameborder="0"
      allowfullscreen=""
      title="Embedded post"
      class="h-full"
      @load="onIframeLoad"
    ></iframe>
  </div>
</template>

<script setup>
import { defineProps, ref, computed } from 'vue'

const props = defineProps({
  postLink: {
    type: String,
    required: true,
  },
})

const isLoading = ref(true)

const modifiedPostLink = computed(() => {
  const url = new URL(props.postLink)
  const pathParts = url.pathname.split('/')
  pathParts.splice(1, 0, 'embed')
  url.pathname = pathParts.join('/')
  return url.toString()
})

const onIframeLoad = () => {
  isLoading.value = false
}
</script>

<style scoped>
.linkedin-preview-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 525px;
}
</style>
