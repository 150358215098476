<template>
  <div
    v-if="$route.name === 'analytics_pdf_report'"
    class="analytic_component_main analytic_component_pdf"
  >
    <div class="component_inner">
      <router-view></router-view>
    </div>
  </div>
  <div v-else class="analytic_component_main">
    <AnalyticPreview></AnalyticPreview>
    <SendReportByEmailModal></SendReportByEmailModal>
    <ScheduleReportModal></ScheduleReportModal>
    <template v-if="showLoader">
      <div class="pt-3">
        <beat-loader :color="'#436aff'"></beat-loader>
      </div>
    </template>
    <template v-else>
      <div class="component_inner analytics-wrapper">
        <div class="component_left analytic_sidebar">
          <ul
            :class="{
              'disable_click reduce_opacity':
                getAnalyticsServiceStatus || getAnalyzeServiceStatus === true,
            }"
            class="sidebar_inner"
          >
            <li class="heading">
              <span>Social Analytics</span>
            </li>

            <router-link :to="{ name: 'group_analytics' }" class="item">
              <i class="icon-groups-cs"></i>
              <span>Overview</span>
            </router-link>
            <template v-if="isAnalyticsEnabledForUser">
              <router-link
                :to="facebookAccountId"
                class="item"
                :class="{ active: $route.name === 'facebook_analytics' }"
              >
                <i class="icon-facebook_icon"></i>
                <span>Facebook</span></router-link
              >
            </template>
            <template v-else>
              <a
                href="javascript:;"
                class="item"
                @click.prevent="$bvModal.show('update-feature-plan')"
              >
                <i class="icon-facebook_icon"></i>
                <span class="mr-2">Facebook</span>
                <i class="fas fa-lock ml-4" style="font-size: 0.875rem"></i>
              </a>
            </template>

              <template v-if="isAnalyticsEnabledForUser">
                <router-link
                  :to="instagramAccountId"
                  class="item"
                  :class="{ active: $route.name === 'instagram_analytics' }"
                >
                  <i class="icon-instagram_icon"></i>
                  <span>Instagram</span>
                </router-link>
              </template>
              <template v-else>
                <a
                  href="javascript:;"
                  class="item"
                  @click.prevent="$bvModal.show('update-feature-plan')"
                >
                  <i class="icon-instagram_icon"></i>
                  <span class="mr-2">Instagram</span>
                  <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i>
                </a>
              </template>

            <template v-if="isAnalyticsEnabledForUser">
              <router-link
                :to="twitterAccountId"
                class="item"
                :class="{ active: $route.name === 'twitter_analytics' }"
              >
                <i class="icon-twitter_icon"></i>
                <span>X (Twitter)</span>
              </router-link>
            </template>
            <template v-else>
              <a
                href="javascript:;"
                class="item"
                @click.prevent="$bvModal.show('update-feature-plan')"
              >
                <i class="icon-twitter_icon"></i>
                <span class="mr-2">X (Twitter)</span>
                <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i>
              </a>
            </template>

            <template v-if="isAnalyticsEnabledForUser">
              <router-link
                :to="linkedinAccountId"
                class="item"
                :class="{ active: $route.name === 'linkedin_analytics' }"
              >
                <i class="icon-linkedin_icon"></i>
                <span>Linkedin</span>
              </router-link>
            </template>
            <template v-else>
              <a
                href="javascript:;"
                class="item"
                @click.prevent="$bvModal.show('update-feature-plan')"
              >
                <i class="icon-linkedin_icon"></i>
                <span class="mr-2">Linkedin</span>
                <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i>
              </a>
            </template>

            <template
              v-if="
                getProfile.email.toLowerCase().includes('d4interactive.io') ||
                getProfile.email.toLowerCase().includes('contentstudio.io')
              "
            >
              <template v-if="isAnalyticsEnabledForUser">
                <router-link
                  :to="pinterestAccountId"
                  class="item"
                  :class="{ active: $route.name === 'pinterest_analytics' }"
                >
                  <i class="icon-pinterest_icon"></i>
                  <span>Pinterest</span>
                </router-link>
              </template>
              <template v-else>
                <a
                  href="javascript:;"
                  class="item"
                  @click.prevent="$bvModal.show('update-feature-plan')"
                >
                  <i class="icon-pinterest_icon"></i>
                  <span class="mr-2">Pinterest</span>
                  <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i>
                </a>
              </template>
            </template>
            <li class="heading">
              <span>Competitor Analytics</span>
            </li>
            <template v-if="isAnalyticsEnabledForUser">
              <router-link
                :to="{ name: 'facebookCompetitor' }"
                class="item"
                :class="{ active: $route.name === 'facebookCompetitor' }"
              >
                <i class="icon-facebook_icon"></i>
                <span>Facebook</span>
              </router-link>
            </template>
            <template v-else>
              <a
                href="javascript:;"
                class="item"
                @click.prevent="$bvModal.show('update-feature-plan')"
              >
                <i class="icon-facebook_icon"></i>
                <span class="mr-2">Facebook</span>
                <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i>
              </a>
            </template>
            <template
              v-if="
                getProfile.email.toLowerCase().includes('d4interactive.io') ||
                getProfile.email.toLowerCase().includes('contentstudio.io')
              "
            >
              <template v-if="isAnalyticsEnabledForUser">
                <router-link
                  :to="{ name: 'instagramCompetitor' }"
                  class="item"
                  :class="{ active: $route.name === 'instagramCompetitor' }"
                >
                  <i class="icon-instagram_icon"></i>
                  <span
                    >Instagram<span
                      class="nav-beta new-feature-available new-feature-available--sidebar"
                    >
                      NEW</span
                    ></span
                  >
                </router-link>
              </template>
              <template v-else>
                <a
                  href="javascript:;"
                  class="item"
                  @click.prevent="$bvModal.show('update-feature-plan')"
                >
                  <i class="icon-instagram_icon"></i>
                  <span class="mr-2">Instagram</span>
                  <i class="fas fa-lock ml-2" style="font-size: 0.875rem"></i>
                </a>
              </template>
            </template>
            <li class="heading">
              <span>Manage Reports</span>
            </li>

            <router-link :to="{ name: 'my_report' }" class="item">
              <i class="icon-reports_icon"></i>
              <span>Scheduled Reports</span>
            </router-link>
            <router-link :to="{ name: 'download_reports' }" class="item">
              <i class="icon-dropdown-cs"></i>
              <span>Download Reports</span>
            </router-link>
            <template v-if="isAnalyticsEnabledForUser">
              <router-link :to="{ name: 'reports_setting' }" class="item">
                <i class="icon-settings-cs"></i>
                <span>Reports Settings</span>
              </router-link>
            </template>
          </ul>
        </div>
        <div class="component_right analytics-content">
          <router-view></router-view>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import AnalyticPreview from '@src/modules/analytics/components/common/AnalyticPreview'
import { mapActions, mapGetters } from 'vuex'
import SendReportByEmailModal from './reports/modals/SendReportByEmailModal'
import ScheduleReportModal from './reports/modals/ScheduleReportModal'

export default {
  components: {
    AnalyticPreview,
    SendReportByEmailModal,
    ScheduleReportModal,
  },
  data() {
    return {
      showLoader: false,
    }
  },
  computed: {
    ...mapGetters([
      'getProfile',
      'getTwitterAccounts',
      'getFacebookAccounts',
      'getFacebookPagesList',
      'getInstagramAccounts',
      'getLinkedinCompanyPages',
      'getPinterestBoards',
      'getAnalyticsServiceStatus',
      'isAnalyticsPlanAllowed',
      'getActiveWorkspaceAddonsLifetime',
      'getWorkspaces',
      'getFetchSocialStatus',
      'getAnalyzeServiceStatus',
    ]),
    isAnalyticsEnabledForUser() {
      if (this.isAnalyticsPlanAllowed) {
        return true
      }
      if (this.getActiveWorkspaceAddonsLifetime.analytics) {
        return true
      }
      return false
    },
    twitterAccountId() {
      let payload = { name: 'twitter_analytics' }
      if (this.getTwitterAccounts.items.length > 0) {
        console.debug('accountId', this.getTwitterAccounts.items[0].twitter_id)
        payload = {
          name: 'twitter_analytics',
          params: { accountId: this.getTwitterAccounts.items[0].twitter_id },
        }
      }
      return payload
    },
    instagramAccountId() {
      let payload = { name: 'instagram_analytics' }
      if (this.getInstagramAccounts.items.length > 0) {
        payload = {
          name: 'instagram_analytics',
          params: {
            accountId: this.getInstagramAccounts.items[0].instagram_id,
          },
        }
      }
      return payload
    },
    facebookAccountId() {
      let payload = { name: 'facebook_analytics' }
      if (this.getFacebookPagesList.length > 0) {
        const accountId = this.getFacebookPagesList[0].facebook_id
        console.log(accountId)
        payload = {
          name: 'facebook_analytics',
          params: { accountId },
        }
      }
      return payload
    },
    linkedinAccountId() {
      let payload = { name: 'linkedin_analytics' }
      if (this.getLinkedinCompanyPages.length > 0) {
        payload = {
          name: 'linkedin_analytics',
          params: { accountId: this.getLinkedinCompanyPages[0].linkedin_id },
        }
      }
      return payload
    },
    pinterestAccountId() {
      let payload = { name: 'pinterest_analytics' }
      console.log(this.getPinterestBoards)
      if (this.getPinterestBoards.length > 0) {
        payload = {
          name: 'pinterest_analytics',
          params: { accountId: this.getPinterestBoards[0].board_id },
        }
      }
      return payload
    },
  },
  watch: {
    'getActiveWorkspace._id': async function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchSocialService({ value: true })
        console.log('this.fetchSocialAccounts()')
        await this.fetchSocialAccounts()
        this.fetchSocialService({ value: false })
        console.log(this.getFetchSocialStatus)
      }
    },
  },
  created() {
    // redirect user ro planner if user role is approver in that workspace
    // let activeWorkspaceMember = this.getLoggedUserRole(this.getWorkspaces.activeWorkspace)
    // if (activeWorkspaceMember && activeWorkspaceMember.role === 'approver') {
    //   this.$router.push({ name: 'feed_view', params: { workspace: this.getWorkspaces.activeWorkspace.slug } })
    // }
  },
  async mounted() {
    if (this.$route.name !== 'analytics_pdf_report') {
    this.fetchSocialService({ value: true })
    console.log('this.fetchSocialAccounts()')
    await this.fetchSocialAccounts()
    this.fetchSocialService({ value: false })
    console.log(this.getFetchSocialStatus)
    }
  },
  methods: {
    ...mapActions(['fetchSocialService', 'fetchSocialAccounts']),
    setLoader() {
      this.showLoader = true
      this.$router.push({ name: 'reportsettings' })
    },
  },
}
</script>

<style scoped>
.no-padding {
  padding: 0 !important;
}
.lock-margin {
  margin-left: 0.5rem !important;
}
.setting-margin {
  margin: auto 0 auto auto;
  font-size: 1rem;
  cursor: pointer;
  color: #9da6ac;
}
</style>
