<script setup>
import { computed, watch } from 'vue'
import debounce from 'lodash.debounce'
import CardsComponent from '@src/modules/analytics/views/linkedin_v2/components/CardsComponent.vue'
import TopPosts from '@src/modules/analytics/views/linkedin_v2/components/TopPosts.vue'
import AudienceGrowthChart from '@src/modules/analytics/views/linkedin_v2/components/graphs/AudienceGrowthChart.vue'
import PostDensityByDay from '@src/modules/analytics/views/linkedin_v2/components/graphs/PostDensityByDay.vue'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import FollowersDemographicsChart from '@src/modules/analytics/views/linkedin_v2/components/graphs/FollowersDemographics.vue'
import RollupCard from '@/src/modules/analytics/views/linkedin_v2/components/RollupCard.vue'
import PublishingBehaviourChart from '@/src/modules/analytics/views/linkedin_v2/components/graphs/PublishingBehaviourChart.vue'
import PublishingBehaviourBreakdownTable from '@/src/modules/analytics/views/linkedin_v2/components/PublishingBehaviourBreakdownTable.vue'
import HashtagsChart from '@/src/modules/analytics/views/linkedin_v2/components/graphs/HashtagsChart.vue'

const {
  routes,
  dateRange,
  selectedAccount,
  overviewPublishingBehaviourData,
  isLoadingStates,
  isReportView,
  rollupTypes,
  fetchMedia,
} = useLinkedinAnalytics()

watch(
  () => [dateRange.value, selectedAccount.value],
  debounce(async () => {
    fetchMedia(routes.SUMMARY)
    fetchMedia(routes.OVERVIEW_AUDIENCE_GROWTH)
    fetchMedia(routes.OVERVIEW_PUBLISHING_BEHAVIOUR)
    fetchMedia(routes.OVERVIEW_TOP_POSTS, {
      limit: 5,
    })
    fetchMedia(routes.OVERVIEW_HASHTAGS)
    fetchMedia(routes.OVERVIEW_POSTS_PER_DAY)
    fetchMedia(routes.OVERVIEW_FOLLOWERS_DEMOGRAPHICS)
  }, 500),
  { deep: true, immediate: true }
)

const breakdownTableData = computed(() => {
  return (
    overviewPublishingBehaviourData.value?.publishing_behaviour_rollup
      ?.current || []
  )
})
</script>

<template>
  <div class="flex flex-col">
    <!-- Cards -->
    <div class="grid grid-cols-6 lg:grid-cols-8 2xl:grid-cols-6 gap-4 mb-4">
      <CardsComponent />
    </div>
    <div class="flex flex-col gap-y-4">
      <!-- Audience Growth Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <AudienceGrowthChart />
        </div>
        <RollupCard :type="rollupTypes.AUDIENCE" />
      </div>
      <!-- Publishing Behaviour Chart -->
      <PublishingBehaviourChart />
      <!-- Publishing Behaviour Breakdown Table -->
      <PublishingBehaviourBreakdownTable
        :data-list="breakdownTableData"
        :is-loading="isLoadingStates[routes.OVERVIEW_PUBLISHING_BEHAVIOUR]"
        :is-report-view="isReportView"
      />

      <!-- Hastags Chart -->
      <div class="grid grid-cols-3 desktop:grid-cols-4 gap-4">
        <div class="col-span-2 desktop:col-span-3">
          <HashtagsChart />
        </div>
        <RollupCard :type="rollupTypes.HASHTAGS" />
      </div>
      <div class="grid grid-cols-1 desktop:grid-cols-2 gap-4">
        <!-- Post Density By Day -->
        <PostDensityByDay />
        <!-- Followers Demographics -->
        <FollowersDemographicsChart />
      </div>
      <!-- Top Posts -->
      <TopPosts />
    </div>
  </div>
</template>

<style scoped></style>
