<script setup>
import {
  blogIconMap,
  getBlogIconStyle,
  getIconStyle,
  socialIconMap,
} from '@modules/billing/constants/plansComparison'
import {planTypesWithoutSuffix} from '@modules/billing/constants/plansDetails'
import SocialNetworks from './SocialNetworks.vue'
import BlogNetworks from './BlogNetworks.vue'

defineProps({
  feature: {
    type: Object,
    required: true,
  },
})
</script>

<template>
  <div class="col-span-2 flex items-center gap-2">
    {{ feature.name }}
    <i
      v-if="feature.nameTooltip"
      v-tooltip="feature.nameTooltip"
      class="far fa-info-circle text-gray-600"
    ></i>
  </div>

  <div class="col-span-3 grid grid-cols-3 gap-4">
    <div v-for="planType in planTypesWithoutSuffix" :key="planType" class="text-center">
      <template v-if="feature.name === 'Social networks'">
        <SocialNetworks
          :get-icon-style="getIconStyle"
          :networks="feature.socialNetworks"
          :social-icon-map="socialIconMap"
        />
      </template>
      <template v-else-if="feature.name === 'Blog networks'">
        <BlogNetworks
          :blog-icon-map="blogIconMap"
          :get-blog-icon-style="getBlogIconStyle"
          :networks="feature.blogNetworks"
          :show-networks="feature[planType].value !== false"
        />
      </template>
      <template v-else>
        <span class="flex items-center justify-center">
          <template v-if="typeof feature[planType].value === 'boolean'">
            <i
              v-if="feature[planType].value"
              class="fas fa-check text-green-500"
            ></i>
            <i v-else class="fas fa-times text-red-500"></i>
          </template>
          <template v-else>
            {{ feature[planType].value }}
          </template>
          <i
            v-if="feature[planType].tooltip"
            v-tooltip="feature[planType].tooltip"
            class="far fa-info-circle text-gray-600 ml-1.5"
          ></i>
        </span>
      </template>
    </div>
  </div>
</template>
