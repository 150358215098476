<script setup>
import { computed, defineEmits } from 'vue'
import AnalyticsFilterBarWrapper from '@src/modules/analytics/views/common/AnalyticsFilterBarWrapper.vue'
import useLinkedinAnalytics from '@src/modules/analytics/views/linkedin_v2/composables/useLinkedinAnalytics.js'
import PlatformAccountSelect from '@src/modules/analytics/views/common/PlatformAccountSelect.vue'

const emit = defineEmits(['scroll-to-top'])
const { dateRange, selectedAccount } = useLinkedinAnalytics()

const disabled = computed(() => {
  return false
})

const handleDatechange = ({ dateRange }) => {
  setDateRange(dateRange)
}
const setDateRange = (newDate) => {
  dateRange.value = newDate.split(' - ')
}
const onchange = (account) => {
  console.log(account)
  selectedAccount.value = account
  emit('scroll-to-top')
}
</script>

<template>
  <AnalyticsFilterBarWrapper
    type="linkedin"
    :selected-accounts="selectedAccount"
    :disabled="disabled"
    @date-change="handleDatechange"
  >
    <template v-slot:left>
      <PlatformAccountSelect type="linkedin" @on-account-change="onchange" />
    </template>
  </AnalyticsFilterBarWrapper>
</template>
