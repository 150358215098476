// src/modules/billing/constants/featureMessages.js
import { ALL_FEATURES } from '@modules/billing/constants/featureList'

const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))} ${sizes[i]}`
}

export const FEATURE_MESSAGES = {
  features: {
    // Basic Publishing Features
    publishing_scheduling:
      'Publishing and scheduling is available in all paid plans',
    visual_calendar: 'Visual content calendar is available in all paid plans',
    auto_first_comment:
      'Auto first comment feature is available in all paid plans',
    twitter_threads: 'Twitter threads feature is available in all paid plans',
    reels_shorts_scheduling:
      'Reels and shorts scheduling is available in all paid plans',
    carousel_posts_scheduling:
      'Carousel posts scheduling is available in all paid plans',

    // Analytics Features
    social_media_analytics:
      'Social media analytics is available in Advanced and Agency Unlimited plans only',
    competitor_analytics:
      'Competitor analytics is available in Advanced and Agency Unlimited plans only',
    campaign_label_analytics:
      'Campaign and label analytics is available in Advanced and Agency Unlimited plans only',
    exports_schedule_reports:
      'Report exports and scheduling is available in Advanced and Agency Unlimited plans only',

    // AI Features
    ai_content_creation: 'AI content creation is available in all paid plans',
    media_assets_library:
      'Media and assets library is available in all paid plans',

    // Advanced Publishing Features
    publishing_queues:
      'Publishing queues are available in Advanced and Agency Unlimited plans only',
    evergreen_recycling:
      'Evergreen recycling is available in Advanced and Agency Unlimited plans only',
    bulk_upload_csv:
      'Bulk upload via CSV is available in Advanced and Agency Unlimited plans only',
    rss_scheduling:
      'RSS feed scheduling is available in Advanced and Agency Unlimited plans only',
    grid_view_planning:
      'Grid view planning is available in Advanced and Agency Unlimited plans only',
    blog_publishing:
      'Blog publishing is available in Advanced and Agency Unlimited plans only',

    // Content Discovery Features
    content_discovery:
      'Content discovery is available in Advanced and Agency Unlimited plans only',
    influencer_discovery:
      'Influencer discovery is available in Advanced and Agency Unlimited plans only',

    // Team Features
    team_collaboration:
      'Team collaboration is available in Advanced and Agency Unlimited plans only',
    approval_workflow:
      'Approval workflow is available in Advanced and Agency Unlimited plans only',

    // Integrations
    canva_integration: 'Canva integration is available in all paid plans',
    custom_link_shortener:
      'Custom link shortener is available in all paid plans',
    custom_video_thumbnails:
      'Custom video thumbnails are available in all paid plans',
    feedly_integration:
      'Feedly integration is available in Advanced and Agency Unlimited plans only',
    twitter_custom_app:
      'Twitter custom app integration is available in Advanced and Agency Unlimited plans only',

    // Communication Features
    social_inbox:
      'Social inbox is available in Advanced and Agency Unlimited plans only',

    // Agency-Exclusive Features
    white_label_reports:
      'White label reports are exclusive to Agency plan',
    easy_connect: 'EasyConnect is exclusive to Agency plan',
    complete_client_management:
      'Complete client management is exclusive to Agency plan',

    // Support Features
    dedicated_onboarding:
      'Dedicated onboarding is exclusive to Agency plan',
    concierge_setup: 'Concierge setup is exclusive to Agency plan',
    priority_support: 'Priority support is exclusive to Agency plan',
    live_training: 'Live training is exclusive to Agency plan',


    article_automation: 'Article automation is available in Advanced and Agency Unlimited plans only',
    video_automation: 'Video automation is available in Advanced and Agency Unlimited plans only',
    shareable_link: 'Shareable post link feature is available in all paid plans',
    insta_collab_post: 'Instagram collaboration posts feature is available in all paid plans',
    twitter_cs_app: 'ContentStudio Twitter app integration is available in all paid plans',
    white_label_addon: 'White label addon is exclusive to Agency plan',
    inbox: 'Inbox feature is available in Advanced and Agency Unlimited plans only',
  },

  limits: {
    workspaces: (limit) =>
      `Your plan allows up to ${limit} workspace${
        limit !== 1 ? 's' : ''
      }. Upgrade to add more workspaces`,
    profiles: (limit) =>
      `You can connect up to ${limit} social profile${
        limit !== 1 ? 's' : ''
      } in your current plan`,
    members: (limit) =>
      `Your plan allows up to ${limit} team member${
        limit !== 1 ? 's' : ''
      }. Upgrade to add more members`,
    posts_per_month: (limit) =>
      limit === 0
        ? 'Unlimited posts available'
        : `You can publish up to ${limit} posts per month`,
    media_storage: (limit) =>
      `Storage limit of ${formatBytes(limit)}. Upgrade for more storage`,
    blogs: (limit) =>
      `You can connect up to ${limit} blog${
        limit !== 1 ? 's' : ''
      } in your current plan`,
    caption_generation_credit: (limit) =>
      `Your plan includes ${limit} AI caption generations per month`,
    image_generation_credit: (limit) =>
      `Your plan includes ${limit} AI image generations per month`,
    automations: (limit) =>
      `You can create up to ${limit} automation${
        limit !== 1 ? 's' : ''
      } in your current plan`,
  },
}

const EXPECTED_LIMITS = [
  'workspaces',
  'profiles',
  'members',
  'posts_per_month',
  'media_storage',
  'blogs',
  'caption_generation_credit',
  'image_generation_credit',
  'automations',
]

const validateMessages = () => {
  const missingFeatures = ALL_FEATURES.filter(
    (feature) => !FEATURE_MESSAGES.features[feature]
  )
  const missingLimits = EXPECTED_LIMITS.filter(
    (limit) => !FEATURE_MESSAGES.limits[limit]
  )

  if (missingFeatures.length > 0) {
    console.warn(`Missing feature messages for: ${missingFeatures.join(', ')}`)
  }

  if (missingLimits.length > 0) {
    console.warn(`Missing limit messages for: ${missingLimits.join(', ')}`)
  }
}

// Run validation in development mode only
if (process.env.NODE_ENV === 'development') {
  validateMessages()
}

export { validateMessages }
